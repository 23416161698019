import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import {
  getIsInTrial,
  getIsCanceled,
  getNeedsToConfirmTerms,
} from "../store/users/selectors";
import { Content, NotificationElement } from "../components/Elements";
import Dashboard from "../pages/Dashboard";
import Contractors from "../pages/Contractors";
import StandaloneContractors from "../pages/StandaloneContractors";
import Contractor from "../pages/Contractor";
import Settings from "../pages/Settings";
import Campaigns from "../pages/Campaigns";
import EditCampaign from "../pages/EditCampaign";
import CreateAssessment from "../pages/CreateAssessment";
import Roles from "../pages/Roles";
import ManualReviews from "../pages/ManualReviews";
import Reporting from "../pages/Reporting";
import Header from "../components/Header";
import { Empty } from "../components/Elements";
import cancelled from "../assets/images/cancelled.svg";
import Modal from "react-modal";
import TermsAccept from "../components/TermsAccept";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "#fff",
    width: "900px",
    height: "70%",
  },
};

function LoggedIn() {
  const isInTrial = useSelector(getIsInTrial);
  const isCanceled = useSelector(getIsCanceled);
  const needsToConfirmTerms = useSelector(getNeedsToConfirmTerms);

  return (
    <div>
      <Header showMenu={true} />
      {isInTrial && (
        <NotificationElement>
          <span>You are currently in a free trial</span>
        </NotificationElement>
      )}

      <Modal
        isOpen={needsToConfirmTerms === true}
        onRequestClose={() => false}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <TermsAccept />
      </Modal>
      <Content hasNotification={isInTrial} isGlobal>
        {isCanceled ? (
          <Empty>
            <br />
            <br />
            <img src={cancelled} />
            <h2>No subscription</h2>
            <p>
              You've cancelled your subscription, to re-enable it please contact
              us
            </p>
          </Empty>
        ) : (
          <Switch>
            <Route path="/main/dashboard" component={Dashboard} />
            <Route path="/main/settings" component={Settings} />
            <Route path="/main/contractors" exact component={Contractors} />
            <Route
              path="/main/contractors/:page"
              exact
              component={Contractors}
            />
            <Route
              path="/main/standalone"
              exact
              component={StandaloneContractors}
            />
            <Route
              path="/main/standalone/:page"
              exact
              component={StandaloneContractors}
            />
            <Route
              path="/main/contractors/edit/:contractorId"
              component={Contractor}
            />
            <Route
              path="/main/contractors/assessment/:companyId/:contractorId/:mode"
              component={CreateAssessment}
            />
            <Route
              path="/main/roles/assessment/:companyId/:contractorId/:mode"
              component={CreateAssessment}
            />
            <Route
              path="/main/manual-reviews/assessment/:companyId/:contractorId/:mode"
              component={CreateAssessment}
            />
            <Route
              path="/main/manual-reviews/role/:companyId/:contractorId/:mode"
              component={CreateAssessment}
            />
            <Route path="/main/roles" component={Roles} />
            <Route path="/main/reporting" component={Reporting} />
            <Route path="/main/manual-reviews" component={ManualReviews} />
            <Route path="/main/campaigns" component={Campaigns} exact />
            <Route path="/main/campaigns/:id" component={EditCampaign} exact />
          </Switch>
        )}
      </Content>
    </div>
  );
}

export default LoggedIn;
