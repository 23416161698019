import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { device } from "./Devices";
import { getDisputeLoading } from "../store/sds/selectors";
import { openDispute } from "../store/sds/actions";
import CRUDForm from "./CRUDForm";
import Form from "./Form";
import FormField from "./FormField";
import FormRow from "./FormRow";

import Button from "./Button";

const Centered = styled.div`
  text-align: center;
`;

const FormWrapper = styled.div`
  width: 600px;

  // @media ${device.laptop} {
  //   padding: 30px 20px;
  //   ${(props) => !props.wrapped && "margin: 0px 30px 0px 0px;"}
  // }
`;

function OpenDispute({ onClose = () => {}, publicId }) {
  const loading = useSelector(getDisputeLoading);

  const dispatch = useDispatch();

  const emptyState = {
    reason: "",
  };

  const [formData, setFormData] = useState(emptyState);

  const cols = 1;

  const sendForm = async (formData) => {
    const { reason } = formData;

    const response = await dispatch(openDispute(publicId, reason));

    if (response && response.complete) {
      onClose();
    }
  };

  const cancel = async (e) => {
    e.preventDefault();

    onClose();
  };

  return (
    <FormWrapper>
      <CRUDForm
        emptyState={emptyState}
        formData={formData}
        loading={loading}
        recordId="new"
        onSubmit={sendForm}
      >
        <Form internal={true}>
          <h3>Open Dispute</h3>
          <FormRow cols={cols}>
            <FormField
              name="reason"
              label="Reason for appeal"
              helpText="Please provide as much detail as possible"
              inputType="textarea"
              height={200}
            />
          </FormRow>

          <Centered>
            <Button
              noFullWidth={true}
              noBind
              onClick={cancel}
              type="distribute"
            >
              Cancel
            </Button>
            <Button noFullWidth={true} loading={loading} type="appeal">
              Submit Dispute
            </Button>
          </Centered>
        </Form>
      </CRUDForm>
    </FormWrapper>
  );
}

export default OpenDispute;
