import DataTable from "react-data-table-component";
import { mainColor } from "../styles/colors";

import styled from "styled-components";

export const StyledDataTable = styled(DataTable)`
  header {
    display: none;
  }

  .rdt_TableHead {
    border-bottom: 1px solid ${mainColor};
  }

  .rdt_ExpanderRow {
    background-color: #fff;
  }
`;

export const darkTheme = {
  title: {
    height: 0,
    overflow: "hidden",
    padding: "0px",
    fontSize: "1.5em",
    fontWeight: "bold",
  },
  contextMenu: {
    backgroundColor: "#E91E63",
    fontColor: "#FFFFFF",
  },
  header: {
    fontSize: "14px",
    textTransform: "none",
    fontColorActive: "000",
    fontColor: "#444",
    fontWeight: "bold",
    backgroundColor: "transparent",
  },
  rows: {
    fontSize: "16px",
    fontColor: "#000",
    backgroundColor: "transparent",
    borderColor: "#ddd",
    hoverFontColor: "black",
    hoverBackgroundColor: "#eee",
  },
  cells: {
    cellPadding: "48px",
  },
  pagination: {
    fontColor: "#888",
    fontWeight: "bold",
    backgroundColor: "transparent",
    buttonFontColor: "#888",
    buttonHoverBackground: "rgba(255, 255, 255, .12)",
  },
  expander: {
    fontColor: "#000",
    backgroundColor: "#363640",
    expanderColor: "#000",
  },
  expanderRow: {
    backgroundColor: "#fff",
  },
};
