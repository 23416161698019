import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getTagLoading } from "../store/tags/selectors";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Loader from "react-loader-spinner";

export const colourOptions = {
  RED: {
    bg: "#F1433F",
    color: "#fff",
  },
  BLUE: {
    bg: "#70B7BA",
    color: "#fff",
  },
  GREEN: {
    bg: "#A9CF54",
    color: "#fff",
  },
  YELLOW: {
    bg: "#F7E967",
    color: "#000",
  },
  GREY: {
    bg: "#3D4C53",
    color: "#fff",
  },
};

const TagElement = styled.div`
  display: inline-block;
  margin-right: 6px;
  margin-bottom: 6px;
  border-radius: 3px;
  padding: 4px 6px;
  text-transform: uppercase;
  font-size: 12px;
  cursor: pointer;
  &.red {
    background-color: ${colourOptions.RED.bg};
    color: ${colourOptions.RED.color};
  }
  &.blue {
    background-color: ${colourOptions.BLUE.bg};
    color: ${colourOptions.BLUE.color};
  }
  &.green {
    background-color: ${colourOptions.GREEN.bg};
    color: ${colourOptions.GREEN.color};
  }
  &.yellow {
    background-color: ${colourOptions.YELLOW.bg};
    color: ${colourOptions.YELLOW.color};
  }
  &.grey {
    background-color: ${colourOptions.GREY.bg};
    color: ${colourOptions.GREY.color};
  }

  &:hover {
    opacity: 0.8;
  }

  .loader {
    display: inline-block;
    margin-right: 5px;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: 6px;
`;

function Tag({ id, text, color, removable, onRemove, onClick, selected }) {
  const wrappedOnRemove = (e) => {
    e.stopPropagation();
    if (onRemove) {
      onRemove({ id, text });
    }
  };

  const wrappedOnClick = (e) => {
    e.stopPropagation();
    if (onClick) {
      onClick({ id, text, color });
    }
  };

  const loading = useSelector((state) => getTagLoading(id, state));

  return (
    <TagElement className={color} onClick={wrappedOnClick}>
      {loading && (
        <Loader
          type="TailSpin"
          color="#000"
          height="12"
          width="12"
          className="loader"
        />
      )}
      {text}
      {removable && (
        <StyledFontAwesomeIcon icon={faTimes} onClick={wrappedOnRemove} />
      )}
      {selected && <StyledFontAwesomeIcon icon={faCheck} />}
    </TagElement>
  );
}

export default Tag;
