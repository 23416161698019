class FormValidation {
  cannedValidators = {
    email: {
      fn: (value) => {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(value).toLowerCase());
      },
      msg: "Email address is not valid",
    },
    password: {
      fn: (value) => {
        // return new RegExp(
        //   // "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
        //   "/^(?=.*d)(?=.*[a-z])(?=.*[A-Z])(?=.[W]).{8,}$/"
        // ).test(value);

        var minMaxLength = /^[\s\S]{8,32}$/,
          upper = /[A-Z]/,
          lower = /[a-z]/,
          number = /[0-9]/,
          special = /[ !"#$%&'()*+,\-./:;<=>?@[\\\]^_`{|}~]/;

        if (
          minMaxLength.test(value) &&
          upper.test(value) &&
          lower.test(value) &&
          number.test(value) &&
          special.test(value)
        ) {
          return true;
        }

        return false;
      },
      msg:
        "Must be at least 8 characters, contain a number, lowercase letter, uppercase letter and special character",
    },
  };

  constructor(validationErrors, validationSetter) {
    this.validationErrors = validationErrors;
    this.validationSetter = validationSetter;
    this.extraRules = {};
    this.monitors = [];
  }

  setRules(rules) {
    this.extraRules = rules;
  }

  monitorFields(fields) {
    this.monitors = fields;
  }

  validate(field, value, allValues, update = true) {
    let errors = [];

    if (this.monitors.indexOf(field) > -1) {
      let customRules = this.extraRules[field];
      let excludeDefault =
        customRules && customRules.find((custom) => custom.excludeDefault);

      if (!value && !excludeDefault) {
        errors.push("Field is required");
      } else {
        if (customRules) {
          customRules.forEach((rule) => {
            if (rule.canned) {
              rule = this.cannedValidators[rule.canned];
            }

            if (!rule.fn(value, allValues)) {
              errors.push(rule.msg);
            }
          });
        }
      }
    }

    if (errors.length) {
      if (update) {
        this.validationSetter({ ...this.validationErrors, [field]: errors });
      } else {
        return errors;
      }
    } else {
      if (update) {
        this.validationSetter({ ...this.validationErrors, [field]: [] });
      } else {
        return [];
      }
    }
  }

  validateForm(formData) {
    let newValidation = {};
    let hasErrors = false;
    if (!this.monitors.length) {
      this.monitors = Object.keys(formData);
    }
    this.monitors.forEach((key) => {
      let result = this.validate(key, formData[key], formData, false);
      if (result.length) {
        hasErrors = true;
      }
      newValidation[key] = result;
    });
    this.validationSetter(newValidation);
    return hasErrors ? newValidation : false;
  }
}

export default FormValidation;
