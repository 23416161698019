import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Comments from "../Comments";
import { device } from "../Devices";
import Answer from "./Answer";
import { getAnswers } from "../../store/assessment/selectors";
import Questions from "./Questions";

const AnswerArea = styled.div`
  display: flex;
  margin-left: 40px;
  margin-bottom: 20px;

  flex-direction: column;

  @media ${device.laptop} {
    margin-bottom: 0px;
    ${(props) =>
      props.child ? "flex-direction: column;" : "flex-direction: row"}
  }

  & > div {
    margin-bottom: 10px;
    @media ${device.laptop} {
      ${(props) => (props.child ? "margin-bottom: 10px;" : "")}
    }
  }
`;

const ChildAnswers = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  padding-left: 40px;
`;

function Answers({
  answers,
  child = false,
  target,
  isReviewer,
  questionId,
  reviewId,
  assessmentResult,
  dissemination,
  view,
  additionalQuestions,
  questionKey,
}) {
  const currentAnswers = useSelector(getAnswers);
  const currentAnswer = answers.find((answer) =>
    currentAnswers.find((current) => current === answer.id)
  );

  return (
    <div>
      <AnswerArea child={child}>
        {answers.map((answer) => (
          <Answer answer={answer} isReviewer={isReviewer || view} />
        ))}
      </AnswerArea>
      {assessmentResult && (
        <Comments questionId={questionId} reviewId={reviewId} readonly={view} />
      )}
      {answers.map((answer) => {
        return (
          <div>
            {currentAnswers &&
            answer.questions.length &&
            currentAnswers.find(
              (currentAnswer) => currentAnswer === answer.id
            ) ? (
              <ChildAnswers>
                <Questions
                  dissemination={dissemination}
                  questions={answer.questions}
                  target={target}
                  isReviewer={isReviewer}
                  reviewId={reviewId}
                  view={view}
                />
              </ChildAnswers>
            ) : null}
          </div>
        );
      })}
      {additionalQuestions &&
        additionalQuestions.getAdditionalQuestion(questionKey, currentAnswer)}
    </div>
  );
}

export default Answers;
