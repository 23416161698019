import React, { useState, useContext } from "react";
import { ColorContext } from "./Bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { confirmAlert } from "react-confirm-alert";
import { logout } from "../store/users/actions";
import { device } from "./Devices";
import { mainColor, lightColor } from "../styles/colors";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTasks,
  faUserFriends,
  faTools,
  faClone,
  faPortrait,
  faSignOutAlt,
  faEye,
  faCloudDownloadAlt,
  faPercentage,
} from "@fortawesome/free-solid-svg-icons";
import {
  getIsWhiteLabel,
  getCompanyLogo,
  getIsStandaloneCompany,
  getHasStandaloneRole,
  getUsingCredits,
  getHasFeature,
  getHasContractorRole,
  getUser,
  getLogoSpace,
  getCanViewSettings,
  getPoweredByLogo,
  getHasManualReviewerRole,
  getRealm,
  getHasCampaignRole,
  getHasCSTRole,
} from "../store/users/selectors";

const HeaderElement = styled.div`
  ${(props) =>
    props.whiteLabel
      ? "background-color: #fff; border-bottom: 1px solid #ccc;"
      : `background-color: ${mainColor};`}

  color: #fff;
  font-family: Blinker;
  top: 0;
  left: 0;

  box-sizing: border-box;
  justify-content: space-between;
  width: 100%;
  height: 75px;
  line-height: 75px;
  padding: 0px 20px;
  width: 100%;

  display: flex;
  position: fixed;

  z-index: 100000;
`;

const Logo = styled.div`
  flex-basis: 100px;
  font-size: 22px;
  font-weight: 700;
`;

const CompanyLogo = styled.div`
  margin-right: 20px;

  img {
    ${(props) => (props.logoSpace ? "margin-top: 20px" : "margin-top: 0px;")}
  }
`;

const PoweredBy = styled.div`
  font-size: 16px;
  font-weight: 700;
  color: #ccc;

  text-align: right;
  display: flex;
  flex-direction: column;

  p {
    line-height: 16px;
    margin-bottom: 5px;
    margin-top: 14px;
    font-size: 14px;
  }
`;

const Menu = styled.div`
  display: none;
  @media ${device.laptop} {
    display: block;
  }

  flex-grow: 1;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;

      a {
        cursor: pointer;
        display: block;
        position: relative;
        ${(props) => (props.whiteLabel ? "color: #000;" : `color: #fff;`)}
        text-decoration: none;
        opacity: 0.6;
        font-weight: 600;
        height: 70px;
        line-height: 65px;
        padding: 0px 20px;
        transition: opacity 200ms ease-in;

        &:hover {
          opacity: 1;
        }

        &.active {
          opacity: 1;
        }

        &:after {
          opacity: 0;
          content: "";
          width: 100%;
          height: 5px;

          ${(props) =>
            props.whiteLabel
              ? `background-color: #bbb`
              : `background-color: ${lightColor};`}
          bottom: 0px;
          left: 0px;
          position: absolute;
          transition: opacity 0.2s ease-in 0s;
        }

        &.active:after {
          opacity: 1;
        }
      }
    }
  }
`;

const SubMenu = styled.div`
  background-color: #ddd;
  padding: 10px;
  text-align: center;
  color: #000;
  position: fixed;
  top: 50px;
  width: 100%;
  z-index: 1000;

  @media ${device.laptop} {
    display: none;
  }

  .menu-content {
    ul {
      padding: 0;
      margin: 0;
      width: 300px;
      margin: auto;
      list-style: none;

      li {
        margin-top: 10px;
        margin-bottom: 10px;
        padding-bottom: 10px;
        border-bottom: 1px solid #888;
      }

      a {
        text-decoration: none;
        color: #000;
        font-weight: bold;
      }
    }
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  ${(props) => `color: ${props.mainColor}`}
`;

const StyledReactTooltip = styled(ReactTooltip)`
  &.type-dark.place-bottom {
    font-size: 14px;
    padding: 20px;
    line-height: 18px;
    max-width: 200px;
  }
`;

const MenuLink = styled(NavLink)``;

function Header({ showMenu }) {
  const dispatch = useDispatch();
  const isWhiteLabel = useSelector(getIsWhiteLabel);
  const companyLogo = useSelector(getCompanyLogo);
  const isStandalone = useSelector(getIsStandaloneCompany);
  const hasStandaloneRole = useSelector(getHasStandaloneRole);
  const logoSpace = useSelector(getLogoSpace);
  const mainColor = useContext(ColorContext);
  const canViewSettings = useSelector(getCanViewSettings);
  const isCampaign = useSelector(getHasCampaignRole);
  const hasRoleBasedFeature = useSelector((state) =>
    getHasFeature(state, "feature_role_based")
  );
  const hasContractorRole = useSelector(getHasContractorRole);
  const poweredLogo = useSelector(getPoweredByLogo);
  const user = useSelector(getUser);
  const [menuShown, setMenuShown] = useState(false);
  const hasManualReviewerRole = useSelector(getHasManualReviewerRole);

  const realm = useSelector(getRealm);

  const isKingsbridge = realm === "ir35/kingsbridge";

  const hasCSTRole = useSelector(getHasCSTRole);

  const confirmLogout = () => {
    const options = {
      title: "Are you sure?",
      message: "Are you sure you want to logout?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            dispatch(logout());
          },
        },
        {
          label: "No",
        },
      ],
    };
    confirmAlert(options);
  };

  const generateMenu = () => {
    return (
      <ul>
        {!hasContractorRole && !hasCSTRole && (
          <>
            <li>
              <MenuLink
                to="/main/dashboard"
                data-tip="Track the progress of each assessment"
              >
                <StyledFontAwesomeIcon icon={faTasks} mainColor={mainColor} />{" "}
                Progress
              </MenuLink>
            </li>
            <li>
              <MenuLink
                to="/main/contractors"
                data-tip="Manage individual contractors and their assessments"
              >
                <StyledFontAwesomeIcon
                  icon={faUserFriends}
                  mainColor={mainColor}
                />
                Contractors
              </MenuLink>
            </li>
            {hasRoleBasedFeature && (
              <li>
                <MenuLink
                  to="/main/roles"
                  data-tip="Create role based assessments as the basis for full assessments"
                >
                  {" "}
                  <StyledFontAwesomeIcon icon={faClone} mainColor={mainColor} />
                  Roles
                </MenuLink>
              </li>
            )}
            {isStandalone && hasStandaloneRole && (
              <li>
                <MenuLink
                  to="/main/standalone"
                  data-tip="Contractor only assessments"
                >
                  {" "}
                  <StyledFontAwesomeIcon
                    icon={faPortrait}
                    mainColor={mainColor}
                  />
                  One-off Assessments
                </MenuLink>
              </li>
            )}
            {hasManualReviewerRole && (
              <li>
                <MenuLink
                  to={"/main/manual-reviews"}
                  data-tip="Carry out manual reviews for clients"
                >
                  {" "}
                  <StyledFontAwesomeIcon icon={faEye} mainColor={mainColor} />
                  Manual Reviews
                </MenuLink>
              </li>
            )}
            {canViewSettings && (
              <li>
                <MenuLink
                  to={"/main/reporting"}
                  data-tip="Search and export your data & manage tags"
                >
                  {" "}
                  <StyledFontAwesomeIcon
                    icon={faCloudDownloadAlt}
                    mainColor={mainColor}
                  />
                  Reporting
                </MenuLink>
              </li>
            )}
            {isKingsbridge && isCampaign && (
              <li>
                <MenuLink
                  to={"/main/campaigns"}
                  data-tip="Manage campaigns for promotions and refer a friend"
                >
                  {" "}
                  <StyledFontAwesomeIcon
                    icon={faPercentage}
                    mainColor={mainColor}
                  />
                  Campaigns
                </MenuLink>
              </li>
            )}
            {canViewSettings && (
              <li>
                <MenuLink
                  to={"/main/settings"}
                  data-tip="Manage your users and clients"
                >
                  {" "}
                  <StyledFontAwesomeIcon icon={faTools} mainColor={mainColor} />
                  Settings
                </MenuLink>
              </li>
            )}
          </>
        )}

        {hasContractorRole && (
          <>
            <li>
              <MenuLink to="/contractor-assessment-list">
                My Assessments
              </MenuLink>
            </li>
            <li>
              <MenuLink to="/contractor-assessment/1">New Assessment</MenuLink>
            </li>
            {realm === "ir35/kingsbridge" && (
              <li>
                <MenuLink to="/refer-a-friend">Refer a friend</MenuLink>
              </li>
            )}
          </>
        )}

        <li>
          <a onClick={confirmLogout}>
            {" "}
            <StyledFontAwesomeIcon icon={faSignOutAlt} mainColor={mainColor} />
            Logout
          </a>
        </li>
      </ul>
    );
  };

  return (
    <>
      <HeaderElement whiteLabel={isWhiteLabel}>
        {!isWhiteLabel ? (
          <Logo>IR35.io</Logo>
        ) : (
          <CompanyLogo logoSpace={logoSpace}>
            <img src={companyLogo} height={logoSpace ? 30 : 75} />
          </CompanyLogo>
        )}

        {showMenu && user ? (
          <Menu whiteLabel={isWhiteLabel}>{generateMenu()}</Menu>
        ) : null}
        {isWhiteLabel ? (
          <PoweredBy>
            <p>Powered by</p>
            <img src={poweredLogo} height={20} />
          </PoweredBy>
        ) : null}
        <StyledReactTooltip place="bottom" effect="solid" />
      </HeaderElement>
      {showMenu && user && (
        <SubMenu>
          <div onClick={() => setMenuShown(!menuShown)}>Menu</div>
          {menuShown && (
            <div className="menu-content" onClick={() => setMenuShown(false)}>
              {generateMenu()}
            </div>
          )}
        </SubMenu>
      )}
    </>
  );
}

export default Header;
