import { fetch } from "../fetchMiddleware";

export const LOAD_TAGS_REQUEST = "LOAD_TAGS_REQUEST";
export const LOAD_TAGS_SUCCESS = "LOAD_TAGS_SUCCESS";
export const LOAD_TAGS_FAILURE = "LOAD_TAGS_FAILURE";

export const loadTagsForCompany = (companyId) => async (dispatch) => {
  dispatch({ type: LOAD_TAGS_REQUEST });

  try {
    const where = `?filter=${encodeURIComponent(
      `{"where": {"company_id": ${companyId}}}`
    )}`;

    const { data: response } = await dispatch(
      fetch(`/Tags/${where}`, {
        method: "GET",
      })
    );

    await dispatch({
      type: LOAD_TAGS_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: LOAD_TAGS_FAILURE,
      payload: error,
    });
  }
};

export const CREATE_TAG_REQUEST = "CREATE_TAG_REQUEST";
export const CREATE_TAG_SUCCESS = "CREATE_TAG_SUCCESS";
export const CREATE_TAG_FAILURE = "CREATE_TAG_FAILURE";

export const createTag = (tag) => async (dispatch) => {
  dispatch({ type: CREATE_TAG_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Tags`, {
        method: "POST",
        data: tag,
      })
    );
    await dispatch({
      type: CREATE_TAG_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: CREATE_TAG_FAILURE,
      payload: error,
    });
  }
};

export const REMOVE_TAG_REQUEST = "REMOVE_TAG_REQUEST";
export const REMOVE_TAG_SUCCESS = "REMOVE_TAG_SUCCESS";
export const REMOVE_TAG_FAILURE = "REMOVE_TAG_FAILURE";

export const removeTag = (tag) => async (dispatch) => {
  dispatch({ type: REMOVE_TAG_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Tags/${tag.id}`, {
        method: "DELETE",
      })
    );

    await dispatch({
      type: REMOVE_TAG_SUCCESS,
      payload: tag,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: REMOVE_TAG_FAILURE,
      payload: error,
    });
  }
};

export const EDIT_TAG_REQUEST = "EDIT_TAG_REQUEST";
export const EDIT_TAG_SUCCESS = "EDIT_TAG_SUCCESS";
export const EDIT_TAG_FAILURE = "EDIT_TAG_FAILURE";

export const editTag = (tag) => async (dispatch) => {
  dispatch({ type: EDIT_TAG_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Tags/${tag.id}`, {
        method: "PATCH",
        data: tag,
      })
    );

    await dispatch({
      type: EDIT_TAG_SUCCESS,
      payload: tag,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: EDIT_TAG_FAILURE,
      payload: error,
    });
  }
};

export const LINK_TAG_REQUEST = "LINK_TAG_REQUEST";
export const LINK_TAG_SUCCESS = "LINK_TAG_SUCCESS";
export const LINK_TAG_FAILURE = "LINK_TAG_FAILURE";

export const linkTag = (tagId, entityId, entityType) => async (dispatch) => {
  dispatch({ type: LINK_TAG_REQUEST, tagId });

  try {
    const { data: response } = await dispatch(
      fetch(`/Tags/link`, {
        method: "POST",
        data: {
          tagId,
          entityId,
          entityType,
        },
      })
    );

    await dispatch({
      type: LINK_TAG_SUCCESS,
      tagId,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: LINK_TAG_FAILURE,
      tagId,
      payload: error,
    });
  }
};

export const UNLINK_TAG_REQUEST = "UNLINK_TAG_REQUEST";
export const UNLINK_TAG_SUCCESS = "UNLINK_TAG_SUCCESS";
export const UNLINK_TAG_FAILURE = "UNLINK_TAG_FAILURE";

export const unLinkTag = (tagId, entityId, entityType) => async (dispatch) => {
  dispatch({ type: UNLINK_TAG_REQUEST, tagId });

  try {
    const { data: response } = await dispatch(
      fetch(`/Tags/unlink`, {
        method: "POST",
        data: {
          tagId,
          entityId,
          entityType,
        },
      })
    );

    await dispatch({
      type: UNLINK_TAG_SUCCESS,
      tagId,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: UNLINK_TAG_FAILURE,
      tagId,
      payload: error,
    });
  }
};

export const LOAD_TAGS_FOR_ENTITY_REQUEST = "LOAD_TAGS_FOR_ENTITY_REQUEST";
export const LOAD_TAGS_FOR_ENTITY_SUCCESS = "LOAD_TAGS_FOR_ENTITY_SUCCESS";
export const LOAD_TAGS_FOR_ENTITY_FAILURE = "LOAD_TAGS_FOR_ENTITY_FAILURE";

export const loadTagsForEntity = (entityId, entityType) => async (dispatch) => {
  dispatch({ type: LOAD_TAGS_FOR_ENTITY_REQUEST });

  console.log("Loading for entity", entityId);

  try {
    const { data: response } = await dispatch(
      fetch(`/Tags/tags-for-entity/${entityType}/${entityId}`, {
        method: "GET",
      })
    );

    await dispatch({
      type: LOAD_TAGS_FOR_ENTITY_SUCCESS,
      entityId,
      entityType,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: LOAD_TAGS_FOR_ENTITY_FAILURE,
      payload: error,
    });
  }
};

export const SWAP_TAG_REQUEST = "SWAP_TAG_REQUEST";
export const SWAP_TAG_SUCCESS = "SWAP_TAG_SUCCESS";
export const SWAP_TAG_FAILURE = "SWAP_TAG_FAILURE";

export const swapTag = (from, to) => async (dispatch) => {
  dispatch({ type: SWAP_TAG_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Tags/swap/${from}/${to}`, {
        method: "GET",
      })
    );

    await dispatch({
      type: SWAP_TAG_SUCCESS,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: SWAP_TAG_FAILURE,
      payload: error,
    });
  }
};

export const LOAD_SEARCHES_FOR_COMPANY_REQUEST =
  "LOAD_SEARCHES_FOR_COMPANY_REQUEST";
export const LOAD_SEARCHES_FOR_COMPANY_SUCCESS =
  "LOAD_SEARCHES_FOR_COMPANY_SUCCESS";
export const LOAD_SEARCHES_FOR_COMPANY_FAILURE =
  "LOAD_SEARCHES_FOR_COMPANY_FAILURE";

export const loadSearchesForCompany = (companyId) => async (dispatch) => {
  dispatch({ type: LOAD_SEARCHES_FOR_COMPANY_REQUEST });

  try {
    const where = `?[filter][where][company_id]=${companyId}`;
    const { data: response } = await dispatch(
      fetch(`/Searches${where}`, {
        method: "GET",
      })
    );

    await dispatch({
      type: LOAD_SEARCHES_FOR_COMPANY_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: LOAD_SEARCHES_FOR_COMPANY_FAILURE,
      payload: error,
    });
  }
};

export const CREATE_SEARCH_REQUEST = "CREATE_SEARCH_REQUEST";
export const CREATE_SEARCH_SUCCESS = "CREATE_SEARCH_SUCCESS";
export const CREATE_SEARCH_FAILURE = "CREATE_SEARCH_FAILURE";

export const createSearch = (companyId, name, settings) => async (dispatch) => {
  dispatch({ type: CREATE_SEARCH_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Searches`, {
        method: "POST",
        data: {
          name,
          company_id: companyId,
          settings: JSON.stringify(settings),
        },
      })
    );
    await dispatch({
      type: CREATE_SEARCH_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: CREATE_SEARCH_FAILURE,
      payload: error,
    });
  }
};

export const SAVE_SEARCH_REQUEST = "SAVE_SEARCH_REQUEST";
export const SAVE_SEARCH_SUCCESS = "SAVE_SEARCH_SUCCESS";
export const SAVE_SEARCH_FAILURE = "SAVE_SEARCH_FAILURE";

export const saveSearch = (id, name, settings) => async (dispatch) => {
  dispatch({ type: SAVE_SEARCH_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Searches/${id}`, {
        method: "PATCH",
        data: {
          name,
          settings: JSON.stringify(settings),
        },
      })
    );

    await dispatch({
      type: SAVE_SEARCH_SUCCESS,
      payload: {
        id,
        name,
        settings: JSON.stringify(settings),
      },
    });
    return response;
  } catch (error) {
    await dispatch({
      type: SAVE_SEARCH_FAILURE,
      payload: error,
    });
  }
};

export const RUN_ASSESSMENT_REPORT_REQUEST = "RUN_ASSESSMENT_REPORT_REQUEST";
export const RUN_ASSESSMENT_REPORT_SUCCESS = "RUN_ASSESSMENT_REPORT_SUCCESS";
export const RUN_ASSESSMENT_REPORT_FAILURE = "RUN_ASSESSMENT_REPORT_FAILURE";

export const buildAssessmentReportUrl = (
  entityType,
  companyId,
  page,
  perPage,
  tags,
  status,
  fromDate,
  toDate
) => {
  let url = "/AssessmentReports/reporting";
  page = page - 1;

  const paginationFilter = `,"skip":${page * perPage}, "limit": ${perPage}`;

  url += `?filter=${encodeURIComponent(
    `{"status": "${status}", "fromDate": "${fromDate}", "toDate": "${toDate}","companyId": ${companyId}, "tags": [${tags}], "entityType": "${entityType}" ${paginationFilter}}`
  )}`;

  return url;
};

export const runAssessmentReport = (
  entityType,
  companyId,
  page = 1,
  perPage,
  tags,
  status,
  fromDate,
  toDate
) => async (dispatch) => {
  dispatch({ type: RUN_ASSESSMENT_REPORT_REQUEST, entityType });

  const url = buildAssessmentReportUrl(
    entityType,
    companyId,
    page,
    perPage,
    tags,
    status,
    fromDate,
    toDate
  );

  try {
    const result = await dispatch(
      fetch(url, {
        method: "GET",
      })
    );

    const { data: response } = result;
    const count = Number(result.headers["x-total-count"]);

    await dispatch({
      type: RUN_ASSESSMENT_REPORT_SUCCESS,
      payload: { response, count },
      entityType,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: RUN_ASSESSMENT_REPORT_FAILURE,
      payload: error,
    });
  }
};
