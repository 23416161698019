import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { ContentArea } from "../components/Elements";
import { getUserToken } from "../store/users/selectors";
import Button from "../components/Button";

const SettingsWrapper = styled.div`
  background-color: #fff;
  margin: 0px 20px 20px 20px;
  border-radius: 10px;
  padding: 20px;
`;

function Credits({ history }) {
  const userToken = useSelector(getUserToken);

  return (
    <ContentArea>
      <SettingsWrapper>
        <h1>Usage Report</h1>
        <p>Download the weekly usage report for the last 7 days</p>
        <Button
          noFullWidth
          onClick={(e) => {
            e.preventDefault();
            window.open(
              `${process.env.REACT_APP_BASE_URL}/Companies/report-from-root?access_token=${userToken}`
            );
          }}
        >
          Download
        </Button>
      </SettingsWrapper>
    </ContentArea>
  );
}

export default Credits;
