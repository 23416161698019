import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { getLoading } from "../store/assessment/selectors";
import { getRealm } from "../store/users/selectors";
import CRUDForm from "../components/CRUDForm";
import Form from "../components/Form";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";

import Button from "../components/Button";

const Teaser = styled.div`
  text-align: center;
  font-size: 30px;
`;

const Centered = styled.div`
  text-align: center;
`;

const Terms = styled.div`
  a {
    color: #000;
  }
  margin-bottom: 20px;
`;

const CompaniesHouse = styled.div`
  text-align: right;

  a {
    color: #000;
  }
`;

const FormFieldWrapper = styled.div``;

function PostAssessmentForm({ onComplete = () => {}, user = false }) {
  const loading = useSelector(getLoading);
  const realm = useSelector(getRealm);
  const emptyState = {
    first_name: "",
    last_name: "",
    email: "",
    marketing_prefs_email: false,
    sector: "",
    company_size: "",
    day_rate: 0,
    agency: "",
    ltd_company_name: "",
    phone_number: "",
  };

  const [formData, setFormData] = useState(emptyState);

  const cols = user ? 1 : 2;

  const sendForm = async (formData) => {
    const {
      first_name,
      last_name,
      email,
      marketing_prefs_email,
      sector,
      company_size,
      day_rate,
      agency,
      ltd_company_name,
      password,
      phone_number,
      ltd_company_number,
      end_client,
      campaign,
    } = formData;

    onComplete({
      contractor: {
        crunch_salesforce_id: sessionStorage.getItem("crunch_salesforce_id"),
        first_name,
        last_name,
        email,
        marketing_prefs_email,
        ltd_company_name,
        password,
        phone_number,
        ltd_company_number,
      },
      engagement: {
        sector,
        company_size,
        day_rate,
        agency,
        campaign,
        end_client,
      },
    });
  };

  return (
    <CRUDForm
      emptyState={emptyState}
      formData={formData}
      loading={loading}
      recordId="new"
      onSubmit={sendForm}
      validationRules={{
        email: [
          {
            canned: "email",
          },
        ],
      }}
    >
      <Form internal={true}>
        <Teaser>Your result is nearly ready...</Teaser>
        {!user && (
          <p>
            We've got all your answers and we're ready to give you your result,
            we just need a bit more information.
          </p>
        )}

        {user && (
          <p>
            We've got all your answers and we're ready to give you your result,
            we just need a bit more information.
          </p>
        )}

        {!user && (
          <FormRow cols={cols}>
            <FormField name="first_name" label="First Name" type="text" />
            <FormField name="last_name" label="Last Name" type="text" />
          </FormRow>
        )}

        {!user && (
          <FormRow cols={cols}>
            <FormField name="email" label="Email" type="email" />

            {realm === "ir35/kingsbridge" && (
              <FormField
                name="password"
                label="Choose a password"
                type="password"
              />
            )}
          </FormRow>
        )}
        <FormRow cols={cols}>
          <FormField name="day_rate" label="Your Daily Rate" type="number" />
          <FormField
            name="agency"
            label="Agency Name"
            optional={true}
            type="text"
          />
        </FormRow>
        <FormRow cols={cols}>
          <FormField
            name="sector"
            label="What sector is this contract in?"
            inputType="select"
            options={[
              { value: "private", name: "Private" },
              { value: "public", name: "Public" },
            ]}
          />
          <FormField
            name="company_size"
            label="What size company is the end client?"
            inputType="select"
            options={[
              { value: "unknown", name: "Unknown" },
              { value: "small", name: "Small" },
              { value: "medium", name: "Medium" },
              { value: "large", name: "Large" },
            ]}
          />
        </FormRow>

        <FormRow cols={cols}>
          {realm === "ir35/kingsbridge" && (
            <FormField name="end_client" label="End Client Name" type="text" />
          )}
        </FormRow>
        {!user && (
          <FormRow cols={cols}>
            <>
              <>
                <FormField
                  name="ltd_company_name"
                  label="Your Ltd Company Name"
                  type="text"
                  optional={true}
                />
              </>

              {realm === "ir35/kingsbridge" && (
                <FormFieldWrapper>
                  <FormField
                    name="ltd_company_number"
                    label="Limited Company Number"
                    type="text"
                  />
                  <CompaniesHouse>
                    <a
                      href="https://beta.companieshouse.gov.uk/"
                      target="_blank"
                    >
                      Search Companies House
                    </a>
                  </CompaniesHouse>
                </FormFieldWrapper>
              )}
            </>
          </FormRow>
        )}
        <FormRow cols={cols}>
          {!user && (
            <>
              {realm === "ir35/kingsbridge" && (
                <FormField
                  name="phone_number"
                  label="Phone Number"
                  type="text"
                />
              )}
            </>
          )}
          {realm === "ir35/kingsbridge" &&
            !sessionStorage.getItem("campaign") &&
            !sessionStorage.getItem("RAF") && (
              <FormField
                name="campaign"
                label="Voucher Code (if you have one)"
                type="text"
                optional
              />
            )}
        </FormRow>
        {realm === "ir35/kingsbridge" && (
          <>
            <FormRow cols={1}>
              <FormField
                name="marketing_prefs_email"
                label="I agree to the terms and conditions"
                type="checkbox"
                inputType="checkbox"
                forceValidate={true}
              />
              <p>
                <a
                  href={`${process.env.REACT_APP_ROOT_URL}/service-agreement.pdf`}
                >
                  Terms &amp; Conditions
                </a>
              </p>
            </FormRow>
          </>
        )}

        {realm === "ir35/crunch" && (
          <>
            <FormRow cols={2}>
              <FormField
                name="marketing_prefs_email"
                label="I am happy for Crunch to contact me by email with appropriate products and services"
                type="checkbox"
                inputType="checkbox"
              />
            </FormRow>
            <Terms>
              By submitting you agree to our{" "}
              <a
                href="https://signup.crunch.co.uk/signup/privacypolicy.xhtml"
                target="_blank"
              >
                Privacy Policy
              </a>
            </Terms>
          </>
        )}

        <Centered>
          <Button noFullWidth={true} loading={loading}>
            Get my result
          </Button>
        </Centered>
      </Form>
    </CRUDForm>
  );
}

export default PostAssessmentForm;
