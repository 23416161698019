import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  loadTagsForCompany,
  linkTag,
  unLinkTag,
  loadTagsForEntity,
} from "../store/tags/actions";
import { getTags, getTagsForEntity } from "../store/tags/selectors";
import TagList from "./TagList";

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-left: 5px;
`;

const Layout = styled.div`
  display: flex;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
  p {
    margin: 0 0 5px 0;
  }
  position: relative;
  background-color: #fff;
`;

const MinMax = styled.div`
  position: absolute;
  top: -20px;
  right: 10px;
  height: 20px;
  background-color: #aaa;
  color: #fff;
  cursor: pointer;
  text-align: center;
  line-height: 20px;
  padding: 0 6px;
`;

const Mask = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  text-align: center;
  z-index: 10000;
  align-items: center;
  justify-content: center;
`;

const Left = styled.div`
  flex: 1;
  border-right: 1px solid #ccc;
  box-sizing: border-box;
  padding: 10px;
`;

const Right = styled.div`
  flex: 1;
  box-sizing: border-box;
  padding: 10px;
`;

const ColumnTitle = styled.h3`
  fontsize: 12px;
  margin: 0;
`;

function AssignTags({
  companyId,
  currentCompanyId,
  entityType,
  entityId,
  masked,
}) {
  const dispatch = useDispatch();

  const [linkedTags, setLinkedTags] = useState([]);

  const linkedTagsInStore = useSelector((state) =>
    getTagsForEntity(entityType, entityId, state)
  );

  const tags = useSelector(getTags).filter(
    (tag) => !linkedTags.find((linked) => tag && linked && tag.id === linked.id)
  );

  const removeLinked = (tag) => {
    const idx = linkedTags.indexOf(
      linkedTags.find((linkedTag) => linkedTag.id === tag.id)
    );

    let newTags = [...linkedTags];

    newTags.splice(idx, 1);

    setLinkedTags(newTags);

    dispatch(unLinkTag(tag.id, entityId, entityType));
  };

  const applyTag = (tag) => {
    setLinkedTags([...linkedTags, tag]);

    dispatch(linkTag(tag.id, entityId, entityType));
  };

  const [companyChanged, setCompanyChanged] = useState(false);

  useEffect(() => {
    if (currentCompanyId) {
      dispatch(loadTagsForCompany(currentCompanyId));
    }

    setCompanyChanged(
      companyId &&
        currentCompanyId &&
        companyId.toString() !== currentCompanyId.toString()
    );
  }, [currentCompanyId]);

  useEffect(() => {
    if (entityId) {
      dispatch(loadTagsForEntity(entityId, entityType));
    }
  }, [entityId]);

  useEffect(() => {
    setLinkedTags(linkedTagsInStore || []);
  }, [linkedTagsInStore]);

  const [minimised, setMinimised] = useState(true);

  return (
    <div>
      <Layout>
        <MinMax onClick={() => setMinimised(!minimised)}>
          {minimised ? "Edit" : "Close"}
          <StyledFontAwesomeIcon icon={minimised ? faAngleDown : faAngleUp} />
        </MinMax>
        {companyChanged && (
          <Mask>
            <p>
              Save record to confirm change of client. This action will clear
              the tags assigned
            </p>
          </Mask>
        )}
        {masked && (
          <Mask>
            <p>Tags will be enabled once record is saved</p>
          </Mask>
        )}
        {!minimised && (
          <Left>
            <ColumnTitle>Available</ColumnTitle>
            <p>Click to add</p>

            <TagList
              showLoadingStatus={false}
              noMargin={true}
              onCreate={() => {}}
              tags={tags}
              companyId={companyId}
              enableSearch={!minimised}
              onClick={applyTag}
            />
          </Left>
        )}

        <Right>
          {!minimised && (
            <>
              <ColumnTitle>Linked</ColumnTitle>
              <p>Click to remove</p>
            </>
          )}
          <TagList
            showLoadingStatus={true}
            noMargin={true}
            tags={linkedTags}
            companyId={companyId}
            enableSearch={false}
            onClick={!minimised && removeLinked}
          />
        </Right>
      </Layout>
    </div>
  );
}

export default AssignTags;
