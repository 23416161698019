import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import {
  getCompanyUsers,
  getLoading,
  getHasCSTRole,
  getHasAdminRole,
  getHasClientAdminRole,
  getHasSuperAdminRole,
  getSearchTerm,
  getUserType,
} from "../store/users/selectors";
import { StyledDataTable, darkTheme } from "./TableStyles";
import {
  getUsersForCompany,
  deleteUser,
  setSearchTerm,
  resetPasswordRemote,
  setUserType,
  swapReviews,
} from "../store/users/actions";
import { loadHasMandatorySignoff } from "../store/clients/actions";
import { getHasMandatorySignoff } from "../store/clients/selectors";
import noContractors from "../assets/images/no-contractors.svg";
import { Empty } from "../components/Elements";
import Loader from "../components/Loader";
import Button from "../components/Button";
import styled from "styled-components";
import FormField from "../components/FormField";

const theme = {
  ...darkTheme,
  rows: {
    hoverBackgroundColor: "#ddd",
    fontSize: "16px",
  },
};

const TypeSelector = styled.select`
  height: 39px;
  margin-top: 20px;
  font-size: 16px;
  border: 1px solid #ccc;
`;

const TargetSelector = styled.select`
  height: 39px;

  font-size: 16px;
  border: 1px solid #ccc;
`;

const SearchWrapper = styled.div`
  width: 400px;
  margin-top: 20px;
`;

const Search = styled(FormField)`
  max-width: 300px;
  margin-top: 20px;
`;

const Relative = styled.div`
  position: relative;
`;

const Swap = styled.div`
  position: absolute;
  margin-left: -200px;
  left: 50%;
  width: 400px;
  background-color: #eee;
  padding 20px;
  border-radius: 4px;
  z-index: 50;

  h2 {
    margin-top: 0
  }

  .cancel {
    top: 20px;
    right: 20px;
    font-size: 12px;
    color: #888;
    position: absolute;
    cursor: pointer;
  }
`;

function UsersForCompany({ companyId, urlStub, history }) {
  const dispatch = useDispatch();
  const users = useSelector((state) => getCompanyUsers(state, companyId));
  const loading = useSelector(getLoading);
  const isCST = useSelector(getHasCSTRole);
  const isAdmin = useSelector(getHasAdminRole);
  const isClientAdmin = useSelector(getHasClientAdminRole);
  const isSuperAdmin = useSelector(getHasSuperAdminRole);

  const admin = isAdmin || isClientAdmin || isSuperAdmin;

  const searchTerm = useSelector(getSearchTerm);

  const [activeUsers, setActiveUsers] = useState([]);

  const userType = useSelector(getUserType);

  const [resetting, setResetting] = useState({});

  const hasMandatorySignoff = useSelector(getHasMandatorySignoff);

  const [deletingUser, setDeletingUser] = useState("");
  const [deletingId, setDeletingId] = useState("");
  const [targetId, setTargetId] = useState("");
  const [targets, setTargets] = useState([]);

  useEffect(() => {
    dispatch(loadHasMandatorySignoff(companyId));
  }, []);

  useEffect(() => {
    dispatch(getUsersForCompany(companyId, userType));
  }, [userType]);

  useEffect(() => {
    if (isCST) {
      dispatch(setUserType("contractor"));
    }
  }, [isCST]);

  useEffect(() => {
    const searchTermLower = searchTerm && searchTerm.toLowerCase();

    setActiveUsers(
      users.filter((user) => {
        if (searchTerm) {
          let test = true;

          test =
            test &&
            (user.email.toLowerCase().includes(searchTermLower) ||
              user.first_name.toLowerCase().includes(searchTermLower) ||
              user.last_name.toLowerCase().includes(searchTermLower));

          return test;
        } else {
          return true;
        }
      })
    );
  }, [users, searchTerm]);

  const doReset = async (id) => {
    setResetting({ ...resetting, [id]: true });
    await dispatch(resetPasswordRemote(id));
    setResetting({ ...resetting, [id]: false });
  };

  const swapAndDelete = async () => {
    const options = {
      title: "Are you sure?",
      message: `Are you sure you want to delete ${deletingUser} and swap their reviews. It's not possible to reverse this operation`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            await dispatch(swapReviews(deletingId, targetId));
            setDeletingUser("");
            setDeletingId(null);
            setTargetId(null);

            await dispatch(deleteUser(deletingId, companyId));
          },
        },
        {
          label: "No",
        },
      ],
    };
    confirmAlert(options);
  };

  const cancelDelete = (e) => {
    e.preventDefault();
    setDeletingUser("");
    setDeletingId(null);
    setTargetId(null);
  };

  const deleteRow = (row) => {
    const options = {
      title: "Are you sure?",
      message: `Are you sure you want to delete ${row.first_name} ${row.last_name} It's not possible to reverse this operation`,
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            if (!isCST) {
              setDeletingUser(`${row.first_name} ${row.last_name}`);
              setDeletingId(row.id);
              const newTargets = await dispatch(
                getUsersForCompany(
                  companyId,
                  hasMandatorySignoff ? "signoff" : "user",
                  false
                )
              );
              setTargets(newTargets.filter((target) => target.id !== row.id));
            } else {
              dispatch(deleteUser(row.id, companyId));
            }
          },
        },
        {
          label: "No",
        },
      ],
    };
    confirmAlert(options);
  };

  const columns = [
    {
      name: "Name",
      selector: "last_name",
      sortable: true,
      cell: (row) => {
        return `${row.first_name} ${row.last_name}`;
      },
    },
    {
      name: "Email",
      selector: row => row.email.toLowerCase(),
      sortable: true,
    },
    {
      cell: (row) => (
        <Button
          size="reduced"
          onClick={() => doReset(row.id)}
          loading={resetting[row.id.toString()]}
          loadingText="Test"
        >
          Reset
        </Button>
      ),
      button: true,
    },
    {
      cell: (row) => (
        <Button
          action
          type="danger"
          size="reduced"
          onClick={() => deleteRow(row)}
          loadingText=""
        >
          Delete
        </Button>
      ),
      button: true,
    },
  ];

  const handleRowClicked = (row) =>
    history.push(`${urlStub}/${companyId}/users/${row.id}`);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        <Relative>
          {deletingId && (
            <Swap>
              <a className="cancel" onClick={cancelDelete}>
                Cancel
              </a>
              <h2>Swap Reviews</h2>
              <p>
                Before deleting {deletingUser} you need to choose who to
                transfer their reviews to:
              </p>
              <p>
                {targets.length ? (
                  <TargetSelector
                    value={targetId}
                    onChange={(e) => setTargetId(e.target.value)}
                  >
                    <option value="">Choose</option>
                    {targets.map((target) => (
                      <option value={target.id}>
                        {target.first_name} {target.last_name}
                      </option>
                    ))}
                  </TargetSelector>
                ) : (
                  <span>
                    There are no target users available, please create one
                  </span>
                )}
              </p>

              <Button onClick={swapAndDelete} disabled={!targetId}>
                Swap and Delete
              </Button>
            </Swap>
          )}
          {!isCST && (
            <TypeSelector
              value={userType}
              onChange={(e) => dispatch(setUserType(e.target.value))}
            >
              {!isCST && <option value="user">Standard Users</option>}
              {!isCST && <option value="signoff">Sign Off Users</option>}
              {!isCST && <option value="sds">Supply Chain Sign-Off Users</option>}
              {isCST || admin ? <option value="EMPTY">No Role</option> : null}
              {isCST || isAdmin ? <option value="cst">CST</option> : null}
              {isCST || isAdmin ? (
                <option value="contractor">Contractor (Direct)</option>
              ) : null}
            </TypeSelector>
          )}
          <SearchWrapper>
            <Search
              placeholder="Search"
              type="text"
              changeMonitor={(value) => dispatch(setSearchTerm(value))}
              className="search"
              value={searchTerm}
            />
          </SearchWrapper>

          {users.length ? (
            <span>
              <StyledDataTable
                defaultSortField="last_name"
                pagination
                customTheme={theme}
                columns={columns}
                data={activeUsers || []}
                fixedHeader
                highlightOnHover
                fixedHeaderScrollHeight="100%"
                onRowClicked={handleRowClicked}
              />
            </span>
          ) : (
            <Empty>
              <img src={noContractors} />
              <h2>You've not added any users yet</h2>
              <p>Click 'New User' above to create one</p>
            </Empty>
          )}
        </Relative>
      )}
    </div>
  );
}

export default UsersForCompany;
