import React, { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import TagList from "../components/TagList";
import {
  loadTagsForCompany,
  removeTag,
  editTag,
  swapTag,
} from "../store/tags/actions";
import { getTags, getTagById } from "../store/tags/selectors";
import { getCurrentClient } from "../store/clients/selectors";
import { getUserCompany } from "../store/users/selectors";
import Button from "../components/Button";
import CRUDForm from "../components/CRUDForm";
import Form from "../components/Form";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import Tag, { colourOptions } from "../components/Tag";

const TagLayout = styled.div`
  display: flex;
`;

const TagSelector = styled.div`
  background-color: #eee;
  max-height: 74vh;
  flex-basis: 400px;
`;

const TagEditing = styled.div`
  flex: 1;
  margin: 20px;
  h2 {
    margin-top: 0px;
  }
`;

function Tags({ history }) {
  const dispatch = useDispatch();

  const tags = useSelector(getTags);
  const currentClient = useSelector(getCurrentClient);
  const company = useSelector(getUserCompany);
  const [selectedTag, setSelectedTag] = useState({});
  const companyId =
    currentClient === 0 ? (company ? company.id : 0) : currentClient;

  const editingTag = useSelector((state) => getTagById(selectedTag.id, state));

  const [localTag, setLocalTag] = useState(null);

  const [swapComplete, setSwapComplete] = useState(false);

  const emptyState = {
    text: "",
  };

  const cols = 1;

  const saveTag = (newValues) => {
    dispatch(editTag(newValues));
  };

  const onRemove = (tag) => {
    const options = {
      title: "Are you sure?",
      message: `Are you sure you want to remove '${tag.text}'`,
      buttons: [
        {
          label: "Cancel",
        },
        {
          label: "Delete",
          onClick: () => {
            dispatch(removeTag(tag));
          },
        },
      ],
    };
    confirmAlert(options);
  };

  const confirmSwapTag = async (from, to) => {
    const options = {
      title: "Are you sure?",
      message: `Are you sure you want to transfer '${from.text}' to '${to.text}'`,
      buttons: [
        {
          label: "Cancel",
        },
        {
          label: "Transfer",
          onClick: async () => {
            const response = await dispatch(swapTag(from.id, to.id));

            if (response.success) {
              setSwapComplete(true);
            }
          },
        },
      ],
    };
    confirmAlert(options);
  };

  const onCreate = async (newTag) => {};

  useEffect(() => {
    dispatch(loadTagsForCompany(companyId));
  }, [currentClient]);

  useEffect(() => {
    setLocalTag({ ...editingTag });
    setSwapComplete(false);
  }, [editingTag]);

  return (
    <TagLayout>
      <TagSelector>
        <TagList
          tags={tags}
          removable={true}
          onRemove={onRemove}
          enableSearch={true}
          onCreate={onCreate}
          companyId={companyId}
          onClick={setSelectedTag}
        />
      </TagSelector>
      {localTag && localTag.text && (
        <TagEditing>
          <h2>Edit: {localTag.text}</h2>

          <CRUDForm
            emptyState={emptyState}
            formData={editingTag}
            recordId="new"
            onSubmit={saveTag}
          >
            <Form internal={true} noPad>
              <FormRow cols={cols}>
                <FormField name="text" label="Tag Name" type="text" />
              </FormRow>

              <FormRow cols={2}>
                <FormField
                  name="color"
                  label="Colour"
                  inputType="radio"
                  layout="vertical"
                  options={Object.keys(colourOptions).map((key) => ({
                    value: key.toLowerCase(),
                    name: key.toLowerCase(),
                  }))}
                  customRender={(item) => (
                    <Tag
                      text={item.value.toLowerCase()}
                      color={item.value.toLowerCase()}
                    />
                  )}
                />
              </FormRow>

              <FormRow cols={cols}>
                <Button noFullWidth={true}>Save Tag</Button>{" "}
              </FormRow>
            </Form>
          </CRUDForm>
          <h2>Transfer to different tag</h2>
          <p>Use this feature to replace this tag with a different one</p>
          <TagList
            tags={tags.filter((tag) => tag.id !== localTag.id)}
            enableSearch={true}
            onCreate={onCreate}
            companyId={companyId}
            onClick={(tag) => confirmSwapTag(localTag, tag)}
            noMargin={true}
          />
          {swapComplete && <p>Swap complete</p>}
        </TagEditing>
      )}
    </TagLayout>
  );
}

export default Tags;
