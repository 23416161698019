import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getActivityLog, getLoading } from "../store/assessment/selectors";
import { loadActivityLog } from "../store/assessment/actions";
import Modal from "react-modal";
import Loader from "./Loader";
import { StyledDataTable, darkTheme } from "./TableStyles";
import moment from "moment";
import Button from "./Button";
import { getUserToken } from "../store/users/selectors";

const ActivityLogOuter = styled.div``;

const theme = {
  ...darkTheme,
  rows: {
    hoverBackgroundColor: "#ddd",
    fontSize: "16px",
  },
};

const TopHolder = styled.div`
  h2 {
    margin: 0;
  }

  display: flex;

  > div {
    flex: 1;
  }

  > div:last-child {
    justify-content: flex-end;
    display: flex;
  }
`;

const columns = [
  {
    name: "Date/Time",
    cell: (row) => moment(row.datetime).format("DD/MM/YYYY hh:mm:ss"),
    width: "100",
  },
  {
    name: "Actor",
    selector: "user",
    width: "120",
  },
  {
    name: "Summary",
    grow: 3,
    selector: "summary",
  },
];

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "700px",
    minHeight: "500px",
    maxHeight: "500px",
  },
};

function ActivityLog({ open, engagementId, close = () => {} }) {
  const dispatch = useDispatch();

  const logs = useSelector(getActivityLog);
  const loading = useSelector(getLoading);
  const userToken = useSelector(getUserToken);

  useEffect(() => {
    if (open) {
      dispatch(loadActivityLog(engagementId));
    }
  }, [dispatch, open]);

  return (
    <Modal
      style={{ zIndex: 10000000 }}
      isOpen={open}
      onRequestClose={close}
      style={customStyles}
    >
      {loading ? (
        <Loader></Loader>
      ) : (
        <ActivityLogOuter>
          <TopHolder>
            <div>
              <h2>Activity Log</h2>
            </div>
            <div>
              <Button
                onClick={() =>
                  window.open(
                    `${process.env.REACT_APP_BASE_URL}/ActivityLogs/download/${engagementId}?access_token=${userToken}`
                  )
                }
                noFullWidth
              >
                Download
              </Button>
            </div>
          </TopHolder>

          <StyledDataTable
            noHeader
            pagination
            customTheme={theme}
            columns={columns}
            data={logs}
            fixedHeader
            highlightOnHover
            fixedHeaderScrollHeight="100%"
          />
        </ActivityLogOuter>
      )}
    </Modal>
  );
}

export default ActivityLog;
