import React from "react";
import styled from "styled-components";
import moment from "moment";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import Loader from "../components/Loader";

const theme = {
  ...darkTheme,
  rows: {
    hoverBackgroundColor: "#ddd",
    fontSize: "16px"
  }
};

const CurrentCredits = styled.div`
  margin: 20px 0px;
`;

const Balance = styled.div`
  font-size: 50px;
  text-align: left;
`;

const SubTitle = styled.div`
  font-size: 12px;
  text-align: left;
  text-transform: uppercase;
`;

function CreditsList({ creditBalance, credits, loading }) {
  const columns = [
    {
      name: "Date",
      selector: "datetime",
      cell: row => <span>{moment(row.datetime).format("Do MMMM YYYY")}</span>
    },
    {
      name: "# credits",
      selector: "amount",
      grow: 0
    },
    {
      name: "Price Per Credit",
      selector: "price_per_credit",
      grow: 0
    },
    {
      name: "Notes",
      selector: "notes"
    }
  ];

  return (
    <>
      {loading || !credits ? (
        <Loader />
      ) : (
        <>
          <h2>Credits</h2>

          <CurrentCredits>
            <SubTitle>Available Credits:</SubTitle>
            <Balance>{creditBalance}</Balance>
          </CurrentCredits>

          <h2>Transactions</h2>

          <StyledDataTable
            pagination
            customTheme={theme}
            columns={columns}
            data={credits || []}
            fixedHeader
            highlightOnHover
            fixedHeaderScrollHeight="100%"
          />
        </>
      )}
    </>
  );
}

export default CreditsList;
