import React, { useEffect, useState, useRef } from "react";
import { confirmAlert } from "react-confirm-alert";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getCurrentClient } from "../store/clients/selectors";
import { getUserCompany } from "../store/users/selectors";
import {
  loadTagsForCompany,
  loadSearchesForCompany,
  createSearch,
  saveSearch,
  runAssessmentReport,
  buildAssessmentReportUrl,
} from "../store/tags/actions";
import {
  getTags,
  getSearches,
  getSearchesLoading,
  getReportingEntityLoading,
  getReportingEntityCount,
  getReportingEntity,
} from "../store/tags/selectors";
import { getUserToken } from "../store/users/selectors";

import Button from "../components/Button";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import TagList from "../components/TagList";
import { statuses } from "./Dashboard";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import { getDisplayName, getRiskClassName } from "../components/ResultScreen";
import MaxMin from "../components/MaxMin";

const Reporting = styled.div`
  h2 {
    margin-top: 0;
  }
  position: relative;
`;

const SearchSelector = styled.select`
  margin-bottom: 20px;
  height: 39px;
  font-size: 16px;
  outline: none;
  border: 1px solid #ccc;
`;

const TagsArea = styled.div`
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  margin-bottom: 20px;
  margin-top: 10px;
`;

const Checkboxes = styled.div`
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 10px;
  margin-top: 10px;
`;

const RunArea = styled.div`
  border-top: 1px solid #ccc;
  padding-top: 10px;
`;

const Result = styled.div`
  padding: 10px;
  background-color: #f6f6f6;
  border-radius: 6px;
  margin-bottom: 20px;
  h3 {
    margin-top: 0;
  }
`;

const TableWrap = styled.div`
  background-color: #fff;
  padding: 10px;
  margin-top: 20px;
`;

function RunReports({ history }) {
  const dispatch = useDispatch();

  const currentClient = useSelector(getCurrentClient);
  const company = useSelector(getUserCompany);
  const companyId =
    currentClient === 0 ? (company ? company.id : 0) : currentClient;

  const tags = useSelector(getTags);

  const searchesLoading = useSelector(getSearchesLoading);
  const searches = useSelector(getSearches);
  const userToken = useSelector(getUserToken);

  const [selectedTags, setSelectedTags] = useState([]);
  const [status, setStatus] = useState("COMPLETE");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [includeContractor, setIncludeContractor] = useState(false);
  const [includeUser, setIncludeUser] = useState(false);
  const [includeRoleAssessment, setIncludeRoleAssessment] = useState(false);
  const [includeAssessment, setIncludeAssessment] = useState(false);
  const [searchName, setSearchName] = useState("");
  const entities = ["assessment", "roleAssessment", "contractor", "user"];

  const entityHeadings = {
    assessment: "Contractor Assessments",
    roleAssessment: "Role Assessments",
    contractor: "Contractors",
    user: "Users",
  };

  const mappedEntitiesSelected = {
    assessment: includeAssessment,
    roleAssessment: includeRoleAssessment,
    contractor: includeContractor,
    user: includeUser,
  };

  const [
    assessmentLoading,
    roleAssessmentLoading,
    contractorLoading,
    userLoading,
  ] = [
    useSelector((state) => getReportingEntityLoading("assessment", state)),
    useSelector((state) => getReportingEntityLoading("roleAssessment", state)),
    useSelector((state) => getReportingEntityLoading("contractor", state)),
    useSelector((state) => getReportingEntityLoading("user", state)),
  ];

  const mappedEntitiesLoading = {
    assessment: assessmentLoading,
    roleAssessment: roleAssessmentLoading,
    contractor: contractorLoading,
    user: userLoading,
  };

  const [assessmentCount, roleAssessmentCount, contractorCount, userCount] = [
    useSelector((state) => getReportingEntityCount("assessment", state)),
    useSelector((state) => getReportingEntityCount("roleAssessment", state)),
    useSelector((state) => getReportingEntityCount("contractor", state)),
    useSelector((state) => getReportingEntityCount("user", state)),
  ];

  const mappedEntitiesCount = {
    assessment: assessmentCount,
    roleAssessment: roleAssessmentCount,
    contractor: contractorCount,
    user: userCount,
  };

  const [assessmentData, roleAssessmentData, contractorData, userData] = [
    useSelector((state) => getReportingEntity("assessment", state)),
    useSelector((state) => getReportingEntity("roleAssessment", state)),
    useSelector((state) => getReportingEntity("contractor", state)),
    useSelector((state) => getReportingEntity("user", state)),
  ];

  const mappedEntitiesData = {
    assessment: assessmentData,
    roleAssessment: roleAssessmentData,
    contractor: contractorData,
    user: userData,
  };

  const buildReportRef = useRef();
  const resultsRef = useRef();

  const [pages, setPages] = useState({
    roleAssessment: 1,
    assessment: 1,
    user: 1,
    contractor: 1,
  });
  const [pageSizes, setPageSizes] = useState({
    roleAssessment: 10,
    assessment: 10,
    user: 10,
    contractor: 10,
  });

  const [searchId, setSearchId] = useState(null);

  useEffect(() => {
    const search = searches.find(
      (searchItem) => Number(searchItem.id) === Number(searchId)
    );

    if (search) {
      const settings = JSON.parse(search.settings);

      setStatus(settings.status);
      setFromDate(settings.fromDate);
      setToDate(settings.toDate);
      setIncludeContractor(settings.includeContractor);
      setIncludeUser(settings.includeUser);
      setIncludeRoleAssessment(settings.includeRoleAssessment);
      setIncludeAssessment(settings.includeAssessment);
      setSearchName(search.name);
      setSelectedTags(settings.tags);
    }
  }, [searchId]);

  const onClick = (tag) => {
    if (selectedTags.includes(tag.id)) {
      setSelectedTags(selectedTags.filter((selected) => selected !== tag.id));
    } else {
      setSelectedTags([...selectedTags, tag.id]);
    }
  };

  useEffect(() => {
    setSelectedTags([]);
    dispatch(loadTagsForCompany(companyId));
    dispatch(loadSearchesForCompany(companyId));
    setSearchId(null);
  }, [companyId]);

  const runReport = async () => {
    if (includeAssessment) {
      await dispatch(
        runAssessmentReport(
          "assessment",
          companyId,
          pages["assessment"],
          pageSizes["assessment"],
          selectedTags,
          status,
          fromDate,
          toDate
        )
      );
    }
    if (includeRoleAssessment) {
      await dispatch(
        runAssessmentReport(
          "roleAssessment",
          companyId,
          pages["roleAssessment"],
          pageSizes["roleAssessment"],
          selectedTags,
          status,
          fromDate,
          toDate
        )
      );
    }

    if (includeUser) {
      await dispatch(
        runAssessmentReport(
          "user",
          companyId,
          pages["user"],
          pageSizes["user"],
          selectedTags,
          status,
          fromDate,
          toDate
        )
      );
    }

    if (includeContractor) {
      await dispatch(
        runAssessmentReport(
          "contractor",
          companyId,
          pages["contractor"],
          pageSizes["contractor"],
          selectedTags,
          status,
          fromDate,
          toDate
        )
      );
    }

    buildReportRef.current.minimise();
    resultsRef.current.maximise();
  };

  const saveSearchLocal = () => {
    let settings = {
      status,
      fromDate,
      toDate,
      includeContractor,
      includeUser,
      includeRoleAssessment,
      includeAssessment,
      tags: selectedTags,
    };
    if (searchId) {
      dispatch(saveSearch(searchId, searchName, settings));
    } else {
      dispatch(createSearch(companyId, searchName, settings));
    }
  };

  const movePage = async (entityType, page) => {
    dispatch(
      runAssessmentReport(
        entityType,
        companyId,
        page,
        pageSizes[entityType],
        selectedTags,
        status,
        fromDate,
        toDate
      )
    );
    dispatch(setPages({ ...pages, [entityType]: page }));
  };

  const changePerPage = async (entityType, newPerPage, page) => {
    dispatch(
      runAssessmentReport(
        entityType,
        companyId,
        page,
        newPerPage,
        selectedTags,
        status,
        fromDate,
        toDate
      )
    );
    dispatch(setPageSizes({ ...pageSizes, [entityType]: newPerPage }));
    dispatch(setPages({ ...pages, [entityType]: page }));
  };

  const columns = {
    assessment: [
      {
        name: "Reference",
        selector: "reference",
      },
      {
        name: "Contractor Name",
        selector: "contractor_name",
      },
      {
        name: "Status",
        cell: (row) => statuses[row.status].name,
      },
      {
        name: "Result",
        cell: (row) =>
          getDisplayName(row.result, row.validation ? "SHORT" : "FULL"),
      },
    ],
    roleAssessment: [
      {
        name: "Reference",
        selector: "reference",
      },
      {
        name: "Role Name",
        selector: "contractor_name",
      },
      {
        name: "Status",
        cell: (row) => statuses[row.status].name,
      },
      {
        name: "Result",
        cell: (row) => getDisplayName(row.result, "FULL"),
      },
    ],
    contractor: [
      {
        name: "Reference",
        selector: "internal_reference",
      },
      {
        name: "Name",
        cell: (row) => `${row.first_name} ${row.last_name}`,
      },
      {
        name: "Email",
        selector: "email",
      },
    ],
    user: [
      {
        name: "Name",
        cell: (row) => `${row.first_name} ${row.last_name}`,
      },
      {
        name: "Email",
        selector: "email",
      },
    ],
  };

  const CategoryTable = (row) => {
    return (
      <StyledDataTable
        customTheme={darkTheme}
        columns={[
          {
            name: "Category",
            selector: "name",
          },
          {
            name: "Result",
            cell: (row) => getDisplayName(row.result, "FULL"),
          },
        ]}
        data={row.data.categories}
        fixedHeader
        fixedHeaderScrollHeight="100%"
        title="Categories"
      />
    );
  };

  return (
    <>
      <Reporting>
        <MaxMin heading="Build Report" headingLevel="h2" ref={buildReportRef}>
          <div>
            {searches && searches.length > 0 && (
              <SearchSelector
                value={searchId}
                onChange={(e) => {
                  setSearchId(e.target.value);
                }}
              >
                <option value={""}>Create New</option>
                {searches.map((search) => (
                  <option value={search.id}>{search.name}</option>
                ))}
              </SearchSelector>
            )}
          </div>
          <label>Choose Tags</label>
          <TagsArea>
            <TagList
              tags={tags}
              noMargin
              enableSearch
              selectedTags={selectedTags}
              onClick={onClick}
            />
          </TagsArea>

          <FormRow cols={4}>
            <FormField
              name="status"
              label="Status"
              inputType="radio"
              options={Object.keys(statuses).map((status) => ({
                value: status,
                name: statuses[status].name,
              }))}
              changeMonitor={(value) => setStatus(value)}
              layout="vertical"
              value={status}
              helpText="These only apply to 'Role Assessments' and 'Full Assessments'"
            />
            <div>
              <label>Entities</label>
              <Checkboxes>
                <FormField
                  name="contractor"
                  label="Contractors"
                  type="checkbox"
                  inputType="checkbox"
                  value={includeContractor}
                  changeMonitor={(value) => setIncludeContractor(value)}
                />
                <FormField
                  name="user"
                  label="Users"
                  type="checkbox"
                  inputType="checkbox"
                  value={includeUser}
                  changeMonitor={(value) => setIncludeUser(value)}
                />
                <FormField
                  name="role-assessment"
                  label="Role Assessments"
                  type="checkbox"
                  inputType="checkbox"
                  value={includeRoleAssessment}
                  changeMonitor={(value) => setIncludeRoleAssessment(value)}
                />
                <FormField
                  name="assessment"
                  label="Full Assessments"
                  type="checkbox"
                  inputType="checkbox"
                  value={includeAssessment}
                  changeMonitor={(value) => setIncludeAssessment(value)}
                />
              </Checkboxes>
            </div>

            <FormField
              placeholder="From"
              type="date"
              changeMonitor={(value) => setFromDate(value)}
              value={fromDate}
              isClearable={true}
              disabled={!includeAssessment && !includeRoleAssessment}
              label="From"
              helpText="This only applies to 'Role Assessments' and 'Full Assessments'"
            />
            <FormField
              placeholder="To"
              type="date"
              changeMonitor={(value) => setToDate(value)}
              value={toDate}
              //disabled={!includeAssessment && !includeRoleAssessment}
              label="To"
              helpText="This only applies to 'Role Assessments' and 'Full Assessments'"
            />
          </FormRow>
          <FormRow cols={2}>
            <FormField
              type="text"
              changeMonitor={(value) => setSearchName(value)}
              value={searchName}
              label="Search Name"
            />
          </FormRow>
          <FormRow cols={1}>
            <Button
              loading={searchesLoading}
              disabled={searchName === ""}
              noFullWidth={true}
              onClick={saveSearchLocal}
            >
              Save Search
            </Button>
          </FormRow>
          <FormRow cols={1}>
            <RunArea>
              {" "}
              <Button
                noFullWidth={true}
                onClick={runReport}
                disabled={
                  (!includeAssessment &&
                    !includeContractor &&
                    !includeRoleAssessment &&
                    !includeUser) ||
                  selectedTags.length === 0
                }
                loading={
                  assessmentLoading ||
                  roleAssessmentLoading ||
                  contractorLoading ||
                  userLoading
                }
              >
                Run
              </Button>
            </RunArea>
          </FormRow>
        </MaxMin>
      </Reporting>
      <MaxMin
        heading="Results"
        headingLevel="h2"
        ref={resultsRef}
        initialMinimised={true}
      >
        {entities.map((entity) => {
          if (mappedEntitiesSelected[entity]) {
            const count = mappedEntitiesCount[entity];
            return (
              <Result>
                <MaxMin
                  heading={`${entityHeadings[entity]} - ${count} ${
                    count === 1 ? "result" : "results"
                  }`}
                  headingLevel="h3"
                  initialMinimised={true}
                >
                  {count > 0 ? (
                    <Button
                      noFullWidth={true}
                      onClick={() =>
                        window.open(
                          `${
                            process.env.REACT_APP_BASE_URL
                          }${buildAssessmentReportUrl(
                            entity,
                            companyId,
                            pages[entity],
                            pageSizes[entity],
                            selectedTags,
                            status,
                            fromDate,
                            toDate
                          )}&access_token=${userToken}&csv=true`
                        )
                      }
                    >
                      Download CSV
                    </Button>
                  ) : null}
                  <TableWrap>
                    <StyledDataTable
                      progressPending={mappedEntitiesLoading[entity]}
                      pagination
                      paginationServer
                      customTheme={darkTheme}
                      columns={columns[entity]}
                      data={mappedEntitiesData[entity]}
                      fixedHeader
                      highlightOnHover
                      fixedHeaderScrollHeight="100%"
                      title="Assessments"
                      //  onRowClicked={handleRowClicked}
                      paginationDefaultPage={pages[entity]}
                      paginationPerPage={pageSizes[entity]}
                      paginationTotalRows={count}
                      onChangeRowsPerPage={(newPerPage, page) =>
                        changePerPage(entity, newPerPage, page)
                      }
                      onChangePage={(page) => movePage(entity, page)}
                      expandableRows={
                        entity === "assessment" || entity === "roleAssessment"
                      }
                      expandableRowsComponent={<CategoryTable />}
                    />
                  </TableWrap>
                </MaxMin>
              </Result>
            );
          }
        })}
      </MaxMin>
    </>
  );
}

export default RunReports;
