import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { device } from "./Devices";
import { getDisputeLoading } from "../store/sds/selectors";
import { closeDispute } from "../store/sds/actions";
import CRUDForm from "./CRUDForm";
import Form from "./Form";
import FormField from "./FormField";
import FormRow from "./FormRow";

import Button from "./Button";

const Centered = styled.div`
  text-align: center;
`;

const FormWrapper = styled.div`
  width: 600px;
  max-height: 80vh;
  z-index: 1000000;

  // @media ${device.laptop} {
  //   padding: 30px 20px;
  //   ${(props) => !props.wrapped && "margin: 0px 30px 0px 0px;"}
  // }
`;

const OriginalReason = styled.div`
  padding: 10px;
  height: 200px;
  overflow: auto;
  border: 1px solid #bbb;

  h4 {
    margin-top: 0;
  }
`;

function CloseDispute({ onClose = () => {}, distributee }) {
  const loading = useSelector(getDisputeLoading);

  const dispatch = useDispatch();

  const emptyState = {
    response: "",
  };

  const [formData, setFormData] = useState(emptyState);

  const cols = 1;

  const sendForm = async (formData) => {
    const { response } = formData;

    const responseFromServer = await dispatch(
      closeDispute(distributee.public_id, response)
    );

    if (responseFromServer && responseFromServer.complete) {
      onClose();
    }
  };

  const cancel = async (e) => {
    e.preventDefault();

    onClose();
  };

  return (
    <FormWrapper>
      <CRUDForm
        emptyState={emptyState}
        formData={formData}
        loading={loading}
        recordId="new"
        onSubmit={sendForm}
      >
        <Form internal={true}>
          <h3>Resolve Dispute</h3>
          <OriginalReason>
            <h4>Original Dispute</h4>
            {distributee.dispute_reason.split("\n").map((i, key) => {
              return <p key={key}>{i}</p>;
            })}
          </OriginalReason>
          <FormRow cols={cols}>
            <FormField
              name="response"
              label="Response"
              helpText="Please provide your response to this dispute"
              inputType="textarea"
              height={200}
            />
          </FormRow>

          <Centered>
            <Button
              noFullWidth={true}
              noBind
              onClick={cancel}
              type="distribute"
            >
              Cancel
            </Button>{" "}
            <Button
              noFullWidth={true}
              loading={loading}
              type="appeal"
              onClick={sendForm}
            >
              Submit Dispute Response
            </Button>
          </Centered>
        </Form>
      </CRUDForm>
    </FormWrapper>
  );
}

export default CloseDispute;
