import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import Comments from "../Comments";
import { device } from "../Devices";
import {
  questionDeltas,
  getNotesForQuestion,
} from "../../store/assessment/selectors";
import QuestionNotes from "../QuestionNotes";
import {
  mainColor,
  errorWeak,
  errorStrong,
  warnBG,
  warnCopy,
} from "../../styles/colors";
import Answers from "./Answers";

const QuestionElement = styled.div`
  background-color: #eee;
  padding: 15px;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 16px;

  &.invalid {
    background-color: ${errorWeak};
    color: ${errorStrong};
  }
`;

const warning = `background-color: ${warnBG};
border: 1px solid ${warnCopy};
color: ${warnCopy};
font-size: 14px;
padding: 10px;
margin-bottom: 20px;`;

const DisseminationWarning = styled.div`
  ${warning}
`;

const QuestionActions = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  border-bottom: 1px solid #ddd;
`;

const QuestionAction = styled.a`
  padding: 10px;
  margin: 0;
  cursor: pointer;
  display: inline-block;

  &:hover,
  &.selected {
    transition: background-color 200ms, color 200ms;
    cursor: pointer;

    background-color: #3d3d3d;
    color: #fff;
  }
  position: relative;
`;

const Badge = styled.div`
  position: absolute;
  top: 2px;
  left: 19px;
  background-color: red;
  color: #fff;
  padding: 2px 5px;
  border-radius: 50%;
  font-size: 10px;
  font-weight: bold;
  height: 12px;
  min-width: 7px;
  text-align: center;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const ActionTable = styled.table`
  margin-top: 10px;

  th,
  td {
    padding: 10px;
  }
  th {
    font-weight: bold;
    background-color: #ddd;
  }

  td {
    background-color: #eee;
  }
`;

const Help = styled.div`
  padding: 10px 15px;
  margin-bottom: 10px;
`;

function Question({
  question,
  invalid,
  target,
  isReviewer,
  reviewId,
  assessmentResult,
  dissemination,
  view,
  additionalQuestions,
}) {
  const deltas = useSelector((state) => questionDeltas(question.id, state));
  const notesForQuestion = useSelector((state) =>
    getNotesForQuestion(question.id, state)
  );
  const [showHistory, setShowHistory] = useState(false);
  const [showNotes, setShowNotes] = useState(false);

  useEffect(() => {
    setShowNotes(false);
  }, [question]);

  return (
    <div>
      <QuestionElement className={invalid ? "invalid" : ""}>
        {target === "client" ? question.title : question.title_contractor}
      </QuestionElement>
      {question.respondent === "CONTRACTOR" && dissemination && (
        <DisseminationWarning>
          This question was originally answered by the contractor, you should
          check before changing the answer
        </DisseminationWarning>
      )}

      <QuestionActions>
        <>
          {deltas && deltas.length > 0 && (
            <QuestionAction
              onClick={() => {
                setShowHistory(!showHistory);
                setShowNotes(false);
              }}
              className={showHistory ? "selected" : ""}
            >
              <StyledFontAwesomeIcon icon={faHistory} />
              Answer History
            </QuestionAction>
          )}
          <QuestionAction
            onClick={() => {
              setShowNotes(!showNotes);
              setShowHistory(false);
            }}
            className={showNotes ? "selected" : ""}
          >
            <StyledFontAwesomeIcon icon={faStickyNote} />
            {notesForQuestion && notesForQuestion.length > 0 && (
              <Badge>{notesForQuestion.length}</Badge>
            )}
            Notes/Evidence
          </QuestionAction>
        </>
      </QuestionActions>

      {showHistory && (
        <ActionTable>
          <thead>
            <th>New Answer</th>
            <th>Changed by</th>
          </thead>

          <tbody>
            {deltas.map((delta) => (
              <tr>
                <td>
                  {delta.answerId !== -1
                    ? question.answers.find(
                        (answer) => answer.id === delta.answerId
                      ).title
                    : "Answer Cleared"}
                </td>
                <td>{delta.author}</td>
              </tr>
            ))}
          </tbody>
        </ActionTable>
      )}

      {showNotes && (
        <QuestionNotes questionId={question.id} readonly={isReviewer || view} />
      )}

      {question.help ? <Help>{question.help}</Help> : null}
      <Answers
        questionKey={question.reference}
        assessmentResult={assessmentResult}
        questionId={question.id}
        isReviewer={isReviewer}
        view={view}
        reviewId={reviewId}
        target={target}
        dissemination={dissemination}
        additionalQuestions={additionalQuestions}
        answers={question.answers.sort((a, b) => {
          if (a.title < b.title) {
            return 1;
          }
          if (a.title > b.title) {
            return -1;
          }
          return 0;
        })}
      />
    </div>
  );
}

export default Question;
