import { set, flow, get, getOr } from "lodash/fp";
import {
  LOAD_SDS_DOCUMENT_REQUEST,
  LOAD_SDS_DOCUMENT_SUCCESS,
  LOAD_SDS_DOCUMENT_FAILURE,
  ACCEPT_SDS_DOCUMENT_REQUEST,
  ACCEPT_SDS_DOCUMENT_SUCCESS,
  ACCEPT_SDS_DOCUMENT_FAILURE,
  ADD_DISTRIBUTEES_REQUEST,
  ADD_DISTRIBUTEES_SUCCESS,
  ADD_DISTRIBUTEES_FAILURE,
  OPEN_DISPUTE_REQUEST,
  OPEN_DISPUTE_SUCCESS,
  OPEN_DISPUTE_FAILURE,
  CLOSE_DISPUTE_REQUEST,
  CLOSE_DISPUTE_SUCCESS,
  CLOSE_DISPUTE_FAILURE,
} from "./actions";

export const sds = (
  state = {
    loading: false,
    error: false,
    sdsData: null,
    distributeesLoading: false,
    distributeesError: false,
    disputeLoading: false,
    disputeError: false,
  },
  action
) => {
  switch (action.type) {
    case OPEN_DISPUTE_REQUEST:
    case CLOSE_DISPUTE_REQUEST:
      return flow(
        set("disputeLoading", true),
        set("disputeError", false)
      )(state);
    case OPEN_DISPUTE_SUCCESS:
    case CLOSE_DISPUTE_SUCCESS:
      return flow(
        set("disputeLoading", false),
        set("disputeError", false)
      )(state);
    case OPEN_DISPUTE_FAILURE:
    case CLOSE_DISPUTE_FAILURE:
      return flow(
        set("disputeLoading", false),
        set("disputeError", true)
      )(state);
    case ADD_DISTRIBUTEES_REQUEST:
      return flow(
        set("distributeesLoading", true),
        set("distributeesError", false)
      )(state);
    case ADD_DISTRIBUTEES_SUCCESS:
      return flow(
        set("distributeesLoading", false),
        set("distributeesError", false)
      )(state);
    case ADD_DISTRIBUTEES_FAILURE:
      return flow(
        set("distributeesLoading", false),
        set("distributeesError", true)
      )(state);
    case LOAD_SDS_DOCUMENT_REQUEST:
    case ACCEPT_SDS_DOCUMENT_REQUEST:
      return flow(set("loading", true), set("error", false))(state);

    case LOAD_SDS_DOCUMENT_FAILURE:
    case ACCEPT_SDS_DOCUMENT_FAILURE:
      return flow(set("loading", false), set("error", true))(state);
    case ACCEPT_SDS_DOCUMENT_SUCCESS:
      return flow(set("loading", false), set("error", false))(state);
    case LOAD_SDS_DOCUMENT_SUCCESS:
      return flow(
        set("loading", false),
        set("error", false),
        set("sdsData", action.payload)
      )(state);

    default:
      return state;
  }
};
