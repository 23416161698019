import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FileBase64 from "react-file-base64";
import Button from "./Button";

const ImageUploadWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;
`;

const Logo = styled.img`
  max-width: 200px;

  margin-top: 20px;
`;

const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Uploader = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  margin-top: 20px;
`;

const Buttons = styled.div`
  display: flex;

  button {
    margin-right: 20px;
  }
`;

function ImageUploadComponent({ onChange, value }) {
  const [editing, setEditing] = useState(false);
  const [preview, setPreview] = useState({});
  const [valid, setValid] = useState(true);

  useEffect(() => {
    if (preview && preview.size) {
      if (parseInt(preview.size) > 45) {
        setValid(false);
      } else {
        setValid(true);
      }
    } else {
      setValid(true);
    }
  }, [preview]);

  return (
    <ImageUploadWrapper>
      {!editing && value && (
        <LogoWrapper>
          <Logo src={value} />
          <StyledButton
            noFullWidth={true}
            onClick={() => {
              setEditing(true);
            }}
          >
            Change
          </StyledButton>
        </LogoWrapper>
      )}
      {(editing || !value) && (
        <Uploader>
          <FileBase64 multiple={false} onDone={setPreview} />
          {preview && preview.base64 && <Logo src={preview.base64} />}
          {preview && <p>Size: {preview.size}</p>}
          {!valid && <p>Image is too large</p>}
          <Buttons>
            <StyledButton
              noFullWidth={true}
              onClick={e => {
                e.preventDefault();
                setPreview({});
                setEditing(false);
              }}
            >
              Cancel
            </StyledButton>
            <StyledButton
              disabled={!valid}
              noFullWidth={true}
              onClick={e => {
                e.preventDefault();
                onChange(preview.base64);
                setPreview({});
                setEditing(false);
              }}
            >
              Set
            </StyledButton>
          </Buttons>
        </Uploader>
      )}
    </ImageUploadWrapper>
  );
}

export default ImageUploadComponent;
