import { fetch } from "../fetchMiddleware";

import { getMe } from "../users/actions";
import { getUser } from "../users/selectors";

export const LOAD_ASSESSMENT_MASTER_REQUEST = "LOAD_ASSESSMENT_MASTER_REQUEST";
export const LOAD_ASSESSMENT_MASTER_SUCCESS = "LOAD_ASSESSMENT_MASTER_SUCCESS";
export const LOAD_ASSESSMENT_MASTER_FAILURE = "LOAD_ASSESSMENT_MASTER_FAILURE";

export const loadAssessmentMaster =
  (type = "SHORT") =>
    async (dispatch) => {
      dispatch({ type: LOAD_ASSESSMENT_MASTER_REQUEST });

      try {
        const { data: response } = await dispatch(
          fetch(`/Assessments/getCurrentAssessment/${type}`, {
            method: "GET",
          })
        );
        await dispatch({
          type: LOAD_ASSESSMENT_MASTER_SUCCESS,
          payload: response,
        });
        return response;
      } catch (error) {
        await dispatch({
          type: LOAD_ASSESSMENT_MASTER_FAILURE,
          payload: error,
        });
      }
    };

export const LOAD_CONTRACTOR_PART_ASSESSMENT_REQUEST =
  "LOAD_CONTRACTOR_PART_ASSESSMENT_REQUEST";
export const LOAD_CONTRACTOR_PART_ASSESSMENT_SUCCESS =
  "LOAD_CONTRACTOR_PART_ASSESSMENT_SUCCESS";
export const LOAD_CONTRACTOR_PART_ASSESSMENT_FAILURE =
  "LOAD_CONTRACTOR_PART_ASSESSMENT_FAILURE";

export const loadContractorPartAssessment = (key) => async (dispatch) => {
  dispatch({ type: LOAD_CONTRACTOR_PART_ASSESSMENT_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/ContractorEngagements/get-contractor-assessment/${key}`, {
        method: "GET",
      })
    );
    if (response.assessment) {
      await dispatch({
        type: LOAD_ASSESSMENT_MASTER_SUCCESS,
        payload: response.assessment,
        shouldShareLeads: response.shouldShare,
      });
    }

    return response;
  } catch (error) {
    await dispatch({
      type: LOAD_ASSESSMENT_MASTER_FAILURE,
    });
  }
};

export const RESET_ANSWERS = "RESET_ANSWERS";

export const resetAnswers = () => (dispatch) => {
  dispatch({
    type: RESET_ANSWERS,
  });
};

export const ADD_ANSWER = "ADD_ANSWER";

export const addAnswer = (answerId) => (dispatch) => {
  dispatch({
    type: ADD_ANSWER,
    payload: {
      answerId,
    },
  });
};

export const REMOVE_ANSWER = "REMOVE_ANSWER";

export const removeAnswer = (answerId) => (dispatch) => {
  dispatch({
    type: REMOVE_ANSWER,
    payload: {
      answerId,
    },
  });
};

export const SET_ADDITIONAL_ANSWER = "SET_ADDITIONAL_ANSWER";

export const setAdditionalAnswer = (key, data) => (dispatch) => {
  dispatch({
    type: SET_ADDITIONAL_ANSWER,
    payload: {
      key,
      data,
    },
  });
};

export const LOAD_CLIENTS_REQUEST = "LOAD_CLIENTS_REQUEST";
export const LOAD_CLIENTS_SUCCESS = "LOAD_CLIENTS_SUCCESS";
export const LOAD_CLIENTS_FAILURE = "LOAD_CLIENTS_FAILURE";

export const loadClients = () => async (dispatch) => {
  dispatch({
    type: LOAD_CLIENTS_SUCCESS,
  });
  return [];

  try {
    const { data: response } = await dispatch(
      fetch(`/Clients`, {
        method: "GET",
      })
    );
    dispatch({
      type: LOAD_CLIENTS_SUCCESS,
    });
    return response;
  } catch (e) {
    dispatch({
      type: LOAD_CLIENTS_FAILURE,
    });
  }
};

export const LOAD_SUPPLIERS_SUCCESS = "LOAD_SUPPLIERS_SUCCESS";
export const LOAD_SUPPLIERS_FAILURE = "LOAD_SUPPLIERS_FAILURE";

export const loadSuppliers = () => async (dispatch) => {
  dispatch({
    type: LOAD_SUPPLIERS_SUCCESS,
  });
  return [];

  try {
    const { data: response } = await dispatch(
      fetch(`/Suppliers`, {
        method: "GET",
      })
    );
    dispatch({
      type: LOAD_SUPPLIERS_SUCCESS,
    });
    return response;
  } catch (e) {
    dispatch({
      type: LOAD_SUPPLIERS_FAILURE,
    });
  }
};

export const LOAD_CLIENTS_SUPPLIERS_REQUEST = "LOAD_CLIENTS_SUPPLIERS_REQUEST";
export const LOAD_CLIENTS_SUPPLIERS_SUCCESS = "LOAD_CLIENTS_SUPPLIERS_SUCCESS";
export const LOAD_CLIENTS_SUPPLIERS_FAILURE = "LOAD_CLIENTS_SUPPLIERS_FAILURE";

export const loadClientsAndSuppliers = () => async (dispatch) => {
  dispatch({ type: LOAD_CLIENTS_SUPPLIERS_REQUEST });

  const clients = await dispatch(loadClients());
  const suppliers = await dispatch(loadSuppliers());

  dispatch({
    type: LOAD_CLIENTS_SUPPLIERS_SUCCESS,
    payload: {
      clients,
      suppliers,
    },
  });
};

export const ADD_NEW_CLIENT = "ADD_NEW_CLIENT";
export const ADD_NEW_CLIENT_FAILURE = "ADD_NEW_CLIENT_FAILURE";

export const addNewClient = (data) => async (dispatch) => {
  try {
    const { data: response } = await dispatch(
      fetch(`/Clients`, {
        method: "POST",
        data,
      })
    );
    await dispatch({
      type: ADD_NEW_CLIENT,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: ADD_NEW_CLIENT_FAILURE,
    });
  }
};

export const ADD_NEW_SUPPLIER = "ADD_NEW_SUPPLIER";

export const addNewSupplier = (data) => async (dispatch) => {
  try {
    const { data: response } = await dispatch(
      fetch(`/Suppliers`, {
        method: "POST",
        data,
      })
    );
    dispatch({
      type: ADD_NEW_SUPPLIER,
      payload: response,
    });
    return response;
  } catch (error) {
    console.error(error);
  }
};

export const CREATE_ENGAGEMENT_REQUEST = "CREATE_ENGAGEMENT_REQUEST";
export const CREATE_ENGAGEMENT_SUCCESS = "CREATE_ENGAGEMENT_SUCCESS";
export const CREATE_ENGAGEMENT_FAILURE = "CREATE_ENGAGEMENT_FAILURE";

export const createEngagement = (data) => async (dispatch) => {
  dispatch({
    type: CREATE_ENGAGEMENT_REQUEST,
  });
  try {
    const { data: response } = await dispatch(
      fetch(`/ContractorEngagements`, {
        method: "POST",
        data,
      })
    );
    dispatch({
      type: CREATE_ENGAGEMENT_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: CREATE_ENGAGEMENT_FAILURE,
    });
  }
};

export const SAVE_ENGAGEMENT_REQUEST = "SAVE_ENGAGEMENT_REQUEST";
export const SAVE_ENGAGEMENT_SUCCESS = "SAVE_ENGAGEMENT_SUCCESS";
export const SAVE_ENGAGEMENT_FAILURE = "SAVE_ENGAGEMENT_FAILURE";

export const saveEngagement = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_ENGAGEMENT_REQUEST,
  });
  try {
    const id = data.id;
    delete data.id;
    const { data: response } = await dispatch(
      fetch(`/ContractorEngagements/${id}`, {
        method: "PUT",
        data,
      })
    );
    dispatch({
      type: SAVE_ENGAGEMENT_SUCCESS,
      payload: data,
    });
    if (data.assessment) {
      dispatch(loadReportingData(data.assessment.id));
    }
    return response;
  } catch (error) {
    dispatch({
      type: SAVE_ENGAGEMENT_FAILURE,
    });
  }
};

export const SUBMIT_ASSESSMENT_REQUEST = "SUBMIT_ASSESSMENT_REQUEST";
export const SUBMIT_ASSESSMENT_SUCCESS = "SUBMIT_ASSESSMENT_SUCCESS";
export const SUBMIT_ASSESSMENT_FAILURE = "SUBMIT_ASSESSMENT_FAILURE";

export const submitAssessment =
  (
    contractorId,
    assessmentId,
    engagementId,
    answers,
    submittedBy,
    reopen,
    notes
  ) =>
    async (dispatch) => {
      dispatch({
        type: SUBMIT_ASSESSMENT_REQUEST,
      });

      let data = {
        assessment_id: assessmentId,
        engagement_id: engagementId,
        answers,
        submittedBy,
        reopen,
        notes,
      };

      try {
        const { data: response } = await dispatch(
          fetch(`/UserAssessments/submitNewAssessment`, {
            method: "POST",
            data,
          })
        );

        dispatch({
          type: SUBMIT_ASSESSMENT_SUCCESS,
          payload: {
            assessment: response,
            engagementId,
            contractorId: contractorId === "rolebased" ? -1 : contractorId,
          },
        });

        dispatch(getMe());

        return response;
      } catch (error) {
        dispatch({
          type: SUBMIT_ASSESSMENT_FAILURE,
        });
      }
    };

export const SUBMIT_CONTRACTOR_ANSWERS_REQUEST =
  "SUBMIT_CONTRACTOR_ANSWERS_REQUEST";
export const SUBMIT_CONTRACTOR_ANSWERS_SUCCESS =
  "SUBMIT_CONTRACTOR_ANSWERS_SUCCESS";
export const SUBMIT_CONTRACTOR_ANSWERS_FAILURE =
  "SUBMIT_CONTRACTOR_ANSWERS_FAILURE";

export const submitContractorAnswers =
  (key, answers, submittedBy, notes, additionalAnswers) => async (dispatch) => {
    dispatch({
      type: SUBMIT_CONTRACTOR_ANSWERS_REQUEST,
    });

    let data = {
      key,
      answers,
      notes,
      submittedBy,
      additionalAnswers,
    };

    try {
      const { data: response } = await dispatch(
        fetch(`/UserAssessments/submitContractorAnswers`, {
          method: "POST",
          data,
        })
      );

      dispatch({
        type: SUBMIT_CONTRACTOR_ANSWERS_SUCCESS,
        payload: {
          assessment: response,
        },
      });

      return response;
    } catch (error) {
      dispatch({
        type: SUBMIT_CONTRACTOR_ANSWERS_FAILURE,
      });
    }
  };

export const LOAD_REPORTING_DATA_REQUEST = "LOAD_REPORTING_DATA_REQUEST";
export const LOAD_REPORTING_DATA_SUCCESS = "LOAD_REPORTING_DATA_SUCCESS";
export const LOAD_REPORTING_DATA_FAILURE = "LOAD_REPORTING_DATA_FAILURE";

export const loadReportingData = (assessmentId) => async (dispatch) => {
  dispatch({
    type: LOAD_REPORTING_DATA_REQUEST,
  });
  dispatch(clearReportingData());

  try {
    const { data: response } = await dispatch(
      fetch(`/UserAssessments/getReportingData/${assessmentId}`, {
        method: "GET",
      })
    );

    dispatch({
      type: LOAD_REPORTING_DATA_SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: LOAD_REPORTING_DATA_FAILURE,
    });
  }
};

export const CLEAR_REPORTING_DATA = "CLEAR_REPORTING_DATA";

export const clearReportingData = () => async (dispatch) => {
  dispatch({
    type: CLEAR_REPORTING_DATA,
  });
};

export const LOAD_DASHBOARD_REQUEST = "LOAD_DASHBOARD_REQUEST";
export const LOAD_DASHBOARD_SUCCESS = "LOAD_DASHBOARD_SUCCESS";
export const LOAD_DASHBOARD_FAILURE = "LOAD_DASHBOARD_FAILURE";

export const loadDashboard =
  (companyId, archived = false) =>
    async (dispatch) => {
      dispatch({
        type: LOAD_DASHBOARD_REQUEST,
      });

      try {
        const { data: response } = await dispatch(
          fetch(`/ContractorEngagements/dashboard/${companyId}/${archived}`, {
            method: "GET",
          })
        );
        dispatch({
          type: LOAD_DASHBOARD_SUCCESS,
          payload: response,
        });
        return response;
      } catch (error) {
        dispatch({
          type: LOAD_DASHBOARD_FAILURE,
        });
      }
    };

export const SET_VALIDATIONS = "SET_VALIDATIONS";

export const setValidations = (validations) => async (dispatch) => {
  dispatch({
    type: SET_VALIDATIONS,
    payload: validations,
  });
};

export const GET_ASSESSMENT_BY_USER_ASSESSMENT_REQUEST =
  "GET_ASSESSMENT_BY_USER_ASSESSMENT_REQUEST";
export const GET_ASSESSMENT_BY_USER_ASSESSMENT_SUCCESS =
  "GET_ASSESSMENT_BY_USER_ASSESSMENT_SUCCESS";
export const GET_ASSESSMENT_BY_USER_ASSESSMENT_FAILURE =
  "GET_ASSESSMENT_BY_USER_ASSESSMENT_FAILURE";

export const getAssessmentByUserAssessment =
  (assessmentId) => async (dispatch) => {
    dispatch({
      type: GET_ASSESSMENT_BY_USER_ASSESSMENT_REQUEST,
    });

    try {
      const { data: response } = await dispatch(
        fetch(
          `/Assessments/get-assessment-by-user-assessment/${assessmentId}`,
          {
            method: "GET",
          }
        )
      );

      dispatch({
        type: GET_ASSESSMENT_BY_USER_ASSESSMENT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: GET_ASSESSMENT_BY_USER_ASSESSMENT_FAILURE,
      });
    }
  };

export const RESTORE_ASSESSMENT_ANSWERS_REQUEST =
  "RESTORE_ASSESSMENT_ANSWERS_REQUEST";
export const RESTORE_ASSESSMENT_ANSWERS_SUCCESS =
  "RESTORE_ASSESSMENT_ANSWERS_SUCCESS";
export const RESTORE_ASSESSMENT_ANSWERS_FAILURE =
  "RESTORE_ASSESSMENT_ANSWERS_FAILURE";

export const restoreAssessmentAnswers = (assessmentId) => async (dispatch) => {
  dispatch({
    type: RESTORE_ASSESSMENT_ANSWERS_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/UserAssessments/restore-answers-by-id/${assessmentId}`, {
        method: "GET",
      })
    );

    dispatch({
      type: RESTORE_ASSESSMENT_ANSWERS_SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: RESTORE_ASSESSMENT_ANSWERS_FAILURE,
    });
  }
};

//Contractor Assessment Portal
export const CONTRACTOR_SUBMIT_QUESTIONS_REQUEST =
  "CONTRACTOR_SUBMIT_QUESTIONS_REQUEST";
export const CONTRACTOR_SUBMIT_QUESTIONS_SUCCESS =
  "CONTRACTOR_SUBMIT_QUESTIONS_SUCCESS";
export const CONTRACTOR_SUBMIT_QUESTIONS_FAILURE =
  "CONTRACTOR_SUBMIT_QUESTIONS_FAILURE";

export const contractorSubmitAnswers =
  (token, answers, notes, additionalAnswers) => async (dispatch) => {
    dispatch({
      type: CONTRACTOR_SUBMIT_QUESTIONS_REQUEST,
    });

    let data = {
      answers,
      notes,
      additionalAnswers,
    };

    try {
      const { data: response } = await dispatch(
        fetch(`/UserAssessments/persist-answers-to-assessment/${token}`, {
          method: "POST",
          data,
        })
      );

      dispatch({
        type: CONTRACTOR_SUBMIT_QUESTIONS_SUCCESS,
        payload: {
          assessment: response,
        },
      });

      return response;
    } catch (error) {
      dispatch({
        type: CONTRACTOR_SUBMIT_QUESTIONS_FAILURE,
      });
    }
  };

export const CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_REQUEST =
  "CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_REQUEST";
export const CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_SUCCESS =
  "CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_SUCCESS";
export const CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_FAILURE =
  "CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_FAILURE";

export const contractorGetAssessmentMasterByToken =
  (token) => async (dispatch) => {
    dispatch({
      type: CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_REQUEST,
    });

    try {
      const { data: response } = await dispatch(
        fetch(`/Assessments/get-assessment-by-id/${token}`, {
          method: "GET",
        })
      );

      dispatch({
        type: CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_FAILURE,
      });
    }
  };

export const CONTRACTOR_GENERATE_ASSESSMENT_REQUEST =
  "CONTRACTOR_GENERATE_ASSESSMENT_REQUEST";
export const CONTRACTOR_GENERATE_ASSESSMENT_SUCCESS =
  "CONTRACTOR_GENERATE_ASSESSMENT_SUCCESS";
export const CONTRACTOR_GENERATE_ASSESSMENT_FAILURE =
  "CONTRACTOR_GENERATE_ASSESSMENT_FAILURE";

export const generateAssessment = () => async (dispatch) => {
  dispatch({
    type: CONTRACTOR_GENERATE_ASSESSMENT_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/UserAssessments/generate-blank-assessment`, {
        method: "GET",
      })
    );

    sessionStorage.setItem("assessment", response.identifier);

    dispatch({
      type: CONTRACTOR_GENERATE_ASSESSMENT_SUCCESS,
      payload: response.identifier,
    });

    return response.identifier;
  } catch (error) {
    dispatch({
      type: CONTRACTOR_GET_ASSESSMENT_MASTER_BY_TOKEN_FAILURE,
    });
  }
};

export const restoreAssessmentId = () => async (dispatch) => {
  const storedAssessmentId = sessionStorage.getItem("assessment");
  let token;
  if (storedAssessmentId) {
    token = await dispatch({
      type: CONTRACTOR_GENERATE_ASSESSMENT_SUCCESS,
      payload: storedAssessmentId,
    });
    token = storedAssessmentId;
  } else {
    token = await dispatch(generateAssessment());
  }

  dispatch(restoreAnswers(token));
};

export const CONTRACTOR_RESTORE_ANSWERS_REQUEST =
  "CONTRACTOR_RESTORE_ANSWERS_REQUEST";
export const CONTRACTOR_RESTORE_ANSWERS_SUCCESS =
  "CONTRACTOR_RESTORE_ANSWERS_SUCCESS";
export const CONTRACTOR_RESTORE_ANSWERS_FAILURE =
  "CONTRACTOR_RESTORE_ANSWERS_FAILURE";

export const restoreAnswers = (token) => async (dispatch) => {
  dispatch({
    type: CONTRACTOR_RESTORE_ANSWERS_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/UserAssessments/restore-answers/${token}`, {
        method: "GET",
      })
    );

    dispatch({
      type: CONTRACTOR_RESTORE_ANSWERS_SUCCESS,
      payload: response || [],
    });

    return response.identifier;
  } catch (error) {
    dispatch({
      type: CONTRACTOR_RESTORE_ANSWERS_FAILURE,
    });
  }
};

export const CONTRACTOR_CLOSE_ASSESSMENT_REQUEST =
  "CONTRACTOR_CLOSE_ASSESSMENT_REQUEST";
export const CONTRACTOR_CLOSE_ASSESSMENT_SUCCESS =
  "CONTRACTOR_CLOSE_ASSESSMENT_SUCCESS";
export const CONTRACTOR_CLOSE_ASSESSMENT_FAILURE =
  "CONTRACTOR_CLOSE_ASSESSMENT_FAILURE";

export const closeContractorAssessment =
  (token, contractor, engagement) => async (dispatch) => {
    dispatch({
      type: CONTRACTOR_CLOSE_ASSESSMENT_REQUEST,
    });

    if (sessionStorage.getItem("passedReference")) {
      engagement.passed_reference = sessionStorage.getItem("passedReference");
    }
    if (sessionStorage.getItem("campaign")) {
      engagement.campaign = sessionStorage.getItem("campaign");
    }
    if (sessionStorage.getItem("RAF")) {
      engagement.RAF = sessionStorage.getItem("RAF");
    }

    try {
      const { data: response } = await dispatch(
        fetch(`/UserAssessments/complete-contractor-assessment/${token}`, {
          method: "POST",
          data: {
            contractor,
            engagement,
          },
        })
      );

      sessionStorage.removeItem("assessment");
      sessionStorage.removeItem("passedReference");
      sessionStorage.removeItem("campaign");

      dispatch({
        type: CONTRACTOR_CLOSE_ASSESSMENT_SUCCESS,
        payload: response || [],
      });

      return response;
    } catch (error) {
      dispatch({
        type: CONTRACTOR_CLOSE_ASSESSMENT_FAILURE,
        payload: error && error.data && error.data.error,
      });
    }
  };

export const CONTRACTOR_LOAD_RESULT_REQUEST = "CONTRACTOR_LOAD_RESULT_REQUEST";
export const CONTRACTOR_LOAD_RESULT_SUCCESS = "CONTRACTOR_LOAD_RESULT_SUCCESS";
export const CONTRACTOR_LOAD_RESULT_FAILURE = "CONTRACTOR_LOAD_RESULT_FAILURE";

export const contractorLoadResult = (token) => async (dispatch) => {
  dispatch({
    type: CONTRACTOR_CLOSE_ASSESSMENT_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/UserAssessments/get-result-by-token/${token}`, {
        method: "GET",
      })
    );

    dispatch({
      type: CONTRACTOR_LOAD_RESULT_SUCCESS,
      payload: response || [],
    });

    return response.identifier;
  } catch (error) {
    dispatch({
      type: CONTRACTOR_LOAD_RESULT_FAILURE,
    });
  }
};

export const CONTRACTOR_BUY_REPORT_REQUEST = "CONTRACTOR_BUY_REPORT_REQUEST";
export const CONTRACTOR_BUY_REPORT_SUCCESS = "CONTRACTOR_BUY_REPORT_SUCCESS";
export const CONTRACTOR_BUY_REPORT_FAILURE = "CONTRACTOR_BUY_REPORT_FAILURE";

export const contractorBuyReport =
  (token, paymentToken) => async (dispatch) => {
    dispatch({
      type: CONTRACTOR_BUY_REPORT_REQUEST,
    });

    try {
      const { data: response } = await dispatch(
        fetch(`/UserAssessments/purchase-report/${token}`, {
          method: "POST",
          data: {
            paymentToken,
          },
        })
      );

      dispatch({
        type: CONTRACTOR_BUY_REPORT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CONTRACTOR_BUY_REPORT_FAILURE,
        payload: error,
      });
    }
  };

export const contractorConfirmPayment =
  (token, intentId) => async (dispatch) => {
    dispatch({
      type: CONTRACTOR_BUY_REPORT_REQUEST,
    });

    try {
      const { data: response } = await dispatch(
        fetch(`/UserAssessments/confirm-payment/${token}`, {
          method: "POST",
          data: {
            intentId,
          },
        })
      );

      dispatch({
        type: CONTRACTOR_BUY_REPORT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CONTRACTOR_BUY_REPORT_FAILURE,
        payload: error,
      });
    }
  };

export const contractorBuySubscription =
  (token, paymentToken, email, password) => async (dispatch) => {
    dispatch({
      type: CONTRACTOR_BUY_REPORT_REQUEST,
    });

    try {
      const { data: response } = await dispatch(
        fetch(`/UserAssessments/purchase-subscription/${token}`, {
          method: "POST",
          data: {
            paymentToken,
            email,
            password,
          },
        })
      );

      dispatch({
        type: CONTRACTOR_BUY_REPORT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CONTRACTOR_BUY_REPORT_FAILURE,
        payload: error,
      });
    }
  };

export const contractorConfirmSubscriptionPayment =
  (token, intentId, password) => async (dispatch) => {
    dispatch({
      type: CONTRACTOR_BUY_REPORT_REQUEST,
    });

    try {
      const { data: response } = await dispatch(
        fetch(`/UserAssessments/confirm-subscription-payment/${token}`, {
          method: "POST",
          data: {
            intentId,
            password,
          },
        })
      );

      dispatch({
        type: CONTRACTOR_BUY_REPORT_SUCCESS,
        payload: response,
      });

      return response;
    } catch (error) {
      dispatch({
        type: CONTRACTOR_BUY_REPORT_FAILURE,
        payload: error,
      });
    }
  };

export const SEND_LEAD_REQUEST = "SEND_LEAD_REQUEST";
export const SEND_LEAD_SUCCESS = "SEND_LEAD_SUCCESS";
export const SEND_LEAD_FAILURE = "SEND_LEAD_FAILURE";

export const sendLead = (data) => async (dispatch) => {
  dispatch({
    type: SEND_LEAD_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/UserAssessments/send-lead`, {
        method: "POST",
        data,
      })
    );

    dispatch({
      type: SEND_LEAD_SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: SEND_LEAD_FAILURE,
      payload: error,
    });
  }
};

export const CLEAR_LEAD_FLAGS = "CLEAR_LEAD_FLAGS";

export const clearLeadFlags = () => async (dispatch) => {
  dispatch({
    type: CLEAR_LEAD_FLAGS,
  });
};

export const LOAD_ROLE_ASSESSMENTS_REQUEST = "LOAD_ROLE_ASSESSMENTS_REQUEST";
export const LOAD_ROLE_ASSESSMENTS_SUCCESS = "LOAD_ROLE_ASSESSMENTS_SUCCESS";
export const LOAD_ROLE_ASSESSMENTS_FAILURE = "LOAD_ROLE_ASSESSMENTS_FAILURE";

export const loadRoleAssessments =
  (companyId = 0) =>
    async (dispatch) => {
      dispatch({
        type: LOAD_ROLE_ASSESSMENTS_REQUEST,
      });

      try {
        const { data: response } = await dispatch(
          fetch(`/ContractorEngagements/role-assessments/${companyId}`, {
            method: "GET",
          })
        );

        // Order by role_name asc by default
        response.sort((a, b) => {
          let fa = a.role_name.toLowerCase(),
            fb = b.role_name.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
        dispatch({
          type: LOAD_ROLE_ASSESSMENTS_SUCCESS,
          payload: response,
        });

        return response;
      } catch (error) {
        dispatch({
          type: LOAD_ROLE_ASSESSMENTS_FAILURE,
          payload: error,
        });
      }
    };

export const REMOVE_ROLE_ASSESSMENTS_REQUEST =
  "REMOVE_ROLE_ASSESSMENTS_REQUEST";
export const REMOVE_ROLE_ASSESSMENTS_SUCCESS =
  "REMOVE_ROLE_ASSESSMENTS_SUCCESS";
export const REMOVE_ROLE_ASSESSMENTS_FAILURE =
  "REMOVE_ROLE_ASSESSMENTS_FAILURE";

export const removeRoleAssessment = (engagementId) => async (dispatch) => {
  dispatch({
    type: REMOVE_ROLE_ASSESSMENTS_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/ContractorEngagements/role-assessments/hide/${engagementId}`, {
        method: "GET",
      })
    );

    dispatch({
      type: REMOVE_ROLE_ASSESSMENTS_SUCCESS,
      engagementId,
    });

    return response;
  } catch (error) {
    dispatch({
      type: REMOVE_ROLE_ASSESSMENTS_FAILURE,
      payload: error,
    });
  }
};
export const LOAD_CONTRACTOR_ASSESSMENTS_REQUEST =
  "LOAD_CONTRACTOR_ASSESSMENTS_REQUEST";
export const LOAD_CONTRACTOR_ASSESSMENTS_SUCCESS =
  "LOAD_CONTRACTOR_ASSESSMENTS_SUCCESS";
export const LOAD_CONTRACTOR_ASSESSMENTS_FAILURE =
  "LOAD_CONTRACTOR_ASSESSMENTS_FAILURE";

export const loadContractorAssessments = () => async (dispatch) => {
  dispatch({
    type: LOAD_CONTRACTOR_ASSESSMENTS_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/UserAssessments/contractor-assessments`, {
        method: "GET",
      })
    );

    dispatch({
      type: LOAD_CONTRACTOR_ASSESSMENTS_SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: LOAD_CONTRACTOR_ASSESSMENTS_FAILURE,
      payload: error,
    });
  }
};

export const RESEND_CONTRACTOR_QUESTIONS_SUCCESS =
  "RESEND_CONTRACTOR_QUESTIONS_SUCCESS";
export const RESEND_CONTRACTOR_QUESTIONS_FAILURE =
  "RESEND_CONTRACTOR_QUESTIONS_FAILURE";

export const resendContractorQuestions = (engagementId) => async (dispatch) => {
  try {
    const { data: response } = await dispatch(
      fetch(`/UserAssessments/resendContractorEmail/${engagementId}`, {
        method: "GET",
      })
    );
    dispatch({
      type: RESEND_CONTRACTOR_QUESTIONS_SUCCESS,
    });
    return response;
  } catch (response) {
    dispatch({
      type: RESEND_CONTRACTOR_QUESTIONS_FAILURE,
    });
    console.log("R", response);
    throw {
      ...response,
      error: new Error(),
    };
  }
};

export const ADD_NEW_NOTE = "ADD_NEW_NOTE";
export const addNote = (questionId, note) => (dispatch, getState) => {
  const user = getUser(getState());

  dispatch({
    type: ADD_NEW_NOTE,
    note,
    questionId,
    name: user
      ? `${user.userData.first_name} ${user.userData.last_name}`
      : "Worker",
    date: new Date(),
  });
};

export const SUBMIT_OVERRIDE_REQUEST = "SUBMIT_OVERRIDE_REQUEST";
export const SUBMIT_OVERRIDE_SUCCESS = "SUBMIT_OVERRIDE_SUCCESS";
export const SUBMIT_OVERRIDE_FAILURE = "SUBMIT_OVERRIDE_FAILURE";

export const submitOverride = (id, newResult, reason) => async (dispatch) => {
  dispatch({
    type: SUBMIT_OVERRIDE_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/UserAssessments/override-result/${id}`, {
        method: "POST",
        data: {
          new_result_reason: reason,
          new_result: newResult,
        },
      })
    );

    dispatch({
      type: SUBMIT_OVERRIDE_SUCCESS,
      payload: {
        assessment: response,
      },
    });

    dispatch(getMe());

    return response;
  } catch (response) {
    throw {
      ...response,
      error: new Error(),
    };
  }
};

export const CHANGE_ARCHIVE_REQUEST = "CHANGE_ARCHIVE_REQUEST";
export const CHANGE_ARCHIVE_SUCCESS = "CHANGE_ARCHIVE_SUCCESS";
export const CHANGE_ARCHIVE_FAILURE = "CHANGE_ARCHIVE_FAILURE";

export const changeArchive = (id, archived) => async (dispatch) => {
  dispatch({
    type: CHANGE_ARCHIVE_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/AssessmentReports/${id}`, {
        method: "PATCH",
        data: {
          archived,
        },
      })
    );

    dispatch({
      type: CHANGE_ARCHIVE_SUCCESS,
      id,
      archived,
    });

    return response;
  } catch (response) {
    throw {
      ...response,
      error: new Error(),
    };
  }
};

export const CHANGE_ARCHIVE_SETTING = "CHANGE_ARCHIVE_SETTING";

export const setArchiveSetting = (setting) => async (dispatch) => {
  dispatch({
    type: CHANGE_ARCHIVE_SETTING,
    setting,
  });
};

export const SET_PROGRESS_SEARCH_TERM = "SET_PROGRESS_SEARCH_TERM";

export const setProgressSearchTerm = (term) => async (dispatch) => {
  dispatch({
    type: SET_PROGRESS_SEARCH_TERM,
    term,
  });
};

export const SET_PROGRESS_FROM = "SET_PROGRESS_FROM";

export const setProgressFrom = (from) => async (dispatch) => {
  dispatch({
    type: SET_PROGRESS_FROM,
    from,
  });
};

export const SET_PROGRESS_TO = "SET_PROGRESS_TO";

export const setProgressTo = (to) => async (dispatch) => {
  dispatch({
    type: SET_PROGRESS_TO,
    to,
  });
};

export const SET_PROGRESS_INSIDE = "SET_PROGRESS_INSIDE";

export const setProgressInside = (setting) => async (dispatch) => {
  dispatch({
    type: SET_PROGRESS_INSIDE,
    setting,
  });
};

export const SET_PROGRESS_BORDERLINE = "SET_PROGRESS_BORDERLINE";

export const setProgressBorderline = (setting) => async (dispatch) => {
  dispatch({
    type: SET_PROGRESS_BORDERLINE,
    setting,
  });
};

export const SET_PROGRESS_OUTSIDE = "SET_PROGRESS_OUTSIDE";

export const setProgressOutside = (setting) => async (dispatch) => {
  dispatch({
    type: SET_PROGRESS_OUTSIDE,
    setting,
  });
};

export const LOAD_NOTIFICATION_USERS_REQUEST =
  "LOAD_NOTIFICATION_USERS_REQUEST";
export const LOAD_NOTIFICATION_USERS_SUCCESS =
  "LOAD_NOTIFICATION_USERS_SUCCESS";
export const LOAD_NOTIFICATION_USERS_FAILURE =
  "LOAD_NOTIFICATION_USERS_FAILURE";

export const loadNotificationUsers = (assessmentId) => async (dispatch) => {
  dispatch({
    type: LOAD_NOTIFICATION_USERS_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/Subscribers?filter[where][entity_id]=${assessmentId}`, {
        method: "GET",
      })
    );

    dispatch({
      type: LOAD_NOTIFICATION_USERS_SUCCESS,
      assessmentId,
      response,
    });

    return response;
  } catch (response) {
    throw {
      ...response,
      error: new Error(),
    };
  }
};

export const SUBSCRIBE_REQUEST = "SUBSCRIBE_REQUEST";
export const SUBSCRIBE_SUCCESS = "SUBSCRIBE_SUCCESS";
export const SUBSCRIBE_FAILURE = "SUBSCRIBE_FAILURE";

export const subscribe = (userId, entityId) => async (dispatch) => {
  dispatch({
    type: SUBSCRIBE_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/Subscribers`, {
        method: "POST",
        data: {
          entity_id: entityId,
          user_id: userId,
        },
      })
    );

    dispatch({
      type: SUBSCRIBE_SUCCESS,
      response,
    });

    return response;
  } catch (response) {
    throw {
      ...response,
      error: new Error(),
    };
  }
};

export const UNSUBSCRIBE_REQUEST = "UNSUBSCRIBE_REQUEST";
export const UNSUBSCRIBE_SUCCESS = "UNSUBSCRIBE_SUCCESS";

export const unsubscribe = (id) => async (dispatch) => {
  dispatch({
    type: UNSUBSCRIBE_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/Subscribers/${id}`, {
        method: "DELETE",
      })
    );

    dispatch({
      type: UNSUBSCRIBE_SUCCESS,
      response,
    });

    return response;
  } catch (response) {
    throw {
      ...response,
      error: new Error(),
    };
  }
};

export const ENGAGEMENT_SET_DATES_REQUEST = "ENGAGEMENT_SET_DATES_REQUEST";
export const ENGAGEMENT_SET_DATES_SUCCESS = "ENGAGEMENT_SET_DATES_SUCCESS";
export const ENGAGEMENT_SET_DATES_FAILURE = "ENGAGEMENT_SET_DATES_FAILURE";

export const setEngagementDates =
  (engagementId, frequency) => async (dispatch) => {
    dispatch({
      type: ENGAGEMENT_SET_DATES_REQUEST,
    });

    try {
      const { data: response } = await dispatch(
        fetch(`/ContractorEngagements/set-reassessment-dates`, {
          method: "POST",
          data: {
            engagementId,
            frequency,
          },
        })
      );

      dispatch({
        type: ENGAGEMENT_SET_DATES_SUCCESS,
        response,
      });

      return response;
    } catch (response) {
      throw {
        ...response,
        error: new Error(),
      };
    }
  };

export const COPY_ENGAGEMENT = "COPY_ENGAGEMENT";

export const copyEngagement = (engagement) => async (dispatch) => {
  dispatch({
    type: COPY_ENGAGEMENT,
    engagement,
  });
};

export const ACTIVITY_LOG_REQUEST = "ACTIVITY_LOG_REQUEST";
export const ACTIVITY_LOG_SUCCESS = "ACTIVITY_LOG_SUCCESS";
export const ACTIVITY_LOG_FAILURE = "ACTIVITY_LOG_FAILURE";

export const loadActivityLog = (engagementId) => async (dispatch) => {
  dispatch({
    type: ACTIVITY_LOG_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/ActivityLogs/view/${engagementId}`, {
        method: "GET",
      })
    );

    dispatch({
      type: ACTIVITY_LOG_SUCCESS,
      response,
    });

    return response;
  } catch (response) {
    throw {
      ...response,
      error: new Error(),
    };
  }
};
export const SET_ASSESSMENT_SEARCH = "SET_ASSESSMENT_SEARCH";

export const setSearchTerm = (searchTerm) => async (dispatch) => {
  dispatch({
    type: SET_ASSESSMENT_SEARCH,
    payload: {
      searchTerm,
    },
  });
};
