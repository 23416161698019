import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { loadDashboard } from "../store/assessment/actions";
import {
  getDashboardData,
  getDashboardLoading,
} from "../store/assessment/selectors";
import { getUserCompany } from "../store/users/selectors";
import {
  faAsterisk,
  faBalanceScaleLeft,
  faCheck,
  faClock,
  faEye,
  faFileAlt,
  faPaperPlane,
  faArrowRight,
  faUserFriends,
  faClone,
} from "@fortawesome/free-solid-svg-icons";
import { loadClients, setCurrentClient } from "../store/clients/actions";
import { getClients, getCurrentClient } from "../store/clients/selectors";
import { TitleArea } from "../components/Elements";
import Loader from "../components/Loader";
import { generateClientOptions } from "./Contractors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tabs, tabStyles } from "../components/Tabs";
import { ContentArea } from "../components/Elements";
import Tags from "./Tags";
import RunReports from "./RunReports";
import Combo from "../components/Combo";

const Main = styled.div`
  display: flex;
  height: 100%;
`;

const Wrap = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
`;

const TabsWrapper = styled.div`
  margin: 20px 0px 0;
`;

const StyledTabs = styled(Tabs)`
  margin-left: 0px;
  margin-right: 0px;
`;

const Tab = styled(NavLink)`
  ${tabStyles}
  text-decoration: none;
`;

const TabContent = styled.div`
  background-color: #fff;
  margin: 20px 20px 20px 20px;
  border-radius: 10px;
  padding: 20px;
`;

const StyledCombo = styled(Combo)`
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: 30px;
`;

function Reporting({ history }) {
  const dispatch = useDispatch();
  const userCompany = useSelector(getUserCompany);
  const currentClient = useSelector(getCurrentClient);
  const company = useSelector(getUserCompany);

  const clients = useSelector(getClients);
  let clientOptions = [];
  if (clients) {
    clientOptions = generateClientOptions(clients, userCompany);
  }

  useEffect(() => {
    dispatch(loadClients());
  }, []);

  useEffect(() => {
    if (userCompany && !currentClient) {
      dispatch(setCurrentClient(userCompany.id));
    }
  }, [userCompany]);

  useEffect(() => {
    dispatch(loadDashboard(currentClient === 0 ? company.id : currentClient));
  }, [currentClient]);

  return (
    <Main>
      <Wrap>
        <div>
          <TitleArea>
            <div className="title">
              <h1>Reporting</h1>
            </div>
          </TitleArea>
          {clients && clients.length ? (
            <StyledCombo
              options={clientOptions}
              value={currentClient}
              onChange={(newValue) => {
                dispatch(setCurrentClient(newValue));
              }}
            />
          ) : null}
        </div>
        <TabsWrapper>
          <StyledTabs>
            <Tab to="/main/reporting" exact>
              Run Reports
            </Tab>
            <Tab to="/main/reporting/tags" exact>
              Manage Tags
            </Tab>
          </StyledTabs>
        </TabsWrapper>
        <TabContent>
          <Switch>
            <Route path="/main/reporting" component={RunReports} exact />
            <Route path="/main/reporting/tags" component={Tags} exact />
          </Switch>
        </TabContent>
      </Wrap>
    </Main>
  );
}

export default withRouter(Reporting);
