import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { orderBy, debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import formatCurrency from "format-currency";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import { generateClientOptions } from "./Contractors";
import {
  getRoleAssessmentsLoading,
  getRoleAssessments,
  getRoleAssessmentsError,
  getSearchTerm
} from "../store/assessment/selectors";
import { getUserCompany } from "../store/users/selectors";
import {
  loadRoleAssessments,
  removeRoleAssessment,
  setSearchTerm
} from "../store/assessment/actions";
import { loadClients, setCurrentClient } from "../store/clients/actions";
import { getClients, getCurrentClient } from "../store/clients/selectors";
import { TitleArea, ContentArea, Empty, Wrapper } from "../components/Elements";
import noContractors from "../assets/images/no-contractors.svg";
import { getDisplayName } from "../components/ResultScreen";
import Button from "../components/Button";
import Loader from "../components/Loader";
import FormField from "../components/FormField";
import { mainColor } from "../styles/colors";
import Combo from "../components/Combo";

const TableWrapper = styled.div`
  margin: 0px 20px;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 4px;
  flex: 1;
`;

const Name = styled.div`
  .initials {
    border-radius: 50%;
    background-color: ${mainColor};
    color: #fff;
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: inline-block;
  }
`;

const StyledCombo = styled(Combo)`
  margin-bottom: 20px;
  margin-top: 20px;
`;

function Contractors({ history, match }) {
  const dispatch = useDispatch();
  const [roleAssessments, setRoleAssessments] = useState([]);

  const loading = useSelector(getRoleAssessmentsLoading);

  const roleAssessmentsError = useSelector(getRoleAssessmentsError);
  const clients = useSelector(getClients);
  const currentClient = useSelector(getCurrentClient);
  const Assessments = useSelector((state) => getRoleAssessments(state, currentClient));
  const userCompany = useSelector(getUserCompany);
  const userCompanyId = userCompany && userCompany.id;
  const searchTerm = useSelector(getSearchTerm);

  const SearchWrapper = styled.div`
  width: 400px;
  margin-top: 20px;
`;

  const Search = styled(FormField)`
  max-width: 300px;
  margin-top: 20px;
`;

  const companyIdToUse =
    Number(currentClient) !== 0 ? currentClient : userCompanyId;

  useEffect(() => {
    dispatch(loadRoleAssessments(currentClient));
  }, [currentClient]);

  useEffect(() => {
    if (userCompany && !currentClient) {
      dispatch(setCurrentClient(userCompany.id));
    }
  }, [userCompany]);

  useEffect(() => {
    dispatch(loadClients());
  }, []);

  useEffect(() => {
    const searchTermLower = searchTerm && searchTerm.toLowerCase();

    setRoleAssessments(
      Assessments.filter((roleAssessments) => {
        if (searchTerm) {
          let test = true;
          test =
            test &&
            (roleAssessments.role_name ? roleAssessments.role_name.toLowerCase().includes(searchTermLower) : false ||
              roleAssessments.assessment.result ? roleAssessments.assessment.result.toLowerCase().includes(searchTermLower) : false);
          console.log(test);
          return test;
        } else {
          return true;
        }
      })
    );
  }, [Assessments, searchTerm]);

  const renderTable = () => {
    const deleteRow = (row) => {
      const options = {
        title: "Are you sure?",
        message: `Are you sure you want to delete this role assessment? It's not possible to reverse this operation`,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(removeRoleAssessment(row.id));
            },
          },
          {
            label: "No",
          },
        ],
      };
      confirmAlert(options);
    };

    const columns = [
      {
        name: "Role Name",
        selector: "role_name",
        sortable: true
      },
      {
        name: "Result",
        selector: (row) =>
          row.assessment
            ? getDisplayName(row.assessment.result, "FULL")
            : "Incomplete",
        sortable: true,
        cell: (row) =>
          row.assessment
            ? getDisplayName(row.assessment.result, "FULL")
            : "Incomplete",
      },
      {
        cell: (row) => (
          <Button
            action
            type="danger"
            size="reduced"
            onClick={() => deleteRow(row)}
          >
            Delete
          </Button>
        ),
        button: true,
      },
    ];

    const handleRowClicked = (row) =>
      history.push(
        `/main/roles/assessment/${row.company_id}/rolebased/${row.id}`
      );

    return (
      <TableWrapper>
        <StyledDataTable
          noHeader
          pagination
          customTheme={darkTheme}
          columns={columns}
          data={roleAssessments}
          fixedHeader
          highlightOnHover
          fixedHeaderScrollHeight="100%"
          title="All Roles"
          onRowClicked={handleRowClicked}
        />
      </TableWrapper>
    );
  };

  let clientOptions = [];
  if (clients) {
    clientOptions = generateClientOptions(clients, userCompany);
  }

  return (
    <Wrapper>
      <TitleArea>
        <div className="title">
          <h1>Roles</h1>

          {clients && clients.length ? (
            <StyledCombo
              options={clientOptions}
              value={currentClient}
              onChange={(newValue) => {
                dispatch(setCurrentClient(newValue));
              }}
            />
          ) : null}
          <SearchWrapper>
            <Search
              placeholder="Search"
              type="text"
              changeMonitor={(value) => dispatch(setSearchTerm(value))}
              className="search"
              autoFocus={true}
              value={searchTerm}
            />
          </SearchWrapper>
        </div>
        <div className="controls">
          <Button
            action
            onClick={() => {
              history.push(
                `/main/roles/assessment/${companyIdToUse}/rolebased/new`
              );
            }}
          >
            New Role
          </Button>
        </div>
      </TitleArea>
      <ContentArea>
        {loading && !roleAssessments && !roleAssessments.length ? (
          <Loader />
        ) : roleAssessments && roleAssessments.length ? (
          renderTable()
        ) : (
          <div>
            <Empty>
              <img src={noContractors} />
              <h2>You've not created any roles yet</h2>
              <p>You can add one by click on 'New Role' above</p>
            </Empty>
          </div>
        )}
      </ContentArea>
    </Wrapper>
  );
}

export default Contractors;
