import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getHasAdminRole } from "../store/users/selectors";
import styled from "styled-components";
import {
  getClients,
  getLoading,
  getClientsPage,
  getClientsPerPage
} from "../store/clients/selectors";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import { ContentArea } from "../components/Elements";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { loadClients, setClientsPage } from "../store/clients/actions";

const theme = {
  ...darkTheme,
  rows: {
    hoverBackgroundColor: "#ddd",
    fontSize: "16px"
  }
};

const SettingsWrapper = styled.div`
  background-color: #fff;
  margin: 0px 20px 20px 20px;
  border-radius: 10px;
  padding: 20px;
`;

const TableWrapper = styled.div`
  ${props => `margin-left: ${props.level * 20}px;`}
`;

function Clients({ history }) {
  const loading = useSelector(getLoading);
  const clients = useSelector(getClients);
  const dispatch = useDispatch();
  const isAdmin = useSelector(getHasAdminRole);
  const page = useSelector(getClientsPage);
  const perPage = useSelector(getClientsPerPage);

  useEffect(() => {
    dispatch(loadClients());
  }, []);

  const movePage = async page => {
    dispatch(setClientsPage(page, perPage));
  };

  const changePerPage = async (newPerPage, page) => {
    dispatch(setClientsPage(page, perPage));
  };

  const columns = [
    {
      name: "Name",
      selector: "name"
    }
  ];

  if (isAdmin) {
    columns.push({
      name: "Credits",
      selector: "credit_balance"
    });
  }

  const CompanyTable = args => {
    let companies = args.data.companies;
    return (
      <TableWrapper level={args.level}>
        <StyledDataTable
          pagination={args.level === 0}
          customTheme={theme}
          columns={columns}
          data={companies || []}
          fixedHeader
          highlightOnHover
          paginationDefaultPage={page}
          paginationPerPage={perPage}
          onChangeRowsPerPage={changePerPage}
          onChangePage={movePage}
          fixedHeaderScrollHeight="100%"
          onRowClicked={handleRowClicked}
          expandableRows={companies && companies.length}
          expandableRowsComponent={
            companies && <CompanyTable level={args.level + 1} />
          }
        />
      </TableWrapper>
    );
  };

  const handleRowClicked = row =>
    history.push(`/main/settings/clients/company/${row.id}`);

  return (
    <ContentArea>
      {loading || !clients ? (
        <Loader />
      ) : (
        <SettingsWrapper>
          <h2>My Clients</h2>
          <Button
            action
            onClick={() => {
              history.push("/main/settings/clients/company/new");
            }}
          >
            New Client
          </Button>
          {CompanyTable({ data: { companies: clients }, level: 0 })}
        </SettingsWrapper>
      )}
    </ContentArea>
  );
}

export default Clients;
