import React, { useState } from "react";
import styled from "styled-components";
import Header from "../components/Header";
import { H1, Content } from "../components/Elements";

const P = styled.p`
  text-align: center;
`;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <>
          <H1>Something went wrong</H1>
          <P>We're sorry, but there was an error. Please try again</P>
        </>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
