import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Header from "../components/Header";
import Assessment from "../components/Assessment";
import { H1, Content } from "../components/Elements";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import { loadContractorAssessments } from "../store/assessment/actions";
import {
  getContractorAssessments,
  getContractorAssessmentsError,
  getContractorAssessmentsLoading,
} from "../store/assessment/selectors";
import moment from "moment";
import { errorStrong, errorWeak } from "../styles/colors";
import Button from "../components/Button";
import { device } from "../components/Devices";
import Loader from "../components/Loader";

const theme = {
  ...darkTheme,
  rows: {
    hoverBackgroundColor: "#ddd",
    fontSize: "16px",
  },
};

const H1custom = styled(H1)`
  margin-top: 0 !important;
`;

const Wrapper = styled.div`
  padding: 10px;

  margin: 10px;
  @media ${device.laptop} {
    width: 800px;
    padding: 40px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  background-color: #fff;
`;

function ContractorList({ history }) {
  const dispatch = useDispatch();
  const loading = useSelector(getContractorAssessmentsLoading);
  const error = useSelector(getContractorAssessmentsError);
  const engagements = useSelector(getContractorAssessments);

  const columns = [
    {
      name: "Date",
      cell: (row) =>
        row.assessment && moment(row.assessment.datetime).format("DD/MM/YYYY"),
    },

    {
      name: "Agency",
      selector: "agency",
    },
  ];

  const handleRowClicked = (row) =>
    history.push(`/contractor-assessment/result/${row.assessment.public_id}`);

  useEffect(() => {
    dispatch(loadContractorAssessments());
  }, []);

  return (
    <div>
      <Header showMenu />
      <Content>
        <Wrapper>
          <H1custom>My Assessments</H1custom>
          {loading || (!engagements && <Loader text="Loading, please wait" />)}
          {!loading &&
            engagements &&
            engagements.map((engagement) => (
              <>
                <h2>{engagement.company.name}</h2>
                <StyledDataTable
                  customTheme={theme}
                  columns={columns}
                  data={engagement.engagements || []}
                  fixedHeader
                  highlightOnHover
                  fixedHeaderScrollHeight="100%"
                  onRowClicked={handleRowClicked}
                />
              </>
            ))}
        </Wrapper>
      </Content>
    </div>
  );
}

export default ContractorList;
