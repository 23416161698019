import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

if (!Array.prototype.flat) {
  Array.prototype.flat = function flat() {
    var depth = isNaN(arguments[0]) ? 1 : Number(arguments[0]);

    return depth
      ? Array.prototype.reduce.call(
          this,
          function(acc, cur) {
            if (Array.isArray(cur)) {
              acc.push.apply(acc, flat.call(cur, depth - 1));
            } else {
              acc.push(cur);
            }

            return acc;
          },
          []
        )
      : Array.prototype.slice.call(this);
  };
}

ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
