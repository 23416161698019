import React, { useState, useEffect, useCallback } from "react";
import Children from "react-children-utilities";
import Loader from "../components/Loader";
import Validation from "../components/FormValidation";

function CRUDForm({
  formData,
  loading,
  recordId,
  validationRules = {},
  onSubmit,
  children,
  updater = () => {},
  onDirty = () => {},
  linkedBooleanValues = {},
}) {
  const [validation, setValidation] = useState({});
  const isNew = recordId === "new";

  const [localFormData, setLocalFormData] = useState(formData);

  useEffect(() => {
    if (formData) {
      setLocalFormData({ ...localFormData, ...formData });
    }
  }, [formData]);

  useEffect(() => {
    updater(localFormData);
  }, [localFormData]);

  const formValidation = useCallback(new Validation(validation, setValidation));
  formValidation.setRules(validationRules);

  const updateFormData = (field, value) => {
    let newLocalFormData = { ...localFormData };
    if (value) {
      if (linkedBooleanValues[field]) {
        linkedBooleanValues[field].forEach((linkedField) => {
          newLocalFormData[linkedField] = true;
        });
      }
    }
    if (!value) {
      Object.keys(linkedBooleanValues).forEach((key) => {
        if (linkedBooleanValues[key]) {
          linkedBooleanValues[key].forEach((linkedField) => {
            if (linkedField === field) {
              newLocalFormData[key] = false;
            }
          });
        }
      });
    }
    onDirty(true);
    newLocalFormData[field] = value;
    setLocalFormData(newLocalFormData);
    formValidation.validate(field, value, localFormData);
  };

  let validationFields = [];
  Children.deepMap(children, (child) => {
    if (!child || (child.props && !child.props.componentType) || !child.props) {
      return;
    }
    switch (child.props.componentType) {
      case "FormField":
        if (
          (child.props.inputType !== "checkbox" || child.props.forceValidate) &&
          !child.props.optional
        ) {
          validationFields.push(child.props.name);
        }

        break;
    }
  });
  formValidation.monitorFields(validationFields);

  const sendForm = async (e) => {
    e.preventDefault();

    const validationErrors = formValidation.validateForm(localFormData);

    if (validationErrors) {
      return;
    }

    onSubmit(localFormData);
  };

  return (
    <div>
      {!isNew && !formData ? (
        <Loader />
      ) : (
        <div>
          {Children.deepMap(children, (child) => {
            let extraProps;

            if (
              !child ||
              (child.props && !child.props.componentType) ||
              !child.props
            ) {
              return child;
            }

            console.log("The type", child.props.componentType);

            switch (child.props.componentType) {
              case "FormField":
                let name = child.props.name;
                extraProps = {
                  changeMonitor: (value) => {
                    updateFormData(name, value);
                  },
                  value: localFormData[name],
                  validation: validation[name],
                };
                return React.cloneElement(child, extraProps);
              case "Button":
                if (!child.props.noBind) {
                  extraProps = {
                    onClick: sendForm,
                    loading,
                  };
                  return React.cloneElement(child, extraProps);
                } else {
                  return child;
                }

              default:
                return child;
            }
          })}
        </div>
      )}
    </div>
  );
}

export default CRUDForm;
