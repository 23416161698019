import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Header from "../components/Header";
import Assessment from "../components/Assessment";
import { H1, Content } from "../components/Elements";
import {
  restoreAssessmentId,
  contractorGetAssessmentMasterByToken,
  contractorSubmitAnswers,
  closeContractorAssessment,
} from "../store/assessment/actions";
import { completeLogin } from "../store/users/actions";
import {
  getSubmissionError,
  getAssessmentToken,
  getLoading,
} from "../store/assessment/selectors";
import { getUser, getRealm } from "../store/users/selectors";
import { errorStrong, errorWeak } from "../styles/colors";
import Button from "../components/Button";
import PostAssessmentForm from "../components/PostAssessmentForm";
import { device } from "../components/Devices";
import ContractorLogin from "../components/ContractorLogin";
import Loader from "../components/Loader";

const H1custom = styled(H1)`
  margin-top: 0 !important;
`;

const Wrapper = styled.div`
  padding: 10px;

  margin: 10px;
  @media ${device.laptop} {
    width: 800px;
    padding: 40px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  background-color: #fff;
`;
const AssessmentWrapper = styled.div`
  margin-top: 20px;

  p {
    text-align: center;
  }
`;

const ErrorWrapper = styled.div`
  border: 1px solid ${errorStrong};
  padding: 10px;
  margin: 10px 0px;
  background-color: ${errorWeak};
`;

const Preamble = styled.div`
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;

  .left {
    flex-basis: 100%;

    @media ${device.laptop} {
      flex: 1;
      padding-right: 20px;
      border-right: 1px solid #ccc;
    }
  }
  .right {
    flex-basis: 100%;

    @media ${device.laptop} {
      flex: 1;
      padding-left: 20px;
    }
  }

  text-align: center;

  p {
    font-size: 18px;
    line-height: 22px;
    max-width: 600px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`;

const Smaller = styled.div`
  p {
    font-size: 12px;
    line-height: 14px;
    text-align: left;
  }
`;

function ContractorPartAssessment({ history, match }) {
  const dispatch = useDispatch();
  const [complete, setComplete] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const submissionError = useSelector(getSubmissionError);
  const assessmentToken = useSelector(getAssessmentToken);
  const started = match.params.step !== "0";
  const answersComplete = match.params.step === "complete";
  const currentStep = Number(match.params.step) - 1;
  const crunchSalesforceId = match.params.crunch_salesforce_id;
  const loading = useSelector(getLoading);
  const user = useSelector(getUser);
  const realm = useSelector((state) => getRealm(state));

  if (crunchSalesforceId) {
    sessionStorage.setItem("crunch_salesforce_id", crunchSalesforceId);
  }

  useEffect(() => {
    dispatch(restoreAssessmentId());
  }, []);

  useEffect(() => {
    if (assessmentToken) {
      dispatch(contractorGetAssessmentMasterByToken(assessmentToken));
    }
  }, [assessmentToken]);

  const setStarted = () => {
    history.push("/contractor-assessment/1");
  };

  const sendAssessment = async (
    assessmentId,
    answers,
    reopen,
    notes,
    additionalAnswers
  ) => {
    await dispatch(
      contractorSubmitAnswers(
        assessmentToken,
        answers.map((answer) => ({ id: answer })),
        notes,
        additionalAnswers
      )
    );

    history.push("/contractor-assessment/complete");
  };

  const sendAnswers = async (answers, nextStep, notes, additionalAnswers) => {
    dispatch(
      contractorSubmitAnswers(
        assessmentToken,
        answers.map((answer) => ({ id: answer })),
        notes,
        additionalAnswers
      )
    );
    history.push(`/contractor-assessment/${nextStep + 1}`);
  };

  const closeAssessment = async (data) => {
    const result = await dispatch(
      closeContractorAssessment(
        assessmentToken,
        data.contractor,
        data.engagement
      )
    );

    if (result && result.accessToken) {
      dispatch(completeLogin(result.accessToken));
    }

    if (result && result.success) {
      history.push(`/contractor-assessment/result/${assessmentToken}`);
    }
  };

  return (
    <div>
      <Header showMenu />
      <Content>
        <Wrapper>
          <H1custom>IR35 Assessment</H1custom>
          {submissionError && (
            <ErrorWrapper>
              <h2>Error</h2>
              {typeof submissionError === "string" &&
              submissionError === "EXISTING_USER" ? (
                <p>
                  There is already an account with this email address, please
                  try again
                </p>
              ) : (
                <p>
                  Something went wrong, please check your internet connection
                  and try again
                </p>
              )}
            </ErrorWrapper>
          )}
          {complete && !submissionError && (
            <>
              <h2>Thank you</h2>
              <p>
                We've got your answers, and once they've been collated we'll be
                in touch.
              </p>
            </>
          )}
          {!started && (
            <Preamble>
              <div className="left">
                <h3>Click below to start your assessment</h3>
                <p>
                  Use our IR35 status tool to assess your risk of being inside
                  IR35 and a subsequent investigation from HMRC. You will not be
                  able to edit your answers once the assessment has been
                  submitted so please answer as accurately as you can, providing
                  notes and evidence where possible to support your answers.
                </p>
                {realm === "ir35/crunch" && (
                  <>
                    <p>
                      Our calculator should be used as guidance only, which
                      highlights your risk - always consult an expert if you
                      have any questions.
                    </p>
                    <p>
                      Please check the answers you provide carefully - you
                      cannot change an answer once submitted. You will need to
                      start a new assessment if you wish to change an answer. If
                      you purchase an IR35 report and Status Determination
                      Statement (SDS), it will be based on your submitted
                      answers and cannot be changed.
                    </p>
                  </>
                )}
                <Button
                  loading={loading}
                  noFullWidth={true}
                  onClick={() => setStarted(true)}
                >
                  Let's Begin
                </Button>
                {realm === "ir35/kingsbridge" && (
                  <Smaller>
                    <p>
                      Once you have submitted your answers you will be prompted
                      to pay for the review, after which you will be presented
                      with an ‘Inside’, ‘Outside’ or ‘Indeterminate’ IR35
                      result. You will also see an overview of how your result
                      has been determined, including how your answers align with
                      the key IR35 status tests.
                    </p>
                    <p>
                      If you receive an ‘Inside’ or ‘Outside’ result your IR35
                      report will be immediately available to download. If you
                      receive an ‘Indeterminate’ result our in-house IR35
                      consultancy will review your answers further and be in
                      touch within 5 working days to discuss your result.
                    </p>
                  </Smaller>
                )}
              </div>
              <div className="right">
                <h3>Already signed up? Login below</h3>
                <ContractorLogin />
              </div>
            </Preamble>
          )}
          {!invalid && !complete && started && !answersComplete && (
            <AssessmentWrapper>
              <Assessment
                wrapped={true}
                onSubmit={sendAssessment}
                removeHeadings={true}
                target="contractor"
                onStepChange={sendAnswers}
                dictateSteps={true}
                currentStep={currentStep}
                additionalConditional={{
                  "PART_AND_PARCEL_BUSINESS-INDEMNITY": true,
                }}
              />
            </AssessmentWrapper>
          )}
          {!invalid && !complete && started && answersComplete && (
            <AssessmentWrapper>
              <PostAssessmentForm onComplete={closeAssessment} user={user} />
            </AssessmentWrapper>
          )}
        </Wrapper>
      </Content>
    </div>
  );
}

export default ContractorPartAssessment;
