import React, { createRef } from "react";
import { useDispatch } from "react-redux";
import InsuranceElsewhere from "./additional-questions/InsuranceElsewhere";
import { setAdditionalAnswer } from "../../store/assessment/actions";
import { getAdditionalAnswer } from "../../store/assessment/selectors";

class AdditionalQuestions {
  constructor(config, target) {
    this.config = { contractor: {}, client: {}, ...config };
    this.questions = {
      contractor: {
        "PART_AND_PARCEL_BUSINESS-INDEMNITY": InsuranceElsewhere,
      },
    };
    this.target = target;
    this.refs = {
      contractor: {},
      client: {},
    };
  }

  getAdditionalQuestion(questionKey, answer) {
    const dispatch = useDispatch();

    if (answer && answer.reference) {
      let keys = answer.reference.split("-");
      answer = keys[keys.length - 1];
    }

    if (
      this.config &&
      this.config[this.target] &&
      this.config[this.target][questionKey] &&
      this.config[this.target][questionKey] === answer
    ) {
      this.refs[this.target][questionKey] = createRef();
      return React.createElement(this.questions[this.target][questionKey], {
        ref: this.refs[this.target][questionKey],
        selector: (state) => getAdditionalAnswer(questionKey, state),
        setAnswers: (answers) =>
          dispatch(setAdditionalAnswer(questionKey, answers)),
      });
    }
  }

  getValues(state) {
    //This is hard coded for now as we only have one set of questions
    return getAdditionalAnswer("PART_AND_PARCEL_BUSINESS-INDEMNITY", state);
  }

  validate() {
    let errors = [];
    Object.keys(this.refs.contractor).forEach((key) => {
      if (this.refs.contractor[key].current) {
        let validation = this.refs.contractor[key].current.validate();

        if (Array.isArray(validation)) {
          errors = errors.concat(validation);
        }
      }
    });
    Object.keys(this.refs.client).forEach((key) => {
      if (this.refs.client[key].current) {
        let validation = this.refs.client[key].current.validate();
        if (Array.isArray(validation)) {
          errors = errors.concat(validation);
        }
      }
    });

    return errors;
  }
}

export default AdditionalQuestions;
