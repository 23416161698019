import React from "react";
import styled from "styled-components";
import Loader from "react-loader-spinner";

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px;
  box-sizing: border-box;

  .loader {
    display: inline-block;
    margin-right: 10px;
  }
`;

function LoaderComponent({ text = "Please Wait" }) {
  return (
    <LoaderWrapper>
      <div>
        <Loader
          type="TailSpin"
          color="#000"
          height="20"
          width="20"
          className="loader"
        />
        {text}
      </div>
    </LoaderWrapper>
  );
}

export default LoaderComponent;
