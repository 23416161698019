import React from "react";
import { useSelector } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Subscription from "./Subscription";
import Company from "./Company";
import Clients from "./Clients";
import Credits from "./Credits";
import User from "./User";
import Users from "./Users";
import {
  getUsingCredits,
  getHasFeature,
  getRealm,
  getHasSuperAdminRole,
  getHasAdminRole,
  getHasCSTRole,
} from "../store/users/selectors";
import { Wrapper } from "../components/Elements";
import { Tabs, tabStyles } from "../components/Tabs";
import Uploads from "./Uploads";
import UsageReport from "./UsageReport";

const TabsWrapper = styled.div`
  margin: 20px 0px 0;
`;

const StyledTabs = styled(Tabs)`
  margin-left: 0px;
  margin-right: 0px;
`;

const Tab = styled(NavLink)`
  ${tabStyles}
  text-decoration: none;
`;

function Settings() {
  const hasChildFeature = useSelector((state) =>
    getHasFeature(state, "feature_children")
  );
  const usingCredits = useSelector(getUsingCredits);
  const realm = useSelector(getRealm);
  const isKingsbridge = realm === "ir35/kingsbridge";
  const isAdmin = useSelector(getHasAdminRole);
  const isCST = useSelector(getHasCSTRole);

  return (
    <Wrapper>
      <TabsWrapper>
        <StyledTabs>
          {!usingCredits && !isCST ? (
            <Tab to="/main/settings" exact>
              Subscription
            </Tab>
          ) : null}

          {usingCredits && !isCST && (
            <Tab to="/main/settings" exact>
              Credits
            </Tab>
          )}
          {hasChildFeature && !isCST ? (
            <Tab to="/main/settings/clients">My Clients</Tab>
          ) : null}
          <Tab to="/main/settings/users">Users</Tab>
          {!isCST && <Tab to="/main/settings/uploads">Uploads</Tab>}
          {isKingsbridge && isAdmin && (
            <Tab to="/main/settings/usage-report">Usage Report</Tab>
          )}
        </StyledTabs>
      </TabsWrapper>
      <Switch>
        <Route
          path="/main/settings"
          component={usingCredits ? Credits : Subscription}
          exact
        />
        <Route path="/main/settings/clients" component={Clients} exact />
        <Route path="/main/settings/users" component={Users} exact />
        <Route
          path="/main/settings/clients/company/:companyId"
          render={(routeProps) => (
            <Company {...routeProps} urlStub="/main/settings/clients/company" />
          )}
          exact
        />
        <Route
          path="/main/settings/clients/company/:companyId/users/:userId"
          render={(routeProps) => (
            <User {...routeProps} urlStub="/main/settings/clients/company" />
          )}
          exact
        />
        <Route
          path="/main/settings/users/:companyId/users/:userId"
          render={(routeProps) => (
            <User {...routeProps} urlStub="/main/settings/users" />
          )}
          exact
        />
        <Route path="/main/settings/uploads" component={Uploads} exact />
        <Route
          path="/main/settings/usage-report"
          component={UsageReport}
          exact
        />
      </Switch>
    </Wrapper>
  );
}

export default Settings;
