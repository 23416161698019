import React from "react";
import { useSelector } from "react-redux";
import { Route, Redirect, withRouter } from "react-router-dom";
import { getUser } from "../store/users/selectors";

function ProtectedRoute({ component: Component, ...rest }) {
  const loggedIn = useSelector(getUser);

  return (
    <Route
      {...rest}
      render={props => {
        return loggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/", state: { referrer: rest.location.pathname } }}
          />
        );
      }}
    />
  );
}

export default withRouter(ProtectedRoute);
