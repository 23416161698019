import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faThumbsUp,
  faCommentAlt,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Button, { ActionButton } from "./Button";
import { getUser } from "../store/users/selectors";
import { passReview } from "../store/review/actions";
import {
  getPassReviewLoading,
  getPassReviewError,
  getUnresolvedComments,
} from "../store/review/selectors";
import { getContractorById } from "../store/contractors/selectors";
import { reviewDark, reviewLight } from "../styles/colors";

const ReviewSurround = styled.div`
  margin-top: 20px;
  border: 1px solid ${reviewDark};
  background-color: ${reviewLight};
  padding: 10px;

  h4 {
    margin: 0px !important;
    text-align: left !important;
    font-size: 16px !important;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const InlineFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${reviewDark};
`;

const Error = styled.div`
  font-weight: bold;
`;

const Table = styled.table`
  width: 100%;

  thead {
    td {
      background-color: ${reviewDark};
      color: #fff;
      text-align: center;
      font-weight: bold;
    }
  }

  th {
    font-weight: bold;
    color: ${reviewDark};
    text-align: left;
    background-color: #fff;
    padding: 4px;
  }

  td {
    padding: 4px;
    background-color: #fff;
  }
`;

function ReviewerPanel({ review, contractorId, assessment }) {
  const dispatch = useDispatch();
  const user = useSelector(getUser);
  const loading = useSelector(getPassReviewLoading);
  const error = useSelector(getPassReviewError);
  const approvals = review && review.approvals;
  const isApprover =
    user &&
    approvals &&
    approvals.find(
      (approval) =>
        approval.user_id === user.userId ||
        Number(approval.assigned) === user.userId
    );
  const approval = isApprover && isApprover.status !== "CLOSED";
  const reviewComplete = review && review.status === "CLOSED";
  const unresolvedComments = useSelector(getUnresolvedComments);
  const contractor = useSelector((state) =>
    getContractorById(state, contractorId)
  );

  const doPassReview = () => {
    const options = {
      title: "Are you sure?",
      message: [
        <p>
          By continuing you agree that, to the best of your knowledge, the
          answers are correct and reflect not just the contract but also the
          reality of the engagement.
        </p>,
      ],
      buttons: [
        {
          label: "Continue to finalise review",
          onClick: () => {
            dispatch(
              passReview(isApprover.id, contractorId, contractor.company_id)
            );
          },
        },
        {
          label: "Cancel",
        },
      ],
    };

    if (unresolvedComments) {
      options.message.unshift(
        <p>{`There ${
          unresolvedComments === 1
            ? "is 1 unresolved comment"
            : `are ${unresolvedComments} unresolved comments`
        } - do you wish to continue?`}</p>
      );
    }

    confirmAlert(options);
  };

  return (
    <ReviewSurround>
      <h4>
        <StyledFontAwesomeIcon icon={faEye} />
        Review
      </h4>
      {approval && (
        <>
          <p>
            You are a reviewer of this assessment. Please go through each of the
            answers; if you disagree with one or want to ask a question, just
            click on the comment icon{" "}
            <InlineFontAwesomeIcon icon={faCommentAlt} /> to raise it with the
            assessor.
          </p>
          <p>
            Once you're ready you can press 'Pass Review' to say you're happy
            with the answers.
          </p>
          {error && <Error>There was an error, please try again</Error>}
          <Button
            type="review"
            noFullWidth={true}
            onClick={doPassReview}
            loading={loading}
          >
            {" "}
            <StyledFontAwesomeIcon icon={faThumbsUp} />
            Finalise Review
          </Button>
        </>
      )}
      {!reviewComplete && !approval && isApprover && (
        <p>You have completed your review for this assessment</p>
      )}

      {(reviewComplete || !isApprover) && (
        <>
          {reviewComplete && contractorId !== "rolebased" && (
            <p>
              The review process has been completed for this assessment. Reports
              can now be downloaded and the SDS document can be distributed to
              all parties.
            </p>
          )}

          {reviewComplete && contractorId === "rolebased" && (
            <p>
              The review process has been completed for this assessment. It can
              now be used to provide an indicative determination to potential
              candidates.
            </p>
          )}

          {!reviewComplete &&
            approvals &&
            assessment.status !== "AWAITING_CONTRACTOR" && (
              <p>The review process is underway</p>
            )}

          {!reviewComplete && assessment.status === "AWAITING_CONTRACTOR" && (
            <p>
              The review process will start once the contractor has entered
              their answers
            </p>
          )}
          {approvals && (
            <Table>
              <thead>
                <tr>
                  <td colspan="2">
                    {reviewComplete &&
                      `The following have reviewed this assessment`}
                    {!reviewComplete &&
                      `The following reviewers are assigned to this assessment`}
                  </td>
                </tr>
                <tr>
                  <th>Name</th>
                  <th>Date Reviewed</th>
                </tr>
              </thead>
              <tbody>
                {approvals &&
                  approvals.map((approval) => (
                    <tr>
                      <td>{approval.reviewer_name}</td>
                      <td>
                        {approval.datetime
                          ? moment(approval.datetime).format("DD/MM/YYYY")
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          )}
        </>
      )}
    </ReviewSurround>
  );
}

export default ReviewerPanel;
