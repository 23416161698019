import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faSignature,
  faGavel,
  faBalanceScaleLeft,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import moment from "moment";
import Header from "../components/Header";
import { H1, Content } from "../components/Elements";
import {
  getSDSData,
  getSDSError,
  getSDSLoading,
  getDisputeLoading,
  getDisputeError,
} from "../store/sds/selectors";
import {
  loadSDSDocument,
  confirmAcceptance,
  openDispute,
} from "../store/sds/actions";
import {
  errorStrong,
  errorWeak,
  distributeDark,
  distributeLight,
  appealDark,
  appealLight,
} from "../styles/colors";
import Button from "../components/Button";
import { device } from "../components/Devices";
import Loader from "../components/Loader";

const H1custom = styled(H1)`
  margin-top: 0 !important;
`;

const Wrapper = styled.div`
  padding: 10px;

  margin: 10px;
  @media ${device.laptop} {
    width: 800px;
    padding: 40px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  background-color: #fff;
`;
const AssessmentWrapper = styled.div`
  margin-top: 20px;

  p {
    text-align: center;
  }
`;

const ErrorWrapper = styled.div`
  border: 1px solid ${errorStrong};
  padding: 10px;
  margin: 10px 0px;
  background-color: ${errorWeak};
`;

const Document = styled.div`
  ul {
    li {
      margin-bottom: 10px;
    }
  }
`;

const HeadingsTable = styled.table`
  margin-top: 20px;
  width: 100%;
  td {
    border-left: 3px solid #ccc;
    padding: 20px;
    background-color: #eee;
    width: 50%;
  }
`;

const Heading = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const Value = styled.div`
  font-size: 18px;
`;

const Acceptance = styled.div`
  background-color: ${distributeLight};
  border: 1px solid ${distributeDark};
  margin-top: 20px;
  padding: 20px;

  input {
    outline: none;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid ${distributeDark};
    font-size: 20px;
    padding: 10px;
    width: 220px;
    margin-bottom: 20px;
  }
`;

const StyledButton = styled(Button)`
  margin-left: 10px;
`;

const Sign = styled.div`
  margin-top: 20px;
  border-top: 1px solid #ccc;
  padding-top: 20px;
`;

const SignOptions = styled.div`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 20px;
`;

const SignButton = styled.div`
  padding: 10px;
  border: 1px solid #000;
  text-align: center;
  font-size: 20px;
  font-weight: bold;
  margin: 10px;
  cursor: pointer;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const Appeal = styled.div`
  background-color: ${appealLight};
  border: 1px solid ${appealDark};
  margin-top: 20px;
  padding: 20px;

  textarea {
    outline: none;
    border: none;
    border: 1px solid ${appealDark};
    font-size: 16px;
    padding: 10px;
    width: 100%;
    margin-bottom: 20px;
    box-sizing: border-box;
  }
`;

const DisputeReason = styled.div`
  padding: 10px;
  border-left: 4px solid ${appealDark};
`;

function ContractorPartAssessment({ history, match }) {
  const dispatch = useDispatch();
  const [complete, setComplete] = useState(false);
  const error = useSelector(getSDSError);
  const token = match.params.token;
  const loading = useSelector(getSDSLoading);
  const sdsData = useSelector(getSDSData);
  const result = sdsData && sdsData.displayResult;
  const acceptance = sdsData && sdsData.acceptance;
  const [signatory, setSignatory] = useState("");
  const [accepting, setAccepting] = useState(true);
  let categories = [];
  const [appealed, setAppealed] = useState(false);
  const inDispute = acceptance && acceptance.status === "IN_DISPUTE";
  const disputeReason = acceptance && acceptance.dispute_reason;
  const disputeResponse = acceptance && acceptance.dispute_response;
  const disputeComplete = acceptance && acceptance.dispute_complete;
  const [reason, setReason] = useState("");
  const disputeLoading = useSelector(getDisputeLoading);
  const disputeError = useSelector(getDisputeError);

  const submitDispute = async () => {
    const response = await dispatch(openDispute(token, reason));

    if (response && response.complete) {
      dispatch(loadSDSDocument(token));
    }
  };

  const getCategoryList = (category) => {
    const match = categories.find(
      (existingCategory) => category.id === existingCategory.id
    );

    if (match) {
      return match.list;
    } else {
      const newEntry = { ...category, list: [] };
      categories.push(newEntry);
      return newEntry.list;
    }
  };

  sdsData &&
    sdsData.assessmentCategories.forEach(function (category) {
      category.questions.forEach((question) => {
        question.userAnswers.forEach(function (ua) {
          if (ua.sds) {
            if (result.resultDef === "OUTSIDE" && ua.risk_impact === 0) {
              const list = getCategoryList(category);
              list.push(ua.sds);
            } else if (result.resultDef === "INSIDE" && ua.risk_impact !== 0) {
              const list = getCategoryList(category);
              list.push(ua.sds);
            }
          }
        });
      });
    });

  useEffect(() => {
    dispatch(loadSDSDocument(token));
  }, []);

  useEffect(() => {
    if (
      sdsData &&
      sdsData.acceptance &&
      sdsData.acceptance.status === "ACCEPTED"
    ) {
      setComplete(true);
    }
  }, [sdsData]);

  const accept = async () => {
    const result = await dispatch(confirmAcceptance(token, signatory));
    if (result && result.success) {
      setComplete(true);
    }
  };

  return (
    <div>
      <Header />
      <Content>
        <Wrapper>
          <H1custom>IR35 SDS Document</H1custom>
          {loading && <Loader text="Loading data, please wait" />}
          {error && (
            <ErrorWrapper>
              <h2>Error</h2>
              <p>
                Something went wrong, please check your internet connection and
                try again
              </p>
            </ErrorWrapper>
          )}

          {!loading && sdsData && (
            <Document>
              <HeadingsTable>
                <tr>
                  <td>
                    <Heading>Ltd Company Name</Heading>
                    <Value>{sdsData.contractorRecord.ltd_company_name}</Value>
                  </td>
                  <td>
                    <Heading>Assessment Date</Heading>
                    <Value>
                      {moment(sdsData.userAssessment.datetime).format(
                        "DD/MM/YYYY"
                      )}
                    </Value>
                  </td>
                </tr>
              </HeadingsTable>
              <p>{`We believe this engagement falls ${result.result} and the contractor is therefore ${result.status} for tax purposes, due to the following reasons:`}</p>

              {categories.map((category) => (
                <>
                  <h2>{category.name}</h2>
                  <ul>
                    {category.list.map((listItem) => (
                      <li>{listItem}</li>
                    ))}
                  </ul>
                </>
              ))}

              {!inDispute && !disputeComplete && (
                <Sign>
                  <p>
                    If you agree with the statements above, you can choose 'I
                    agree with this determination' and continue. If you don't,
                    you can select 'I DO NOT agree with this determination' and
                    raise a dispute.
                  </p>
                  <SignOptions>
                    <SignButton onClick={() => setAccepting(true)}>
                      {accepting && <StyledFontAwesomeIcon icon={faCheck} />}I
                      agree with this determination
                    </SignButton>
                    <SignButton onClick={() => setAccepting(false)}>
                      {!accepting && <StyledFontAwesomeIcon icon={faCheck} />}I
                      DO NOT agree with this determination
                    </SignButton>
                  </SignOptions>
                </Sign>
              )}

              {disputeComplete && (
                <Appeal>
                  <h2>
                    {" "}
                    <StyledFontAwesomeIcon icon={faBalanceScaleLeft} />
                    Dispute Closed
                  </h2>
                  <p>A dispute was original raised as follows:</p>
                  <DisputeReason>
                    {disputeReason &&
                      disputeReason.split("\n").map((i, key) => {
                        return <p key={key}>{i}</p>;
                      })}
                  </DisputeReason>
                  <p>This has now been reviewed and this is the response:</p>
                  <DisputeReason>
                    {disputeResponse &&
                      disputeResponse.split("\n").map((i, key) => {
                        return <p key={key}>{i}</p>;
                      })}
                  </DisputeReason>
                  <p>This Dispute is now closed.</p>
                </Appeal>
              )}
              {accepting && !inDispute && (
                <Acceptance>
                  <h2>
                    {" "}
                    <StyledFontAwesomeIcon icon={faSignature} />
                    Acknowledge SDS
                  </h2>
                  {complete && !error && (
                    <>
                      <p>
                        Acknowledgement is complete, you can download a copy of
                        the SDS document below.
                      </p>
                      <StyledButton
                        noFullWidth={true}
                        type="distribute"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}/userassessments/generate-signoff-sds/${token}`
                          )
                        }
                      >
                        Download SDS
                      </StyledButton>
                    </>
                  )}
                  {!complete && (
                    <>
                      <p>
                        I, {acceptance.name}, acknowledge receipt of this Status
                        Determination Statement.
                      </p>
                      <p>Signed,</p>
                      <input
                        type="text"
                        placeholder="Type your name"
                        onChange={(e) => setSignatory(e.target.value)}
                      />
                      <StyledButton
                        noFullWidth={true}
                        type="distribute"
                        disabled={signatory === ""}
                        onClick={() => accept()}
                      >
                        Complete
                      </StyledButton>
                    </>
                  )}
                </Acceptance>
              )}
              {!accepting && !inDispute && (
                <Appeal>
                  <h2>
                    {" "}
                    <StyledFontAwesomeIcon icon={faBalanceScaleLeft} />
                    Dispute SDS
                  </h2>
                  {!appealed && (
                    <>
                      <p>
                        If there are statements within this SDS that you don't
                        agree with and you wish to dispute it, then please
                        specify below. Please note; the end client has 45 days
                        to respond to an appeal and can either reject it and
                        provide a reason or uphold it and make changes to the
                        assessment.
                      </p>
                      <textarea
                        rows="10"
                        placeholder="Please enter details of your dispute here"
                        onChange={(e) => {
                          setReason(e.target.value);
                        }}
                        value={reason}
                      ></textarea>
                      {disputeError && <p>An error ocurred</p>}
                      <StyledButton
                        onClick={submitDispute}
                        noFullWidth={true}
                        type="appeal"
                        disabled={reason.length < 10}
                        loading={disputeLoading}
                      >
                        Send Dispute
                      </StyledButton>
                    </>
                  )}
                </Appeal>
              )}
              {inDispute && (
                <Appeal>
                  <h2>
                    {" "}
                    <StyledFontAwesomeIcon icon={faBalanceScaleLeft} />
                    In Dispute
                  </h2>
                  <p>
                    This status determination is currently in dispute due to the
                    following reason(s):
                  </p>
                  <DisputeReason>
                    {disputeReason &&
                      disputeReason.split("\n").map((i, key) => {
                        return <p key={key}>{i}</p>;
                      })}
                  </DisputeReason>
                  <p>
                    This will be reviewed in due course and you will be informed
                    of the result via email.
                  </p>
                </Appeal>
              )}
            </Document>
          )}
        </Wrapper>
      </Content>
    </div>
  );
}

export default ContractorPartAssessment;
