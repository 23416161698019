import { set, get, flow } from "lodash/fp";
import {
  CREATE_CONTRACTOR_REQUEST,
  CREATE_CONTRACTOR_SUCCESS,
  LOAD_CONTRACTOR_REQUEST,
  LOAD_CONTRACTOR_SUCCESS,
  LOAD_CONTRACTORS_REQUEST,
  LOAD_CONTRACTORS_SUCCESS,
  SAVE_CONTRACTOR_REQUEST,
  SAVE_CONTRACTOR_SUCCESS,
  REMOVE_CONTRACTOR_REQUEST,
  REMOVE_CONTRACTOR_SUCCESS,
  STANDALONE_SUMMARY_SUCCESS,
  STANDALONE_SUMMARY_REQUEST,
  STANDALONE_SUMMARY_FAILURE,
  SET_STANDALONE_PAGE,
  SET_PAGE,
  SET_PER_PAGE,
  SET_STANDALONE_PER_PAGE,
  SET_SEARCH_TERM,
  SET_STANDALONE_SEARCH_TERM,
  CHECK_SHARE_LEADS_SUCCESS,
  SEARCH_COMPANIES_HOUSE_REQUEST,
  SEARCH_COMPANIES_HOUSE_SUCCESS,
} from "./actions";

import {
  SUBMIT_ASSESSMENT_SUCCESS,
  CREATE_ENGAGEMENT_SUCCESS,
  SAVE_ENGAGEMENT_SUCCESS,
  SAVE_ENGAGEMENT_REQUEST,
  CREATE_ENGAGEMENT_REQUEST,
} from "../assessment/actions";

const cleanseContractor = (contractor) => {
  contractor.engagements = contractor.engagements
    ? contractor.engagements.map((engagement) => {
        return {
          ...engagement,
          start_date: new Date(engagement.start_date),
          completion_date: new Date(engagement.completion_date),
          validation: !!engagement.validation,
        };
      })
    : [];
  return contractor;
};

export const contractors = (
  state = {
    list: [],
    failures: null,
    loading: false,
    standaloneSummary: {},
    totalRows: 0,
    page: 0,
    standalonePage: 0,
    perPage: 10,
    standalonePerPage: 10,
    searchTerm: "",
    standaloneSearchTerm: "",
    canShareLeads: false,
    includesValidationTool: false,
    searchingCompaniesHouse: false,
    companiesHouseResults: null,
  },
  action
) => {
  let currentContractors = get("list", state);
  let newContractors;
  let cleansedPayload;
  switch (action.type) {
    case SET_STANDALONE_PAGE:
      return set("standalonePage", action.payload, state);
    case SET_PAGE:
      return set("page", action.payload, state);
    case SET_PER_PAGE:
      return set("perPage", action.payload, state);
    case SET_STANDALONE_PER_PAGE:
      return set("standalonePerPage", action.payload, state);
    case SET_SEARCH_TERM:
      return set("searchTerm", action.payload, state);
    case SET_STANDALONE_SEARCH_TERM:
      return set("standaloneSearchTerm", action.payload, state);
    case STANDALONE_SUMMARY_FAILURE:
      return flow(set("loading", false), set("failures", true))(state);

    case SEARCH_COMPANIES_HOUSE_REQUEST:
      return flow(set("searchingCompaniesHouse", false))(state);

    case CREATE_CONTRACTOR_REQUEST:
    case LOAD_CONTRACTOR_REQUEST:
    case LOAD_CONTRACTORS_REQUEST:
    case SAVE_CONTRACTOR_REQUEST:
    case SAVE_ENGAGEMENT_REQUEST:
    case CREATE_ENGAGEMENT_REQUEST:
    case REMOVE_CONTRACTOR_REQUEST:
    case STANDALONE_SUMMARY_REQUEST:
      return set("loading", true, state);
    case CREATE_CONTRACTOR_SUCCESS:
      return flow(
        set("list", [...currentContractors, action.payload]),
        set("loading", false)
      )(state);
    case SAVE_CONTRACTOR_SUCCESS:
      if (
        currentContractors.find((current) => current.id === action.payload.id)
      ) {
        newContractors = currentContractors.map((contractor) => {
          return contractor.id === action.payload.id
            ? { ...contractor, ...action.payload }
            : contractor;
        });
        return flow(set("list", newContractors), set("loading", false))(state);
      }
      break;
    case REMOVE_CONTRACTOR_SUCCESS:
      return flow(
        set("loading", false),
        set(
          "list",
          state.list.filter((item) => item.id !== action.payload.id)
        )
      )(state);
    case LOAD_CONTRACTOR_SUCCESS:
      cleansedPayload = cleanseContractor(action.payload);
      if (
        currentContractors.find((current) => current.id === cleansedPayload.id)
      ) {
        newContractors = currentContractors.map((contractor) => {
          return contractor.id === cleansedPayload.id
            ? cleansedPayload
            : contractor;
        });
        return flow(set("list", newContractors), set("loading", false))(state);
      } else {
        return flow(
          set("list", [...currentContractors, cleansedPayload]),
          set("loading", false)
        )(state);
      }

    case CREATE_ENGAGEMENT_SUCCESS:
    case SAVE_ENGAGEMENT_SUCCESS:
      const newEngagement = action.payload;
      const engagementContractorId = newEngagement.contractor_id;

      if (!newEngagement.id) {
        return flow(set("loading", false))(state);
      }

      newContractors = currentContractors.map((contractor) => {
        if (
          contractor &&
          contractor.id.toString() === engagementContractorId.toString()
        ) {
          let engagements = contractor.engagements || [];
          if (
            !engagements.find(
              (engagement) =>
                engagement &&
                newEngagement &&
                engagement.id &&
                engagement.id.toString() === newEngagement.id.toString()
            )
          ) {
            engagements.push(newEngagement);
          } else {
            engagements = engagements.map((engagement) => {
              if (
                engagement &&
                newEngagement &&
                engagement.id.toString() === newEngagement.id.toString()
              ) {
                return { ...engagement, ...newEngagement };
              }
              return engagement;
            });
          }
          return cleanseContractor({ ...contractor, engagements });
        }
        return contractor;
      });
      return flow(set("list", newContractors), set("loading", false))(state);

    case LOAD_CONTRACTORS_SUCCESS:
      return flow(
        set("loading", false),
        set(
          "list",
          action.payload.response.map((contractor) =>
            cleanseContractor(contractor)
          )
        ),
        set("totalRows", action.payload.count)
      )(state);
    case STANDALONE_SUMMARY_SUCCESS:
      return flow(
        set("loading", false),
        set("submissionError", false),
        set("standaloneSummary", action.payload)
      )(state);
    case SUBMIT_ASSESSMENT_SUCCESS:
      const { contractorId, engagementId, assessment } = action.payload;
      newContractors = currentContractors.map((contractor) => {
        if (contractor.id === Number(contractorId)) {
          contractor.engagements = contractor.engagements.map((engagement) => {
            if (engagement.id === engagementId) {
              return { ...engagement, assessment };
            }
            return engagement;
          });
          return cleanseContractor({ ...contractor });
        }
        return contractor;
      });
      return set("list", newContractors, state);
    case CHECK_SHARE_LEADS_SUCCESS:
      return set("canShareLeads", action.payload.shouldShareLeads, state);

    case SEARCH_COMPANIES_HOUSE_SUCCESS:
      return flow(
        set("searchingCompaniesHouse", false),
        set("companiesHouseResults", action.payload.items)
      )(state);

    default:
      return state;
  }
};
