import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { orderBy, debounce } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import formatCurrency from "format-currency";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import {
  getLoading,
  getContractors,
  getTotalRows,
  getCurrentPage,
  getPerPage,
  getSearchTerm,
  getCanShareLeadsForCompany,
} from "../store/contractors/selectors";
import {
  loadContractors,
  removeContractor,
  setPage,
  setPerPage,
  setSearchTerm,
  checkCanShareLeads,
  saveContractor,
} from "../store/contractors/actions";
import {
  getOutOfCredits,
  getHasAdminRole,
  getHasSuperAdminRole,
  getHasClientAdminRole,
} from "../store/users/selectors";
import {
  loadClients,
  setCurrentClient,
  loadHasInsuredWithKB,
} from "../store/clients/actions";
import {
  getClients,
  getCurrentClient,
  getHasInsuredWithKB,
} from "../store/clients/selectors";
import { TitleArea, ContentArea, Empty, Wrapper } from "../components/Elements";
import noContractors from "../assets/images/no-contractors.svg";
import noResults from "../assets/images/no-search-results.svg";
import Button from "../components/Button";
import Loader from "../components/Loader";
import FormField from "../components/FormField";
import {
  mainColor,
  successStrong,
  errorStrong,
  undeterminedStrong,
} from "../styles/colors";
import { getUserCompany } from "../store/users/selectors";
import Combo from "../components/Combo";
import ReactTooltip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${undeterminedStrong};
  font-size: 18px;
`;

const TableWrapper = styled.div`
  margin: 0px 20px;
  background-color: #fff;
  padding: 30px 20px;
  border-radius: 4px;
  flex: 1;
`;

const Name = styled.div`
  .initials {
    border-radius: 50%;
    background-color: ${mainColor};
    color: #fff;
    text-align: center;
    line-height: 30px;
    width: 30px;
    height: 30px;
    margin-right: 10px;
    display: inline-block;
  }
`;

const StyledCombo = styled(Combo)`
  margin-bottom: 20px;
  margin-top: 20px;
`;

const HasInsurance = styled.div`
  background-color: ${successStrong};
  padding: 5px;
  text-transform: uppercase;
  color: #fff;
  width: 30px;
  text-align: center;
`;

const NoInsurance = styled.div`
  background-color: ${errorStrong};
  padding: 5px;
  text-transform: uppercase;
  color: #fff;
  width: 30px;
  text-align: center;
`;
const HasInsuranceCaution = styled.div`
  background-color: #f5741f;
  padding: 5px;
  text-transform: uppercase;
  color: #fff;
  width: 30px;
  text-align: center;
`;

const StyledReactTooltip = styled(ReactTooltip)`
  &.type-dark.place-bottom {
    font-size: 14px;
    padding: 20px;
    line-height: 18px;
    max-width: 200px;
  }
`;

const Caution = styled.div`
  text-align: center;
  padding-left: 10px;
`;
const NA = styled.div`
  text-align: center;
  padding-left: 10px;
`;

const ir35Types = ["IR35_PROTECT", "IR35", "IR35A"];

export const generateClientOptions = (clients, userCompany) => {
  if (userCompany) {
    clients = [{ ...userCompany, companies: clients }];
  }

  const generateClientOptionsRecursive = (clients) => {
    return clients.map((client) => ({
      value: client.id,
      name: client.name,
      children: client.companies
        ? generateClientOptionsRecursive(
            client.companies.sort(function (a, b) {
              return a.name && b.name && a.name.localeCompare(b.name);
            })
          )
        : [],
    }));
  };

  const finalOptions = generateClientOptionsRecursive(clients);

  return finalOptions;
};

function Contractors({ history, match }) {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading);
  const contractors = useSelector(getContractors);
  const clients = useSelector(getClients);
  const currentClient = useSelector(getCurrentClient);
  const totalRows = useSelector(getTotalRows);
  const perPage = useSelector(getPerPage);
  const page = useSelector(getCurrentPage) + 1;
  const searchTerm = useSelector(getSearchTerm);
  const userCompany = useSelector(getUserCompany);

  const adminRole = useSelector(getHasAdminRole);
  const superAdminRole = useSelector(getHasSuperAdminRole);
  const clientAdminRole = useSelector(getHasClientAdminRole);

  const canChangeComms = adminRole || superAdminRole || clientAdminRole;
  const canShareLeads = useSelector(getCanShareLeadsForCompany);

  const hasInsuredWithKB = useSelector(getHasInsuredWithKB);

  useEffect(() => {
    if (currentClient !== 0) {
      dispatch(loadContractors(null, currentClient, false, page, perPage));
      dispatch(checkCanShareLeads(currentClient));
      dispatch(loadHasInsuredWithKB(currentClient));
    }
  }, [currentClient]);

  useEffect(() => {
    if (userCompany && !currentClient) {
      dispatch(setCurrentClient(userCompany.id));
    }
  }, [userCompany]);

  useEffect(() => {
    dispatch(loadClients());
  }, []);

  useEffect(() => {
    if (currentClient) {
      if (searchTerm) {
        dispatch(loadContractors(searchTerm, currentClient, false, 1, perPage));
        dispatch(setPage(0));
      }
    }
  }, [searchTerm]);

  const movePage = async (page) => {
    if (sortDirectionState && columnState) {
      dispatch(
        loadContractors(
          null,
          currentClient,
          false,
          page,
          perPage,
          sortDirectionState,
          columnState
        )
      );
    } else {
      dispatch(loadContractors(null, currentClient, false, page, perPage));
    }
    dispatch(setPage(page - 1));
  };

  const changePerPage = async (newPerPage, page) => {
    dispatch(loadContractors(null, currentClient, false, page, newPerPage));
    dispatch(setPerPage(newPerPage));
    dispatch(setPage(page - 1));
  };

  const [searching, setSearching] = useState(false);
  const [sortDirectionState, setSortDirection] = useState(null);
  const [columnState, setColumn] = useState(null);

  const NameField = ({ row, onClick }) => (
    <Name onClick={onClick}>
      <span className="initials">
        {row.first_name && row.first_name[0]}
        {row.last_name && row.last_name[0]}
      </span>
      {row.first_name} {row.last_name}
    </Name>
  );

  const renderTable = () => {
    const deleteRow = (row) => {
      const options = {
        title: "Are you sure?",
        message: `Are you sure you want to delete ${row.first_name} ${row.last_name} It's not possible to reverse this operation`,
        buttons: [
          {
            label: "Yes",
            onClick: () => {
              dispatch(removeContractor(row.id));
            },
          },
          {
            label: "No",
          },
        ],
      };
      confirmAlert(options);
    };

    const columns = [
      {
        name: "Name",
        selector: "last_name",
        sortable: true,
        cell: (row) => (
          <NameField
            row={row}
            onClick={() => {
              handleRowClicked(row);
            }}
          />
        ),
      },
      {
        name: "Ltd Company Name",
        selector: "ltd_company_name",
        sortable: true,
      },
      {
        name: "Email",
        selector: "email",
        sortable: true,
      },
      {
        name: "Phone",
        selector: "phone_number",
        sortable: true,
      },
    ];

    if (canShareLeads && canChangeComms) {
      columns.push({
        name: (
          <div
            data-tip={`If you untick this box, the consultant and limited company's details will not be shared with Kingsbridge for insurance purposes. If this box is ticked, Kingsbridge will contact the limited company about professional insurances and show whether or not the limited company has IR35 Insurance with Kingsbridge within the tool. `}
          >
            Share with Kingsbridge
          </div>
        ),
        width: 120,
        cell: (row) => (
          <FormField
            type="checkbox"
            inputType="checkbox"
            value={row.opt_in_comms}
            changeMonitor={(value) => {
              dispatch(saveContractor({ id: row.id, opt_in_comms: value }));
            }}
          />
        ),
      });

      if (hasInsuredWithKB) {
        columns.push({
          name: "IR35 Protect",

          width: 120,
          cell: (row) => {
            let hasAPassResult = false;

            // return (
            //   <>
            //     <div
            //       data-tip={`We're still loading your data, please check back soon...`}
            //     >
            //       Pending...
            //     </div>
            //     <StyledReactTooltip place="bottom" effect="solid" />
            //   </>
            // );

            const regex = new RegExp(
              "^(AC|ZC|FC|GE|LP|OC|SE|SA|SZ|SF|GS|SL|SO|SC|ES|NA|NZ|NF|GN|NL|NC|R0|NI|EN|[0-9]{2})[0-9]{6}$"
            );

            if (!regex.test(row.ltd_company_number)) {
              return (
                <>
                  <Caution
                    data-tip={`The current value for Ltd Company Number is invalid and therefore we can't provide insurance data.  Please update it.`}
                  >
                    <StyledFontAwesomeIcon icon={faExclamationTriangle} />
                  </Caution>
                  <StyledReactTooltip place="bottom" effect="solid" />
                </>
              );
            }

            if (!row.engagements_unscoped || !row.opt_in_comms) {
              return (
                <>
                  <NA
                    data-tip={`Either there is no current outside IR35 engagement or the insurance feed is not enabled for this contractor`}
                  >
                    N/A
                  </NA>
                  <StyledReactTooltip place="bottom" effect="solid" />
                </>
              );
            }

            row.engagements_unscoped.forEach((engagement) => {
              if (engagement.reporting.length) {
                hasAPassResult = true;
              }
            });

            if (!hasAPassResult) {
              return (
                <>
                  <NA
                    data-tip={`There is no current outside IR35 engagement for this contractor`}
                  >
                    N/A
                  </NA>
                  <StyledReactTooltip place="bottom" effect="solid" />
                </>
              );
            }

            const insuranceData =
              row.insurance_data && JSON.parse(row.insurance_data.data);

            if (!insuranceData) {
              return (
                <>
                  <NoInsurance
                    data-tip={`There is currently no IR35 insurance in place but there is a current Outside IR35 engagement active`}
                  >
                    No
                  </NoInsurance>
                  <StyledReactTooltip place="bottom" effect="solid" />
                </>
              );
            }

            const protect = insuranceData.find(
              (cover) =>
                ir35Types.includes(cover.type) &&
                moment().isSameOrBefore(moment(cover.expiryDate))
            );

            if (!protect) {
              return (
                <>
                  <NoInsurance
                    data-tip={`There is currently no IR35 insurance in place but there is a current Outside IR35 engagement active`}
                  >
                    No
                  </NoInsurance>
                  <StyledReactTooltip place="bottom" effect="solid" />
                </>
              );
            } else {
              if (
                !moment(protect.expiryDate).isSameOrAfter(moment().add(45, "d"))
              ) {
                return (
                  <>
                    <HasInsuranceCaution
                      data-tip={`IR35 insurance is in place but expires in ${moment(
                        protect.expiryDate
                      ).diff(moment(), "days")} days`}
                    >
                      Yes
                    </HasInsuranceCaution>
                    <StyledReactTooltip place="bottom" effect="solid" />
                  </>
                );
              } else {
                return (
                  <>
                    <HasInsurance
                      data-tip={`IR35 insurance is in place and expires on ${moment(
                        protect.expiryDate
                      ).format("DD/MM/YYYY")}`}
                    >
                      Yes
                    </HasInsurance>
                    <StyledReactTooltip place="bottom" effect="solid" />
                  </>
                );
              }
            }
          },
        });
      }
    }

    columns.push({
      cell: (row) => (
        <Button
          action
          type="danger"
          size="reduced"
          onClick={() => deleteRow(row)}
        >
          Delete
        </Button>
      ),
      button: true,
    });

    const handleRowClicked = (row) =>
      history.push(`/main/contractors/edit/${row.id}`);

    return (
      <TableWrapper>
        <StyledDataTable
          noHeader
          progressPending={loading}
          pagination
          paginationServer
          customTheme={darkTheme}
          columns={columns}
          data={contractors}
          fixedHeader
          highlightOnHover
          fixedHeaderScrollHeight="100%"
          title="All Contractors"
          onRowClicked={handleRowClicked}
          paginationDefaultPage={page}
          paginationPerPage={perPage}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={changePerPage}
          onChangePage={movePage}
          onSort={handleSort}
          sortServer
        />
      </TableWrapper>
    );
  };

  const handleSort = async (column, sortDirection) => {
    // Set the direction and column as this is not passed in page change for the react-data-table-component package
    setSortDirection(sortDirection);
    setColumn(column.selector);
    dispatch(
      loadContractors(
        null,
        currentClient,
        false,
        page,
        perPage,
        sortDirection,
        column.selector
      )
    );
  };

  const executeSearch = (value) => {
    if (!value) {
      dispatch(setPage(0));
      dispatch(loadContractors(null, currentClient, false, 1, perPage));
    }
    setSearching(!!value);
    dispatch(setSearchTerm(value));
  };

  let clientOptions = [];

  if (clients) {
    clientOptions = generateClientOptions(clients, userCompany);
  }

  return (
    <Wrapper>
      <TitleArea>
        <div className="title">
          {" "}
          <h1>Contractors</h1>
          {clients && clients.length ? (
            <StyledCombo
              options={clientOptions}
              value={currentClient}
              onChange={(newValue) => {
                dispatch(setCurrentClient(newValue));
                dispatch(setPage(0));
              }}
            />
          ) : null}
          <FormField
            placeholder="Search"
            type="text"
            changeMonitor={(value) => executeSearch(value)}
            className="search"
            value={searchTerm}
          />
        </div>
        <div className="controls">
          <Button
            action
            onClick={() => {
              history.push("/main/contractors/edit/new");
            }}
          >
            New Contractor
          </Button>
        </div>
      </TitleArea>
      <ContentArea>
        {loading && !contractors && !contractors.length ? (
          <Loader />
        ) : contractors && contractors.length ? (
          renderTable()
        ) : (
          <div>
            {!searching ? (
              <Empty>
                <img src={noContractors} />
                <h2>You've not created any contractors yet</h2>
                <p>You can add one by click on 'New Contractor' above</p>
              </Empty>
            ) : (
              <Empty>
                <img src={noResults} />
                <h2>No search results</h2>
                <p>Try searching for something else</p>
              </Empty>
            )}
          </div>
        )}
      </ContentArea>
    </Wrapper>
  );
}

export default Contractors;
