import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import _ from "lodash";
import {
  getLoading,
  getContractorById,
  getCanShareLeadsForCompany,
} from "../store/contractors/selectors";
import styled from "styled-components";
import {
  createContractor,
  loadContractor,
  saveContractor,
  checkCanShareLeads,
  searchCompaniesHouse,
} from "../store/contractors/actions";
import {
  SplitView,
  TitleArea,
  ContentArea,
  Empty,
  Wrapper,
} from "../components/Elements";
import AsyncSelect from "react-select/async";
import {
  getOutOfCredits,
  getHasAdminRole,
  getHasSuperAdminRole,
  getHasClientAdminRole,
  getHideValidations,
} from "../store/users/selectors";
import { loadClients, loadHasInsuredWithKB } from "../store/clients/actions";
import { getClients, getHasInsuredWithKB } from "../store/clients/selectors";
import { getUserCompany } from "../store/users/selectors";
import Loader from "../components/Loader";
import CRUDForm from "../components/CRUDForm";
import Form from "../components/Form";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import noAssessments from "../assets/images/no-assessments.svg";
import Button from "../components/Button";
import EngagementTable from "../components/EnagementTable";
import AssignTags from "../components/AssignTags";
import formatCurrency from "format-currency";
import { getCreditContactDetails } from "../store/users/selectors";

const TableWrapper = styled.div`
  background-color: #eee;
  border-radius: 4px;
  padding: 30px 20px;
  margin: 0px 30px 0px 0px;
  position: relative;

  &.no-pad {
    padding: 0px 20px;
    border-radius: 4px;
  }
`;

const TagsWrapper = styled.div`
  background-color: #eee;
  padding: 30px 20px;
  margin: 0px 30px 30px 0px;
`;

const CreditsWarning = styled.div`
  margin-top: 5px;
  font-size: 12px;
  background-color: #fff;
  padding: 10px;
`;

const ButtonsSurround = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 200px;
`;

const SearchSurround = styled.div`
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 20px;

  h4 {
    margin-top: 0;
  }
`;

const InsuranceStatus = styled.div`
  margin-bottom: 20px;
  margin-top: -20px;
  h4 {
    margin-top: 0;
  }
`;

const ProductsWrapper = styled.div`
  display: flex;
  justify-content: start;
`;

const Product = styled.div`
  width: 25%;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin: 10px;

  h5 {
    margin-top: 0;
    font-size: 16px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 10px;
  }
`;

const ProductDate = styled.p`
  &.expired {
    color: red;
  }

  &.current {
    color: green;
  }
`;

const typesToDisplay = ["PI", "IR35_PROTECT", "IR35", "IR35A", "PL", "LE"];

const getProductName = (type) => {
  switch (type) {
    case "PI":
      return "Professional Indemnity";
    case "IR35_PROTECT":
    case "IR35":
    case "IR35A":
      return "IR35 Protect";
    case "PL":
      return "Public Liability";
    case "LE":
      return "Legal Expenses";
  }
};

const outStatuses = ["PASS", "GOOD_PASS"];

function Contractor({ match, history }) {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading);
  const recordId = match.params.contractorId;
  const isNew = recordId === "new";
  const userCompany = useSelector(getUserCompany);
  const outOfCredits = useSelector(getOutOfCredits);
  const hideValidations = useSelector(getHideValidations);
  const adminRole = useSelector(getHasAdminRole);
  const superAdminRole = useSelector(getHasSuperAdminRole);
  const clientAdminRole = useSelector(getHasClientAdminRole);

  const canChangeComms = adminRole || superAdminRole || clientAdminRole;

  const canShareLeads = useSelector(getCanShareLeadsForCompany);

  const contactDetails = useSelector(getCreditContactDetails);

  const hasInsuredWithKB = useSelector(getHasInsuredWithKB);

  const emptyState = {
    first_name: "",
    last_name: "",
    ltd_company_name: "",
    opt_in_comms: true,
  };

  const record = useSelector((state) =>
    getContractorById(state, Number(recordId))
  );

  const insuranceData =
    record && record.insurance_data && JSON.parse(record.insurance_data.data);

  const clients = useSelector(getClients);

  const [formData, setFormData] = useState(emptyState);

  const [dataFromForm, setDataFromForm] = useState({});

  useEffect(() => {
    dispatch(loadClients());
    if (recordId !== "new") {
      dispatch(loadContractor(recordId));
    }
  }, []);

  const [hasCurrentOutsideEngagement, setHasCurrentOutsideEngagement] =
    useState(false);

  useEffect(() => {
    if (record) {
      const recordToUse = record || {};
      setFormData({ ...emptyState, ...recordToUse });
    }
    if (record && record.company_id) {
      dispatch(checkCanShareLeads(record.company_id));
    }

    if (record && record.engagements) {
      setHasCurrentOutsideEngagement(
        !!record.engagements.find((engagement) => {
          const startDate = moment(engagement.start_date);
          const endDate = moment(engagement.completion_date);
          const now = moment();

          return (
            startDate.isSameOrBefore(now) &&
            endDate.isSameOrAfter(now) &&
            engagement.assessment &&
            outStatuses.includes(engagement.assessment.result)
          );
        })
      );
    }
  }, [recordId, record]);

  useEffect(() => {
    if (dataFromForm && dataFromForm.company_id) {
      dispatch(checkCanShareLeads(dataFromForm.company_id));
      dispatch(loadHasInsuredWithKB(dataFromForm.company_id));
    }
  }, [dataFromForm]);

  const sendForm = async (formData) => {
    if (isNew) {
      const response = await dispatch(createContractor(formData));
      history.push(`/main/contractors/edit/${response.id}`);
    } else {
      dispatch(saveContractor(formData));
    }
  };

  const loadCompaniesHouseOptions = useRef(
    _.debounce(async (value, callback) => {
      const results = await dispatch(searchCompaniesHouse(value));
      callback(
        results.map((result) => ({
          value: result.company_number,
          label: result.title,
        }))
      );
    }, 200)
  ).current;

  return (
    <Wrapper>
      {!isNew && !record ? (
        <Loader />
      ) : (
        <Wrapper>
          <TitleArea>
            <div className="title">
              {" "}
              <h1>
                {isNew
                  ? "New Contractor"
                  : `Edit ${formData.first_name} ${formData.last_name}`}
              </h1>
            </div>
            <div className="controls">
              <ButtonsSurround>
                {" "}
                <Button
                  action
                  disabled={isNew || outOfCredits}
                  onClick={() => {
                    history.push(
                      `/main/contractors/assessment/${record.company_id}/${
                        record ? record.id : "new"
                      }/new`
                    );
                  }}
                >
                  New Assessment
                </Button>
                {outOfCredits && (
                  <CreditsWarning>
                    You are out of credits, please speak to your tool provider
                    or email {contactDetails}
                  </CreditsWarning>
                )}
              </ButtonsSurround>
            </div>
          </TitleArea>
          <ContentArea>
            <SplitView>
              <div className="left">
                <CRUDForm
                  emptyState={emptyState}
                  formData={formData}
                  loading={loading}
                  recordId={recordId}
                  onSubmit={sendForm}
                  updater={(newData) => {
                    setDataFromForm(newData);
                  }}
                  validationRules={{
                    email: [
                      {
                        canned: "email",
                      },
                    ],
                    ltd_company_number: [
                      {
                        fn: (value) => {
                          const regex = new RegExp("^[a-zA-Z0-9]{8}$");
                          return regex.test(value);
                        },
                        msg: "Companies house must be alphanumeric and 8 characters long",
                      },
                    ],
                  }}
                >
                  <Form internal={true}>
                    <h2>Contractor's Details</h2>
                    <FormRow cols={2}>
                      <FormField
                        name="first_name"
                        label="First Name"
                        type="text"
                      />
                      <FormField
                        name="last_name"
                        label="Last Name"
                        type="text"
                      />
                    </FormRow>
                    <FormRow cols={1}>
                      <SearchSurround>
                        <h4>Search for Ltd Company</h4>
                        <p>
                          This feature will search Companies House by name to
                          get the correct name and company number
                        </p>
                        <AsyncSelect
                          cacheOptions
                          // value={props.options.find((option) => option.value === props.value)}
                          onChange={({ value, label }) => {
                            setFormData({
                              ...dataFromForm,
                              ltd_company_number: value,
                              ltd_company_name: label,
                            });
                          }}
                          loadOptions={loadCompaniesHouseOptions}
                        />
                      </SearchSurround>
                    </FormRow>
                    <FormRow cols={2}>
                      <FormField
                        name="ltd_company_name"
                        label="Ltd company name"
                        type="text"
                      />

                      <FormField
                        name="ltd_company_number"
                        label="Ltd company number"
                        type="text"
                        helpText="Ensure you are using the Companies House look up and not the number on a Certificate of Incorporation as this may exclude a digit where the Limited Company starts with a zero"
                      />
                    </FormRow>

                    <FormRow cols={2}>
                      <FormField
                        name="email"
                        label="Email Address"
                        type="email"
                      />
                      <FormField
                        name="phone_number"
                        label="Phone Number"
                        type="text"
                      />
                    </FormRow>
                    <FormRow cols={2}>
                      {clients && clients.length ? (
                        <FormField
                          name="company_id"
                          label="Which client does this contractor belong to?"
                          inputType="select"
                          options={[userCompany]
                            .concat(clients)
                            .map((company) => ({
                              value: company.id,
                              name: company.name,
                            }))}
                        />
                      ) : null}
                      <FormField
                        name="external_reference"
                        label="External Reference"
                        type="text"
                        optional={true}
                      />
                    </FormRow>
                    {canChangeComms && canShareLeads && (
                      <FormRow cols={1}>
                        <FormField
                          name="opt_in_comms"
                          label="Share and receive contractor information with and from Kingsbridge"
                          type="checkbox"
                          inputType="checkbox"
                          optional={true}
                          helpText="If you untick this box, the consultant and limited company's details will not be shared with Kingsbridge for insurance purposes. If this box is ticked, Kingsbridge will contact the limited company about professional insurances and show whether or not the limited company has insurance with Kingsbridge within the tool."
                        />
                      </FormRow>
                    )}

                    {canShareLeads &&
                      insuranceData &&
                      dataFromForm.opt_in_comms &&
                      hasCurrentOutsideEngagement &&
                      hasInsuredWithKB && (
                        <InsuranceStatus>
                          <h4>Kingsbridge Insurance Status</h4>
                          <ProductsWrapper>
                            {insuranceData
                              .filter((item) =>
                                typesToDisplay.includes(item.type)
                              )
                              .map((item) => {
                                return (
                                  <Product>
                                    <h5>{getProductName(item.type)}</h5>
                                    <ProductDate
                                      className={
                                        moment(item.expiryDate).isBefore(
                                          moment()
                                        )
                                          ? "expired"
                                          : "current"
                                      }
                                    >
                                      Expires:{" "}
                                      {moment(item.expiryDate).format(
                                        "DD/MM/YYYY"
                                      )}
                                    </ProductDate>
                                    <p>
                                      Cover level:{" "}
                                      {formatCurrency(item.coverLevel, {
                                        format: "%s%v",
                                        symbol: "£",
                                      })}
                                    </p>
                                  </Product>
                                );
                              })}
                          </ProductsWrapper>
                        </InsuranceStatus>
                      )}

                    <FormRow cols={2}>
                      <Button noFullWidth={true}>Save</Button>
                    </FormRow>
                  </Form>
                </CRUDForm>
              </div>
              <div className="right">
                {record && record.id && (
                  <TagsWrapper>
                    <h2>Contractor Tags</h2>
                    <AssignTags
                      companyId={record.company_id}
                      currentCompanyId={dataFromForm.company_id}
                      entityId={record.id}
                      entityType="contractor"
                    />
                  </TagsWrapper>
                )}
                <TableWrapper>
                  {record && record.engagements && record.engagements.length ? (
                    <span>
                      <h2>Assessments</h2>
                      <EngagementTable
                        reload={() => dispatch(loadContractor(recordId))}
                        companyId={record.company_id}
                        data={record.engagements.filter((record) => {
                          if (hideValidations) {
                            return !record.validation;
                          } else {
                            return record;
                          }
                        })}
                        history={history}
                        pagination={true}
                      />
                    </span>
                  ) : (
                    <Empty>
                      <img src={noAssessments} />
                      <h2>
                        You've not created any assessments for this contractor
                        yet
                      </h2>
                      <p>
                        {isNew
                          ? `You'll be able to create assessments once you've saved the new contractor's details`
                          : `You can add one by click on 'New Assessment' above`}
                      </p>
                    </Empty>
                  )}
                </TableWrapper>
              </div>
            </SplitView>
          </ContentArea>
        </Wrapper>
      )}
    </Wrapper>
  );
}

export default Contractor;
