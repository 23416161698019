import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import styled from "styled-components";
import { getSubscription, getLoading } from "../store/users/selectors";
import { ContentArea } from "../components/Elements";
import Button from "../components/Button";
import Loader from "../components/Loader";

const SettingsWrapper = styled.div`
  background-color: #fff;
  margin: 0px 20px 20px 20px;
  border-radius: 10px;
  padding: 20px;
`;

const ButtonWrapper = styled.div`
  margin-top: 20px;
`;

function Subscription() {
  const subscription = useSelector(getSubscription);
  const loading = useSelector(getLoading);
  const parsedDate = new Date(subscription.subscription_end_date);
  const endDate = moment(parsedDate);
  const formattedEndDate = endDate.format("Do MMMM YYYY");

  const dispatch = useDispatch();
  let status;

  switch (subscription.subscription_status) {
    case "trialing":
      status = `You're currently in a 7 day trial which ends on ${formattedEndDate}, you can cancel up until this date before being charged`;
      break;
    case "active":
      status = `Your subscription ends on ${formattedEndDate}.  You can cancel your subscription renewal so that we will not automatically renew your subscription after this date`;
      break;
    case "pending_cancel":
      status = `You've cancelled your subscription so it will end on ${formattedEndDate}. You can reverse the cancellation until this date.`;
      break;
  }

  return (
    <ContentArea>
      {loading ? (
        <Loader />
      ) : (
        <SettingsWrapper>
          <h2>My Subscription</h2>
          {status}
        </SettingsWrapper>
      )}
    </ContentArea>
  );
}

export default Subscription;
