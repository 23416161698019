import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";

import { sendLead } from "../store/assessment/actions";
import {
  getLeadLoading,
  getLeadError,
  getLeadSuccess
} from "../store/assessment/selectors";
import CRUDForm from "./CRUDForm";
import Form from "./Form";
import FormField from "./FormField";
import FormRow from "./FormRow";
import { errorStrong, errorWeak } from "../styles/colors";

import Button from "../components/Button";

const Teaser = styled.div`
  text-align: center;
  font-size: 30px;
`;

const Success = styled.div`
  text-align: center;
  padding: 20px;
`;

const Error = styled.div`
  text-align: center;
  padding: 20px;
  background-color: ${errorWeak};
  color: ${errorStrong};
`;

function Company({ product, email }) {
  const dispatch = useDispatch();
  const loading = useSelector(getLeadLoading);
  const errors = useSelector(getLeadError);
  const success = useSelector(getLeadSuccess);
  const emptyState = {
    phone: "",
    callback: ""
  };

  const [formData, setFormData] = useState(emptyState);

  let productName;

  switch (product) {
    case "ACCOUNTING":
      productName = "Crunch Accounting Service";
      break;
    case "UMBRELLA":
      productName = "Crunch Umbrella Service";
      break;
    case "INSURANCE":
      productName = "Insurance";
      break;
    case "REVIEW":
      productName = "IR35 Contract Review";
      break;
  }

  const sendForm = async formData => {
    let data = {
      Phone: formData.phone,
      Requested_Call_Back_Time: formData.callback,
      Email: email
    };
    switch (product) {
      case "ACCOUNTING":
        data.Product_of_interest = "Business Accounting";
        break;
      case "UMBRELLA":
        data.Product_of_interest = "Umbrella";
        break;
      case "INSURANCE":
        data.Insurance_Legal_Expenses = true;
        break;
      case "REVIEW":
        data.Accountancy_Contract_Review = true;
        break;
    }

    dispatch(sendLead(data));
  };

  return (
    <CRUDForm
      emptyState={emptyState}
      formData={formData}
      loading={loading}
      recordId="new"
      onSubmit={sendForm}
      validationRules={{
        email: [
          {
            canned: "email"
          }
        ]
      }}
    >
      {errors && (
        <Error>
          Something went wrong, please check your connection and try again
        </Error>
      )}
      {!success && (
        <Form internal={true}>
          <Teaser>Enquire about {productName}</Teaser>
          <p>
            So that our trusted partner can get in touch with you, please
            provide your phone number and the best time for us to get back to
            you. Then leave the rest to us.
          </p>
          <FormRow cols={2}>
            <FormField name="phone" label="Your phone number" type="number" />
            <FormField
              name="callback"
              label="Best time to contact you"
              type="text"
            />
          </FormRow>
          <FormRow cols={2}>
            <Button noFullWidth={true} loading={loading}>
              Send Enquiry
            </Button>
          </FormRow>
        </Form>
      )}

      {success && (
        <Success>We've received your enquiry, we'll be in touch.</Success>
      )}
    </CRUDForm>
  );
}

export default Company;
