import React, { useState, useContext } from "react";
import { ColorContext } from "../components/Bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getLoading, getResetComplete } from "../store/users/selectors";
import { startResetPassword } from "../store/users/actions";
import Validation from "../components/FormValidation";
import styled from "styled-components";
import Header from "../components/Header";
import Form from "../components/Form";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import Button from "../components/Button";
import { device } from "../components/Devices";
import { Content, FormLayoutWrapper, Success } from "../components/Elements";

const H1 = styled.h1`
  font-family: Blinker;
  margin: 0;
  color: #fff;
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  margin-top: 0px;
  @media ${device.laptop} {
    margin-top: 100px;
  }
`;

const H2 = styled.h2`
  font-family: Blinker;
  font-weight: 100;
  color: #888;
  font-size: 20px;
  margin: 0;
  margin-bottom: 20px;
`;

function Forgot() {
  const [formData, setFormData] = useState({
    email: ""
  });
  const mainColor = useContext(ColorContext);
  const [validation, setValidation] = useState({});
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const resetComplete = useSelector(getResetComplete);

  const formValidation = new Validation(validation, setValidation);

  formValidation.setRules({
    email: [
      {
        canned: "email"
      }
    ]
  });

  const updateFormData = (field, value) => {
    setFormData({ ...formData, [field]: value });
    formValidation.validate(field, value);
  };

  const sendForm = async e => {
    e.preventDefault();
    const validationErrors = formValidation.validateForm(formData);
    if (!validationErrors) {
      dispatch(startResetPassword(formData.email));
    }
  };

  return (
    <div>
      <Header />
      <Content mainColor={mainColor}>
        <H1>Forgot password</H1>
        <FormLayoutWrapper>
          <Form>
            <H2>Reset your password</H2>
            <p>
              Enter your email address and we'll send you a link to reset your
              password
            </p>
            <FormRow cols={1}>
              <FormField
                name="email"
                label="Your email address"
                type="text"
                changeMonitor={value => updateFormData("email", value)}
                value={formData.email}
                validation={validation.email}
              />
            </FormRow>
            <FormRow cols={1}>
              <Button onClick={sendForm} loading={loading} disabled={loading}>
                Send
              </Button>
            </FormRow>
            {resetComplete ? (
              <Success>
                <h5>
                  We've sent an email to the address you provided with further
                  instructions
                </h5>
              </Success>
            ) : null}
          </Form>

          {/* <Detail>
            <H2>Stuck?</H2>
            <p>
              Still need help logging in? Email us:{" "}
              <a href="mailto:help@ir35.io">help@ir35.io</a>
            </p>
          </Detail> */}
        </FormLayoutWrapper>
      </Content>
    </div>
  );
}

export default Forgot;
