import styled from "styled-components";
import { device } from "./Devices";
import {
  errorWeak,
  errorStrong,
  successWeak,
  successStrong,
  contrastColor,
  contrastFontColor,
  warnBG,
  warnCopy
} from "../styles/colors";

export const H1 = styled.h1`
  font-family: Blinker;
  margin: 0;
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  margin-top: 0px;
  @media ${device.laptop} {
    margin-top: 100px;
  }
`;

export const Content = styled.div`

${props => (props.mainColor ? `background-color: ${props.mainColor};` : "")}

${props =>
  props.isGlobal
    ? props.hasNotification
      ? "padding-top: 155px;     height: calc(100vh - 155px);"
      : "padding-top: 120px;    height: calc(100vh - 100px);"
    : "padding-top: 100px;    height: calc(100vh - 100px);"}

@media ${device.laptop} {
  ${props =>
    props.isGlobal
      ? props.hasNotification
        ? "padding-top: 115px;     height: calc(100vh - 115px);"
        : "padding-top: 70px;    height: calc(100vh - 50px);"
      : "padding-top: 50px;    height: calc(100vh - 50px);"}
}

  
  display: flex;
  flex-direction: column;
`;

export const FormLayoutWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin: 10px;
  @media ${device.laptop} {
    width: 500px;
  }

  margin: auto;
  display: flex;
  margin-top: 40px;
  flex-wrap: wrap;
`;

export const Errors = styled.div`
  border-radius: 4px;
  border: 1px solid ${errorStrong};
  color: ${errorStrong};
  background-color: ${errorWeak};
  padding: 20px;

  h5 {
    margin: 0px;
    font-size: 18px;
    margin-bottom: 5px;
  }

  ul {
    padding-left: 18px;
  }
`;

export const Success = styled.div`
  border-radius: 4px;
  border: 1px solid ${successStrong};
  color: ${successStrong};
  background-color: ${successWeak};
  padding: 20px;

  h5 {
    margin: 0px;
    font-size: 18px;
    margin-bottom: 5px;
  }

  ul {
    padding-left: 18px;
  }
`;

export const NotificationElement = styled.div`
  display: block;
  background-color: ${warnBG};
  color: ${warnCopy};
  font-family: Blinker;
  line-height: 50px;
  height: 50px;
  padding: 0px 40px;
  text-align: center;
  position: fixed;
  top: 76px;
  left: 0px;
  width: 100%;
  box-sizing: border-box;
  z-index: 10000;

  a {
    color: ${contrastFontColor};s
  }
`;

export const SplitView = styled.div`
  background-color: #f6f6f6;
  display: flex;
  width: 100%;

  .left {
    flex: 1;
  }

  .right {
    flex: 1;
  }
`;

export const ContentArea = styled.div`
  background-color: #f6f6f6;

  width: 100%;

  padding-top: 40px;
  padding-bottom: 40px;
}
`;

export const TitleArea = styled.div`
  width: 100%;
  background-color: #f6f6f6;
  box-sizing: border-box;
  padding: 30px 30px 0px 30px;
  display: flex;
  h1 {
    font-size: 28px;
    margin: 0px;
    font-family: Blinker;
  }

  .search {
    input {
      max-width: 300px;
    }
  }

  .title {
    flex: 1;
  }
  .controls {
    flex-basis: 300px;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }
`;

export const Empty = styled.div`
  text-align: center;
  img {
    width: 300px;
  }
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  flex: 1;
  box-sizing: border-box;
`;

export const Detail = styled.div``;
