import React from "react";
import styled from "styled-components";
import { Products, Product } from "../pages/ContractorMainAssessmentResult";

function CrunchUpSellsCredit() {
  return (
    <>
      <Products>
        <Product>
          <h3>What next?</h3>
          <p>
            For maximum peace of mind, we can also have one of our IR35 experts
            check your contract.
          </p>
          <p>
            We’ll review your contract with your end client and the actual
            working practices in place to help you understand your employment
            status. If necessary, we’ll suggest amendments to your contract, so
            you can show your employment status is outside IR35.{" "}
          </p>

          <p>
            You have one IR35 accountant contract review included in your
            package every 12 months. If you’d like us to review your contract
            based on your results please send your contract to{" "}
            <a href="mailto:support@crunch.co.uk">support@crunch.co.uk</a> along
            with this report, and we’ll be in touch with you.
          </p>
        </Product>
      </Products>
    </>
  );
}

export default CrunchUpSellsCredit;
