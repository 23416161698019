import React, { useEffect, useState } from "react";
import { Route, Switch, withRouter, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  loadCampaigns,
  saveCampaign,
  publishCampaign,
  uploadURLs,
  loadCampaignData,
  unpublishCampaign,
} from "../store/campaigns/actions";
import {
  getCampaign,
  getCampaignLoading,
  getCampaignError,
  getCampaignData,
} from "../store/campaigns/selectors";
import { getUserToken } from "../store/users/selectors";
import { SplitView, ContentArea } from "../components/Elements";
import { TitleArea } from "../components/Elements";
import CRUDForm from "../components/CRUDForm";
import Form from "../components/Form";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import Button from "../components/Button";
import { errorStrong } from "../styles/colors";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import { Tabs, tabStyles } from "../components/Tabs";

const theme = {
  ...darkTheme,
  rows: {
    hoverBackgroundColor: "#ddd",
    fontSize: "16px",
  },
};

const Main = styled.div`
  display: flex;
  height: 100%;
`;

const TableWrapper = styled.div`
  background-color: #eee;
  border-radius: 4px;
  padding: 30px 20px;
  margin: 0px 30px 0px 0px;
  position: relative;

  &.no-pad {
    padding: 20px 20px;
  }
`;

const Wrap = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
`;

const Failures = styled.div`
  border: 1px solid ${errorStrong};
  padding: 10px;
  color: ${errorStrong};
  margin-bottom: 20px;
`;

const FormWrapper = styled.div`
  margin-top: 20px;
`;

const Line = styled.div`
  width: 100%;
  display: block;
`;

const LineSurround = styled.div`
  display: block;
  flex: 1;
`;

const TabsWrapper = styled.div`
  margin: 0px 0px 0;
  padding-top: 10px;
`;

const StyledTabs = styled(Tabs)`
  margin-left: 0px;
  margin-right: 0px;
`;

const Tab = styled.a`
  ${tabStyles}
  text-decoration: none;
`;

const TabContent = styled.div`
  background-color: #fff;
  border-radius: 5px;
  margin: 0 15px 0px;
`;

const ValidationError = styled.div`
  color: red;
`;

const ContentWrapper = styled.div`
  padding: 20px 40px;

  h2 {
    font-weight: normal;
  }
`;

const OverView = styled.div`
  display: flex;
`;

const DataPoint = styled.div`
  padding: 5px;
  border: 1px solid #ccc;
  margin: 5px;
  flex: 1;

  h3 {
    font-size: 16px;
    text-align: center;
    margin: 0;
    font-weight: normal;
  }
`;

const Value = styled.div`
  font-size: 48px;
  text-align: center;
`;

const Buttons = styled.div`
  display: flex;

  button {
    margin-right: 5px;
  }
`;

const DefaultLink = styled.div`
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
`;

function EditCampaign({ history, match }) {
  const dispatch = useDispatch();
  const id = match.params.id;

  useEffect(() => {
    dispatch(loadCampaigns());
  }, []);

  useEffect(() => {
    if (id !== "new") {
      dispatch(loadCampaignData(id));
    }
  }, [id]);

  const record = useSelector((state) => getCampaign(id, state));
  const loading = useSelector(getCampaignLoading);
  const error = useSelector(getCampaignError);
  const campaignData = useSelector(getCampaignData);

  const emptyState = {
    name: "",
    type: "",
    usage: "SINGLE",
    date_expire: new Date(),
    date_start: new Date(),
  };

  const [formData, setFormData] = useState(emptyState);

  const [dataFromForm, setDataFromForm] = useState({});

  const [activeTab, setActiveTab] = useState("DETAIL");

  const [validateMessage, setValidateMessage] = useState("");
  const [file, setFile] = useState(null);
  const [generateLoading, setGenerateLoading] = useState(false);

  const userToken = useSelector(getUserToken);

  useEffect(() => {
    if (record) {
      const recordToUse = record || {};
      setFormData({ ...emptyState, ...recordToUse });
    }
  }, [id, record]);

  const sendForm = async (formData) => {
    const idToSend = id === "new" ? null : id;

    const result = await dispatch(saveCampaign(idToSend, formData));

    if (result && result.id && id === "new") {
      history.push(`/main/campaigns/${result.id}`);
    }
  };

  const publish = async (id) => {
    await dispatch(publishCampaign(id));
    dispatch(loadCampaigns());
  };

  const unpublish = async (id) => {
    await dispatch(unpublishCampaign(id));
    dispatch(loadCampaigns());
  };

  const generateURLs = async (e) => {
    e.preventDefault();
    setGenerateLoading(true);
    let formData = new FormData();

    formData.append("file", file);

    let response;

    setValidateMessage("");
    try {
      response = await dispatch(uploadURLs(id, formData));
    } catch (e) {
      return setValidateMessage(e.data.body.errors.join(", "));
    }

    setGenerateLoading(false);

    return response;
  };

  const columns = [
    {
      name: "Name",
      selector: "user",
    },
    {
      name: "Date",
      selector: "date",
    },
    {
      cell: (row) => {
        const publishedValues = JSON.parse(row.published_values || {});
        delete publishedValues.published_values;
        delete publishedValues.date_created;
        delete publishedValues.archived;
        delete publishedValues.logs;
        delete publishedValues.last_edit;
        delete publishedValues.id;

        const keys = Object.keys(publishedValues);

        const returnObj = keys.map((key) => {
          return (
            <Line>
              <strong>{key}</strong>: {publishedValues[key]}
            </Line>
          );
        });

        return <LineSurround>{returnObj}</LineSurround>;
      },
      name: "Values",
    },
  ];

  return (
    <Main>
      <Wrap>
        <div>
          <TitleArea>
            <div className="title">
              <h1>{id === "new" ? "New Campaign" : "Edit Campaign"}</h1>
            </div>
          </TitleArea>
        </div>
        <FormWrapper>
          <SplitView>
            <div className="left">
              <TabContent>
                <TabsWrapper>
                  <StyledTabs>
                    <Tab
                      className={activeTab === "DETAIL" && "active"}
                      onClick={(e) => {
                        e.preventDefault();
                        setActiveTab("DETAIL");
                      }}
                    >
                      Detail
                    </Tab>
                    {id !== "new" && !dataFromForm.is_raf && (
                      <Tab
                        className={activeTab === "LINKS" && "active"}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab("LINKS");
                        }}
                      >
                        Generate Links
                      </Tab>
                    )}
                    {id !== "new" && (
                      <Tab
                        className={activeTab === "DATA" && "active"}
                        onClick={(e) => {
                          e.preventDefault();
                          setActiveTab("DATA");
                        }}
                      >
                        Campaign Data
                      </Tab>
                    )}
                  </StyledTabs>
                </TabsWrapper>

                {activeTab === "DETAIL" && (
                  <CRUDForm
                    emptyState={emptyState}
                    formData={formData}
                    loading={loading}
                    recordId={id}
                    onSubmit={sendForm}
                    updater={(newData) => {
                      setDataFromForm(newData);
                    }}
                    linkedBooleanValues={{
                      feature_review: ["feature_children"],
                    }}
                    validationRules={{
                      include_borderline: [
                        {
                          excludeDefault: true,
                          fn: (value) => {
                            if (value === null || value === undefined) {
                              return false;
                            }
                            return true;
                          },
                          msg: "This field is required",
                        },
                      ],
                      campaign_code: [
                        {
                          fn: (value) => {
                            return new RegExp("^[A-Za-z0-9_]*$").test(value);
                          },
                          msg:
                            "Must only contain numbers, letters and underscores and 15 characters or less",
                        },
                      ],
                      gift_amount: [
                        {
                          fn: (value) => {
                            return new RegExp("^[0-9.]*$").test(value);
                          },
                          msg: "Must only contain numbers and decimal points",
                        },
                      ],
                      discount: [
                        {
                          fn: (value) => {
                            const v = Number(value);

                            return v >= 1 && v <= 100;
                          },
                          msg: "Must be between 1 and 100",
                        },
                      ],
                    }}
                  >
                    <Form internal={true}>
                      <h2>Campaign Details</h2>
                      {error && <Failures>{error.message}</Failures>}

                      <FormRow cols={2}>
                        <FormField
                          name="name"
                          label="Campaign Name"
                          type="text"
                        />
                      </FormRow>

                      <FormRow cols={2}>
                        <FormField
                          name="is_raf"
                          label="Refer a friend offer"
                          type="checkbox"
                          inputType="checkbox"
                        />
                      </FormRow>

                      {dataFromForm.is_raf && (
                        <FormRow cols={2}>
                          <FormField
                            name="raf_headline"
                            label="Headline"
                            type="text"
                          />
                        </FormRow>
                      )}
                      {dataFromForm.is_raf && (
                        <FormRow cols={2}>
                          <FormField
                            name="raf_explainer"
                            label="Explainer"
                            type="textarea"
                            inputType="textarea"
                          />
                        </FormRow>
                      )}
                      {dataFromForm.is_raf && (
                        <FormRow cols={2}>
                          <FormField
                            name="raf_terms"
                            label="Terms"
                            type="textarea"
                            inputType="textarea"
                          />
                        </FormRow>
                      )}

                      <FormRow cols={2}>
                        <FormField
                          name="campaign_code"
                          label="Campaign Code"
                          type="text"
                          disabled={record && record.logs && record.logs.length}
                          maxLength="15"
                        />
                      </FormRow>

                      {!dataFromForm.is_raf && (
                        <FormRow cols={2}>
                          <FormField
                            name="usage"
                            label="Usage"
                            inputType="radio"
                            options={[
                              { value: "SINGLE", name: "Single" },
                              { value: "MULTI", name: "Multiple" },
                            ]}
                          />
                        </FormRow>
                      )}

                      <FormRow cols={2}>
                        <FormField
                          name="date_start"
                          label="Campaign Start"
                          inputType="date"
                        />
                      </FormRow>
                      <FormRow cols={2}>
                        <FormField
                          name="date_expire"
                          label="Campaign Expiry"
                          inputType="date"
                        />
                      </FormRow>

                      <FormRow cols={2}>
                        <FormField
                          name="type"
                          label="Campaign Type"
                          inputType="radio"
                          options={[
                            { value: "E_GIFT", name: "E-gift" },
                            { value: "OFFER", name: "Offer" },
                          ]}
                        />
                      </FormRow>

                      {dataFromForm.type === "E_GIFT" && (
                        <FormRow cols={2}>
                          <FormField
                            name="gift_amount"
                            label="Gift Amount"
                            type="number"
                          />
                        </FormRow>
                      )}
                      {dataFromForm.type === "OFFER" && (
                        <FormRow cols={2}>
                          <FormField
                            name="discount"
                            label="Discount %"
                            type="number"
                            maxLength="3"
                          />
                        </FormRow>
                      )}

                      {dataFromForm.type === "OFFER" &&
                        Number(dataFromForm.discount) === 100 && (
                          <FormRow cols={2}>
                            <FormField
                              booleanValues
                              name="include_borderline"
                              label="Include Borderline Reviews?"
                              inputType="radio"
                              options={[
                                { value: true, name: "Yes" },
                                { value: false, name: "No" },
                              ]}
                            />
                          </FormRow>
                        )}

                      <Buttons>
                        <Button noFullWidth={true} loading={loading}>
                          {id === "new" ? "Create new campaign" : "Save"}
                        </Button>
                        {id !== "new" && (
                          <Button
                            noFullWidth={true}
                            loading={loading}
                            noBind
                            onClick={(e) => {
                              e.preventDefault();
                              publish(id);
                            }}
                          >
                            Publish
                          </Button>
                        )}
                        {id !== "new" && (
                          <Button
                            noFullWidth={true}
                            loading={loading}
                            noBind
                            onClick={(e) => {
                              e.preventDefault();
                              unpublish(id);
                            }}
                          >
                            Unpublish
                          </Button>
                        )}
                      </Buttons>
                    </Form>
                  </CRUDForm>
                )}
                {activeTab === "LINKS" && (
                  <ContentWrapper>
                    <h2>Generate Links</h2>
                    <h3>Default Link</h3>
                    <DefaultLink>{formData.default_url}</DefaultLink>
                    <FormRow cols={2}>
                      <FormField
                        placeholder="Choose File"
                        type="file"
                        changeMonitor={setFile}
                        label="File to upload"
                      />
                    </FormRow>
                    <FormRow>
                      <Button
                        noFullWidth={true}
                        loading={generateLoading}
                        onClick={generateURLs}
                      >
                        Generate URLs
                      </Button>
                    </FormRow>
                    {validateMessage && (
                      <FormRow>
                        <ValidationError>{validateMessage}</ValidationError>
                      </FormRow>
                    )}
                  </ContentWrapper>
                )}
                {activeTab === "DATA" && (
                  <ContentWrapper>
                    <h2>Campaign Data</h2>
                    <OverView>
                      <DataPoint>
                        <h3>Leads Generated</h3>
                        <Value>{campaignData.leads}</Value>
                        <Button
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_BASE_URL}/campaigns/download-leads/${id}?access_token=${userToken}`
                            )
                          }
                        >
                          Download Leads
                        </Button>
                      </DataPoint>

                      <DataPoint>
                        <h3>Reviews Purchased</h3>
                        <Value>{campaignData.totalPurchased}</Value>
                        <Button
                          onClick={() =>
                            window.open(
                              `${process.env.REACT_APP_BASE_URL}/campaigns/download-purchases/${id}?access_token=${userToken}`
                            )
                          }
                        >
                          Download Purchases
                        </Button>
                      </DataPoint>
                      <DataPoint>
                        <h3>Total Revenue</h3>
                        <Value>£{campaignData.revenue}</Value>
                      </DataPoint>
                      {campaignData.discount ? (
                        <DataPoint>
                          <h3>Total Discount</h3>
                          <Value>£{campaignData.discount}</Value>
                        </DataPoint>
                      ) : null}
                      {campaignData.gifts ? (
                        <DataPoint>
                          <h3>Total Gifted</h3>
                          <Value>£{campaignData.gifts}</Value>
                        </DataPoint>
                      ) : null}
                    </OverView>
                  </ContentWrapper>
                )}
              </TabContent>
            </div>
            {id !== "new" && (
              <div className="right">
                <TableWrapper className="no-pad">
                  <h2>Logs</h2>
                  <StyledDataTable
                    defaultSortField="date"
                    defaultSortAsc={false}
                    noHeader
                    pagination
                    customTheme={theme}
                    columns={columns}
                    data={record && record.logs}
                    fixedHeader
                    highlightOnHover
                    fixedHeaderScrollHeight="100%"
                  />
                </TableWrapper>
              </div>
            )}
          </SplitView>
        </FormWrapper>
      </Wrap>
    </Main>
  );
}

export default withRouter(EditCampaign);
