import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  loadNotificationUsers,
  subscribe,
  unsubscribe,
} from "../store/assessment/actions";
import {
  getSubscribersLoading,
  getSubscribers,
} from "../store/assessment/selectors";
import { StyledDataTable, darkTheme } from "./TableStyles";
import Button from "./Button";
import { confirmAlert } from "react-confirm-alert";
import { getUsersForCompany } from "../store/users/actions";
import {
  getCompanyUsers,
  getLoading,
  getUser,
  getReviewerCompany,
} from "../store/users/selectors";
import { warnBG, warnCopy } from "../styles/colors";

const TagElement = styled.div``;

const Selector = styled.select`
  height: 39px;
  margin-top: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  margin-right: 20px;
`;

const Label = styled.label`
  display: block;
`;

const WarningBox = styled.div`
  background-color: ${warnBG};
  color: ${warnCopy};
  padding: 10px;
  font-size: 12px;
  border-radius: 3px;
  margin-bottom: 10px;
  border: 1px solid ${warnCopy};
`;

const theme = {
  ...darkTheme,
  rows: {
    hoverBackgroundColor: "#ddd",
    fontSize: "16px",
  },
};

function Notifications({ assessmentId }) {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  useEffect(() => {
    dispatch(loadNotificationUsers(assessmentId));
  }, []);

  useEffect(() => {
    if (user && user.userData) {
      dispatch(getUsersForCompany(user.userData.company_id));
    }
  }, [user]);
  const [currentUser, setCurrentUser] = useState(null);
  const loading = useSelector(getSubscribersLoading);
  const subscribers = useSelector(getSubscribers);
  const users = useSelector((state) =>
    getCompanyUsers(state, user && user.userData && user.userData.company_id)
  ).filter(
    (user) =>
      !subscribers
        .map((subscriber) => Number(subscriber.user_id))
        .includes(Number(user.id))
  ).sort((a, b) => {
    if (a.last_name < b.last_name) {
      return -1;
    }
    if (a.last_name > b.last_name) {
      return 1;
    }
    return 0;
  });

  const addCurrentSubscriber = async () => {
    await dispatch(subscribe(currentUser, assessmentId));
    await dispatch(loadNotificationUsers(assessmentId));
    setCurrentUser(null);
  };

  const removeSubscriber = (id) => {
    const options = {
      title: "Are you sure?",
      message:
        "This will mean there are no subscribers for this assessment and so no one will be alerted when there are changes.",
      buttons: [
        {
          label: "Cancel",
        },
        {
          label: "Confirm",
          onClick: () => {
            doRemoveSubscriber(id);
          },
        },
      ],
    };
    if (subscribers.length === 1) {
      confirmAlert(options);
    } else {
      doRemoveSubscriber(id);
    }
  };

  const doRemoveSubscriber = async (id) => {
    await dispatch(unsubscribe(id));
    await dispatch(loadNotificationUsers(assessmentId));
  };

  const columns = [
    {
      name: "Name",
      cell: (row) => {
        return row.user
          ? `${row.user.first_name} ${row.user.last_name}`
          : "Unknown User";
      },
      grow: 3,
    },
    {
      cell: (row) => (
        <Button
          action
          type="danger"
          size="reduced"
          onClick={(e) => {
            e.preventDefault();
            removeSubscriber(row.id);
          }}
        >
          Unsubscribe
        </Button>
      ),
      width: 100,
    },
  ];

  return (
    <div>
      <WarningBox>
        Important: You must ensure the assessment has at least one user
        subscribed to receiving notifications, otherwise no one will be informed
        when changes take place or a dispute is raised.
      </WarningBox>
      <Label>Add recipient</Label>
      <Selector
        onChange={(e) => setCurrentUser(e.target.value)}
        value={currentUser}
      >
        <option value={null}>Choose</option>
        {users.map((user) => (
          <option value={user.id}>
            {user.first_name} {user.last_name}
          </option>
        ))}
      </Selector>
      <Button
        noFullWidth
        onClick={(e) => {
          e.preventDefault();
          addCurrentSubscriber();
        }}
      >
        Add
      </Button>
      <StyledDataTable
        pagination
        noHeader
        customTheme={theme}
        columns={columns}
        data={subscribers || []}
        fixedHeader
        highlightOnHover
        fixedHeaderScrollHeight="100%"
      />
    </div>
  );
}

export default Notifications;
