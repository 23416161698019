import { get } from "lodash/fp";

export const getLoading = (state) => {
  return get("clients.loading", state);
};
export const getClientById = (state, id) => {
  const clients = get("clients.list", state);
  let result;

  function searchChildCompanies(companies) {
    companies.forEach((company) => {
      if (company.id === Number(id)) {
        result = company;
      } else if (company.companies) {
        searchChildCompanies(company.companies);
      }
    });
  }

  if (!clients) {
    return null;
  }

  searchChildCompanies(clients);

  let finalResult;
  if (result) {
    finalResult = result;
  } else {
    //See if it's the one attached to this user
    var userCompany = get("users.userData.userData.company", state);
    if (userCompany) {
      finalResult = userCompany;
    }
  }

  return finalResult;
};

export const getClients = (state) => {
  return get("clients.list", state);
};

export const getCurrentClient = (state) => {
  return get("clients.currentClient", state);
};

export const getMyCredits = (state) => {
  return get("clients.credits", state);
};
export const getCompanyCredits = (state) => {
  return get("clients.companyCredits", state);
};

export const getClientFailures = (state) => {
  return get("clients.failures", state);
};

export const getClientsPage = (state) => {
  return get("clients.page", state);
};

export const getClientsPerPage = (state) => {
  return get("clients.perPage", state);
};

export const getIncludesValidationTool = (state) => {
  return get("clients.includesValidationTool", state);
};
export const getHasInsuredWithKB = (state) => {
  return get("clients.hasInsuredWithKB", state);
};

export const getHasMandatorySignoff = (state) => {
  console.log("MS", get("clients.hasMandatorySignoff", state));
  return get("clients.hasMandatorySignoff", state);
};
