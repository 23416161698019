import React, { useEffect, useState } from "react";
import moment from "moment";
import { Route, Switch, withRouter, NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { loadCampaigns, saveCampaign } from "../store/campaigns/actions";
import {
  getCampaigns,
  getCampaignLoading,
  getUpdaters,
} from "../store/campaigns/selectors";
import Button from "../components/Button";
import Loader from "../components/Loader";
import { TitleArea } from "../components/Elements";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import SplitButton from "../components/SplitButton";
import FormField from "../components/FormField";
import { confirmAlert } from "react-confirm-alert";

const theme = {
  ...darkTheme,
  rows: {
    hoverBackgroundColor: "#ddd",
    fontSize: "16px",
  },
};

const FormWrapper = styled.div`
  margin: 20px;
`;

const Main = styled.div`
  display: flex;
  height: 100%;
`;

const Wrap = styled.div`
  flex-direction: column;
  width: 100%;
  display: flex;
`;

const SearchWrapper = styled.div`
  margin-bottom: 20px;
`;

const SearchArea = styled.div`
  margin-bottom: 20px;
  display: flex;
  .item {
    padding: 10px;
    flex: 1;
  }
`;

function Campaigns({ history }) {
  const dispatch = useDispatch();

  const campaigns = useSelector(getCampaigns);
  const loading = useSelector(getCampaignLoading);

  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState("LIVE");
  const [filterRAF, setFilterRAF] = useState(false);

  const [filteredResults, setFilteredResults] = useState([]);
  const [publisher, setPublisher] = useState("");
  const updaters = useSelector(getUpdaters);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [published, setPublished] = useState(null);

  const [showArchived, setShowArchived] = useState(false);

  useEffect(() => {
    dispatch(loadCampaigns(showArchived));
  }, [showArchived]);

  useEffect(() => {
    setFilteredResults(
      campaigns.filter((campaign) => {
        let test = true;
        let searchResult =
          campaign.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1;

        if (searchTerm) {
          test = test && searchResult;
        }

        let statusResult =
          campaign.status &&
          campaign.status.toLowerCase() === filterStatus.toLowerCase();

        test = test && statusResult;

        test = test && !!campaign.is_raf === filterRAF;

        if (publisher) {
          test = test && campaign.last_edit_by === publisher;
        }

        if (startDate) {
          test = test && new Date(campaign.date_start) >= startDate;
        }
        if (endDate) {
          test = test && new Date(campaign.date_expire) >= endDate;
        }
        if (published) {
          test = test && new Date(campaign.last_published) >= published;
        }

        return test;
      })
    );
  }, [
    searchTerm,
    filterStatus,
    campaigns,
    filterRAF,
    publisher,
    startDate,
    endDate,
    published,
  ]);

  const handleRowClicked = (row) => {
    history.push(`/main/campaigns/${row.id}`);
  };

  const doArchive = async (id) => {
    await dispatch(saveCampaign(id, { archived: true }));
    dispatch(loadCampaigns());
  };

  const archive = (id) => {
    const options = {
      title: "Are you sure?",
      message: "Are you sure you want to archive this campaign?",
      buttons: [
        {
          label: "Cancel",
        },
        {
          label: "Confirm",
          onClick: () => {
            doArchive(id);
          },
        },
      ],
    };

    confirmAlert(options);
  };

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: false,
    },
    {
      name: "Created",
      cell: (row) => moment(row.date_created).format("DD/MM/YYYY"),
      sortable: false,
    },
    {
      name: "Status",
      sortable: false,
      selector: "status",
    },
    {
      name: "Last Published",
      cell: (row) => {
        return row.last_published
          ? moment(row.last_published).format("DD/MM/YYYY")
          : "N/A";
      },
      sortable: false,
    },
    {
      name: "Last Edited",
      cell: (row) => {
        return moment(row.last_edit).format("DD/MM/YYYY");
      },
      sortable: false,
    },
    {
      name: "",
      width: 120,
      cell: (row) => {
        const publishedValues = JSON.parse(row.published_values);

        if (
          publishedValues &&
          new Date(publishedValues.date_expire) < new Date()
        ) {
          if (row.archived) {
            return "Archived";
          }
          return (
            <Button size="reduced" noFullWidth onClick={() => archive(row.id)}>
              Archive
            </Button>
          );
        }
      },
      sortable: false,
    },
  ];

  return (
    <Main>
      <Wrap>
        <div>
          <TitleArea>
            <div className="title">
              <h1>Campaigns</h1>
            </div>
            <div className="controls">
              <Button
                action
                onClick={() => {
                  history.push("/main/campaigns/new");
                }}
              >
                New Campaign
              </Button>
            </div>
          </TitleArea>
          <FormWrapper>
            <SearchArea>
              <div className="item">
                <FormField
                  placeholder="Search"
                  type="text"
                  changeMonitor={(value) => setSearchTerm(value)}
                  className="search"
                  value={searchTerm}
                />
              </div>
              <div className="item">
                <FormField
                  label="Include Archived"
                  changeMonitor={(value) => setShowArchived(value)}
                  type="checkbox"
                  inputType="checkbox"
                  value={showArchived}
                />
              </div>
              <div className="item">
                <SplitButton
                  value={filterStatus}
                  onChange={setFilterStatus}
                  options={[
                    { value: "LIVE", display: "Live" },
                    { value: "PENDING", display: "Pending" },

                    { value: "DRAFT", display: "Draft" },
                    { value: "EXPIRED", display: "Expired" },
                  ]}
                />
              </div>
              <div className="item">
                <SplitButton
                  value={filterRAF}
                  onChange={setFilterRAF}
                  options={[
                    { value: true, display: "RAF" },
                    { value: false, display: "Standard" },
                  ]}
                />
              </div>
              <div className="item">
                <FormField
                  placeholder="Last updated by"
                  changeMonitor={(value) => setPublisher(value)}
                  className="search"
                  value={publisher}
                  inputType="select"
                  options={updaters.map((updater) => ({
                    name: updater,
                    value: updater,
                  }))}
                />
              </div>
              <div className="item">
                <FormField
                  placeholder="Start Date"
                  changeMonitor={(value) => {
                    setStartDate(
                      value
                        ? moment(value).minute(0).second(0).hour(0).toDate()
                        : null
                    );
                  }}
                  value={startDate}
                  inputType="date"
                  autocomplete="off"
                  name="search-start-date"
                />
              </div>
              <div className="item">
                <FormField
                  placeholder="End Date"
                  changeMonitor={(value) => {
                    setEndDate(
                      value
                        ? moment(value).minute(0).second(0).hour(0).toDate()
                        : null
                    );
                  }}
                  value={endDate}
                  inputType="date"
                  autocomplete="off"
                  name="search-end-date"
                />
              </div>
              <div className="item">
                <FormField
                  placeholder="Last Published"
                  changeMonitor={(value) => {
                    setPublished(
                      value
                        ? moment(value).minute(0).second(0).hour(0).toDate()
                        : null
                    );
                  }}
                  value={published}
                  inputType="date"
                  autocomplete="off"
                  name="search-published-date"
                />
              </div>
            </SearchArea>
            {loading && <Loader></Loader>}
            <StyledDataTable
              noHeader
              pagination
              customTheme={theme}
              columns={columns}
              data={filteredResults}
              fixedHeader
              highlightOnHover
              fixedHeaderScrollHeight="100%"
              onRowClicked={handleRowClicked}
            />
          </FormWrapper>
        </div>
      </Wrap>
    </Main>
  );
}

export default withRouter(Campaigns);
