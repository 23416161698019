import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { progressBar, progressBarBase } from "../styles/colors";

const ProgressOuter = styled.div`
  background-color: ${progressBarBase};
  position: relative;
  height: 20px;
`;

const ProgressInner = styled.div`
  background-color: ${progressBar};
  ${props => `width: ${props.barWidth}%;`}
  transition: width 200ms ease-in;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
`;

function Progress({ step, numberOfSteps, className }) {
  const [barWidth, setBarWidth] = useState(0);

  useEffect(() => {
    setBarWidth((step / numberOfSteps) * 100);
  }, [step]);

  return (
    <ProgressOuter className={className}>
      <ProgressInner barWidth={barWidth}></ProgressInner>
    </ProgressOuter>
  );
}

export default Progress;
