import { set, flow, get, getOr } from "lodash/fp";
import {
  LOAD_TAGS_REQUEST,
  LOAD_TAGS_SUCCESS,
  LOAD_TAGS_FAILURE,
  CREATE_TAG_REQUEST,
  CREATE_TAG_SUCCESS,
  CREATE_TAG_FAILURE,
  REMOVE_TAG_REQUEST,
  REMOVE_TAG_SUCCESS,
  REMOVE_TAG_FAILURE,
  EDIT_TAG_REQUEST,
  EDIT_TAG_SUCCESS,
  EDIT_TAG_FAILURE,
  LINK_TAG_REQUEST,
  LINK_TAG_SUCCESS,
  LOAD_TAGS_FOR_ENTITY_SUCCESS,
  UNLINK_TAG_REQUEST,
  UNLINK_TAG_SUCCESS,
  SWAP_TAG_REQUEST,
  SWAP_TAG_SUCCESS,
  LOAD_SEARCHES_FOR_COMPANY_REQUEST,
  LOAD_SEARCHES_FOR_COMPANY_SUCCESS,
  LOAD_SEARCHES_FOR_COMPANY_FAILURE,
  CREATE_SEARCH_REQUEST,
  CREATE_SEARCH_SUCCESS,
  CREATE_SEARCH_FAILURE,
  SAVE_SEARCH_REQUEST,
  SAVE_SEARCH_SUCCESS,
  RUN_ASSESSMENT_REPORT_REQUEST,
  RUN_ASSESSMENT_REPORT_SUCCESS,
} from "./actions";

export const tags = (
  state = {
    list: [],
    loading: false,
    error: null,
    linkingLoading: {},
    tagsForEntities: {
      contractor: {},
      user: {},
      engagement: {},
    },
    searches: [],
    searchesLoading: false,
    reportsLoading: {
      roleAssessment: false,
      assessment: false,
      user: false,
      contractor: false,
    },
    reports: {
      roleAssessment: [],
      assessment: [],
      user: [],
      contractor: [],
    },
    reportsCount: {
      roleAssessment: 0,
      assessment: 0,
      user: 0,
      contractor: 0,
    },
  },
  action
) => {
  switch (action.type) {
    case LOAD_TAGS_FOR_ENTITY_SUCCESS:
      return set(
        `tagsForEntities.${action.entityType}.${action.entityId}`,
        action.payload,
        state
      );
    case LOAD_TAGS_REQUEST:
    case CREATE_TAG_REQUEST:
    case REMOVE_TAG_REQUEST:
    case EDIT_TAG_REQUEST:
    case SWAP_TAG_REQUEST:
      return flow(set("loading", true), set("error", null))(state);
    case LINK_TAG_REQUEST:
    case UNLINK_TAG_REQUEST:
      return set(
        "linkingLoading",
        {
          ...state.linkingLoading,
          [action.tagId]: true,
        },
        state
      );
    case LINK_TAG_SUCCESS:
    case UNLINK_TAG_SUCCESS:
      return set(
        "linkingLoading",
        {
          ...state.linkingLoading,
          [action.tagId]: false,
        },
        state
      );
    case LOAD_TAGS_FAILURE:
    case CREATE_TAG_FAILURE:
    case REMOVE_TAG_FAILURE:
      return flow(set("loading", false), set("error", action.error))(state);
    case LOAD_TAGS_SUCCESS:
      return flow(
        set("loading", false),
        set("error", null),
        set("list", action.payload)
      )(state);
    case CREATE_TAG_SUCCESS:
      return flow(
        set("loading", false),
        set("error", null),
        set("list", [...state.list, action.payload])
      )(state);
    case REMOVE_TAG_SUCCESS:
      return flow(
        set("loading", false),
        set("error", null),
        set(
          "list",
          state.list.filter((tag) => tag.id !== action.payload.id)
        )
      )(state);
    case EDIT_TAG_SUCCESS:
      return flow(
        set(
          "list",
          state.list.map((item) =>
            item.id === action.payload.id ? action.payload : item
          )
        ),
        set("loading", false),
        set("error", null)
      )(state);
    case SWAP_TAG_SUCCESS:
      return flow(set("loading", false), set("error", null))(state);
    case LOAD_SEARCHES_FOR_COMPANY_REQUEST:
    case CREATE_SEARCH_REQUEST:
    case SAVE_SEARCH_REQUEST:
      return set("searchesLoading", true, state);
    case LOAD_SEARCHES_FOR_COMPANY_SUCCESS:
      return flow(
        set("searchesLoading", false),
        set("searches", action.payload)
      )(state);
    case CREATE_SEARCH_SUCCESS:
      return flow(
        set("searchesLoading", false),
        set("searches", [...state.searches, action.payload])
      )(state);
    case SAVE_SEARCH_SUCCESS:
      return flow(
        set("searchesLoading", false),
        set(
          "searches",
          state.searches.map((search) => {
            if (search.id === Number(action.payload.id)) {
              return { ...action.payload };
            }
            return search;
          })
        )
      )(state);
    case RUN_ASSESSMENT_REPORT_REQUEST:
      return set(
        "reportsLoading",
        { ...state.reportsLoading, [action.entityType]: true },
        state
      );
    case RUN_ASSESSMENT_REPORT_SUCCESS:
      return flow(
        set("reportsLoading", {
          ...state.reportsLoading,
          [action.entityType]: false,
        }),
        set("reportsCount", {
          ...state.reportsCount,
          [action.entityType]: action.payload.count,
        }),
        set("reports", {
          ...state.reports,
          [action.entityType]: action.payload.response,
        })
      )(state);
    default:
      return state;
  }
};
