import { get, getOr } from "lodash/fp";

export const getPassReviewLoading = (state) => {
  return get("review.loadingReviewPass", state);
};

export const getPassReviewError = (state) => {
  return get("review.loadingReviewError", state);
};

export const getCommentsForQuestionIsOpen = (state, questionId) => {
  return get("review.commentsOpenState", state).includes(questionId);
};

export const getCommentsByQuestionId = (state, questionId) => {
  return getOr({}, "review.commentsByQuestion", state)[questionId];
};

export const getLoadingComments = (state) => {
  return get("review.loadingComments", state);
};

export const getCommentsError = (state) => {
  return get("review.commentsError", state);
};

export const getSaving = (state) => {
  return get("review.saving", state);
};

export const getSavingError = (state) => {
  return get("review.savingError", state);
};

export const getQuestionsWithComments = (state) => {
  return Object.keys(getOr({}, "review.commentsByQuestion", state));
};

export const getShowQuestionsWithComments = (state) => {
  return get("review.showQuestionsWithComments", state);
};

export const getResolving = (state) => {
  return get("review.savingResolve", state);
};

export const getResolvingError = (state) => {
  return get("review.resolveError", state);
};

export const getUnresolvedComments = (state) => {
  let comments = getOr({}, "review.commentsByQuestion", state);
  let unresolved = 0;
  Object.keys(comments).forEach((questionId) => {
    comments[questionId].forEach((comment) => {
      if (comment.status !== "RESOLVED") {
        unresolved += 1;
      }
    });
  });
  return unresolved;
};

export const getDashboardLoading = (state) => {
  return get("review.dashboardLoading", state);
};

export const getDashboardError = (state) => {
  return get("review.dashboardError", state);
};

export const getReviewDashboardData = (state) => {
  return get("review.dashboardData", state);
};

export const getManualReviews = (state) => {
  return get("review.manualReviews", state);
};

export const getManualReviewsError = (state) => {
  return get("review.manualReviewsError", state);
};

export const getManualReviewsLoading = (state) => {
  return get("review.manualReviewsLoading", state);
};

export const getCurrentPage = (state) => {
  return get("review.page", state);
};

export const getPerPage = (state) => {
  return get("review.perPage", state);
};

export const getTotalRows = (state) => {
  return get("review.totalRows", state);
};

export const getFilterOpenClosed = (state) => {
  return get("review.filterOpenClosed", state);
};

export const getFilterAssigned = (state) => {
  return get("review.filterAssigned", state);
};

export const getAssignLoading = (state) => {
  return get("review.assignLoading", state);
};

export const getSearchTerm = (state) => {
  return get("review.searchTerm", state);
};
