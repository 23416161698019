import { set, flow, get, getOr } from "lodash/fp";
import {
  PASS_REVIEW_REQUEST,
  PASS_REVIEW_SUCCESS,
  PASS_REVIEW_FAILURE,
  SET_COMMENTS_OPEN_STATE,
  LOAD_COMMENTS_REQUEST,
  LOAD_COMMENTS_SUCCESS,
  LOAD_COMMENTS_FAILURE,
  ADD_COMMENT_REQUEST,
  ADD_COMMENT_SUCCESS,
  ADD_COMMENT_FAILURE,
  SET_QUESTIONS_WITH_COMMENTS,
  RESOLVE_COMMENT_REQUEST,
  RESOLVE_COMMENT_SUCCESS,
  RESOLVE_COMMENT_FAILURE,
  LOAD_REVIEW_DASHBOARD_REQUEST,
  LOAD_REVIEW_DASHBOARD_SUCCESS,
  LOAD_REVIEW_DASHBOARD_FAILURE,
  LOAD_MANUAL_REVIEWS_FAILURE,
  LOAD_MANUAL_REVIEWS_SUCCESS,
  LOAD_MANUAL_REVIEWS_REQUEST,
  SET_PAGE,
  SET_PER_PAGE,
  SET_FILTER_OPEN_CLOSED,
  SET_FILTER_ASSIGNED,
  UNASSIGN_REVIEW_REQUEST,
  UNASSIGN_REVIEW_SUCCESS,
  UNASSIGN_REVIEW_FAILURE,
  ASSIGN_REVIEW_REQUEST,
  ASSIGN_REVIEW_SUCCESS,
  ASSIGN_REVIEW_FAILURE,
  SET_SEARCH_TERM,
} from "./actions";

export const review = (
  state = {
    loadingReviewPass: false,
    loadingReviewError: null,
    commentsOpenState: [],
    commentsByQuestion: {},
    loadingComments: false,
    commentsError: false,
    saving: false,
    savingError: false,
    showQuestionsWithComments: false,
    savingResolve: false,
    resolveError: false,
    dashboardData: [],
    dashboardLoading: false,
    dashboardError: false,
    manualReviewsError: false,
    manualReviews: [],
    manualReviewsLoading: false,
    page: 0,
    perPage: 10,
    totalRows: 0,
    filterOpenClosed: "OPEN",
    filterAssigned: false,
    assignLoading: false,
    assignError: false,
    searchTerm: "",
  },
  action
) => {
  switch (action.type) {
    case UNASSIGN_REVIEW_SUCCESS:
    case ASSIGN_REVIEW_SUCCESS:
      const currentManualReviews = get("manualReviews", state);

      return flow(
        set("assignLoading", false),
        set("assignError", false),
        set(
          "manualReviews",
          currentManualReviews.map((currentReview) => {
            if (
              Number(currentReview.approval_id) === Number(action.payload.id)
            ) {
              return {
                ...currentReview,
                manual_reviewer: action.payload.userId,
              };
            } else {
              return currentReview;
            }
          })
        )
      )(state);

    case UNASSIGN_REVIEW_REQUEST:
    case ASSIGN_REVIEW_REQUEST:
      return flow(set("assignLoading", true), set("assignError", false))(state);
    case ASSIGN_REVIEW_FAILURE:
    case UNASSIGN_REVIEW_FAILURE:
      return flow(set("assignLoading", false), set("assignError", true))(state);
    case SET_FILTER_OPEN_CLOSED:
      return set("filterOpenClosed", action.payload, state);
    case SET_FILTER_ASSIGNED:
      return set("filterAssigned", action.payload, state);
    case SET_SEARCH_TERM:
      return set("searchTerm", action.payload, state);
    case SET_PAGE:
      return set("page", action.payload, state);
    case SET_PER_PAGE:
      return set("perPage", action.payload, state);
    case LOAD_MANUAL_REVIEWS_REQUEST:
      return flow(
        set("manualReviewsLoading", true),
        set("manualReviewsError", false)
      )(state);
    case LOAD_MANUAL_REVIEWS_FAILURE:
      return flow(
        set("manualReviewsLoading", false),
        set("manualReviewsError", action.payload)
      )(state);
    case LOAD_MANUAL_REVIEWS_SUCCESS:
      return flow(
        set("manualReviewsLoading", false),
        set("manualReviewsError", false),
        set("manualReviews", action.payload.response),
        set("totalRows", action.payload.count)
      )(state);
    case LOAD_REVIEW_DASHBOARD_REQUEST:
      return flow(
        set("dashboardLoading", true),
        set("dashboardError", false)
      )(state);

    case LOAD_REVIEW_DASHBOARD_FAILURE:
      return flow(
        set("dashboardLoading", false),
        set("dashboardError", true)
      )(state);
    case LOAD_REVIEW_DASHBOARD_SUCCESS:
      return flow(
        set("dashboardLoading", false),
        set("dashboardError", false),
        set("dashboardData", action.payload)
      )(state);
    case RESOLVE_COMMENT_REQUEST:
      return flow(
        set("savingResolve", action.commentId),
        set("resolveError", false)
      )(state);
    case RESOLVE_COMMENT_FAILURE:
      return flow(
        set("savingResolve", false),
        set("resolveError", action.commentId)
      )(state);

    case RESOLVE_COMMENT_SUCCESS:
      let editedComments = { ...state.commentsByQuestion };

      editedComments[action.questionId] = editedComments[action.questionId].map(
        (comment) => {
          if (comment.id === action.commentId) {
            return action.payload;
          }
          return comment;
        }
      );
      return flow(
        set("savingResolve", action.commentId),
        set("resolveError", false),
        set("commentsByQuestion", editedComments)
      )(state);
    case SET_QUESTIONS_WITH_COMMENTS:
      return set("showQuestionsWithComments", action.payload, state);
    case PASS_REVIEW_REQUEST:
      return flow(
        set("loadingReviewPass", true),
        set("loadingReviewError", null)
      )(state);
    case PASS_REVIEW_SUCCESS:
      return flow(
        set("loadingReviewPass", false),
        set("loadingReviewError", null)
      )(state);
    case PASS_REVIEW_FAILURE:
      return flow(
        set("loadingReviewPass", false),
        set("loadingReviewError", true)
      )(state);
    case SET_COMMENTS_OPEN_STATE:
      if (action.show) {
        return set(
          "commentsOpenState",
          [...state.commentsOpenState, action.questionId],
          state
        );
      } else {
        return set(
          "commentsOpenState",
          state.commentsOpenState.filter((id) => id !== action.questionId),
          state
        );
      }
    case LOAD_COMMENTS_REQUEST:
      return flow(
        set("loadingComments", true),
        set("commentsError", false)
      )(state);
    case LOAD_COMMENTS_FAILURE:
      return flow(
        set("loadingComments", false),
        set("commentsError", true)
      )(state);
    case LOAD_COMMENTS_SUCCESS:
      let mappedComments = {};

      action.payload.forEach((comment) => {
        mappedComments[comment.question_id] =
          mappedComments[comment.question_id] || [];

        mappedComments[comment.question_id].push(comment);
      });

      return flow(
        set("loadingComments", false),
        set("commentsError", false),
        set("commentsByQuestion", mappedComments)
      )(state);
    case ADD_COMMENT_REQUEST:
      return flow(
        set("saving", `${action.parent}|${action.questionId}`),
        set("savingError", false)
      )(state);
    case ADD_COMMENT_FAILURE:
      return flow(
        set("saving", false),
        set("savingError", `${action.parent}|${action.questionId}`)
      )(state);
    case ADD_COMMENT_SUCCESS:
      let questionId = action.payload.question_id;

      let finalisedComments = { ...state.commentsByQuestion };

      action.payload.comments = [];

      if (!finalisedComments[questionId]) {
        finalisedComments[questionId] = [action.payload];
      } else {
        if (action.payload.parent === 0) {
          finalisedComments[questionId].push(action.payload);
        } else {
          //If we get to here then we're going to have to traverse the tree and find our parent
          const traverseTree = (comments) => {
            comments.forEach((comment) => {
              if (comment.id === action.payload.parent) {
                comment.comments = comment.comments || [];
                comment.comments.push(action.payload);
              } else {
                if (comment.comments) {
                  traverseTree(comment.comments);
                }
              }
            });
          };

          traverseTree(finalisedComments[questionId]);
        }
      }

      return flow(
        set("saving", false),
        set("savingError", false),
        set("commentsByQuestion", finalisedComments)
      )(state);

    default:
      return state;
  }
};
