import React, { forwardRef } from "react";
import styled from "styled-components";
import DatePicker from "react-datepicker";
import Creatable from "react-select/creatable";
import "react-datepicker/dist/react-datepicker.css";
import { CardElement } from "react-stripe-elements";
import { errorStrong, errorWeak } from "../styles/colors";
import ImageUpload from "./ImageUpload";
import SplitButton from "./SplitButton";

const Label = styled.label`
  color: #000;
  display: block;
  margin-bottom: 10px;
`;
const inputNoPad = `
width: 100%;
border: 1px solid #ccc;
font-size: 16px;

box-sizing: border-box;
border-radius: 4px;
margin-top: 0px;
background-color: #fff;
outline: none;
&.invalid {
  background-color: ${errorWeak};
  border: 1px solid ${errorStrong};
}
`;
const input = `
padding: 10px 10px;
${inputNoPad}
  `;

const StyledTextarea = styled.textarea`
  ${input}
  font-family: Blinker;
  ${(props) => `height: ${props.height || 100}px;`}
`;

const Input = styled.input`
  ${input}
`;
const Checkbox = styled.input`
  margin-right: 10px;
`;

const Select = styled.select`
  ${inputNoPad}
  height: 40px;
`;

const InternalWrapper = styled.div`
  ${(props) => (props.inputType === "checkbox" ? "display: flex;" : "")}
`;

const CardWrapper = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px 10px;
  box-sizing: border-box;
`;

const StyledDatePicker = styled(DatePicker)`
  .react-datepicker__input-container {
    opacity: 0.1;
  }
  input {
    padding: 6px 10px !important;
  }
`;

const FieldErrors = styled.ul`
  color: ${errorStrong};
  padding-left: 18px;
  margin: 5px 0px 0px 0px;
`;

const FieldWrapper = styled.div`
  input[type="text"] {
    ${input}
  }

  .react-datepicker-wrapper {
    width: 100%;
    display: block !important;

    .react-datepicker__input-container {
      width: 100%;
      display: block !important;
    }
  }
`;

const RadioWrapper = styled.div`
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  input {
    width: auto;
  }
  label {
    margin-right: 10px;
  }
  ${(props) =>
    props.layout === "vertical"
      ? "flex-direction: column"
      : "flex-direction: row"}

  > div {
    ${(props) =>
      props.layout === "vertical" ? "margin-top: 5px; margin-bottom: 5px" : ""}
  }
`;

const CheckBoxSurround = styled.div`
  display: flex;
`;

const Help = styled.div`
  font-size: 12px;
  margin-bottom: 10px;
`;

function FormField(props) {
  const {
    label,
    name,
    value,
    stripe,
    validation,
    inputType = "input",
    changeMonitor,
    layout = "horizontal",
    helpText,
    className,
  } = props;

  const renderInput = () => {
    switch (inputType) {
      case "textarea":
        return (
          <StyledTextarea
            className={className}
            value={value}
            id={`input-${name}`}
            {...props}
            onChange={(e) => {
              changeMonitor(e.target.value);
            }}
            className={validation && validation.length ? "invalid" : ""}
          />
        );
      case "date":
        console.log("PROPS", props);
        return (
          <StyledDatePicker
            className={className}
            dateFormat="dd/MM/yyyy"
            selected={value}
            isClearable
            id={`input-${name}`}
            {...props}
            onChange={(date) => changeMonitor(date)}
            className={validation && validation.length ? "invalid" : ""}
            placeholderText={props.placeholder}
          />
        );
      case "input":
        return (
          <Input
            className={className}
            id={`input-${name}`}
            {...props}
            onChange={(e) => {
              if (props.type === "file") {
                changeMonitor(e.target.files[0]);
              } else {
                changeMonitor(e.target.value);
              }
            }}
            className={validation && validation.length ? "invalid" : ""}
          />
        );
      case "checkbox":
        return (
          <Checkbox
            className={className}
            id={`input-${name}`}
            {...props}
            checked={value}
            reverse
            onChange={(e) => {
              changeMonitor(e.target.checked);
            }}
            className={validation && validation.length ? "invalid" : ""}
          />
        );
      case "combo":
        return (
          <Creatable
            className={className}
            id={`input-${name}`}
            {...props}
            value={props.options.find((option) => option.value === props.value)}
            onChange={(selectedOption) => {
              console.log("event", selectedOption);
              changeMonitor(selectedOption);
            }}
            className={validation && validation.length ? "invalid" : ""}
          />
        );
      case "image":
        return (
          <ImageUpload
            className={className}
            onChange={(value) => changeMonitor(value)}
            value={value}
          />
        );
      case "radio":
        return (
          <RadioWrapper layout={layout} className={className}>
            {props.options.map((option) => (
              <div>
                <input
                  type="radio"
                  checked={props.value === option.value}
                  name={props.name}
                  value={option.value}
                  disabled={props.disabled}
                  id={`${props.name}-${option.value}`}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (props.booleanValues) {
                      value = value === "false" ? false : true;
                    }
                    changeMonitor(value);
                  }}
                />
                <label for={`${props.name}-${option.value}`}>
                  {props.customRender
                    ? props.customRender(option)
                    : option.name}
                </label>
              </div>
            ))}
          </RadioWrapper>
        );
      case "select":
        return (
          <Select
            className={className}
            name={props.name}
            value={value}
            {...props}
            disabled={props.disabled}
            id={`input-${props.name}`}
            onChange={(e) => {
              changeMonitor(e.target.value);
            }}
            className={validation && validation.length ? "invalid" : ""}
          >
            <option value="">
              {props.placeholder ? props.placeholder : "Choose"}
            </option>
            {props.options.map((option) => (
              <option value={option.value}>{option.name}</option>
            ))}
          </Select>
        );
      case "splitbutton":
        return (
          <SplitButton
            {...props}
            className={validation && validation.length ? "invalid" : ""}
            id={`input-${props.name}`}
            value={value}
            onChange={(value) => {
              changeMonitor(value);
            }}
            name="validation"
            options={props.options}
          />
        );
    }
  };

  return (
    <FieldWrapper>
      {inputType !== "checkbox" && label && (
        <Label for={`input-${name}`}>{label}</Label>
      )}

      {!stripe ? (
        <InternalWrapper inputType={inputType}>
          {renderInput()}
          {inputType === "checkbox" && (
            <Label for={`input-${name}`}>{label}</Label>
          )}
          {validation && validation.length ? (
            <FieldErrors>
              {validation.map((error) => (
                <li>{error}</li>
              ))}
            </FieldErrors>
          ) : null}
        </InternalWrapper>
      ) : (
        <CardWrapper>
          <CardElement />
        </CardWrapper>
      )}
      {helpText && <Help>{helpText}</Help>}
    </FieldWrapper>
  );
}

FormField.defaultProps = {
  componentType: "FormField",
};

export default FormField;
