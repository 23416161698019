import React from "react";
import { Redirect } from "react-router-dom";

function RAFRedirect({ match }) {
  sessionStorage.setItem("RAF", match.params.raf_code);

  return (
    <Redirect to={`/contractor-assessment/0?RAF=${match.params.raf_code}`} />
  );
}

export default RAFRedirect;
