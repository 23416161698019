import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { getMyCredits, getLoading } from "../store/clients/selectors";
import { getCreditBalance } from "../store/users/selectors";
import { ContentArea } from "../components/Elements";
import CreditsList from "../components/CreditsList";
import Loader from "../components/Loader";
import { loadMyCredits } from "../store/clients/actions";

const SettingsWrapper = styled.div`
  background-color: #fff;
  margin: 0px 20px 20px 20px;
  border-radius: 10px;
  padding: 20px;
`;

function Credits({ history }) {
  const loading = useSelector(getLoading);
  const credits = useSelector(getMyCredits);
  const creditBalance = useSelector(getCreditBalance);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadMyCredits());
  }, []);

  return (
    <ContentArea>
      {loading || !credits ? (
        <Loader />
      ) : (
        <SettingsWrapper>
          <CreditsList
            creditBalance={creditBalance}
            loading={loading}
            credits={credits}
          />
        </SettingsWrapper>
      )}
    </ContentArea>
  );
}

export default Credits;
