import styled from "styled-components";
import { device } from "./Devices";

export default styled.div`
margin-bottom: 10px;
@media ${device.laptop} {
  margin-bottom: 20px;
}
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > div:first-child {
    margin-bottom: ${(props) => (props.cols === 1 ? " 20px" : "0")}
  }

  > div {
    width: 100%;
    @media ${device.laptop} {
      width: ${(props) => `${(100 - (props.cols - 1) * 2) / props.cols}%;`}

    }
`;
