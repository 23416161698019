import styled from "styled-components";
import { mainColor } from "../styles/colors";
export const Tabs = styled.div`
  display: flex;
  margin-left: -20px;
  margin-right: -20px;
  border-bottom: 1px solid #ccc;
  padding-left: 20px;
`;

export const tabStyles = `
padding: 10px 20px;
margin-right: 4px;
color: #999;
position: relative;
transition: color 200ms ease-in;
cursor: pointer;

&:hover {
  color: ${mainColor};
}

&.active {
  color: ${mainColor};
}

&.active:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: ${mainColor};
  bottom: -1px;
  left: 0;
}`;

export const Tab = styled.div`
  ${tabStyles}
`;
