import React, { useState, useContext } from "react";
import { ColorContext } from "../components/Bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  getLoading,
  getChangePasswordComplete,
  getChangePasswordError,
  getRealm,
} from "../store/users/selectors";
import { confirmAlert } from "react-confirm-alert";
import { changePassword } from "../store/users/actions";
import Validation from "../components/FormValidation";
import styled from "styled-components";
import Header from "../components/Header";
import Form from "../components/Form";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import Button from "../components/Button";
import { device } from "../components/Devices";
import {
  Content,
  FormLayoutWrapper,
  Success,
  Errors,
} from "../components/Elements";

const Detail = styled.div`
  flex-basis: 100%;
  padding: 40px 20px;

  @media ${device.laptop} {
    flex: 1;
    margin-left: 40px;
  }
`;

const H1 = styled.h1`
  font-family: Blinker;
  margin: 0;
  color: #fff;
  font-size: 40px;
  font-weight: 100;
  text-align: center;
  margin-top: 0px;
  @media ${device.laptop} {
    margin-top: 100px;
  }
`;

const H2 = styled.h2`
  font-family: Blinker;
  font-weight: 100;
  color: #888;
  font-size: 20px;
  margin: 0;
  margin-bottom: 20px;
`;

function ChangePassword({ match, history }) {
  const [formData, setFormData] = useState({
    password: "",
    passwordConfirm: "",
  });
  const [validation, setValidation] = useState({});
  const mainColor = useContext(ColorContext);
  const dispatch = useDispatch();
  const loading = useSelector(getLoading);
  const changePasswordComplete = useSelector(getChangePasswordComplete);
  const changePasswordError = useSelector(getChangePasswordError);
  const realm = useSelector(getRealm);

  const formValidation = new Validation(validation, setValidation);

  formValidation.setRules({
    password: [
      {
        canned: "password",
      },
    ],
    passwordConfirm: [
      {
        fn: (value, formValues) => {
          return value === formValues.password;
        },
        msg: "Passwords don't match",
      },
    ],
  });

  const updateFormData = (field, value) => {
    setFormData({ ...formData, [field]: value });
    formValidation.validate(field, value, formData);
  };

  const sendForm = async (e) => {
    e.preventDefault();
    const validationErrors = formValidation.validateForm(formData);
    if (!validationErrors) {
      const result = await dispatch(
        changePassword(formData.password, match.params.token, realm)
      );
      if (result && result.error) {
        const options = {
          title: "Error",
          message: `Your access token has expired, please reset your password`,
          buttons: [
            {
              label: "Continue",
            },
          ],
        };
        confirmAlert(options);
        history.push("/forgot-password");
      } else {
        const options = {
          title: "Complete",
          message: `Registration complete, you can now login`,
          buttons: [
            {
              label: "Continue",
            },
          ],
        };
        confirmAlert(options);
        history.push("/");
      }
    }
  };

  return (
    <div>
      <Header />
      <Content mainColor={mainColor}>
        <H1>Welcome</H1>
        <FormLayoutWrapper>
          <Form>
            <H2>To continue you'll need to choose a password</H2>
            <FormRow cols={1}>
              <FormField
                name="password"
                label="Choose a new password"
                type="password"
                changeMonitor={(value) => updateFormData("password", value)}
                value={formData.password}
                validation={validation.password}
              />
              <FormField
                name="password"
                label="Confirm new password"
                type="password"
                changeMonitor={(value) =>
                  updateFormData("passwordConfirm", value)
                }
                value={formData.passwordConfirm}
                validation={validation.passwordConfirm}
              />
            </FormRow>

            <FormRow cols={1}>
              <Button onClick={sendForm} loading={loading} disabled={loading}>
                Set Password
              </Button>
            </FormRow>
            {changePasswordComplete ? (
              <Success>
                <h5>We've changed your password successfully</h5>
              </Success>
            ) : null}
            {changePasswordError ? (
              <Errors>
                <h5>
                  There was a problem changing your password, please try again
                </h5>
              </Errors>
            ) : null}
          </Form>
        </FormLayoutWrapper>
      </Content>
    </div>
  );
}

export default ChangePassword;
