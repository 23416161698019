import axios from "axios";
import { get, merge, set, flow } from "lodash/fp";
import { getUserToken, getRealm } from "./users/selectors";

const FETCH = "middleware/FETCH";
export const urlRoot = process.env.REACT_APP_BASE_URL;

export default ({ onError } = {}) => ({ dispatch, getState }) => {
  const setAuthHeader = (request = {}) => {
    const userToken = getUserToken(getState());
    const realm = getRealm(getState());

    if (userToken) {
      return flow(
        set("headers.Authorization", `${userToken}`),
        set("headers.Realm", realm)
      )(request);
    }
    return flow(set("headers.Realm", realm))(request);
  };

  return (next) => async (action) => {
    if (!action) {
      return;
    }

    if (action && action.type !== FETCH) {
      return next(action);
    }

    const uri = urlRoot + action.url;

    const getParams = () => {
      const defaultParams = {
        method: "get",
      };
      return setAuthHeader(merge(defaultParams, action.params));
    };

    console.log("URL", uri);
    console.log("Params", getParams());

    return axios(uri, getParams())
      .then((response) => {
        const hasError = get("data.error", response);
        if (hasError) {
          throw {
            message: "Error requesting data",
          };
        }
        return response;
      })
      .catch((error) => {
        const status = get("response.status", error);
        const body = get("response.data", error);
        const message = error.message;
        const messageId = Math.round(Math.random() * 100000);
        const logMessage = `API failure: id: ${messageId} ${uri} (error ${status}, ${message}) params: ${JSON.stringify(
          getParams()
        )}, body: ${JSON.stringify(body)}`;
        console.warn(logMessage);

        if (onError) {
          dispatch(onError(body));
        }
        throw {
          data: {
            status,
            body,
            message,
            error: body ? (body.error ? body.error : "unknown") : "unknown",
          },
          error: new Error(),
        };
      })
      .then((response) => response);
  };
};

export const fetch = (url, params = {}) => ({
  type: FETCH,
  url,
  params,
});
