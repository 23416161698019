import React from "react";
import styled from "styled-components";
import { Contact } from "../pages/ContractorMainAssessmentResult";

function CrunchContact({ setSelectedProduct, freeVersion }) {
  return (
    <Contact>
      Any questions? Call us on 0333 311 0721
      <p>
        We're available from 9am - 8pm Monday to Friday, and 10am - 6pm on
        Saturday. If you've contacted us out of hours we'll get back to you the
        next working day. Find out how{" "}
        <a href="https://www.crunch.co.uk/ir35/" target="_blank">
          IR35 could affect you
        </a>{" "}
        .
      </p>
    </Contact>
  );
}

export default CrunchContact;
