import { set, get, flow } from "lodash/fp";
import {
  LOAD_CLIENTS_REQUEST,
  CREATE_CLIENT_REQUEST,
  SAVE_CLIENT_REQUEST,
  CREATE_CLIENT_SUCCESS,
  SAVE_CLIENT_SUCCESS,
  LOAD_CLIENTS_SUCCESS,
  SET_CURRENT_CLIENT,
  GET_MY_CREDITS_REQUEST,
  GET_MY_CREDITS_SUCCESS,
  GET_COMPANY_CREDITS_REQUEST,
  GET_COMPANY_CREDITS_SUCCESS,
  ADD_CREDIT_REQUEST,
  ADD_CREDIT_SUCCESS,
  SAVE_CLIENT_FAILURE,
  CREATE_CLIENT_FAILURE,
  GET_MY_CREDITS_FAILURE,
  GET_COMPANY_CREDITS_FAILURE,
  ADD_CREDIT_FAILURE,
  CHANGE_CLIENTS_PAGE,
  INCLUDES_VALIDATION_TOOL_REQUEST,
  INCLUDES_VALIDATION_TOOL_SUCCESS,
  HAS_MANDATORY_SIGNOFF_SUCCESS,
  HAS_MANDATORY_SIGNOFF_REQUEST,
  HAS_INSURED_WITH_KB_SUCCESS,
  HAS_INSURED_WITH_KB_REQUEST,
} from "./actions";

export const clients = (
  state = {
    list: [],
    failures: null,
    loading: false,
    currentClient: 0,
    credits: [],
    companyCredits: [],
    page: 1,
    perPage: 10,
    includesValidationTool: false,
    hasMandatorySignoff: false,
    hasInsuredWithKB: false,
  },
  action
) => {
  const currentClients = get("list", state);
  let newClients;
  switch (action.type) {
    case CHANGE_CLIENTS_PAGE:
      return flow(
        set("page", action.page),
        set("perPage", action.perPage)
      )(state);
    case SAVE_CLIENT_FAILURE:
    case CREATE_CLIENT_FAILURE:
    case GET_MY_CREDITS_FAILURE:
    case GET_COMPANY_CREDITS_FAILURE:
    case ADD_CREDIT_FAILURE:
      return flow(set("loading", false), set("failures", true))(state);
    case ADD_CREDIT_SUCCESS:
      const newList = state.list.map((item) => {
        if (item.id === Number(action.companyId)) {
          item.credit_balance =
            Number(item.credit_balance) + Number(action.amount);
        }
        return item;
      });
      return flow(set("loading", false), set("list", newList))(state);
    case GET_MY_CREDITS_SUCCESS:
      return flow(set("credits", action.payload), set("loading", false))(state);
    case GET_COMPANY_CREDITS_SUCCESS:
      return flow(
        set("companyCredits", action.payload),
        set("loading", false)
      )(state);
    case SET_CURRENT_CLIENT:
      return set("currentClient", action.id, state);
    case LOAD_CLIENTS_REQUEST:
    case CREATE_CLIENT_REQUEST:
    case SAVE_CLIENT_REQUEST:
    case GET_MY_CREDITS_REQUEST:
    case GET_COMPANY_CREDITS_REQUEST:
    case ADD_CREDIT_REQUEST:
    case INCLUDES_VALIDATION_TOOL_REQUEST:
    case HAS_MANDATORY_SIGNOFF_REQUEST:
    case HAS_INSURED_WITH_KB_REQUEST:
      return set("loading", true, state);
    case CREATE_CLIENT_SUCCESS:
      return flow(
        set("list", [...currentClients, action.payload]),
        set("loading", false)
      )(state);
    case SAVE_CLIENT_SUCCESS:
      debugger;
      if (currentClients.find((current) => current.id === action.payload.id)) {
        newClients = currentClients.map((client) => {
          return client.id === action.payload.id ? action.payload : client;
        });
        return flow(set("list", newClients), set("loading", false))(state);
      } else {
        return set("loading", false, state);
      }
      break;
    case LOAD_CLIENTS_SUCCESS:
      return flow(set("loading", false), set("list", action.payload))(state);
    case INCLUDES_VALIDATION_TOOL_SUCCESS:
      return set("includesValidationTool", action.payload.result, state);
    case HAS_MANDATORY_SIGNOFF_SUCCESS:
      return flow(
        set("hasMandatorySignoff", action.payload.result),
        set("loading", false)
      )(state);
    case HAS_INSURED_WITH_KB_SUCCESS:
      return flow(
        set("hasInsuredWithKB", action.payload.result),
        set("loading", false)
      )(state);
    default:
      return state;
  }
};
