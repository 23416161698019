import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { getCurrentTermsVersion } from "../store/users/selectors";
import { getMe, acceptCurrentTerms } from "../store/users/actions";
import { Document, Page, pdfjs } from "react-pdf";
import Button from "./Button";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Wrapper = styled.div`
  position: relative;
  height: 100%;

  & > label.disabled {
    opacity: 0.5;
  }
`;

const Scroller = styled.div`
  width: 100%;
  height: 70%;
  overflow: auto;
  border: 1px solid #ccc;
  margin: 0 0 20px 0;
`;

const Border = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #ccc;
`;

function TermsAccept() {
  const dispatch = useDispatch();
  const currentVersion = useSelector(getCurrentTermsVersion);

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  const [atBottom, setAtBottom] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [loadError, setLoadError] = useState(false);

  const handleScroll = (e) => {
    const computed = e.target.scrollHeight - e.target.scrollTop - 10;

    const bottom = computed < e.target.clientHeight;
    if (bottom) {
      setAtBottom(true);
    }
  };

  const handlePDFError = () => {
    setLoadError(true);
  };

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const acceptTerms = async () => {
    setLoading(true);
    try {
      await dispatch(acceptCurrentTerms());
      await dispatch(getMe());
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e);
    }
  };

  return (
    <Wrapper>
      <h3>Updated Terms & Conditions</h3>
      <p>
        We've recently updated our terms & conditions - please read and confirm
        you accept them below.
      </p>
      {!loadError && (
        <Scroller onScroll={handleScroll}>
          <Document
            file={`${process.env.REACT_APP_ROOT_URL}/terms/${currentVersion}.pdf`}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={handlePDFError}
          >
            {Array.apply(null, Array(numPages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page pageNumber={page} scale={1} />
              ))}
          </Document>
        </Scroller>
      )}
      {loadError && (
        <>
          <p>
            We couldn't load the PDF automatically into your browser. Instead,
            please click the link below to view it then come back to this screen
            to accept its contents.
          </p>
          <Button
            noFullWidth
            onClick={() => {
              window.open(
                `${process.env.REACT_APP_ROOT_URL}/terms/${currentVersion}.pdf`
              );
              setAtBottom(true);
            }}
          >
            View Terms
          </Button>
          <Border />
        </>
      )}

      <div>
        <input
          type="checkbox"
          value={accepted}
          disabled={!atBottom}
          onChange={(e) => setAccepted(e.target.checked)}
          id="accept"
        />
        <label for="accept" className={atBottom ? "disabled" : ""}>
          I accept the new terms & conditions
        </label>
      </div>
      <div>
        <Button
          disabled={!accepted}
          noFullWidth={true}
          loading={loading}
          onClick={acceptTerms}
        >
          Save
        </Button>
      </div>
      <div>
        <a
          href={`${process.env.REACT_APP_ROOT_URL}/terms/${currentVersion}.pdf`}
          target="_blank"
        >
          Print Terms
        </a>
      </div>
      {error && <div>{error}</div>}
    </Wrapper>
  );
}

export default TermsAccept;
