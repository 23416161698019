import { get, getOr } from "lodash/fp";

export const getSDSLoading = (state) => {
  return get("sds.loading", state);
};

export const getSDSError = (state) => {
  return get("sds.error", state);
};
export const getSDSData = (state) => {
  return get("sds.sdsData", state);
};

export const getDistributeesLoading = (state) => {
  return get("assessment.distributeesLoading", state);
};

export const getDistributeesError = (state) => {
  return get("assessment.distributeesError", state);
};

export const getDisputeLoading = (state) => {
  return get("sds.disputeLoading", state);
};

export const getDisputeError = (state) => {
  return get("sds.disputeError", state);
};
