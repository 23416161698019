import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { getLoginError, getUser, getRealm } from "../store/users/selectors";
import { Link } from "react-router-dom";
import { login } from "../store/users/actions";
import Validation from "./FormValidation";
import styled from "styled-components";
import Form from "./Form";
import FormField from "./FormField";
import FormRow from "./FormRow";
import Button from "./Button";
import { mainColor } from "../styles/colors";
import { Success, Errors } from "./Elements";

const ALink = styled(Link)`
  color: ${mainColor};
`;

function ContractorLogin({ noRedirect }) {
  const [formData, setFormData] = useState({
    email: "",
    password: ""
  });
  const [validation, setValidation] = useState({});
  const dispatch = useDispatch();
  const loginError = useSelector(getLoginError);
  const user = useSelector(getUser);
  const realm = useSelector(getRealm);

  const formValidation = new Validation(validation, setValidation);

  formValidation.setRules({
    email: [
      {
        canned: "email"
      }
    ]
  });

  const updateFormData = (field, value) => {
    setFormData({ ...formData, [field]: value });
    formValidation.validate(field, value, formData);
  };

  const sendForm = async e => {
    e.preventDefault();
    const validationErrors = formValidation.validateForm(formData);

    if (validationErrors) {
      return;
    }

    dispatch(login(formData.email, formData.password, realm));
  };

  if (user && !noRedirect) {
    return <Redirect to="/contractor-assessment/1" />;
  }

  return (
    <Form>
      <FormRow cols={1}>
        <FormField
          name="email"
          label="Your email address"
          type="text"
          changeMonitor={value => updateFormData("email", value)}
          value={formData.email}
          validation={validation.email}
        />
        <FormField
          name="password"
          label="Password"
          type="password"
          changeMonitor={value => updateFormData("password", value)}
          value={formData.password}
          validation={validation.password}
        />
      </FormRow>
      <FormRow cols={1}>
        <Button onClick={sendForm}>Login</Button>
      </FormRow>
      <FormRow cols={1}>
        <ALink to="/forgot-password">I forgot my password</ALink>
      </FormRow>
      {user ? (
        <Success>
          <h5>You're logged in, we won't show this normally</h5>
        </Success>
      ) : null}
      {loginError ? (
        <Errors>
          <h5>Incorrect username/password - please try again</h5>
        </Errors>
      ) : null}
    </Form>
  );
}

export default ContractorLogin;
