import { set, flow, get } from "lodash/fp";
import {
  RESETPASSWORD_COMPLETE,
  RESETPASSWORD_REQUEST,
  CHANGEPASSWORD_REQUEST,
  CHANGEPASSWORD_SUCCESS,
  CHANGEPASSWORD_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  BOOTSTRAP_COMPLETE,
  LOGOUT_SUCCESS,
  GET_USERS_FOR_COMPANY_REQUEST,
  GET_USERS_FOR_COMPANY_SUCCESS,
  CREATE_USER_REQUEST,
  CREATE_USER_SUCCESS,
  SAVE_USER_REQUEST,
  SAVE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  DELETE_USER_REQUEST,
  CREATE_USER_FAILURE,
  BRANDING_SUCCESS,
  UPLOAD_BULK_FILE_REQUEST,
  UPLOAD_BULK_FILE_SUCCESS,
  UPLOAD_BULK_FILE_FAILURE,
  SET_USER_SEARCH,
  SET_USER_TYPE,
} from "./actions";

const addRoles = (users) => {
  return users.map((user) => {
    if (user.roles) {
      user.roles.forEach((role) => {
        user[`role_${role.name}`] = true;
      });
    }
    return user;
  });
};

export const users = (
  state = {
    registrationErrors: null,
    loading: false,
    resetComplete: false,
    changePasswordComplete: false,
    changePasswordError: false,
    loginError: false,
    userData: null,
    bootstrapped: false,
    companyUsers: {},
    branding: null,
    uploadLoading: false,
    userSearch: "",
    userType: "user",
  },
  action
) => {
  let currentUsers = get("companyUsers", state);
  let newUsers;

  switch (action.type) {
    case UPLOAD_BULK_FILE_REQUEST:
      return set("uploadLoading", true, state);
    case UPLOAD_BULK_FILE_SUCCESS:
    case UPLOAD_BULK_FILE_FAILURE:
      return set("uploadLoading", false, state);
    case RESETPASSWORD_REQUEST:
    case CHANGEPASSWORD_REQUEST:
    case LOGIN_REQUEST:
    case GET_USERS_FOR_COMPANY_REQUEST:
    case CREATE_USER_REQUEST:
    case SAVE_USER_REQUEST:
    case DELETE_USER_REQUEST:
      return set("loading", true, state);
    case BRANDING_SUCCESS:
      return set("branding", action.payload, state);
    case CREATE_USER_FAILURE:
      return flow(
        set(
          "registrationErrors",
          (action.payload &&
            action.payload.response &&
            action.payload.response.data) ||
            null
        ),
        set("loading", false)
      )(state);
    case RESETPASSWORD_COMPLETE:
      return flow(set("loading", false), set("resetComplete", true))(state);
    case CHANGEPASSWORD_FAILURE:
      return flow(
        set("changePasswordError", true),
        set("loading", false)
      )(state);
    case CHANGEPASSWORD_SUCCESS:
      return flow(
        set("loading", false),
        set("changePasswordError", false),
        set("changePasswordComplete", true)
      )(state);
    case LOGIN_FAILURE:
      return flow(
        set("loading", false),
        set("loginError", action.payload.data.error.message)
      )(state);
    case LOGIN_SUCCESS:
      return flow(
        set("loading", false),
        set("loginError", false),
        set("userData", action.payload)
      )(state);
    case GET_USERS_FOR_COMPANY_SUCCESS:
      return flow(
        set("loading", false),
        set("companyUsers", {
          ...state.companyUsers,
          [action.companyId]: addRoles(action.payload),
        })
      )(state);
    case CREATE_USER_SUCCESS:
      currentUsers[action.companyId] = [
        ...(currentUsers[action.companyId] || []),
        action.payload,
      ];
      return flow(
        set("loading", false),
        set("companyUsers", currentUsers)
      )(state);
    case SAVE_USER_SUCCESS:
      currentUsers[action.companyId] = currentUsers[action.companyId].map(
        (user) => {
          return user.id === action.payload.id ? action.payload : user;
        }
      );

      return flow(
        set("companyUsers", currentUsers),
        set("loading", false)
      )(state);
      break;
    case DELETE_USER_SUCCESS:
      currentUsers[action.companyId] = currentUsers[action.companyId].filter(
        (user) => {
          return user.id !== action.payload.id;
        }
      );
      return flow(
        set("companyUsers", currentUsers),
        set("loading", false)
      )(state);
    case LOGOUT_SUCCESS:
      return set("userData", null, state);
    case BOOTSTRAP_COMPLETE:
      return set("bootstrapped", true, state);
    case SET_USER_SEARCH:
      return set("userSearch", action.payload.searchTerm, state);
    case SET_USER_TYPE:
      return set("userType", action.payload.userType, state);
    default:
      return state;
  }
};
