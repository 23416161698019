import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { confirmAlert } from "react-confirm-alert";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperPlane, faPlus } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Button, { ActionButton } from "./Button";
import { getUsersForCompany } from "../store/users/actions";
import {
  addAndRemoveDistributees,
  resendSDSNotification,
} from "../store/sds/actions";
import { loadContractor } from "../store/contractors/actions";
import { getCompanyUsers } from "../store/users/selectors";
import {
  getDistributeesLoading,
  getDistributeesError,
} from "../store/sds/selectors";
import { saveEngagement } from "../store/assessment/actions";
import { getContractorById } from "../store/contractors/selectors";
import { distributeDark, distributeLight } from "../styles/colors";
import Modal from "react-modal";
import OpenDispute from "./OpenDispute";
import CloseDispute from "./CloseDispute";
import ViewDispute from "./ViewDispute";

const DistributeSurround = styled.div`
  margin-top: 20px;
  border: 1px solid ${distributeDark};
  background-color: ${distributeLight};
  padding: 10px;

  h4 {
    margin: 0px !important;
    text-align: left !important;
    font-size: 16px !important;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const Error = styled.div`
  font-weight: bold;
`;

const Table = styled.table`
  width: 100%;
  margin-bottom: 20px;

  thead {
    td {
      background-color: ${distributeDark};
      color: #fff;
      text-align: center;
      font-weight: bold;
    }
  }

  th {
    font-weight: bold;
    color: ${distributeDark};
    text-align: left;
    background-color: #fff;
    padding: 4px;
  }

  td {
    padding: 4px;
    background-color: #fff;
  }
`;

const Selector = styled.select`
  width: 100%;
  font-size: 16px;
  outline: none;
  border: 1px solid #ccc;
  margin-right: 10px;
`;

function DistributePanel({ engagement, contractorId, noSDS, reload }) {
  const dispatch = useDispatch();
  const loading = useSelector(getDistributeesLoading);
  const error = useSelector(getDistributeesError);
  const distributees = engagement && engagement.distributees;
  const hasDistributees = distributees && distributees.length > 0;
  const hasContractor =
    hasDistributees &&
    distributees.length &&
    distributees.find((distributee) => distributee.type === "CONTRACTOR");
  const [newUsers, setNewUsers] = useState([0]);
  const [remove, setRemove] = useState([]);
  const contractor = useSelector((state) =>
    getContractorById(state, Number(contractorId))
  );
  const recruiters =
    distributees &&
    distributees.filter((distributee) => distributee.type === "RECRUITER");
  const companyUsers = useSelector((state) =>
    getCompanyUsers(state, contractor && contractor.company_id)
  );
  const hasNewUsers = newUsers.filter((newUser) => newUser !== 0).length;
  const buttonDisabled = false;
  const contractorDistributee =
    distributees &&
    distributees.find((distributee) => distributee.type === "CONTRACTOR");
  const [resendLoading, setResendLoading] = useState(0);

  const [disputeModalOpen, setDisputeModalOpen] = useState(false);
  const [disputePublicId, setDisputePublicId] = useState(null);

  const [closeDisputeModalOpen, setCloseDisputeModalOpen] = useState(null);
  const [viewDisputeModalOpen, setViewDisputeModalOpen] = useState(null);
  const [disputeDistributee, setDisputeDistributee] = useState(null);

  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (contractor) {
      dispatch(getUsersForCompany(contractor.company_id, "all"));
    }
  }, [contractor]);

  const doDistribute = () => {
    const options = {
      title: "Are you sure?",
      message: [
        <p>
          By continuing the system will email the parties you have identified
          with the Status Determination Statement.
        </p>,
        <p>Do you wish to continue?</p>,
      ],
      buttons: [
        {
          label: "Continue",
          onClick: () => {
            let newToSend = [];
            if (!hasContractor || hasContractor.status === "READY_REISSUE") {
              newToSend.push({
                id: hasContractor && hasContractor.id,
                name: `${contractor.first_name} ${contractor.last_name}`,
                email: contractor.email,
                engagement_id: engagement.id,
                type: "CONTRACTOR",
              });
            }
            companyUsers.forEach((user) => {
              const match = recruiters.find(
                (recruiter) => recruiter.email === user.email
              );
              if (newUsers.includes(user.id) || match) {
                newToSend.push({
                  id: match && match.id,
                  name: `${user.first_name} ${user.last_name}`,
                  email: user.email,
                  engagement_id: engagement.id,
                  type: "RECRUITER",
                });
              }
            });
            setSaving(true);
            dispatch(addAndRemoveDistributees(newToSend, remove, contractorId));

            setNewUsers([0]);
            setRemove([]);
          },
        },
        {
          label: "Cancel",
        },
      ],
    };

    confirmAlert(options);
  };

  const removeNew = (idx) => setNewUsers(newUsers.splice(idx, 1));

  const addNew = () => setNewUsers([...newUsers, 0]);

  const removeExisting = (id) => setRemove([...remove, id]);

  const doResend = async (id) => {
    setResendLoading(id);
    await dispatch(resendSDSNotification(id));
    setResendLoading(0);
    dispatch(loadContractor(contractorId));
  };

  const doCancel = () => {
    const options = {
      title: "Are you sure?",
      message: [
        <p>
          By continuing you will remove any signatories already made and revoke
          access for those not yet complete.
        </p>,
        <p>This cannot be undone</p>,
        <p>Do you wish to continue?</p>,
      ],
      buttons: [
        {
          label: "Continue",
          onClick: () => {
            dispatch(
              addAndRemoveDistributees(
                [],
                [
                  ...recruiters.map((recruiter) => recruiter.id),
                  contractorDistributee.id,
                ],
                contractorId
              )
            );

            setNewUsers([0]);
            setRemove([]);
          },
        },
        {
          label: "Cancel",
        },
      ],
    };

    confirmAlert(options);
  };

  const openModal = (publicId) => {
    setDisputePublicId(publicId);
    setDisputeModalOpen(true);
  };

  const openCloseDisputeModal = (distributee) => {
    setDisputeDistributee(distributee);
    setCloseDisputeModalOpen(true);
  };
  const openViewDisputeModal = (distributee) => {
    setDisputeDistributee(distributee);
    setViewDisputeModalOpen(true);
  };

  const closeModal = () => {
    setDisputeModalOpen(false);
    dispatch(loadContractor(contractorId));
  };

  const reEnableSDS = () => {
    const options = {
      title: "Are you sure?",
      message: [
        <p>
          By continuing, you will re-enable the SDS functionality for this
          assessment. This will move this assessment from 'Complete' to 'Waiting
          SDS Distribution' and is not reversible.
        </p>,
      ],
      buttons: [
        {
          label: "Continue",
          onClick: async () => {
            reload();
          },
        },
        {
          label: "Cancel",
        },
      ],
    };

    confirmAlert(options);
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  return (
    <DistributeSurround>
      <Modal
        style={{ zIndex: 100000000000 }}
        isOpen={disputeModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Open Dispute"
      >
        <OpenDispute
          onClose={closeModal}
          publicId={disputePublicId}
        ></OpenDispute>
      </Modal>
      <Modal
        style={{ zIndex: 100000000000 }}
        isOpen={closeDisputeModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Resolve Dispute"
      >
        <CloseDispute
          onClose={closeModal}
          distributee={disputeDistributee}
        ></CloseDispute>
      </Modal>
      <Modal
        style={{ zIndex: 100000000000 }}
        isOpen={viewDisputeModalOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="View Dispute"
      >
        <ViewDispute
          onClose={closeModal}
          distributee={disputeDistributee}
        ></ViewDispute>
      </Modal>
      <h4>
        <StyledFontAwesomeIcon icon={faPaperPlane} />
        Distribute
      </h4>
      {noSDS && (
        <>
          <p>
            As this is a reassessment, an SDS will not be distributed by
            default. You are able to re-enable the SDS functionality by pressing
            the button below. This action is not reversable.
          </p>
          <Button noFullWidth={true} type="distribute" onClick={reEnableSDS}>
            Re-enable SDS Distribution
          </Button>
        </>
      )}
      {!noSDS && (
        <>
          {!hasDistributees && (
            <p>
              Add parties to send the Status Determination Statement to. They
              will be asked to acknowledge receipt and offered the chance to
              dispute the outcome.
            </p>
          )}
          {hasDistributees && <p>The SDS has been distributed</p>}
          <Table>
            <thead>
              <tr>
                <td colspan="4">
                  {distributees &&
                    "The following people have been sent the SDS"}
                  {!distributees && "Send SDS to relevant parties"}
                </td>
              </tr>
              <tr>
                <th width="180">Party</th>
                <th width="180">Name</th>
                <th width="120">Status</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Contractor</td>
                <td>
                  {contractor &&
                    `${contractor.first_name} ${contractor.last_name}`}
                </td>
                <td>
                  {!contractorDistributee && "Not sent"}
                  {contractorDistributee &&
                    contractorDistributee.status === "READY_TO_SEND" &&
                    "Ready to resend"}
                  {contractorDistributee &&
                    contractorDistributee.status === "ACCEPTED" &&
                    `Acknowledged on ${moment(
                      contractorDistributee.datetime
                    ).format("DD/MM/YYYY")}`}
                  {contractorDistributee &&
                    contractorDistributee.status !== "ACCEPTED" &&
                    contractorDistributee.status !== "IN_DISPUTE" &&
                    contractorDistributee.status !== "READY_TO_SEND" &&
                    "Sent"}
                  {contractorDistributee &&
                    contractorDistributee.status === "IN_DISPUTE" &&
                    "Disputed"}
                </td>
                <td>
                  {contractorDistributee &&
                    contractorDistributee.status !== "ACCEPTED" && (
                      <Button
                        type="distribute"
                        size="reduced"
                        loading={resendLoading === contractorDistributee.id}
                        noFullWidth={true}
                        onClick={() => doResend(contractorDistributee.id)}
                      >
                        Resend
                      </Button>
                    )}

                  {contractorDistributee &&
                    contractorDistributee.status !== "IN_DISPUTE" &&
                    !contractorDistributee.dispute_complete && (
                      <Button
                        type="appeal"
                        size="reduced"
                        noFullWidth={true}
                        onClick={() =>
                          openModal(contractorDistributee.public_id)
                        }
                      >
                        Open Dispute
                      </Button>
                    )}

                  {contractorDistributee &&
                    contractorDistributee.status === "IN_DISPUTE" &&
                    !contractorDistributee.dispute_complete && (
                      <Button
                        type="appeal"
                        size="reduced"
                        noFullWidth={true}
                        onClick={() =>
                          openCloseDisputeModal(contractorDistributee)
                        }
                      >
                        Resolve Dispute
                      </Button>
                    )}
                  {contractorDistributee &&
                    contractorDistributee.dispute_complete && (
                      <Button
                        type="appeal"
                        size="reduced"
                        noFullWidth={true}
                        onClick={() =>
                          openViewDisputeModal(contractorDistributee)
                        }
                      >
                        View Dispute
                      </Button>
                    )}
                </td>
              </tr>
              {recruiters &&
                recruiters
                  .filter((recruiter) => !remove.includes(recruiter.id))
                  .map((recruiter) => (
                    <tr>
                      <td>Supply Chain Member</td>
                      <td>{recruiter.name}</td>
                      <td>
                        {recruiter.status === "READY_TO_SEND" &&
                          "Ready to resend"}
                        {recruiter.status === "ACCEPTED"
                          ? `Acknowledged on ${moment(
                              recruiter.datetime
                            ).format("DD/MM/YYYY")}`
                          : recruiter.status === "IN_DISPUTE"
                          ? "Disputed"
                          : recruiter.status !== "READY_TO_SEND" && "Sent"}
                      </td>
                      <td>
                        {recruiter.status !== "ACCEPTED" && (
                          <Button
                            type="distribute"
                            size="reduced"
                            noFullWidth={true}
                            loading={resendLoading === recruiter.id}
                            onClick={() => doResend(recruiter.id)}
                          >
                            Resend
                          </Button>
                        )}
                        {"  "}
                        <Button
                          type="danger"
                          size="reduced"
                          noFullWidth={true}
                          onClick={() => removeExisting(recruiter.id)}
                        >
                          Remove
                        </Button>
                        {"  "}
                        {recruiter.status !== "IN_DISPUTE" &&
                          !recruiter.dispute_complete && (
                            <Button
                              type="appeal"
                              size="reduced"
                              noFullWidth={true}
                              onClick={() => openModal(recruiter.public_id)}
                            >
                              Open Dispute
                            </Button>
                          )}
                        {"  "}
                        {recruiter.status === "IN_DISPUTE" &&
                          !recruiter.dispute_complete && (
                            <Button
                              type="appeal"
                              size="reduced"
                              noFullWidth={true}
                              onClick={() => openCloseDisputeModal(recruiter)}
                            >
                              Resolve Dispute
                            </Button>
                          )}
                        {recruiter.dispute_complete && (
                          <Button
                            type="appeal"
                            size="reduced"
                            noFullWidth={true}
                            onClick={() => openViewDisputeModal(recruiter)}
                          >
                            View Dispute
                          </Button>
                        )}
                      </td>
                    </tr>
                  ))}
              {newUsers &&
                newUsers.map((newUser, indx) => (
                  <tr>
                    <td>Supply Chain Member</td>
                    <td>
                      <Selector
                        value={newUser}
                        onChange={(e) => {
                          //Add to the new array
                          const copiedUsers = [...newUsers];
                          copiedUsers[indx] = Number(e.target.value);
                          setNewUsers(copiedUsers);
                        }}
                      >
                        <option value="0">Choose</option>
                        {companyUsers.map((user) => {
                          return (
                            <option value={user.id}>{`${user.first_name} ${
                              user.last_name
                            } ${
                              user.external_company_name
                                ? `(${user.external_company_name})`
                                : ``
                            }`}</option>
                          );
                        })}
                      </Selector>
                    </td>
                    <td>Not sent</td>
                    <td>
                      {indx !== 0 && (
                        <Button
                          type="distribute"
                          size="reduced"
                          onClick={() => removeNew(indx)}
                        >
                          Remove
                        </Button>
                      )}
                    </td>
                  </tr>
                ))}
              <tr>
                <td colspan="4">
                  <Button
                    type="distribute"
                    noFullWidth={true}
                    size="reduced"
                    onClick={() => addNew()}
                    disabled={newUsers[newUsers.length - 1] === 0}
                  >
                    <StyledFontAwesomeIcon icon={faPlus} />
                    Add New
                  </Button>
                </td>
              </tr>
            </tbody>
          </Table>
          <Button
            type="distribute"
            disabled={buttonDisabled}
            noFullWidth={true}
            loading={loading || saving}
            onClick={doDistribute}
          >
            Save
          </Button>{" "}
          <Button
            type="distribute"
            disabled={!contractorDistributee}
            noFullWidth={true}
            loading={loading}
            onClick={doCancel}
          >
            Cancel
          </Button>
        </>
      )}
    </DistributeSurround>
  );
}

export default DistributePanel;
