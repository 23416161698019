import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Loader from "react-loader-spinner";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCommentAlt, faThumbsUp } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import Button, { ActionButton } from "./Button";
import {
  setCommentsOpenState,
  addComment,
  resolveComment,
} from "../store/review/actions";
import {
  getCommentsForQuestionIsOpen,
  getCommentsByQuestionId,
  getLoadingComments,
  getSaving,
  getSavingError,
  getResolving,
  getResolvingError,
} from "../store/review/selectors";
import { reviewDark, reviewLight } from "../styles/colors";

const CommentsSurround = styled.div`
  margin-top: 10px;
  border-top: 1px solid ${reviewDark};
  padding: 10px;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 20px;
`;

const CommentsHead = styled.div`
  display: flex;

  .left {
    flex: 1;
  }
  .right {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const CommentsLink = styled.span`
  text-decoration: underline;
  color: ${reviewDark};
  cursor: pointer;
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.7;
  }
`;

const CommentsArea = styled.div`
  margin-top: 10px;
  border-top: 1px solid ${reviewDark};
  padding: 10px 0px;
`;

const CommentsEmpty = styled.div`
  color: #888;
  text-align: center;
`;

const Comment = styled.div`
  .single-comment {
    display: flex;
    margin-top: 10px;

    .initials {
      background-color: ${reviewDark};
      color: #fff;
      font-size: 24px;
      text-align: center;
      width: 40px;
      height: 40px;
      line-height: 40px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .comment-body {
      flex: 1;

      .name {
        font-size: 12px;
        font-weight: bold;
      }

      .comment {
        font-size: 18px;
      }
    }

    .comment-footer {
      margin-top: 10px;
      border-top: 1px solid ${reviewDark};
      padding-top: 5px;
      color: ${reviewDark};

      .resolve {
        text-decoration: underline;
        transition: opacity 200ms ease-in;
        cursor: pointer;
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
`;

const CommentLevelWrapper = styled.div`
  margin-top: 20px;
  border-left: 2px solid #ccc;
  padding-left: 20px;
  margin-left: 20px;
`;

const Reply = styled.div`
  border-top: 1px solid #eee;
  padding-top: 10px;
  margin-top: 10px;
  margin-bottom: 20px;

  input {
    width: calc(100% - 20px);
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 16px;
  }
`;

const LoadingWrapper = styled.div`
  margin-top: 5px;
  .loader {
    display: inline-block;
    margin-right: 5px;
  }
`;

const Surround = styled.div`
  margin-top: 5px;
`;

function Comments({ questionId, reviewId, readonly }) {
  const dispatch = useDispatch();
  const open = useSelector((state) =>
    getCommentsForQuestionIsOpen(state, questionId)
  );
  const loading = useSelector(getLoadingComments);
  const saving = useSelector(getSaving);
  const savingError = useSelector(getSavingError);
  const comments = useSelector((state) =>
    getCommentsByQuestionId(state, questionId)
  );

  const generateInitials = (name) => {
    let parts = name.replace("  ", " ").split(" ");

    return `${parts[0][0].toUpperCase()}${parts[1][0].toUpperCase()}`;
  };

  const CommentLevel = ({ comments, level, parent = 0 }) => {
    let [newComment, setNewComment] = useState("");

    const submitComment = async () => {
      const result = await dispatch(
        addComment(reviewId, questionId, parent, newComment)
      );

      if (result && result.id) {
        setNewComment("");
      }
    };

    let resolveLoading = useSelector((state) => getResolving(state));
    let resolveError = useSelector((state) => getResolvingError(state));

    return (
      <CommentLevelWrapper level={level}>
        {comments &&
          comments.map((comment) => {
            return (
              <Comment>
                <div className="single-comment">
                  <div className="initials">
                    {generateInitials(comment.author_name)}
                  </div>
                  <div className="comment-body">
                    <div className="name">{comment.author_name}</div>
                    <div className="comment">{comment.comment}</div>
                    {level === 0 && (
                      <div className="comment-footer">
                        {comment.status === "RESOLVED" && (
                          <div>Marked as resolved by {comment.resolved_by}</div>
                        )}
                        {comment.status === "UNRESOLVED" && !readonly && (
                          <div
                            className="resolve"
                            onClick={() =>
                              dispatch(
                                resolveComment(comment.id, comment.question_id)
                              )
                            }
                          >
                            <>
                              <LoadingWrapper>
                                {resolveLoading !== comment.id ? (
                                  <StyledFontAwesomeIcon icon={faThumbsUp} />
                                ) : (
                                  <Loader
                                    type="TailSpin"
                                    color="#000"
                                    height="20"
                                    width="20"
                                    className="loader"
                                  />
                                )}
                                Mark as resolved{" "}
                              </LoadingWrapper>

                              {resolveError === comment.id && (
                                <span>
                                  <strong>An error ocurred, try again</strong>
                                </span>
                              )}
                            </>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>

                {comment.comments && (
                  <CommentLevel
                    level={level + 1}
                    parent={comment.id}
                    comments={comment.comments}
                  />
                )}
              </Comment>
            );
          })}
        {level < 2 && !readonly && (
          <Reply>
            <input
              type="text"
              placeholder={level === 0 ? "Add Comment" : "Reply"}
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter" && newComment.length > 0) {
                  submitComment();
                }
              }}
            />
            <Surround>
              <Button
                disabled={saving === `${parent}|${questionId}`}
                noFullWidth={true}
                type="review"
                onClick={submitComment}
              >
                Add Comment
              </Button>
              {saving === `${parent}|${questionId}` && (
                <LoadingWrapper>
                  <Loader
                    type="TailSpin"
                    color="#000"
                    height="20"
                    width="20"
                    className="loader"
                  />
                  Saving Comment...
                </LoadingWrapper>
              )}
              {savingError === `${parent}|${questionId}` && (
                <div>
                  <strong>An error ocurred, please try again</strong>
                </div>
              )}
            </Surround>
          </Reply>
        )}
      </CommentLevelWrapper>
    );
  };

  return (
    <CommentsSurround>
      <CommentsHead>
        <div className="left">
          <CommentsLink
            onClick={() => dispatch(setCommentsOpenState(questionId, !open))}
          >
            <StyledFontAwesomeIcon icon={faCommentAlt} />
            {comments ? comments.length : "0"}{" "}
            {comments && comments.length === 1 ? "Comment" : "Comments"}
          </CommentsLink>
        </div>
        <div className="right"></div>
      </CommentsHead>
      {open && (
        <CommentsArea>
          <CommentLevel level={0} comments={comments} />
        </CommentsArea>
      )}
    </CommentsSurround>
  );
}

export default Comments;
