import React, { useContext } from "react";
import styled from "styled-components";
import {
  contrastColor,
  errorStrong,
  errorStronger,
  mainColor,
  reviewDark,
  distributeDark,
  appealDark,
} from "../styles/colors";
import { ColorContext } from "./Bootstrap";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

const ButtonElement = styled.button`
  color: #fff;

  ${(props) =>
    props.type === "" || !props.type
      ? `background-color: ${props.mainColor};`
      : ``}
  ${(props) =>
    props.type === "danger" ? `background-color: ${errorStrong};` : ``}
  
 
    ${(props) =>
    props.type === "review" ? `background-color: ${reviewDark};` : ``}
    ${(props) =>
    props.type === "distribute" ? `background-color: ${distributeDark};` : ``}
      ${(props) =>
    props.type === "appeal" ? `background-color: ${appealDark};` : ``}
  padding: 10px 20px;
  text-align: center;
  font-family: Blinker;
  font-weight: 500;
  ${(props) => (props.noFullWidth ? "width: auto;" : "width: 100%;")}
  background-image: none;
  border: none;
  font-size: 18px;
  line-height: 20px;
  border-radius: 4px;
  transition: opacity 100ms ease-in;

  ${(props) =>
    props.size === "reduced" ? `font-size: 16px;padding: 5px 10px;` : ``}

  &:disabled {
    opacity: 0.5;
  }

  &:hover {
    opacity: 0.7;
  }

  .loader {
    display: inline !important;
    margin-right: 5px;
    margin-top: 2px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

export const ActionButton = styled.button`
  ${(props) => `background-color: ${props.mainColor};`}

  border-radius: 4px;
  background-image: none;
  border: none;
  font-size: 18px;
  padding: 10px 20px;
  line-height: 20px;
  color: #fff;
  font-family: Blinker;
  transition: opacity 100ms ease-in;
  outline: none;

  ${(props) =>
    props.type === "" || !props.type
      ? `background-color: ${props.mainColor};`
      : ``}
  ${(props) =>
    props.type === "danger" ? `background-color: ${errorStrong};` : ``}
  
  ${(props) =>
    props.size === "reduced" ? `font-size: 16px;padding: 5px 10px;` : ``}
  
    ${(props) =>
    props.type === "review" ? `background-color: ${reviewDark};` : ``}
    ${(props) =>
    props.type === "distribute" ? `background-color: ${distributeDark};` : ``}
    ${(props) =>
    props.type === "appeal" ? `background-color: ${appealDark};` : ``}
  &:hover {
    opacity: 0.7;
  }

  &:disabled {
    opacity: 0.5;
  }
`;

function Button(props) {
  const { loading, children, action = false, loadingText } = props;
  const mainColor = useContext(ColorContext);

  return action ? (
    <ActionButton {...props} mainColor={mainColor}>
      {children}
    </ActionButton>
  ) : (
    <ButtonElement
      {...props}
      disabled={loading || props.disabled}
      mainColor={mainColor}
    >
      {!loading ? (
        children
      ) : (
        <span>
          <Loader
            type="TailSpin"
            color="#fff"
            height="20"
            width="20"
            className="loader"
            text={loadingText}
          />
          <span>Please Wait</span>
        </span>
      )}
    </ButtonElement>
  );
}

Button.defaultProps = {
  componentType: "Button",
};

export default Button;
