import { get, getOr } from "lodash/fp";

export const getLoading = (state) => {
  return get("assessment.loading", state);
};
export const getMaster = (state) => {
  return getOr({ categories: [] }, "assessment.master", state);
};

export const getMappings = (state) => {
  return get("assessment.answerMappings", state);
};

export const getIsSelected = (state, answerId) => {
  const answers = get("assessment.answers", state);
  return answers ? answers.indexOf(answerId) > -1 : false;
};

export const getIsOverrideDetected = (state) => {
  const answers = getAnswers(state);
  const overrides = getOr([], "assessment.overrides", state);

  let hasOverride = false;

  answers.forEach((answer) => {
    if (overrides.includes(answer)) {
      hasOverride = true;
    }
  });

  return hasOverride;
};

export const getValidations = (state) => {
  return getOr([], "assessment.validations", state);
};

export const getAnswers = (state) => {
  return getOr([], "assessment.answers", state);
};

export const getSuppliers = (state) => {
  return getOr([], "assessment.suppliers", state);
};

export const getClients = (state) => {
  return getOr([], "assessment.clients", state);
};

export const getClientsSuppliersLoading = (state) => {
  return get("assessment.loadingClientsSuppliers", state);
};

export const getReportingData = (state) => {
  return get("assessment.reportingData", state);
};

export const getDashboardData = (state) => {
  return get("assessment.dashboardData", state);
};

export const getSubmissionError = (state) => {
  return get("assessment.submissionError", state);
};

export const getAssessmentToken = (state) => {
  return get("assessment.assessmentToken", state);
};

export const getContractorResult = (state) => {
  return get("assessment.contractorResult", state);
};

export const getLeadLoading = (state) => {
  return get("assessment.leadLoading", state);
};
export const getLeadError = (state) => {
  return get("assessment.leadError", state);
};

export const getLeadSuccess = (state) => {
  return get("assessment.leadSuccess", state);
};

export const getPurchaseFailure = (state) => {
  return get("assessment.purchaseFailure", state);
};

export const getPurchaseLoading = (state) => {
  return get("assessment.purchaseLoading", state);
};

export const getRoleAssessmentsLoading = (state) => {
  return get("assessment.roleAssessmentsLoading", state);
};

export const getRoleAssessmentsError = (state) => {
  return get("assessment.roleAssessmentsError", state);
};

export const getRoleAssessments = (state) => {
  return get("assessment.roleAssessments", state);
};

export const getContractorAssessmentsLoading = (state) => {
  return get("assessment.contractorAssessmentsLoading", state);
};

export const getContractorAssessmentsError = (state) => {
  return get("assessment.contractorAssessmentsError", state);
};
export const getContractorAssessments = (state) => {
  return get("assessment.contractorAssessments", state);
};

export const questionDeltas = (questionId, state) => {
  const deltas = get("assessment.deltas", state);

  return deltas ? deltas[questionId] : [];
};

export const getNotesForQuestion = (questionId, state) => {
  return getOr([], `assessment.notes.${questionId}`, state);
};

export const getNotes = (state) => {
  return get("assessment.notes", state);
};

export const getDashboardLoading = (state) => {
  return get("assessment.dashboardLoading", state);
};

export const getArchiveSetting = (state) => {
  return get("assessment.archiveSetting", state);
};
export const getProgressSearchTerm = (state) => {
  return get("assessment.progressSearchTerm", state);
};
export const getProgressFrom = (state) => {
  return get("assessment.progressFrom", state);
};
export const getProgressTo = (state) => {
  return get("assessment.progressTo", state);
};
export const getProgressInside = (state) => {
  return get("assessment.progressInside", state);
};
export const getProgressBorderline = (state) => {
  return get("assessment.progressBorderline", state);
};
export const getProgressOutside = (state) => {
  return get("assessment.progressOutside", state);
};
export const getSubscribersLoading = (state) => {
  return get("assessment.subscribersLoading", state);
};
export const getSubscribers = (state) => {
  return get("assessment.subscribers", state);
};
export const getAdditionalAnswers = (state) => {
  return getOr({}, "assessment.additionalAnswers", state);
};
export const getAdditionalAnswer = (key, state) => {
  return getOr({}, key, getAdditionalAnswers(state));
};
export const getShouldShareLeads = (state) => {
  return get("assessment.shouldShareLeads", state);
};
export const getCopyEngagement = (state) => {
  return getOr({}, "assessment.copyEngagement", state);
};
export const getActivityLog = (state) => {
  return get("assessment.activityLog", state);
};
export const getSearchTerm = (state) => {
  return get("assessment.assessmentSearch", state);
};
