import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getLoading,
  getClientById,
  getCompanyCredits,
  getClientFailures,
  getIncludesValidationTool,
} from "../store/clients/selectors";
import styled from "styled-components";
import {
  createClient,
  loadClients,
  saveClient,
  loadCreditsByCompany,
  addCredit,
  loadIncludesValidationTool,
} from "../store/clients/actions";
import {
  getHasAdminRole,
  getHasSuperAdminRole,
  getChildrenShareLeads,
  getUser,
  getRealm,
} from "../store/users/selectors";
import CreditsList from "../components/CreditsList";
import { SplitView, ContentArea } from "../components/Elements";
import UsersForCompany from "../components/UsersForCompany";
import Loader from "../components/Loader";
import CRUDForm from "../components/CRUDForm";
import Form from "../components/Form";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import Button from "../components/Button";
import { errorStrong } from "../styles/colors";

const TableWrapper = styled.div`
  background-color: #eee;
  border-radius: 4px;
  padding: 30px 20px;
  margin: 0px 30px 0px 0px;
  position: relative;

  &.no-pad {
    padding: 0px 20px;
  }
`;

const TableControls = styled.div`
  display: flex;
  justify-content: flex-start;
`;

const Failures = styled.div`
  border: 1px solid ${errorStrong};
  padding: 10px;
  color: ${errorStrong};
  margin-bottom: 20px;
`;

function Company({ match, history, urlStub }) {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading);
  const credits = useSelector(getCompanyCredits);
  const isAdmin = useSelector(getHasAdminRole);
  const isSuperAdmin = useSelector(getHasSuperAdminRole);
  const failures = useSelector(getClientFailures);
  const [showCreditForm, setShowCreditForm] = useState(false);
  const childrenShareLeads = useSelector(getChildrenShareLeads);
  const user = useSelector(getUser);
  const userCompanyIncludesValidation = useSelector(getIncludesValidationTool);
  const showValidationField =
    userCompanyIncludesValidation || isAdmin || isSuperAdmin;
  const realm = useSelector(getRealm);
  const isKingsbridge = realm === "ir35/kingsbridge";
  const recordId = match.params.companyId;
  const isNew = recordId === "new";
  const products = [
    {
      id: "LOW",
      name: "Low",
      amount: 6480,
      metadata: {
        contractors: 20,
      },
    },
    {
      id: "MED",
      name: "Medium",
      amount: 8100,
      metadata: {
        contractors: 50,
      },
    },
    {
      id: "HIGH",
      name: "High",
      amount: 12150,
      metadata: {
        contractors: 100,
      },
    },
  ];

  const emptyState = {
    name: "",
    subscription_status: "",
    billing_frequency: "",
    price: 0,
    trialDays: 0,
    feature_review: false,
    feature_children: false,
    feature_role_based: true,
    hide_rate: false,
    feature_dissemination: false,
    white_label: false,
    more_credits_phone: "",
    more_credits_email: "",
  };

  const record = useSelector((state) =>
    recordId === "new" ? null : getClientById(state, Number(recordId))
  );

  const [formData, setFormData] = useState(emptyState);

  const [dataFromForm, setDataFromForm] = useState({});

  useEffect(() => {
    if (user) {
      dispatch(loadIncludesValidationTool(user.userData.company_id));
    }
  }, [user]);

  useEffect(() => {
    dispatch(loadClients(true, true));
  }, []);

  useEffect(() => {
    if (isAdmin) {
      dispatch(loadCreditsByCompany(recordId));
    }
  }, [isAdmin]);

  useEffect(() => {
    if (record) {
      const recordToUse = record || {};
      setFormData({ ...emptyState, ...recordToUse });
    }
  }, [recordId, record]);

  const sendForm = async (formData) => {
    if (isNew) {
      const response = await dispatch(createClient(formData));
      await dispatch(loadClients(true, true));
      history.push(`/main/settings/clients/company/${response.id}`);
    } else {
      dispatch(saveClient(formData));
    }
  };

  const emptyStateCredits = {
    amount: "",
    price_per_credit: "",
  };
  const formDataCredits = {
    amount: "",
    price_per_credit: "",
  };

  const sendCreditForm = async (formData) => {
    await dispatch(
      addCredit(recordId, formData.amount, formData.price_per_credit)
    );
    dispatch(loadCreditsByCompany(recordId));
  };

  return (
    <div>
      {(!isNew && !record) || !products ? (
        <Loader />
      ) : (
        <ContentArea>
          <SplitView>
            <div className="left">
              <CRUDForm
                emptyState={emptyState}
                formData={formData}
                loading={loading}
                recordId={recordId}
                onSubmit={sendForm}
                updater={(newData) => {
                  setDataFromForm(newData);
                }}
                linkedBooleanValues={{ feature_review: ["feature_children"] }}
              >
                <Form internal={true}>
                  <h2>Client's Details</h2>
                  {failures && (
                    <Failures>
                      <p>Something went wrong, please try again</p>
                    </Failures>
                  )}
                  {isAdmin && (
                    <>
                      <FormRow cols={2}>
                        <FormField
                          name="white_label"
                          label="White Label"
                          type="checkbox"
                          inputType="checkbox"
                        />
                      </FormRow>
                      {dataFromForm.white_label && (
                        <>
                          <FormRow cols={2}>
                            <FormField
                              name="logo"
                              label="Logo"
                              inputType="image"
                            />
                          </FormRow>

                          <FormRow cols={2}>
                            <FormField
                              name="logo_space"
                              label="Add space around the logo"
                              type="checkbox"
                              inputType="checkbox"
                            />
                          </FormRow>
                          <FormRow cols={2}>
                            <FormField
                              name="cta_bg"
                              label="Accent Colour"
                              type="text"
                            />
                          </FormRow>
                          <FormRow cols={2}>
                            <FormField
                              name="sub_domain"
                              label="Sub Domain"
                              type="text"
                            />
                          </FormRow>
                          <FormRow cols={2}>
                            <FormField
                              name="powered_by"
                              label="Powered by...."
                              type="checkbox"
                              inputType="checkbox"
                              helpText={`Whether child companies will be marked as powered by ${dataFromForm.name}`}
                            />
                          </FormRow>
                          {dataFromForm.powered_by && (
                            <>
                              <FormRow cols={2}>
                                <FormField
                                  name="more_credits_email"
                                  label="Email for more credits"
                                  type="text"
                                  helpText="The email clients should contact to buy more credits"
                                />
                              </FormRow>
                              <FormRow cols={2}>
                                <FormField
                                  name="more_credits_phone"
                                  label="Phone number for more credits"
                                  type="text"
                                  helpText="The phone number clients should phone to buy more credits"
                                />
                              </FormRow>
                            </>
                          )}
                          {isSuperAdmin && (
                            <FormRow cols={2}>
                              <FormField
                                name="contains_standalone"
                                label="Standalone Assessments"
                                type="checkbox"
                                inputType="checkbox"
                              />
                            </FormRow>
                          )}
                          {isSuperAdmin && dataFromForm.contains_standalone && (
                            <FormRow cols={2}>
                              <FormField
                                name="standalone_subscription"
                                label="Standalone Assessments can have subscriptions"
                                type="checkbox"
                                inputType="checkbox"
                              />
                            </FormRow>
                          )}
                          {isSuperAdmin && dataFromForm.contains_standalone && (
                            <FormRow cols={2}>
                              <FormField
                                name="standalone_preview_result"
                                label="Standalone Assessments preview result before purchase"
                                type="checkbox"
                                inputType="checkbox"
                              />
                            </FormRow>
                          )}
                          {isSuperAdmin && dataFromForm.contains_standalone && (
                            <FormRow cols={2}>
                              <FormField
                                name="standalone_assessment_price"
                                label="Cost of a single assessment"
                                helpText="Excluding VAT in pence (eg £49.50 would be 4950)"
                                type="text"
                              />
                            </FormRow>
                          )}
                          {isSuperAdmin && (
                            <FormRow cols={2}>
                              <FormField
                                name="manual_reviewer"
                                label="Carries out manual reviews"
                                type="checkbox"
                                inputType="checkbox"
                              />
                            </FormRow>
                          )}
                        </>
                      )}
                    </>
                  )}
                  <FormRow cols={2}>
                    <FormField name="name" label="Company Name" type="text" />
                  </FormRow>
                  {isAdmin && (
                    <FormRow cols={2}>
                      <FormField
                        name="company_type"
                        label="Type of business"
                        inputType="radio"
                        options={[
                          { value: "recruiter", name: "Recruiter" },
                          { value: "end_client", name: "End Client" },
                        ]}
                      />
                    </FormRow>
                  )}
                  {isAdmin && (
                    <>
                      <FormRow cols={2}>
                        {" "}
                        <label>Features</label>
                      </FormRow>
                      <FormRow cols={2}>
                        <FormField
                          name="include_validation_tool"
                          label="Include Insurance Validation Tool"
                          type="checkbox"
                          inputType="checkbox"
                        />
                      </FormRow>

                      <FormRow cols={2}>
                        <FormField
                          name="feature_review"
                          label="Enable 'External Review' feature"
                          type="checkbox"
                          inputType="checkbox"
                        />
                      </FormRow>
                      <FormRow cols={2}>
                        <FormField
                          name="feature_children"
                          label="Enable 'Create Children' feature"
                          type="checkbox"
                          inputType="checkbox"
                        />
                      </FormRow>
                      <FormRow cols={2}>
                        <FormField
                          name="feature_role_based"
                          label="Enable 'Role Based Assessments' feature"
                          type="checkbox"
                          inputType="checkbox"
                        />
                      </FormRow>
                      <FormRow cols={2}>
                        <FormField
                          name="feature_dissemination"
                          label="Enable 'Contractor Dissemination' feature"
                          type="checkbox"
                          inputType="checkbox"
                        />
                      </FormRow>

                      {isKingsbridge && (
                        <FormRow cols={2}>
                          <FormField
                            name="include_insured_with_kb"
                            label="Show Insured With Kingsbridge Column"
                            type="checkbox"
                            inputType="checkbox"
                          />
                        </FormRow>
                      )}
                    </>
                  )}
                  {showValidationField && (
                    <FormRow cols={2}>
                      <FormField
                        name="hide_validations"
                        label="Hide Insurance Validations"
                        type="checkbox"
                        inputType="checkbox"
                      />
                    </FormRow>
                  )}

                  <FormRow cols={2}>
                    <FormField
                      name="hide_rate"
                      label="Hide rate field"
                      type="checkbox"
                      inputType="checkbox"
                      helpText="With this option enabled, the client won't see the day rate for each contractor but they will still see the overall financial impact"
                    />
                  </FormRow>
                  {isSuperAdmin && dataFromForm.powered_by && (
                    <FormRow cols={2}>
                      <FormField
                        name="children_share_leads"
                        label="Children can share leads"
                        type="checkbox"
                        inputType="checkbox"
                        helpText="Whether you can allow this client to enable their clients to share leads"
                      />
                    </FormRow>
                  )}
                  {isAdmin &&
                    (childrenShareLeads ||
                      dataFromForm.children_share_leads) && (
                      <FormRow cols={2}>
                        <FormField
                          name="share_leads"
                          label="Share Leads"
                          type="checkbox"
                          inputType="checkbox"
                          helpText="With this option enabled, we will share leads from this customer"
                        />
                      </FormRow>
                    )}
                  <FormRow cols={2}>
                    <FormField
                      name="custom_reference"
                      label="Custom Reference"
                      optional={true}
                      type="text"
                    />
                  </FormRow>
                  <FormRow cols={2}>
                    <FormField
                      name="mandatory_signoff"
                      label="Enable 'Mandatory sign off' feature"
                      type="checkbox"
                      inputType="checkbox"
                    />
                  </FormRow>
                  {isAdmin && (
                    <FormRow cols={2}>
                      <FormField
                        name="do_not_show_terms"
                        label="Do not show terms and conditions"
                        type="checkbox"
                        inputType="checkbox"
                      />
                    </FormRow>
                  )}
                  {isAdmin && (
                    <FormRow cols={2}>
                      <FormField
                        name="subscription_status"
                        label="Subscription Status"
                        inputType="radio"
                        options={[
                          { value: "trialing", name: "Trialing" },
                          { value: "active", name: "Active" },
                        ]}
                      />
                    </FormRow>
                  )}
                  {dataFromForm.subscription_status === "trialing" && (
                    <>
                      <FormRow cols={2}>
                        <FormField
                          name="trialDays"
                          label={
                            isNew
                              ? "Trial Length (days)"
                              : "Extend trial (days)"
                          }
                          type="number"
                        />
                      </FormRow>
                    </>
                  )}
                  <FormRow cols={2}>
                    <Button noFullWidth={true}>
                      {isNew ? "Create new client" : "Save"}
                    </Button>
                  </FormRow>
                </Form>
              </CRUDForm>
            </div>
            <div className="right">
              {!isNew && (
                <>
                  <TableWrapper>
                    <h2>Users</h2>
                    <TableControls>
                      <Button
                        action
                        onClick={() =>
                          history.push(
                            `/main/settings/clients/company/${recordId}/users/new`
                          )
                        }
                      >
                        New User
                      </Button>
                    </TableControls>
                    <UsersForCompany
                      companyId={recordId}
                      urlStub={urlStub}
                      history={history}
                    />
                  </TableWrapper>
                  <br />
                  {record.using_credits && isAdmin && (
                    <>
                      {" "}
                      <TableWrapper>
                        <TableControls>
                          <>
                            {!showCreditForm && (
                              <Button
                                noFullWidth={true}
                                onClick={() => setShowCreditForm(true)}
                              >
                                Add Credits
                              </Button>
                            )}
                            {showCreditForm && (
                              <CRUDForm
                                emptyState={emptyStateCredits}
                                formData={formDataCredits}
                                loading={loading}
                                recordId={1}
                                onSubmit={sendCreditForm}
                              >
                                <Form internal={true}>
                                  <FormRow cols={2}>
                                    <FormField
                                      name="amount"
                                      label="Amount"
                                      type="text"
                                    />
                                    <FormField
                                      name="price_per_credit"
                                      label="Price Per Credit"
                                      type="text"
                                    />
                                  </FormRow>

                                  <FormRow cols={2}>
                                    <Button noFullWidth={true}>
                                      Add Credits
                                    </Button>
                                  </FormRow>
                                </Form>
                              </CRUDForm>
                            )}
                          </>
                        </TableControls>

                        <CreditsList
                          creditBalance={record.credit_balance}
                          loading={loading}
                          credits={credits}
                        />
                      </TableWrapper>
                    </>
                  )}
                </>
              )}
            </div>
          </SplitView>
        </ContentArea>
      )}
    </div>
  );
}

export default Company;
