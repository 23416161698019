import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { device } from "./Devices";
import { getDisputeLoading } from "../store/sds/selectors";
import CRUDForm from "./CRUDForm";
import Form from "./Form";

import Button from "./Button";

const Centered = styled.div`
  text-align: center;
`;

const FormWrapper = styled.div`
  width: 600px;
  max-height: 80vh;
  z-index: 1000000;

  // @media ${device.laptop} {
  //   padding: 30px 20px;
  //   ${(props) => !props.wrapped && "margin: 0px 30px 0px 0px;"}
  // }
`;

const OriginalReason = styled.div`
  padding: 10px;
  height: 200px;
  overflow: auto;
  border: 1px solid #bbb;

  h4 {
    margin-top: 0;
  }
  margin-bottom: 20px;
`;

function ViewDispute({ onClose = () => {}, distributee }) {
  const cancel = async (e) => {
    e.preventDefault();

    onClose();
  };

  return (
    <FormWrapper>
      <h3>View Dispute</h3>
      <OriginalReason>
        <h4>Original Dispute</h4>
        {distributee.dispute_reason.split("\n").map((i, key) => {
          return <p key={key}>{i}</p>;
        })}
      </OriginalReason>
      <OriginalReason>
        <h4>Response</h4>
        {distributee.dispute_response.split("\n").map((i, key) => {
          return <p key={key}>{i}</p>;
        })}
      </OriginalReason>

      <Centered>
        <Button noFullWidth={true} noBind onClick={cancel} type="distribute">
          Close
        </Button>
      </Centered>
    </FormWrapper>
  );
}

export default ViewDispute;
