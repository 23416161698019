import { fetch } from "../fetchMiddleware";

import { loadContractor } from "../contractors/actions";

export const PASS_REVIEW_REQUEST = "PASS_REVIEW_REQUEST";
export const PASS_REVIEW_SUCCESS = "PASS_REVIEW_SUCCESS";
export const PASS_REVIEW_FAILURE = "PASS_REVIEW_FAILURE";

export const passReview = (id, contractorId, companyId) => async (dispatch) => {
  dispatch({
    type: PASS_REVIEW_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/ReviewApprovals/pass-review/${id}`, {
        method: "GET",
      })
    );

    dispatch({
      type: PASS_REVIEW_SUCCESS,
      payload: response,
    });

    dispatch(loadContractor(contractorId, companyId));

    return response;
  } catch (error) {
    dispatch({
      type: PASS_REVIEW_FAILURE,
      payload: error,
    });
  }
};

export const SET_COMMENTS_OPEN_STATE = "SET_COMMENTS_OPEN_STATE";

export const setCommentsOpenState = (questionId, show) => (dispatch) => {
  dispatch({
    type: SET_COMMENTS_OPEN_STATE,
    show,
    questionId,
  });
};

export const LOAD_COMMENTS_REQUEST = "LOAD_COMMENTS_REQUEST";
export const LOAD_COMMENTS_SUCCESS = "LOAD_COMMENTS_SUCCESS";
export const LOAD_COMMENTS_FAILURE = "LOAD_COMMENTS_FAILURE";

export const loadCommentsForReview = (reviewId) => async (dispatch) => {
  dispatch({
    type: LOAD_COMMENTS_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/ReviewComments/for-review/${reviewId}`, {
        method: "GET",
      })
    );

    dispatch({
      type: LOAD_COMMENTS_SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: LOAD_COMMENTS_FAILURE,
      payload: error,
    });
  }
};

export const ADD_COMMENT_REQUEST = "ADD_COMMENT_REQUEST";
export const ADD_COMMENT_SUCCESS = "ADD_COMMENT_SUCCESS";
export const ADD_COMMENT_FAILURE = "ADD_COMMENT_FAILURE";

export const addComment = (reviewId, questionId, parent, comment) => async (
  dispatch
) => {
  dispatch({
    type: ADD_COMMENT_REQUEST,
    questionId,
    parent,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/ReviewComments`, {
        method: "POST",
        data: {
          review_id: reviewId,
          comment,
          question_id: questionId,
          parent,
        },
      })
    );

    dispatch({
      type: ADD_COMMENT_SUCCESS,
      payload: response,
      parent,
      questionId,
    });

    return response;
  } catch (error) {
    dispatch({
      type: ADD_COMMENT_FAILURE,
      payload: error,
      parent,
      questionId,
    });
  }
};

export const SET_QUESTIONS_WITH_COMMENTS = "SET_QUESTIONS_WITH_COMMENTS";

export const setShowQuestionsWithComments = (value) => (dispatch) => {
  dispatch({
    type: SET_QUESTIONS_WITH_COMMENTS,
    payload: value,
  });
};

export const RESOLVE_COMMENT_REQUEST = "RESOLVE_COMMENT_REQUEST";
export const RESOLVE_COMMENT_SUCCESS = "RESOLVE_COMMENT_SUCCESS";
export const RESOLVE_COMMENT_FAILURE = "RESOLVE_COMMENT_FAILURE";

export const resolveComment = (commentId, questionId) => async (dispatch) => {
  dispatch({
    type: RESOLVE_COMMENT_REQUEST,
    commentId,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/ReviewComments/${commentId}`, {
        method: "PATCH",
        data: {
          status: "RESOLVED",
        },
      })
    );

    dispatch({
      type: RESOLVE_COMMENT_SUCCESS,
      payload: response,
      commentId,
      questionId,
    });

    return response;
  } catch (error) {
    dispatch({
      type: RESOLVE_COMMENT_FAILURE,
      payload: error,
      commentId,
    });
  }
};

export const LOAD_REVIEW_DASHBOARD_REQUEST = "LOAD_REVIEW_DASHBOARD_REQUEST";
export const LOAD_REVIEW_DASHBOARD_SUCCESS = "LOAD_REVIEW_DASHBOARD_SUCCESS";
export const LOAD_REVIEW_DASHBOARD_FAILURE = "LOAD_REVIEW_DASHBOARD_FAILURE";

export const loadReviewDashboard = (companyId, filter) => async (dispatch) => {
  dispatch({
    type: LOAD_REVIEW_DASHBOARD_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/ContractorEngagements/review-dashboard/${companyId}/${filter}`, {
        method: "GET",
      })
    );

    dispatch({
      type: LOAD_REVIEW_DASHBOARD_SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: LOAD_REVIEW_DASHBOARD_FAILURE,
      payload: error,
    });
  }
};

export const LOAD_MANUAL_REVIEWS_REQUEST = "LOAD_MANUAL_REVIEWS_REQUEST";
export const LOAD_MANUAL_REVIEWS_SUCCESS = "LOAD_MANUAL_REVIEWS_SUCCESS";
export const LOAD_MANUAL_REVIEWS_FAILURE = "LOAD_MANUAL_REVIEWS_FAILURE";

export const loadManualReviews = (
  page,
  pageSize,
  status,
  filterAssigned,
  searchTerm
) => async (dispatch) => {
  dispatch({
    type: LOAD_MANUAL_REVIEWS_REQUEST,
  });

  let url = `/ReviewApprovals/manual-reviews/${status}/${filterAssigned}?page=${
    page - 1
  }&pageSize=${pageSize}`;

  if (searchTerm) {
    url += `&searchTerm=${searchTerm}`;
  }

  try {
    const result = await dispatch(
      fetch(url, {
        method: "GET",
      })
    );

    const { data: response } = result;
    const count = result.headers["x-total-count"];

    dispatch({
      type: LOAD_MANUAL_REVIEWS_SUCCESS,
      payload: { response, count },
    });

    return response;
  } catch (error) {
    await dispatch({
      type: LOAD_MANUAL_REVIEWS_FAILURE,
      payload: error && error.response && error.response.data,
    });
  }
};

export const SET_PAGE = "SET_PAGE";

export const setPage = (page) => (dispatch) => {
  dispatch({
    type: SET_PAGE,
    payload: page,
  });
};

export const SET_PER_PAGE = "SET_PER_PAGE";

export const setPerPage = (perPage) => (dispatch) => {
  dispatch({
    type: SET_PER_PAGE,
    payload: perPage,
  });
};

export const SET_SEARCH_TERM = "SET_SEARCH_TERM";

export const setSearchTerm = (searchTerm) => (dispatch) => {
  dispatch({
    type: SET_SEARCH_TERM,
    payload: searchTerm,
  });
};

export const SET_FILTER_OPEN_CLOSED = "SET_FILTER_OPEN_CLOSED";

export const setFilterOpenClosed = (openClosed) => (dispatch) => {
  dispatch({
    type: SET_FILTER_OPEN_CLOSED,
    payload: openClosed,
  });
};

export const SET_FILTER_ASSIGNED = "SET_FILTER_ASSIGNED";

export const setFilterAssigned = (assigned) => (dispatch) => {
  dispatch({
    type: SET_FILTER_ASSIGNED,
    payload: assigned,
  });
};

export const ASSIGN_REVIEW_REQUEST = "ASSIGN_REVIEW_REQUEST";
export const ASSIGN_REVIEW_SUCCESS = "ASSIGN_REVIEW_SUCCESS";
export const ASSIGN_REVIEW_FAILURE = "ASSIGN_REVIEW_FAILURE";

export const assignReview = (id, userId) => async (dispatch) => {
  dispatch({
    type: ASSIGN_REVIEW_REQUEST,
    id,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/ReviewApprovals/manual-reviews/assign/${id}`, {
        method: "PUT",
      })
    );

    dispatch({
      type: ASSIGN_REVIEW_SUCCESS,
      payload: { response, id, userId },
    });

    return response;
  } catch (error) {
    await dispatch({
      type: ASSIGN_REVIEW_FAILURE,
      payload: error && error.response && error.response.data,
    });
  }
};

export const UNASSIGN_REVIEW_REQUEST = "UNASSIGN_REVIEW_REQUEST";
export const UNASSIGN_REVIEW_SUCCESS = "UNASSIGN_REVIEW_SUCCESS";
export const UNASSIGN_REVIEW_FAILURE = "UNASSIGN_REVIEW_FAILURE";

export const unassignReview = (id) => async (dispatch) => {
  dispatch({
    type: UNASSIGN_REVIEW_REQUEST,
    id,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/ReviewApprovals/manual-reviews/assign/${id}`, {
        method: "DELETE",
      })
    );

    dispatch({
      type: UNASSIGN_REVIEW_SUCCESS,
      payload: { response, id, userId: null },
    });

    return response;
  } catch (error) {
    await dispatch({
      type: UNASSIGN_REVIEW_FAILURE,
      payload: error && error.response && error.response.data,
    });
  }
};
