import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import formatCurrency from "format-currency";
import { Doughnut } from "react-chartjs-2";
import "react-confirm-alert/src/react-confirm-alert.css";
import {
  loadReportingData,
  resendContractorQuestions,
} from "../store/assessment/actions";
import { getReportingData } from "../store/assessment/selectors";
import {
  getUserToken,
  getCompanyType,
  getHasManualReviewerRole,
} from "../store/users/selectors";
import Loader from "./Loader";
import styled from "styled-components";
import {
  successStrong,
  errorStrong,
  undeterminedStrong,
  mainColor,
  errorScale2,
  errorScale3,
  errorScale4,
  errorScale5,
} from "../styles/colors";
import Button from "./Button";
import ReviewerPanel from "./ReviewerPanel";
import DistributePanel from "./DistributePanel";
import { getHasFeature } from "../store/users/selectors";
import SplitButton from "./SplitButton";

export const ResultElement = styled.div`
  padding-bottom: 20px;

  .pass {
    color: ${successStrong};
  }

  .fail {
    color: ${errorStrong};
  }

  .undetermined {
    color: ${undeterminedStrong};
  }

  h4 {
    text-align: center;
    font-size: 12px;
    text-transform: uppercase;
    margin: 0;
  }

  h2 {
    text-align: center;
    font-size: 38px;
    margin: 0;
  }

  h1 {
    background-color: #222;
    color: #fff;
    text-align: center;
    margin: 0 -20px;
    padding: 10px;
    font-size: 30px;
  }
`;

const RiskSummary = styled.div`
  margin-left: -20px;

  padding: 20px 40px;
  background-color: #ddd;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  width: calc(100% + 40px);
  margin-bottom: 10px;
  box-sizing: border-box;

  p {
    line-height: 24px;
  }
`;

export const RiskScale = styled.div`
  border: 2px solid #000;
  border-radius: 6px;
  position: relative;
  width: 100%;
  height: 40px;
  margin-top: 20px;
  &:after {
    content: "";
    border-radius: 4px;
    position: absolute;
    top: 4px;
    left: 4px;
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    background: #4e8f27; /* Old browsers */

    background: linear-gradient(
      to right,
      #c52c30 0%,
      #f4b536 50%,
      #4e8f27 100%
    );
  }
  margin-bottom: 40px;

  .scale-label {
    position: absolute;
    text-align: center;
    top: 48px;
    background-color: #000;
    border: 1px solid #000;
    height: 26px;
    line-height: 26px;
    margin: auto;
    color: #fff;
    font-weight: bold;
    width: 100px;
    ${(props) =>
      props.result > 0
        ? props.result > 0.9
          ? "right: 0"
          : `left: calc(${100 * props.result}% - 60px)`
        : "0"}
  }

  .scale-label:before {
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    background-color: #000;
    width: 8px;
    height: 8px;
    transform: rotate(45deg);
    position: absolute;
    top: -5px;
  }

  .scale-label:after {
    content: "";
    left: 0;
    right: 0;
    margin: auto;
    background-color: #000;
    width: 2px;

    position: absolute;
    top: -51px;
    height: 51px;
  }
`;

const Buttons = styled.div`
  button {
    margin-right: 10px;
  }
  display: flex;
  justify-content: center;
`;

const Summary = styled.div`
  display: flex;
  justify-content: space-around;

  > div {
    flex: 1;
  }
`;

const ResultGraph = styled.div`
  position: relative;
`;

const DividingLine = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 1px;
  right: 0;
  margin: auto;
  height: 100%;
  border-right: 1px dashed #000;
`;

const FailLabel = styled.div`
  position: absolute;
  top: 10px;
  left: 5px;
  font-size: 12px;
  text-transform: uppercase;
`;

const PassLabel = styled.div`
  position: absolute;
  top: 10px;
  right: 5px;
  font-size: 12px;
  text-transform: uppercase;
`;

const AlgorithmWarning = styled.div`
  border: 2px solid ${errorStrong}
  padding: 20px;
  margin-bottom: 20px;
`;
const AwaitingContractor = styled.div`
  border: 2px solid ${errorScale3}
  padding: 20px;
  margin-bottom: 20px;
`;

const LoadingParas = styled.div`
  ${(props) => props.short && "margin-top: 20px;"}
`;

const FlashingPara = styled.div`
  background-color: #888;
  height: 16px;
  margin-bottom: 5px;

  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.6;
    }
    100% {
      opacity: 1;
    }
  }
  animation: pulse 0.8s infinite;

  ${(props) => (props.smaller ? "margin-right: 140px;" : "")}
`;

const OverrideArea = styled.div`
  background-color: #eee;
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  p {
    text-align: center;
  }
`;

const OverrideButtons = styled.div`
  display: flex;
  justify-content: center;
`;

const ReasonBox = styled.textarea`
  padding: 10px;
  margin-top: 10px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  height: 140px;
`;

const Version = styled.p`
  font-size: 12px;
  color: #888;
`;

export const getDisplayName = (resultName, type) => {
  if (type === "FULL") {
    switch (resultName) {
      case "GOOD_PASS":
      case "PASS":
        return "Outside IR35";
      case "BORDER_LINE_PASS":
      case "BORDER_LINE_FAIL":
      case "BORDERLINE":
        return "Borderline";
      case "FAIL":
      case "BAD_FAIL":
      case "OVERRIDE":
        return "Inside IR35";
      case "NA":
        return "Not Applicable";
      default:
        return "No Status";
    }
  } else {
    switch (resultName) {
      case "GOOD_PASS":
        return "Pass";
      case "BAD_FAIL":
        return "Fail";
    }
  }
};

export const getDisplayNameFromScore = (score) => {
  switch (score) {
    case 0:
      return "Inside (Definite)";
    case 1:
      return "Inside (High)";
    case 2:
      return "Inside (Low)";
    case 3:
      return "Borderline";
    case 4:
      return "Outside (Low)";
    case 5:
      return "Outside (High)";
  }
};

export const getDetailDisplayName = (resultName) => {
  switch (resultName) {
    case "GOOD_PASS":
      return "Very Good";
    case "PASS":
      return "Good";
    case "BORDER_LINE_PASS":
    case "BORDER_LINE_FAIL":
    case "BORDERLINE":
      return "Caution";
    case "FAIL":
      return "Poor";
    case "BAD_FAIL":
    case "OVERRIDE":
      return "Very Poor";
    case "NA":
      return "Not Applicable";
  }
};

export const getRiskClassName = (resultName) => {
  switch (resultName) {
    case "GOOD_PASS":
    case "PASS":
      return "pass";
    case "BORDER_LINE_PASS":
      return "undetermined";
    case "BORDER_LINE_FAIL":
      return "undetermined";
    case "BORDERLINE":
      return "undetermined";
    case "FAIL":
    case "BAD_FAIL":
    case "OVERRIDE":
      return "fail";
  }
};

function ResultScreen(props) {
  const {
    assessmentResult,
    contractorId,
    summaryOnly,
    review,
    engagement,
    reopen,
    setReopen,
    view,
    setView,
    isManualReviewer,
    onOverride,
    inReview,
    assessmentType,
    noSDS,
    reload,
  } = props;

  const dispatch = useDispatch();
  const reportingData = useSelector(getReportingData);
  const [currentTab, setCurrentTab] = useState(null);
  const companyType = useSelector(getCompanyType);
  const userToken = useSelector(getUserToken);
  const hasReviewFeature = useSelector((state) =>
    getHasFeature(state, "feature_review")
  );
  const hasManualReviewRole = useSelector(getHasManualReviewerRole);

  const showOverride = isManualReviewer && inReview && hasManualReviewRole;

  console.log(
    "isManualReviewer",
    isManualReviewer,
    "inReview",
    inReview,
    "hasManualReviewRole",
    hasManualReviewRole
  );

  useEffect(() => {
    dispatch(loadReportingData(assessmentResult.id));
  }, [assessmentResult]);

  const Categories = styled.div`
    margin: 20px;
  `;

  const Category = styled.div`
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
    margin-bottom: 5px;
    border-bottom: 1px solid #ddd;
  `;

  const [resending, setResending] = useState(false);
  const [resendingError, setResendingError] = useState(false);
  const [overrideValue, setOverrideValue] = useState(null);
  const [reason, setReason] = useState("");

  const sendOverride = () => {
    onOverride(reportingData.userAssessment.id, overrideValue, reason);
  };

  const resend = async () => {
    setResending(true);

    try {
      await dispatch(resendContractorQuestions(engagement.id));
      setTimeout(() => setResending(false), 2000);
    } catch (error) {
      setResending(false);
      setResendingError(true);
    }
  };

  const renderCategories = () => {
    if (
      assessmentType !== "FULL" ||
      !reportingData ||
      reportingData.reason ||
      !reportingData.assessmentCategories
    ) {
      return null;
    }

    return (
      <Categories>
        <h3>Result breakdown</h3>
        {reportingData.assessmentCategories
          .filter(
            (category) =>
              !reportingData.containsOverride ||
              (reportingData.containsOverride && category.containsOverride)
          )
          .map((category) => (
            <Category>
              <div>{category.name}</div>
              <div className={getRiskClassName(category.result)}>
                {getDetailDisplayName(category.result)}
              </div>
            </Category>
          ))}
      </Categories>
    );
  };

  const defaultDoughnutOptions = {
    width: 200,
    height: 120,
    options: {
      cutoutPercentage: 70,
      maintainAspectRatio: false,
      responsive: true,
      legend: { display: false },
      rotation: 1 * Math.PI,
      circumference: 1 * Math.PI,
      tooltips: {
        enabled: false,
      },
    },
  };

  const generateColor = (score) => {
    const colors = {
      0: errorStrong,
      0.2: errorScale2,
      0.4: errorScale3,
      0.6: errorScale4,
      0.8: errorScale5,
      1: successStrong,
    };

    let chosenColor;

    for (let x in colors) {
      let color = colors[x];
      if (score >= Number(x)) {
        chosenColor = color;
      }
    }

    return chosenColor;
  };

  const dataForDoughnut = () => {
    const actual =
      1 -
      (reportingData && typeof reportingData.overallScore === "number"
        ? reportingData.overallScore
        : 5) /
        5;

    return {
      labels: ["Give the value"],
      datasets: [
        {
          label: "Total Risk Score",
          data: [actual, 1 - actual],
          backgroundColor: [generateColor(actual), "#eee"],
        },
      ],
    };
  };

  const waitingContractor =
    reportingData && reportingData.reason === "AWAITING_CONTRACTOR";

  return (
    <ResultElement>
      {assessmentType === "SHORT" && <h1>Insurance Validation Result</h1>}
      {assessmentType === "FULL" && <h1>IR35 Assessment Result</h1>}
      {!reportingData || waitingContractor ? (
        <RiskSummary>
          <Summary>
            <div>
              <h4>Overall Result</h4>
              <h2>{waitingContractor ? "Waiting..." : "Loading..."}</h2>
            </div>
            <div>
              <h4>Potential Financial Exposure</h4>
              <h2></h2>
            </div>
            {assessmentType === "FULL" && (
              <ResultGraph>
                <FailLabel>Inside</FailLabel>
                <PassLabel>Outside</PassLabel>
                <DividingLine></DividingLine>
                <Doughnut
                  data={dataForDoughnut()}
                  {...defaultDoughnutOptions}
                  plugins={{ datalabels: { display: false } }}
                />
              </ResultGraph>
            )}
          </Summary>
          <div>
            <div>
              {assessmentType === "FULL" && <RiskScale result={5}></RiskScale>}
              {waitingContractor && (
                <>
                  <p>
                    We're waiting for the responses from the contractor before
                    we're able to provide your score.
                  </p>
                  <p>
                    We sent the questions to:{" "}
                    <strong>{reportingData.email}</strong>
                  </p>
                  <Button
                    loading={resending}
                    onClick={resend}
                    noFullWidth={true}
                  >
                    Resend Email
                  </Button>
                  {resendingError && <span>Error: try again</span>}
                </>
              )}
              {!waitingContractor && (
                <LoadingParas short={assessmentType === "SHORT"}>
                  <FlashingPara />
                  <FlashingPara />
                  <FlashingPara />
                  <FlashingPara smaller />
                </LoadingParas>
              )}
            </div>
          </div>
        </RiskSummary>
      ) : (
        <RiskSummary>
          {!reportingData.reason ? (
            <Summary>
              <div>
                <h4>Overall Result</h4>
                <h2 className={getRiskClassName(assessmentResult.result)}>
                  {getDisplayName(assessmentResult.result, assessmentType)}
                </h2>
              </div>
              <div>
                <h4>Potential Financial Exposure</h4>
                <h2>
                  {engagement && engagement.financial_exposure
                    ? `${
                        engagement &&
                        formatCurrency(engagement.financial_exposure, {
                          format: "%s%v",
                          symbol: "£",
                        })
                      }`
                    : ""}
                </h2>
              </div>
              {assessmentType === "FULL" && (
                <ResultGraph>
                  <FailLabel>Inside</FailLabel>
                  <PassLabel>Outside</PassLabel>
                  <DividingLine></DividingLine>
                  <Doughnut
                    data={dataForDoughnut()}
                    {...defaultDoughnutOptions}
                    plugins={{ datalabels: { display: false } }}
                  />
                </ResultGraph>
              )}
            </Summary>
          ) : (
            <>
              {reportingData.reason === "ALGORITHM" && (
                <AlgorithmWarning>
                  <h3>Report Unavailable</h3>
                  <p>
                    This report was generated using our old algorithm which we
                    no longer support. Unfortunately this means it will need to
                    be repeated. We recommend you delete the assessment and redo
                    it.
                  </p>
                  <p>
                    We apologise for any inconvenience this may cause - this
                    step won't be necessary for future updates.
                  </p>
                </AlgorithmWarning>
              )}
            </>
          )}

          <div>
            {!reportingData.reason ? (
              <div>
                {assessmentType === "FULL" && (
                  <RiskScale
                    className={getRiskClassName(assessmentResult.result)}
                    result={1 - reportingData.overallScore / 5}
                  >
                    {" "}
                    <div className="scale-label">
                      {reportingData.visualResult}
                    </div>
                  </RiskScale>
                )}
                {contractorId === "rolebased" && (
                  <p>
                    <strong>
                      NOTE: This is not a complete assessment and should only be
                      used for indicative purposes before a role has been filled
                    </strong>
                  </p>
                )}
                {reportingData.summaryText
                  ? reportingData.summaryText
                      .split("\n\n")
                      .map((content) => <p>{content}</p>)
                  : ""}
                <Version>
                  Question Set Version: {reportingData.masterAssessment.version}
                </Version>
              </div>
            ) : null}

            {reportingData &&
              reportingData.userAssessment &&
              reportingData.userAssessment.overridden && (
                <>
                  <h2>Result Manually Overridden</h2>
                  <p>
                    This assessment was overridden manually by{" "}
                    <strong>
                      {reportingData.userAssessment.overridden_by}
                    </strong>{" "}
                    for the following reason(s):
                  </p>
                  <p>
                    <i>"{reportingData.userAssessment.override_reason}"</i>
                  </p>
                </>
              )}

            {showOverride && contractorId !== "rolebased" && (
              <OverrideArea>
                <h2>Override Result</h2>
                <p>
                  If you feel the result is incorrect you can override it below
                </p>
                <OverrideButtons>
                  <SplitButton
                    options={[
                      { display: "Inside", value: "BAD_FAIL" },
                      { display: "Outside", value: "GOOD_PASS" },
                    ]}
                    value={overrideValue}
                    onChange={setOverrideValue}
                  />
                </OverrideButtons>
                {overrideValue && (
                  <>
                    <ReasonBox
                      placeholder="Reason for override"
                      onChange={(e) => {
                        setReason(e.target.value);
                      }}
                      value={reason}
                    ></ReasonBox>
                    <Button onClick={() => sendOverride()} noFullWidth={true}>
                      Submit Override
                    </Button>
                  </>
                )}
              </OverrideArea>
            )}

            <Buttons>
              {!reportingData.reason ? (
                <>
                  {!summaryOnly &&
                  (assessmentType === "FULL" ||
                    assessmentResult.result === "GOOD_PASS") ? (
                    <>
                      <Button
                        action
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_BASE_URL}/UserAssessments/generatePDFReport/${assessmentResult.id}?access_token=${userToken}`
                          )
                        }
                      >
                        {assessmentType === "FULL"
                          ? "Download Report"
                          : "Download Report"}
                      </Button>
                      {contractorId !== "rolebased" &&
                        assessmentType === "FULL" && (
                          <Button
                            action
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_BASE_URL}/UserAssessments/generateSDSDoc/${assessmentResult.id}?access_token=${userToken}`
                              )
                            }
                          >
                            Download SDS
                          </Button>
                        )}
                    </>
                  ) : (
                    <div></div>
                  )}
                </>
              ) : null}
              {!summaryOnly &&
                reportingData &&
                reportingData.reason !== "AWAITING_CONTRACTOR" && (
                  <Button
                    action
                    onClick={() => {
                      setReopen(true);
                    }}
                  >
                    Reopen
                  </Button>
                )}
              {!summaryOnly &&
                reportingData &&
                reportingData.reason !== "AWAITING_CONTRACTOR" && (
                  <Button
                    action
                    onClick={() => {
                      setView(true);
                    }}
                  >
                    View Answers
                  </Button>
                )}
              {summaryOnly && reopen && (
                <Button
                  action
                  onClick={() => {
                    setReopen(false);
                  }}
                >
                  Cancel
                </Button>
              )}
              {summaryOnly && view && (
                <Button
                  action
                  onClick={() => {
                    setView(false);
                  }}
                >
                  Close
                </Button>
              )}
            </Buttons>
          </div>
        </RiskSummary>
      )}
      {review &&
        review.id &&
        review.approvals.length > 0 &&
        !reopen &&
        !view && (
          <ReviewerPanel
            review={review}
            contractorId={contractorId}
            assessment={assessmentResult}
          />
        )}
      {(hasReviewFeature || companyType === "end_client") &&
        contractorId !== "rolebased" &&
        reportingData &&
        reportingData.reason !== "AWAITING_CONTRACTOR" &&
        !reopen &&
        !view &&
        assessmentType === "FULL" &&
        (!review ||
          (review && !review.id) ||
          (review && review.status === "CLOSED")) && (
          <DistributePanel
            reload={reload}
            noSDS={noSDS}
            engagement={engagement}
            contractorId={contractorId}
          />
        )}

      {!summaryOnly ? (
        <>{!reportingData ? "" : <div>{renderCategories()}</div>}</>
      ) : null}
    </ResultElement>
  );
}

export default ResultScreen;
