import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { getCompanyId } from "../store/users/selectors";
import { SplitView, ContentArea } from "../components/Elements";
import UsersForCompany from "../components/UsersForCompany";

import Button from "../components/Button";

const TableWrapper = styled.div`
  position: relative;

  &.no-pad {
    padding: 0px 20px;
  }
`;

const TableControls = styled.div`
  display: flex;
  justify-content: flex-start;
`;
const SettingsWrapper = styled.div`
  background-color: #fff;
  margin: 0px 20px 20px 20px;
  border-radius: 10px;
  padding: 20px;
`;

function Users({ history }) {
  const companyId = useSelector(getCompanyId);

  return (
    <div>
      <ContentArea>
        <SettingsWrapper>
          <TableWrapper>
            <h2>Users</h2>
            <TableControls>
              <Button
                action
                onClick={() =>
                  history.push(`/main/settings/users/${companyId}/users/new`)
                }
              >
                New User
              </Button>
            </TableControls>
            <UsersForCompany
              noBg
              companyId={companyId}
              urlStub="/main/settings/users"
              history={history}
            />
          </TableWrapper>
        </SettingsWrapper>
      </ContentArea>
    </div>
  );
}

export default Users;
