import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getClientById } from "../store/clients/selectors";
import styled from "styled-components";
import { loadClients } from "../store/clients/actions";
import {
  getUsersForCompany,
  createUser,
  saveUser,
} from "../store/users/actions";
import {
  getUserById,
  getLoading,
  getRegistrationErrors,
  getHasAdminRole,
  getHasClientAdminRole,
  getHasSuperAdminRole,
  getIsCompanyReviewer,
  getHasCampaignRole,
  getRealm,
  getHasCSTRole,
} from "../store/users/selectors";
import { SplitView, ContentArea, Errors } from "../components/Elements";
import Loader from "../components/Loader";
import CRUDForm from "../components/CRUDForm";
import Form from "../components/Form";
import FormField from "../components/FormField";
import FormRow from "../components/FormRow";
import AssignTags from "../components/AssignTags";

import Button from "../components/Button";

const TagsWrapper = styled.div`
  background-color: #eee;
  padding: 30px 20px;
  margin: 0px 30px 30px 0px;
`;

function User({ match, history, urlStub }) {
  const dispatch = useDispatch();

  const loading = useSelector(getLoading);
  const companyId = match.params.companyId;
  const recordId = match.params.userId;
  const isNew = recordId === "new";
  const [error, setError] = useState(false);
  const isAdmin = useSelector(getHasAdminRole);
  const isCampaign = useSelector(getHasCampaignRole);
  const isClientAdmin = useSelector(getHasClientAdminRole);
  const isSuperAdmin = useSelector(getHasSuperAdminRole);
  const isCST = useSelector(getHasCSTRole);
  const realm = useSelector(getRealm);
  const isCompanyReviewer = useSelector((state) =>
    getIsCompanyReviewer(state, companyId)
  );

  const emptyState = {
    first_name: "",
    last_name: "",
    email: "",
    is_company_admin: false,
    role: "user",
    external_company_name: "",
  };
  const [dataFromForm, setDataFromForm] = useState({});

  const company = useSelector((state) =>
    getClientById(state, Number(companyId))
  );
  const record = useSelector((state) =>
    getUserById(state, Number(recordId), companyId)
  );
  const registrationErrors = useSelector(getRegistrationErrors);

  const [formData, setFormData] = useState(emptyState);

  useEffect(() => {
    dispatch(loadClients());
    if (recordId !== "new" && !record) {
      dispatch(getUsersForCompany(companyId));
    }

    if (record) {
      const recordToUse = record || {};
      setFormData({ ...emptyState, ...recordToUse });
    }
  }, [recordId, record]);

  const sendForm = async (formData) => {
    if (!formData.external_company_name) {
      formData.external_company_name = company.name;
    }

    const redirect = (id) => {
      const path = history.location.pathname;

      if (path.indexOf("/settings/users") > -1) {
        history.push("/main/settings/users");
      } else {
        history.push(path.replace(`/users/${id}`, ""));
      }
    };

    if (isNew && isCST) {
      formData.role_cst = true;
    }

    if (isNew) {
      setError(false);
      try {
        await dispatch(createUser(formData, companyId));
        redirect("new");
      } catch (error) {
        setError(error.data.error);
      }
    } else {
      try {
        const response = await dispatch(saveUser(formData, companyId));

        redirect(response.id);
      } catch (error) {
        setError(error.data.error);
      }
    }
  };

  const displayErrors = () => {
    if (error) {
      switch (error) {
        case "duplicated_user":
          return [
            <li>There's already a user registered with that email address</li>,
          ];
        default:
          return [<li>An error ocurred, please try again</li>];
      }
    }
  };

  return (
    <div>
      {(!isNew && !record) || !company ? (
        <Loader />
      ) : (
        <ContentArea>
          <SplitView>
            <div className="left">
              <CRUDForm
                emptyState={emptyState}
                formData={formData}
                loading={loading}
                recordId={recordId}
                onSubmit={sendForm}
                validationRules={{
                  email: [
                    {
                      canned: "email",
                    },
                  ],
                }}
                updater={(newData) => {
                  setDataFromForm(newData);
                }}
              >
                <Form internal={true}>
                  <h3>User for: {company.name}</h3>
                  <h2>User's Details</h2>

                  <FormRow cols={2}>
                    <FormField
                      name="first_name"
                      label="First Name"
                      type="text"
                    />
                  </FormRow>
                  <FormRow cols={2}>
                    <FormField name="last_name" label="Last Name" type="text" />
                  </FormRow>
                  <FormRow cols={2}>
                    <FormField name="email" label="Email" type="text" />
                  </FormRow>
                  {isNew && !isCST && (
                    <FormRow cols={2}>
                      <FormField
                        name="role"
                        label="Type of user"
                        helpText="A standard user will be able to login and see all of their contractors and assessments. Supply Chain Sign-Off Users will never receive a login but will be available to select as recipients of SDS documents."
                        inputType="radio"
                        options={[
                          { value: "user", name: "Standard User" },
                          { value: "sds", name: "Supply Chain Sign-Off User" },
                        ]}
                      />
                    </FormRow>
                  )}
                  {dataFromForm.role === "sds" && (
                    <>
                      <FormRow cols={2}>
                        <FormField
                          name="external_company_name"
                          label="Company Name"
                          type="text"
                        />
                      </FormRow>
                    </>
                  )}

                  {dataFromForm.role !== "sds" && !isCST && (
                    <>
                      <FormRow cols={2}>
                        {" "}
                        <label>Roles</label>
                      </FormRow>
                      {isAdmin && (
                        <FormRow cols={2}>
                          <FormField
                            name="role_admin"
                            label="System Admin"
                            type="checkbox"
                            inputType="checkbox"
                          />
                        </FormRow>
                      )}

                      <FormRow cols={2}>
                        <FormField
                          name="role_signoff"
                          label="Signoff"
                          type="checkbox"
                          inputType="checkbox"
                        />
                      </FormRow>

                      {(isAdmin || isClientAdmin) && (
                        <FormRow cols={2}>
                          <FormField
                            name="role_client_admin"
                            label="Client Admin"
                            type="checkbox"
                            inputType="checkbox"
                          />
                        </FormRow>
                      )}

                      {(isCompanyReviewer || isSuperAdmin) && !isCST && (
                        <FormRow cols={2}>
                          <FormField
                            name="role_manual_reviewer"
                            label="Manual Reviewer"
                            type="checkbox"
                            inputType="checkbox"
                          />
                        </FormRow>
                      )}
                      {(isAdmin || isClientAdmin) && (
                        <FormRow cols={2}>
                          <FormField
                            name="role_compliance"
                            label="Compliance"
                            type="checkbox"
                            inputType="checkbox"
                          />
                        </FormRow>
                      )}

                      {(isAdmin || isCampaign) && (
                        <FormRow cols={2}>
                          <FormField
                            name="role_campaign"
                            label="Campaign"
                            type="checkbox"
                            inputType="checkbox"
                          />
                        </FormRow>
                      )}

                      {(isAdmin || isSuperAdmin) &&
                        realm === "ir35/kingsbridge" && (
                          <FormRow cols={2}>
                            <FormField
                              name="role_cst"
                              label="Customer Service Team (Kingsbridge)"
                              type="checkbox"
                              inputType="checkbox"
                            />
                          </FormRow>
                        )}
                    </>
                  )}
                  {error ? (
                    <FormRow cols={1}>
                      <Errors>
                        <h5>The following errors occurred</h5>
                        <ul>{displayErrors()}</ul>
                      </Errors>
                    </FormRow>
                  ) : null}
                  <FormRow cols={2}>
                    <Button noFullWidth={true}>
                      {isNew ? "Create User" : "Save"}
                    </Button>
                  </FormRow>
                </Form>
              </CRUDForm>
            </div>
            <div className="right">
              {record && !isCST && (
                <TagsWrapper>
                  <h2>User Tags</h2>
                  <AssignTags
                    companyId={record.company_id}
                    currentCompanyId={dataFromForm.company_id}
                    entityId={record.id}
                    entityType="user"
                  />
                </TagsWrapper>
              )}
            </div>
          </SplitView>
        </ContentArea>
      )}
    </div>
  );
}

export default User;
