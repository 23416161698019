import React from "react";
import styled from "styled-components";
import {
  Products,
  Product,
  ProductButton,
  SubHeading,
} from "../pages/ContractorMainAssessmentResult";

function CrunchUpSells({ setSelectedProduct, freeVersion }) {
  return (
    <>
      <SubHeading>Get extra support</SubHeading>
      <Products>
        <Product>
          <h3>Crunch - Accounting service</h3>
          <p>
            Our Plus package offers a unique blend of solutions. Our limited
            company Plus package includes IR35 payroll to make the correct
            employment tax deductions, or to deal with receiving a net payment
            (tax and NI already deducted) from agencies or end clients. It also
            comes complete with a detailed IR35 online assessment, report and
            contract review, dedicated IR35 support from our team of experts,
            and even allows you to switch between limited company and umbrella
            payroll, depending on your assignment.
          </p>
          <ProductButton onClick={() => setSelectedProduct("ACCOUNTING")}>
            Get started
          </ProductButton>
        </Product>
        <Product>
          <h3>Crunch - Umbrella service</h3>
          <p>
            If your assignment is inside IR35 and you’ve been asked to work
            through an Umbrella company then Crunch’s Umbrella service is
            perfect for you. We offer dedicated payroll support, weekly or
            monthly payroll runs, comprehensive employee insurance cover, and
            we’ll produce all your payslips, make all the necessary deductions
            and take care of HMRC for you.
          </p>
          <ProductButton onClick={() => setSelectedProduct("UMBRELLA")}>
            Get started
          </ProductButton>
        </Product>
      </Products>
      <Products>
        <Product>
          <h3>Insurance</h3>
          <div className="price">
            <span className="vat">From</span>
            <div className="price-component">74</div>
            <span className="vat">+VAT</span>
          </div>
          <p>
            Our Legal Expenses insurance covers a range of personal and
            commercial situations, personal consumer disputes, personal injury,
            and any costs you may incur when initially responding to HMRC tax
            investigations and IR35 status enquiries. From £74.50 per annum.
          </p>
          <ProductButton onClick={() => setSelectedProduct("INSURANCE")}>
            Get started
          </ProductButton>
        </Product>
        {!freeVersion && (
          <Product>
            <h3>IR35 Contract Review</h3>
            <div className="price">
              <span className="vat">From</span>
              <div className="price-component no-after">90</div>
              <span className="vat">+VAT</span>
            </div>
            <p>
              For the ultimate peace of mind, you can get an IR35 contract
              review from one of our expert accountants. We’ll go through the
              clauses in your contract and look at your actual working practices
              to give you absolute confidence about whether the assignment is
              ‘inside’ or ‘outside’ IR35. Prices start from £90 +VAT.
            </p>
            <ProductButton onClick={() => setSelectedProduct("REVIEW")}>
              Get started
            </ProductButton>
          </Product>
        )}
      </Products>
    </>
  );
}

export default CrunchUpSells;
