import React, { useContext } from "react";
import styled from "styled-components";
import {
  contrastColor,
  errorStrong,
  errorStronger,
  mainColor,
  reviewDark,
  distributeDark,
  appealDark,
} from "../styles/colors";
import { ColorContext } from "./Bootstrap";

const SplitButtonElement = styled.div`
  display: inline-flex;
  ${(props) => `border: 1px solid ${props.mainColor};`}
  border-radius: 2px;
  ${(props) => (props.disabled ? "opacity: 0.6" : "opacity: 1")}
`;

const SplitButtonClickSpot = styled.div`
  padding: 10px;
  background-color: #fff;
  color: #000;
  text-align: center;

  ${(props) => `border-right: 1px solid ${props.mainColor};`}

  &:last-child {
    border-right: none;
  }

  &.selected {
    color: #fff;
    ${(props) => `background-color: ${props.mainColor};`}
  }
`;

function SplitButton(props) {
  const { options, onChange, value, disabled } = props;
  const mainColor = useContext(ColorContext);

  return (
    <SplitButtonElement mainColor={mainColor} disabled={disabled}>
      {options.map((option) => (
        <SplitButtonClickSpot
          mainColor={mainColor}
          onClick={() => {
            if (!disabled) {
              onChange(option.value);
            }
          }}
          className={value === option.value ? "selected" : ""}
        >
          {option.display}
        </SplitButtonClickSpot>
      ))}
    </SplitButtonElement>
  );
}

SplitButton.defaultProps = {
  componentType: "SplitButton",
};

export default SplitButton;
