import { fetch } from "../fetchMiddleware";

export const LOAD_CLIENTS_REQUEST = "LOAD_CLIENTS_REQUEST";
export const LOAD_CLIENTS_SUCCESS = "LOAD_CLIENTS_SUCCESS";
export const LOAD_CLIENTS_FAILURE = "LOAD_CLIENTS_FAILURE";

export const loadClients =
  (allFields = false, force = false) =>
  async (dispatch, getState) => {
    dispatch({ type: LOAD_CLIENTS_REQUEST });

    const currentValues = getState().clients.list;

    if (currentValues && currentValues.length && !force) {
      dispatch(
        await dispatch({
          type: LOAD_CLIENTS_SUCCESS,
          payload: currentValues,
        })
      );
      return currentValues;
    }

    try {
      const { data: response } = await dispatch(
        fetch(
          `/Companies/child-companies${allFields ? "?allFields=true" : ""}`,
          {
            method: "GET",
          }
        )
      );
      await dispatch({
        type: LOAD_CLIENTS_SUCCESS,
        payload: response,
      });
      return response;
    } catch (error) {
      await dispatch({
        type: LOAD_CLIENTS_FAILURE,
        payload: error && error.response && error.response.data,
      });
    }
  };

export const CREATE_CLIENT_REQUEST = "CREATE_CLIENT_REQUEST";
export const CREATE_CLIENT_SUCCESS = "CREATE_CLIENT_SUCCESS";
export const CREATE_CLIENT_FAILURE = "CREATE_CLIENT_FAILURE";

export const createClient = (data) => async (dispatch) => {
  dispatch({ type: CREATE_CLIENT_REQUEST });
  try {
    const { data: response } = await dispatch(
      fetch(`/Companies/create-child-company`, {
        method: "POST",
        data,
      })
    );
    await dispatch({
      type: CREATE_CLIENT_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: CREATE_CLIENT_FAILURE,
      payload: error && error.response && error.response.data,
    });
  }
};

export const SAVE_CLIENT_REQUEST = "SAVE_CLIENT_REQUEST";
export const SAVE_CLIENT_SUCCESS = "SAVE_CLIENT_SUCCESS";
export const SAVE_CLIENT_FAILURE = "SAVE_CLIENT_FAILURE";

export const saveClient = (data) => async (dispatch) => {
  dispatch({ type: SAVE_CLIENT_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Companies/save-child-company`, {
        method: "PUT",
        data,
      })
    );
    await dispatch({
      type: SAVE_CLIENT_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: SAVE_CLIENT_FAILURE,
      payload: error && error.response && error.response.data,
    });
  }
};

export const SET_CURRENT_CLIENT = "SET_CURRENT_CLIENT";

export const setCurrentClient = (id) => (dispatch) => {
  dispatch({
    type: SET_CURRENT_CLIENT,
    id,
  });
};

export const GET_MY_CREDITS_REQUEST = "GET_MY_CREDITS_REQUEST";
export const GET_MY_CREDITS_SUCCESS = "GET_MY_CREDITS_SUCCESS";
export const GET_MY_CREDITS_FAILURE = "GET_MY_CREDITS_FAILURE";

export const loadMyCredits = () => async (dispatch) => {
  dispatch({ type: GET_MY_CREDITS_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/credittransactions/get-credit-history`, {
        method: "GET",
      })
    );
    await dispatch({
      type: GET_MY_CREDITS_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: GET_MY_CREDITS_FAILURE,
      payload: error,
    });
  }
};

export const GET_COMPANY_CREDITS_REQUEST = "GET_COMPANY_CREDITS_REQUEST";
export const GET_COMPANY_CREDITS_SUCCESS = "GET_COMPANY_CREDITS_SUCCESS";
export const GET_COMPANY_CREDITS_FAILURE = "GET_COMPANY_CREDITS_FAILURE";

export const loadCreditsByCompany = (companyId) => async (dispatch) => {
  dispatch({ type: GET_COMPANY_CREDITS_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/credittransactions/get-credit-history-by-company/${companyId}`, {
        method: "GET",
      })
    );
    await dispatch({
      type: GET_COMPANY_CREDITS_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: GET_COMPANY_CREDITS_FAILURE,
      payload: error,
    });
  }
};

export const ADD_CREDIT_REQUEST = "ADD_CREDIT_REQUEST";
export const ADD_CREDIT_SUCCESS = "ADD_CREDIT_SUCCESS";
export const ADD_CREDIT_FAILURE = "ADD_CREDIT_FAILURE";

export const addCredit =
  (companyId, amount, price_per_credit) => async (dispatch) => {
    dispatch({ type: ADD_CREDIT_REQUEST });

    try {
      const { data: response } = await dispatch(
        fetch(`/credittransactions/add-credit`, {
          method: "POST",
          data: {
            companyId,
            amount,
            price_per_credit,
          },
        })
      );
      await dispatch({
        type: ADD_CREDIT_SUCCESS,
        payload: response,
        companyId,
        amount,
      });
      return response;
    } catch (error) {
      await dispatch({
        type: ADD_CREDIT_FAILURE,
        payload: error,
      });
    }
  };

export const CHANGE_CLIENTS_PAGE = "CHANGE_CLIENTS_PAGE";

export const setClientsPage = (page, perPage) => (dispatch) => {
  dispatch({
    type: CHANGE_CLIENTS_PAGE,
    page,
    perPage,
  });
};

export const INCLUDES_VALIDATION_TOOL_REQUEST =
  "INCLUDES_VALIDATION_TOOL_REQUEST";
export const INCLUDES_VALIDATION_TOOL_SUCCESS =
  "INCLUDES_VALIDATION_TOOL_SUCCESS";
export const INCLUDES_VALIDATION_TOOL_FAILURE =
  "INCLUDES_VALIDATION_TOOL_FAILURE";

export const loadIncludesValidationTool = (companyId) => async (dispatch) => {
  dispatch({
    type: INCLUDES_VALIDATION_TOOL_REQUEST,
  });

  try {
    const { data: response } = await dispatch(
      fetch(`/Companies/includes-validation-tool/${companyId}`, {
        method: "GET",
      })
    );

    dispatch({
      type: INCLUDES_VALIDATION_TOOL_SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: INCLUDES_VALIDATION_TOOL_FAILURE,
      payload: error,
    });
  }
};
export const HAS_MANDATORY_SIGNOFF_REQUEST = "HAS_MANDATORY_SIGNOFF_REQUEST";
export const HAS_MANDATORY_SIGNOFF_SUCCESS = "HAS_MANDATORY_SIGNOFF_SUCCESS";
export const HAS_MANDATORY_SIGNOFF_FAILURE = "HAS_MANDATORY_SIGNOFF_FAILURE";

export const loadHasMandatorySignoff = (companyId) => async (dispatch) => {
  dispatch({
    type: HAS_MANDATORY_SIGNOFF_REQUEST,
  });
  try {
    const { data: response } = await dispatch(
      fetch(`/Companies/has-mandatory-signoff/${companyId}`, {
        method: "GET",
      })
    );

    dispatch({
      type: HAS_MANDATORY_SIGNOFF_SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: HAS_MANDATORY_SIGNOFF_FAILURE,
      payload: error,
    });
  }
};

export const HAS_INSURED_WITH_KB_REQUEST = "HAS_INSURED_WITH_KB_REQUEST";
export const HAS_INSURED_WITH_KB_SUCCESS = "HAS_INSURED_WITH_KB_SUCCESS";
export const HAS_INSURED_WITH_KB_FAILURE = "HAS_INSURED_WITH_KB_FAILURE";

export const loadHasInsuredWithKB = (companyId) => async (dispatch) => {
  dispatch({
    type: HAS_INSURED_WITH_KB_REQUEST,
  });
  try {
    const { data: response } = await dispatch(
      fetch(`/Companies/includes-insured-with-kb/${companyId}`, {
        method: "GET",
      })
    );

    dispatch({
      type: HAS_INSURED_WITH_KB_SUCCESS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: HAS_INSURED_WITH_KB_FAILURE,
      payload: error,
    });
  }
};
