import React from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import formatCurrency from "format-currency";
import { StyledDataTable, darkTheme } from "../components/TableStyles";
import { getDisplayName } from "./ResultScreen";
import { getHasFeature } from "../store/users/selectors";
import Button from "./Button";
import { confirmAlert } from "react-confirm-alert";
import { saveEngagement } from "../store/assessment/actions";

const theme = {
  ...darkTheme,
  rows: {
    hoverBackgroundColor: "#ddd",
    fontSize: "16px",
  },
};

const TableWrapper = styled.div`
  ${(props) => (props.level > 0 ? `margin-left: 47px;` : "")}
  ${(props) =>
    props.level > 0 ? `border-left: 1px solid #ddd;padding-left: 20px;` : ""}
`;

function EngagementTable(props) {
  const { history, data, hideStatus, pagination, companyId, reload } = props;
  const dispatch = useDispatch();

  const hideRate = useSelector((state) => getHasFeature(state, "hide_rate"));

  const dataToRender = data.length
    ? data.filter((dataItem) => !dataItem.parent)
    : [];

  //Arrange the data hierarchically

  const arrangeData = (line) => {
    line.forEach((lineItem) => {
      lineItem.children = arrangeData(
        data.filter((item) => Number(item.parent) === Number(lineItem.id))
      );
    });

    return line;
  };

  const finalData = arrangeData(dataToRender);

  const columns = [
    {
      name: "Potential Liability",
      cell: (row) =>
        formatCurrency(row.financial_exposure, {
          format: "%s%v",
          symbol: "£",
        }),
    },
    {
      name: "Result",
      cell: (row) =>
        row.assessment
          ? getDisplayName(
              row.assessment.result,
              row.validation ? "SHORT" : "FULL"
            )
          : "Incomplete",
    },
    {
      name: "Assessment Type",
      cell: (row) => {
        if (row.validation) {
          return "Validation";
        } else {
          return "Decision";
        }
      },
    },
    {
      name: "",
      cell: (row) => {
        if (
          !row.assessment ||
          row.assessment.status === "AWAITING_CONTRACTOR"
        ) {
          const options = {
            title: "Are you sure?",
            message: [
              <p>
                This action cannot be reversed, are you sure you want to delete?
              </p>,
            ],
            buttons: [
              {
                label: "Cancel",
              },
              {
                label: "Confirm",
                onClick: async () => {
                  await dispatch(
                    saveEngagement({
                      ...row,
                      hidden: true,
                    })
                  );

                  if (reload) {
                    reload();
                  }
                },
              },
            ],
          };
          return (
            <Button
              type="danger"
              size="reduced"
              noFullWidth
              onClick={() => {
                confirmAlert(options);
              }}
            >
              Delete
            </Button>
          );
        }
      },
    },
  ];

  if (!hideRate) {
    columns.unshift({
      name: "Rate",
      cell: (row) =>
        formatCurrency(row.day_rate, {
          format: "%s%v",
          symbol: "£",
        }),
    });
  }

  const AssessmentTable = (args) => {
    let engagements = args.data.children;

    return (
      <TableWrapper level={args.level}>
        <StyledDataTable
          noHeader
          pagination={args.level === 0 && pagination}
          customTheme={theme}
          columns={columns}
          data={engagements}
          fixedHeader
          highlightOnHover
          fixedHeaderScrollHeight="100%"
          onRowClicked={handleRowClicked}
          expandableRows={engagements && engagements.length}
          expandableRowsComponent={
            engagements && <AssessmentTable level={args.level + 1} />
          }
        />
      </TableWrapper>
    );
  };

  const handleRowClicked = (row) =>
    history.push(
      `/main/contractors/assessment/${companyId}/${row.contractor_id}/${row.id}`
    );

  return <AssessmentTable data={{ children: finalData }} level={0} />;
}

export default EngagementTable;
