import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Comments from "../Comments";
import { device } from "../Devices";
import Question from "./Question";
import { getValidations } from "../../store/assessment/selectors";

function Questions({
  questions,
  target,
  isReviewer,
  reviewId,
  assessmentResult,
  dissemination,
  view,
  additional,
}) {
  const validations = useSelector(getValidations);

  return (
    <div>
      {questions.map((question) => (
        <Question
          assessmentResult={assessmentResult}
          isReviewer={isReviewer}
          reviewId={reviewId}
          target={target}
          question={question}
          invalid={validations.indexOf(question.id) > -1}
          dissemination={dissemination}
          view={view}
          additionalQuestions={additional}
        />
      ))}
    </div>
  );
}

export default Questions;
