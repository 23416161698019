import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bootstrap } from "../store/users/actions";
import { isBoostrapped, getMainColor } from "../store/users/selectors";
import Loader from "./Loader";

export const ColorContext = React.createContext("");
export const ColorProvider = ColorContext.Provider;
export const ColorConsumer = ColorContext.Consumer;

function Bootstrap({ children }) {
  const dispatch = useDispatch();
  const bootstrapped = useSelector(isBoostrapped);
  const mainColor = useSelector(getMainColor);
  console.log("MC", mainColor);
  useEffect(() => {
    dispatch(bootstrap());
  }, []);
  return bootstrapped ? (
    <ColorProvider value={mainColor || "#1a6eff"}>{children}</ColorProvider>
  ) : (
    <Loader />
  );
}

export default Bootstrap;
