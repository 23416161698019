import { fetch } from "../fetchMiddleware";

import { loadContractor } from "../contractors/actions";

export const LOAD_SDS_DOCUMENT_REQUEST = "LOAD_SDS_DOCUMENT_REQUEST";
export const LOAD_SDS_DOCUMENT_SUCCESS = "LOAD_SDS_DOCUMENT_SUCCESS";
export const LOAD_SDS_DOCUMENT_FAILURE = "LOAD_SDS_DOCUMENT_FAILURE";

export const loadSDSDocument = (token) => async (dispatch) => {
  dispatch({ type: LOAD_SDS_DOCUMENT_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Distributees/retrieve-acceptance/${token}`, {
        method: "GET",
      })
    );
    await dispatch({
      type: LOAD_SDS_DOCUMENT_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: LOAD_SDS_DOCUMENT_FAILURE,
      payload: error,
    });
  }
};

export const ADD_DISTRIBUTEES_REQUEST = "ADD_DISTRIBUTEES_REQUEST";
export const ADD_DISTRIBUTEES_SUCCESS = "ADD_DISTRIBUTEES_SUCCESS";
export const ADD_DISTRIBUTEES_FAILURE = "ADD_DISTRIBUTEES_FAILURE";

export const addDistributees = (distributees) => async (dispatch) => {
  dispatch({
    type: ADD_DISTRIBUTEES_REQUEST,
  });

  try {
    let promises = distributees.map(async (distributee) => {
      let add;
      if (distributee.id) {
        add = await dispatch(
          fetch(`/Distributees/${distributee.id}`, {
            method: "PATCH",
            data: distributee,
          })
        );
      } else {
        delete distributee.id;
        add = await dispatch(
          fetch(`/Distributees`, {
            method: "POST",
            data: distributee,
          })
        );
      }

      return add;
    });

    await Promise.all(promises);

    dispatch({
      type: ADD_DISTRIBUTEES_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: ADD_DISTRIBUTEES_FAILURE,
      payload: error,
    });
  }
};

export const REMOVE_DISTRIBUTEE_REQUEST = "REMOVE_DISTRIBUTEE_REQUEST";
export const REMOVE_DISTRIBUTEE_SUCCESS = "REMOVE_DISTRIBUTEE_SUCCESS";
export const REMOVE_DISTRIBUTEE_FAILURE = "REMOVE_DISTRIBUTEE_FAILURE";

export const removeDistributees = (ids) => async (dispatch) => {
  dispatch({
    type: REMOVE_DISTRIBUTEE_REQUEST,
  });

  try {
    let promises = ids.map(async (id) => {
      let del = await dispatch(
        fetch(`/Distributees/${id}`, {
          method: "DELETE",
        })
      );
      return del;
    });

    await Promise.all(promises);

    dispatch({
      type: REMOVE_DISTRIBUTEE_SUCCESS,
    });
  } catch (error) {
    dispatch({
      type: REMOVE_DISTRIBUTEE_FAILURE,
      payload: error,
    });
  }
};

export const addAndRemoveDistributees = (add, remove, contractorId) => async (
  dispatch
) => {
  await dispatch(addDistributees(add));
  await dispatch(removeDistributees(remove));

  dispatch(loadContractor(contractorId));
};

export const ACCEPT_SDS_DOCUMENT_REQUEST = "ACCEPT_SDS_DOCUMENT_REQUEST";
export const ACCEPT_SDS_DOCUMENT_SUCCESS = "ACCEPT_SDS_DOCUMENT_SUCCESS";
export const ACCEPT_SDS_DOCUMENT_FAILURE = "ACCEPT_SDS_DOCUMENT_FAILURE";

export const confirmAcceptance = (token, signatory) => async (dispatch) => {
  dispatch({ type: ACCEPT_SDS_DOCUMENT_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Distributees/confirm-acceptance/${token}`, {
        method: "POST",
        data: { signatory },
      })
    );
    await dispatch({
      type: ACCEPT_SDS_DOCUMENT_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: ACCEPT_SDS_DOCUMENT_FAILURE,
      payload: error,
    });
  }
};

export const resendSDSNotification = (id) => async (dispatch) => {
  try {
    const { data: response } = await dispatch(
      fetch(`/Distributees/resend-notification/${id}`, {
        method: "GET",
      })
    );
    return response;
  } catch (error) {
    return { failed: true };
  }
};

export const OPEN_DISPUTE_REQUEST = "OPEN_DISPUTE_REQUEST";
export const OPEN_DISPUTE_SUCCESS = "OPEN_DISPUTE_SUCCESS";
export const OPEN_DISPUTE_FAILURE = "OPEN_DISPUTE_FAILURE";

export const openDispute = (publicId, reason) => async (dispatch) => {
  dispatch({ type: OPEN_DISPUTE_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Distributees/open-dispute`, {
        method: "POST",
        data: {
          publicId,
          reason,
        },
      })
    );
    await dispatch({
      type: OPEN_DISPUTE_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: OPEN_DISPUTE_FAILURE,
      payload: error,
    });
  }
};

export const CLOSE_DISPUTE_REQUEST = "CLOSE_DISPUTE_REQUEST";
export const CLOSE_DISPUTE_SUCCESS = "CLOSE_DISPUTE_SUCCESS";
export const CLOSE_DISPUTE_FAILURE = "CLOSE_DISPUTE_FAILURE";

export const closeDispute = (publicId, response) => async (dispatch) => {
  dispatch({ type: CLOSE_DISPUTE_REQUEST });

  try {
    const { data: responseFromServer } = await dispatch(
      fetch(`/Distributees/close-dispute`, {
        method: "POST",
        data: {
          publicId,
          response,
        },
      })
    );
    await dispatch({
      type: CLOSE_DISPUTE_SUCCESS,
      payload: response,
    });
    return responseFromServer;
  } catch (error) {
    await dispatch({
      type: CLOSE_DISPUTE_FAILURE,
      payload: error,
    });
  }
};
