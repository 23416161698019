import { get, getOr } from "lodash/fp";

export const getCampaignError = (state) => {
  return get("campaigns.error", state);
};

export const getCampaignLoading = (state) => {
  return get("campaigns.loading", state);
};

export const getCampaigns = (state) => {
  return getOr([], "campaigns.list", state);
};
export const getCampaignData = (state) => {
  return getOr({}, "campaigns.campaignData", state);
};

export const getCampaign = (id, state) => {
  const campaigns = getCampaigns(state);

  return campaigns.find((campaign) => Number(campaign.id) === Number(id));
};

export const getRAFOffer = (state) => {
  return getOr({}, "campaigns.raf", state);
};
export const getUpdaters = (state) => {
  return getOr([], "campaigns.updaters", state);
};
