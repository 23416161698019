import { fetch } from "../fetchMiddleware";

export const RESETPASSWORD_REQUEST = "RESETPASSWORD_REQUEST";
export const RESETPASSWORD_COMPLETE = "RESTPASSWORD_COMPLETE";
export const RESETPASSWORD_FAILURE = "RESETPASSWORD_FAILURE";

export const startResetPassword = (email) => async (dispatch) => {
  dispatch({ type: RESETPASSWORD_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/AppUsers/reset-password-by-realm`, {
        method: "POST",
        data: {
          email,
        },
      })
    );
    await dispatch({
      type: RESETPASSWORD_COMPLETE,
      payload: response,
    });
  } catch (e) {
    await dispatch({
      type: RESETPASSWORD_FAILURE,
      payload: e,
    });
  }
};

export const CHANGEPASSWORD_REQUEST = "CHANGEPASSWORD_REQUEST";
export const CHANGEPASSWORD_SUCCESS = "CHANGEPASSWORD_SUCCESS";
export const CHANGEPASSWORD_FAILURE = "CHANGEPASSWORD_FAILURE";

export const changePassword =
  (newPassword, token, realm) => async (dispatch) => {
    dispatch({ type: CHANGEPASSWORD_REQUEST });

    try {
      const { data: response } = await dispatch(
        fetch(`/Appusers/reset-password`, {
          method: "POST",
          data: {
            newPassword,
            realm,
          },
          headers: {
            Authorization: token,
          },
        })
      );
      await dispatch({
        type: CHANGEPASSWORD_SUCCESS,
        payload: response,
      });
    } catch (error) {
      await dispatch({
        type: CHANGEPASSWORD_FAILURE,
        payload: error,
      });
      return error;
    }
  };

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const login = (username, password, realm) => async (dispatch) => {
  dispatch({ type: LOGIN_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Appusers/login`, {
        method: "POST",
        data: {
          username,
          password,
          realm,
        },
      })
    );
    await dispatch(completeLogin(response));
    dispatch(getMe());
  } catch (error) {
    await dispatch({
      type: LOGIN_FAILURE,
      payload: error,
    });
  }
};

export const completeLogin = (data) => (dispatch) => {
  sessionStorage.setItem("user", JSON.stringify(data));

  dispatch({
    type: LOGIN_SUCCESS,
    payload: data,
  });
};

export const getMe = () => async (dispatch) => {
  const currentUserInStorage = JSON.parse(sessionStorage.getItem("user"));
  const { data: response } = await dispatch(
    fetch(`/Appusers/getMe`, {
      method: "GET",
    })
  );

  localStorage.setItem("orgid", response.company.id);

  currentUserInStorage.userData = response;
  dispatch(completeLogin(currentUserInStorage));
};

export const acceptCurrentTerms = () => async (dispatch) => {
  const { data: response } = await dispatch(
    fetch(`/Appusers/accept-terms`, {
      method: "GET",
    })
  );

  return response;
};

export const BOOTSTRAP_COMPLETE = "BOOTSTRAP_COMPLETE";

export const bootstrap = () => async (dispatch) => {
  await dispatch(getBranding());
  await dispatch(restoreUser());
  dispatch({
    type: BOOTSTRAP_COMPLETE,
  });
};

export const BRANDING_SUCCESS = "BRANDING_SUCCESS";

export const getBranding = () => async (dispatch) => {
  let orgId = localStorage.getItem("orgid");
  let subdomain = window.location.host.split(".")[0];

  if (subdomain !== "portal") {
    orgId = null;
  }

  if (subdomain === "192") {
    subdomain = "192.168.0.85:3001";
  }

  const { data: response } = await dispatch(
    fetch(`/Companies/get-branding/${subdomain}/${orgId}`, {
      method: "GET",
    })
  );

  await dispatch({
    type: BRANDING_SUCCESS,
    payload: response,
  });
};

export const restoreUser = () => async (dispatch) => {
  let storedUser = sessionStorage.getItem("user");
  if (storedUser) {
    storedUser = JSON.parse(storedUser);
    dispatch({
      type: LOGIN_SUCCESS,
      payload: storedUser,
    });
    dispatch(getMe());
  }
};

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const logout = () => async (dispatch) => {
  dispatch({ type: LOGOUT_REQUEST });

  try {
    await dispatch(
      fetch(`/Appusers/logout`, {
        method: "POST",
      })
    );
    await dispatch({
      type: LOGOUT_SUCCESS,
    });
    sessionStorage.clear();
  } catch (error) {
    await dispatch({
      type: LOGOUT_FAILURE,
      payload: error,
    });
  }
};

export const logoutWithoutRequest = () => async (dispatch) => {
  dispatch({
    type: LOGOUT_SUCCESS,
  });
  sessionStorage.clear();
};

export const GET_USERS_FOR_COMPANY_REQUEST = "GET_USERS_FOR_COMPANY_REQUEST";
export const GET_USERS_FOR_COMPANY_SUCCESS = "GET_USERS_FOR_COMPANY_SUCCESS";
export const GET_USERS_FOR_COMPANY_FAILURE = "GET_USERS_FOR_COMPANY_FAILURE";

export const getUsersForCompany =
  (companyId, role = "user", writeIntoState = true) =>
  async (dispatch) => {
    if (writeIntoState) {
      dispatch({ type: GET_USERS_FOR_COMPANY_REQUEST });
    }

    try {
      const { data: response } = await dispatch(
        fetch(`/Appusers/company-users/${companyId}/${role}`, {
          method: "GET",
        })
      );
      if (writeIntoState) {
        await dispatch({
          type: GET_USERS_FOR_COMPANY_SUCCESS,
          payload: response,
          companyId,
        });
      }

      return response;
    } catch (error) {
      await dispatch({
        type: GET_USERS_FOR_COMPANY_FAILURE,
        payload: error.response.data,
      });
    }
  };

export const CREATE_USER_REQUEST = "CREATE_USER_REQUEST";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";

export const createUser = (data, companyId) => async (dispatch) => {
  dispatch({ type: CREATE_USER_REQUEST });

  data.company_id = companyId;
  data.username = data.email;

  try {
    const { data: response } = await dispatch(
      fetch(`/AppUsers/create-user`, {
        method: "POST",
        data,
      })
    );
    await dispatch({
      type: CREATE_USER_SUCCESS,
      payload: response,
      companyId,
    });
    return response;
  } catch (response) {
    await dispatch({
      type: CREATE_USER_FAILURE,
      payload: response.error,
    });
    throw {
      ...response,
      error: new Error(),
    };
  }
};

export const SAVE_USER_REQUEST = "SAVE_USER_REQUEST";
export const SAVE_USER_SUCCESS = "SAVE_USER_SUCCESS";
export const SAVE_USER_FAILURE = "SAVE_USER_FAILURE";

export const saveUser = (data, companyId) => async (dispatch) => {
  dispatch({ type: SAVE_USER_REQUEST });

  data.username = data.email;

  try {
    const { data: response } = await dispatch(
      fetch(`/AppUsers/update-user/${data.id}`, {
        method: "PUT",
        data,
      })
    );

    await dispatch({
      type: SAVE_USER_SUCCESS,
      payload: response,
      companyId,
    });
    return response;
  } catch (response) {
    await dispatch({
      type: SAVE_USER_FAILURE,
      payload: response.error,
    });
    throw {
      ...response,
      error: new Error(),
    };
  }
};

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const deleteUser = (id, companyId) => async (dispatch) => {
  dispatch({ type: DELETE_USER_REQUEST });

  try {
    await dispatch(
      fetch(`/AppUsers/delete-user/${id}`, {
        method: "DELETE",
      })
    );
    await dispatch({
      type: DELETE_USER_SUCCESS,
      payload: {
        id,
      },
      companyId,
    });
  } catch (response) {
    await dispatch({
      type: DELETE_USER_FAILURE,
      payload: response.error,
    });
    throw {
      ...response,
      error: new Error(),
    };
  }
};

export const UPLOAD_BULK_FILE_REQUEST = "UPLOAD_BULK_FILE_REQUEST";
export const UPLOAD_BULK_FILE_SUCCESS = "UPLOAD_BULK_FILE_SUCCESS";
export const UPLOAD_BULK_FILE_FAILURE = "UPLOAD_BULK_FILE_FAILURE";

export const uploadBulkFile =
  (data, validate = true, overwriteDuplicates = false) =>
  async (dispatch) => {
    dispatch({ type: UPLOAD_BULK_FILE_REQUEST });

    try {
      const { data: response } = await dispatch(
        fetch(
          `/Contractors/upload-csv?validate=${validate}&overwriteDuplicates=${overwriteDuplicates}`,
          {
            method: "POST",
            data,
          }
        )
      );
      await dispatch({
        type: UPLOAD_BULK_FILE_SUCCESS,
      });
      return response;
    } catch (response) {
      dispatch({
        type: UPLOAD_BULK_FILE_FAILURE,
      });
      throw {
        ...response,
        error: new Error(),
      };
    }
  };

export const SET_USER_SEARCH = "SET_USER_SEARCH";

export const setSearchTerm = (searchTerm) => async (dispatch) => {
  dispatch({
    type: SET_USER_SEARCH,
    payload: {
      searchTerm,
    },
  });
};

export const SET_USER_TYPE = "SET_USER_TYPE";

export const setUserType = (userType) => async (dispatch) => {
  dispatch({
    type: SET_USER_TYPE,
    payload: {
      userType,
    },
  });
};

export const resetPasswordRemote = (id) => async (dispatch) => {
  try {
    const { data: response } = await dispatch(
      fetch(`/AppUsers/remote-reset/${id}`, {
        method: "GET",
      })
    );
    return response;
  } catch (response) {
    throw {
      ...response,
      error: new Error(),
    };
  }
};

export const swapReviews = (from, to) => async (dispatch) => {
  const { data: response } = await dispatch(
    fetch(`/AppUsers/transfer-user-reviews/${from}/${to}`, {
      method: "GET",
    })
  );

  return response;
};
