import React, { useContext, useEffect, useState } from "react";
import { get } from "lodash/fp";
import { useDispatch, useSelector } from "react-redux";
import { injectStripe } from "react-stripe-elements";
import styled from "styled-components";
import Modal from "react-modal";
import Header from "../components/Header";
import { H1, Content } from "../components/Elements";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import {
  contractorLoadResult,
  contractorBuyReport,
  clearLeadFlags,
  contractorConfirmPayment,
  contractorBuySubscription,
  contractorConfirmSubscriptionPayment,
} from "../store/assessment/actions";
import {
  getSubmissionError,
  getLoading,
  getContractorResult,
  getPurchaseFailure,
  getPurchaseLoading,
} from "../store/assessment/selectors";
import { completeLogin } from "../store/users/actions";
import {
  getShowStandalonePreview,
  getStandaloneSubscriptionEnabled,
  getStandaloneAssessmentPrice,
  getUserToken,
} from "../store/users/selectors";
import { errorStrong, errorWeak } from "../styles/colors";
import Button from "../components/Button";
import { device } from "../components/Devices";
import Loader from "../components/Loader";
import { ColorContext } from "../components/Bootstrap";
import FormField from "../components/FormField";
import LeadForm from "../components/LeadForm";
import { getUser, getRealm } from "../store/users/selectors";
import {
  RiskScale,
  getRiskClassName,
  getDisplayName,
  ResultElement,
  getDetailDisplayName,
} from "../components/ResultScreen";
import ContractorLogin from "../components/ContractorLogin";
import CrunchUpSells from "../components/CrunchUpSells";
import CrunchContact from "../components/CrunchContact";
import CrunchContactCredit from "../components/CrunchContactCredit";
import CrunchUpSellsCredit from "../components/CrunchUpSellsCredit";

const RiskHeading = styled.h1`
  font-size: 30px;
  text-align: center;
  @media ${device.laptop} {
    font-size: 34px;
  }

  margin-top: 0;
  font-weight: 300;
  color: #000;
`;
export const SubHeading = styled.h2`
  font-size: 30px;
  margin-top: 0;
  font-weight: bold;
  text-align: center;
`;

const Reason = styled.h3`
  font-weight: normal;
  text-align: center;
`;

const Wrapper = styled.div`
  margin: 10px;
  @media ${device.laptop} {
    width: 1000px;

    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  background-color: #fff;
`;
const AssessmentWrapper = styled.div`
  p {
    text-align: center;
  }
`;

const ErrorWrapper = styled.div`
  border: 1px solid ${errorStrong};
  padding: 10px;
  margin: 10px 0px;
  background-color: ${errorWeak};
`;

export const Products = styled.div`
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  @media ${device.laptop} {
  }
`;

export const Product = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #eee;
  border-radius: 6px;
  padding: 20px;
  margin: 10px;
  text-align: center;
  h3 {
    font-size: 26px;
  }
  p {
    line-height: 22px;
    flex-grow: 1;
    text-align: left;
  }
  position: relative;
  -webkit-box-shadow: 0px 0px 8px 2px rgba(219, 219, 219, 1);
  -moz-box-shadow: 0px 0px 8px 2px rgba(219, 219, 219, 1);
  box-shadow: 0px 0px 8px 2px rgba(219, 219, 219, 1);
  max-width: 330px;
  width: 100%;
  flex: 1 1 330px;
  @media ${device.laptop} {
    flex: 1;
  }

  .price {
    background-color: #fafafa;
    padding: 10px;
    text-align: center;
    position: relative;
    ${(props) => `color: ${props.mainColor}`}

    .price-component {
      font-size: 40px;
      display: inline-block;
      position: relative;

      &.discounted {
        text-decoration: line-through;
      }
    }

    .price-component:before {
      position: absolute;
      content: "£";
      position: absolute;
      top: 0px;
      left: -14px;
      font-size: 20px;
    }

    .price-component.no-after:after {
      display: none;
    }

    .vat {
      display: block;
    }
  }

  ul {
    margin: 10px 0 20px;
    li {
      text-align: left;
      margin-bottom: 10px;
    }
  }

  .banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    color: #fff;
    padding: 10px 0;
    font-size: 14px;
    text-align: center;
    background-color: #33adff;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`;

export const ProductButton = styled(Button)`
  margin-top: auto;
`;

const standardPadding = `
padding: 10px;
  @media ${device.laptop} {
    padding: 40px 40px;
  }`;

const ResultWrapper = styled.div`
  background-color: #eee;
  ${standardPadding}
`;

const ProductsWrapper = styled.div`
  // ${(props) => `background-color: ${props.mainColor}`}
  // mainColor={mainColor}
  ${standardPadding}
`;

const PaymentForm = styled.div`
  background-color: #eee;
  border-radius: 6px;
  padding: 20px;
  margin-bottom: 20px;

  h4 {
    font-size: 20px;
    margin: 0px 0px 10px;
    text-align: left;
  }

  p {
    text-align: left;
  }

  label {
    text-align: left;
    font-weight: bold;
  }

  .card-wrapper {
    background-color: #fff;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
`;

const BuyButton = styled(Button)`
  margin-top: 5px;
  background-color: #5dd564;
`;

const AssessmentSummary = styled.div`
  ${standardPadding}
  text-align: left;

  p {
    text-align: left;
  }

  h2 {
    font-size: 20px;
    @media ${device.laptop} {
      font-size: 30px;
    }
    text-align: left;
  }
`;

const Categories = styled.div`
  margin: 20px 0px;
`;

const Category = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid #ddd;
`;

const Downloads = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const DownloadButton = styled(Button)`
  margin: 0 10px 10px;
  width: 100%;
  @media ${device.laptop} {
    width: auto;
  }
`;

const PaymentError = styled.div`
  color: ${errorStrong};
  padding: 10px;
`;

export const Contact = styled.div`
  text-align: center;
  margin-top: 20px;
  font-size: 30px;

  padding: 0px 20px 40px;

  p {
    font-size: 16px;
  }
`;

const LoginArea = styled.div`
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;

  p {
    text-align: left;
  }
`;

function ContractorPartAssessment({ history, match, stripe }) {
  const dispatch = useDispatch();
  const showPreview = useSelector(getShowStandalonePreview);
  const allowSubs = useSelector(getStandaloneSubscriptionEnabled);
  const submissionError = useSelector(getSubmissionError);
  const loading = useSelector(getLoading);
  const assessmentResult = useSelector(getContractorResult);
  const assessmentToken = match.params.token;
  const mainColor = useContext(ColorContext);
  const [showBuyReport, setShowBuyReport] = useState(false);
  const [showBuySubscription, setShowBuySubscription] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState("");
  const purchaseError = useSelector(getPurchaseFailure);
  const purchaseLoading = useSelector(getPurchaseLoading);
  const [paymentMode, setPaymentMode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const urlRealm = useSelector((state) => getRealm(state, true));
  const realm = useSelector((state) => getRealm(state));
  const user = useSelector(getUser);
  const standalonePrice = useSelector(getStandaloneAssessmentPrice);
  const userToken = useSelector(getUserToken);

  const creditUsed = get(
    "engagement.assessment.stripe_reference",
    assessmentResult
  );

  const manualReviewRequiresPurchase = get(
    "engagement.assessment.manual_requires_payment",
    assessmentResult
  );

  useEffect(() => {
    if (showBuyReport) {
      setPaymentMode("REPORT");
    }
  }, [showBuyReport]);

  useEffect(() => {
    if (showBuySubscription) {
      setPaymentMode("SUBSCRIPTION");
    }
  }, [showBuySubscription]);

  useEffect(() => {
    dispatch(contractorLoadResult(assessmentToken));
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(contractorLoadResult(assessmentToken));
    }
  }, [user]);

  const getRiskHeading = (result) => {
    switch (result) {
      case "GOOD_PASS":
      case "PASS":
        return "Your assignment is outside IR35 - you can find out why below";

      case "BORDERLINE":
        return "IR35 may apply to your assignment - you can find out why below";
      case "FAIL":
      case "BAD_FAIL":
      case "OVERRIDE":
        return "Your assignment is inside IR35 - you can find out why below";
    }
  };

  const getDeterminationName = (resultName) => {
    switch (resultName) {
      case "GOOD_PASS":
      case "PASS":
      case "BORDER_LINE_PASS":
        return "Outside";
      case "BORDER_LINE_FAIL":
        return "Borderline Fail";
      case "BORDERLINE":
        return "Borderline";
      case "FAIL":
      case "BAD_FAIL":
      case "OVERRIDE":
        return "Inside";
    }
  };

  const handlePaymentResponse = async (response) => {
    if (!response) {
      return;
    }
    if (response.requires_action) {
      stripe
        .handleCardAction(response.payment_intent_client_secret)
        .then(async function (result) {
          if (result.error) {
          } else {
            let confirmation;
            switch (paymentMode) {
              case "REPORT":
                confirmation = await dispatch(
                  contractorConfirmPayment(
                    assessmentToken,
                    result.paymentIntent.id
                  )
                );
                break;
              case "SUBSCRIPTION":
                confirmation = await dispatch(
                  contractorConfirmSubscriptionPayment(
                    assessmentToken,
                    result.paymentIntent.id,
                    newPassword
                  )
                );
                break;
            }
            handlePaymentResponse(confirmation);
          }
        });
    } else {
      dispatch(contractorLoadResult(assessmentToken));

      switch (paymentMode) {
        case "REPORT":
          dispatch(contractorLoadResult(assessmentToken));
          break;
        case "SUBSCRIPTION":
          if (response && response.id) {
            dispatch(completeLogin(response));
          }
          break;
      }
    }
  };

  const submitForm = async () => {
    const { first_name, last_name, email } = assessmentResult.contractor;

    if (paymentMode === "SUBSCRIPTION" && newPassword.length < 8) {
      return setPasswordError(true);
    } else {
      setPasswordError(false);
    }

    stripe
      .createPaymentMethod("card", {
        billing_details: {
          name: `${first_name} ${last_name}`,
        },
      })
      .then(async function (completion) {
        let result;
        switch (paymentMode) {
          case "REPORT":
            result = await dispatch(
              contractorBuyReport(assessmentToken, completion.paymentMethod)
            );
            break;
          case "SUBSCRIPTION":
            result = await dispatch(
              contractorBuySubscription(
                assessmentToken,
                completion.paymentMethod,
                email,
                newPassword
              )
            );
            break;
        }
        if (result) {
          handlePaymentResponse(result);
        }
      });
  };

  const hasValidAccount = assessmentResult && assessmentResult.hasValidAccount;
  const reportingData = assessmentResult && assessmentResult.reportingData;
  const contractorRecord = assessmentResult && assessmentResult.contractor;
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  const underReview =
    assessmentResult &&
    assessmentResult.engagement &&
    assessmentResult.engagement.review &&
    assessmentResult.engagement.review.status === "OPEN";

  const hasOffer = assessmentResult.campaignAvailable;
  const offerAttempted = assessmentResult.campaignAttempted;
  let discountedPrice;
  let giftAmount;
  if (hasOffer) {
    if (assessmentResult.discountAmount) {
      let discount = Number(assessmentResult.discountAmount) / 100;
      discountedPrice =
        standalonePrice / 100 - (standalonePrice / 100) * discount;
    }
    if (assessmentResult.giftAmount) {
      giftAmount = assessmentResult.giftAmount.toFixed(2);
    }
  }

  return (
    <div>
      <Modal
        isOpen={selectedProduct !== ""}
        onRequestClose={() => {
          setSelectedProduct("");
          dispatch(clearLeadFlags());
        }}
        contentLabel="Product Enquiry"
        style={customStyles}
      >
        <LeadForm
          product={selectedProduct}
          email={contractorRecord && contractorRecord.email}
        />
      </Modal>
      <Header showMenu />
      <Content>
        <Wrapper>
          {submissionError && (
            <ErrorWrapper>
              <h2>Error</h2>
              <p>
                Something went wrong, please check your internet connection and
                try again
              </p>
            </ErrorWrapper>
          )}
          <AssessmentWrapper>
            {loading && <Loader text="Please wait" />}
            {!loading && (
              <>
                {(!assessmentResult.freeVersion || showPreview) && (
                  <ResultWrapper>
                    <RiskHeading>
                      {getRiskHeading(assessmentResult.result)}
                    </RiskHeading>
                    <RiskScale
                      className={getRiskClassName(assessmentResult.result)}
                      result={1 - assessmentResult.score / 5}
                    >
                      <div className="scale-label">
                        {getDeterminationName(assessmentResult.result)}
                      </div>
                    </RiskScale>
                  </ResultWrapper>
                )}

                {!assessmentResult.freeVersion && (
                  <ResultElement>
                    <AssessmentSummary>
                      {realm === "ir35/kingsbridge" && underReview && (
                        <>
                          <h2>Result is under review</h2>
                          <p>
                            We are reviewing your result because it was classed
                            as 'Borderline'. This means there are a number of
                            conflicting factors making up your result. As soon
                            as we have reviewed the result we will let you know
                            by email. We may contact you to ask for more
                            details.
                          </p>
                        </>
                      )}
                      <h2>What does this mean?</h2>
                      <p>{reportingData && reportingData.summaryText}</p>
                      <h2>More about your result</h2>
                      <p>
                        We've broken down your assessment result into the
                        different areas of the legislation:
                      </p>
                      <Categories>
                        {reportingData &&
                          reportingData.assessmentCategories.map((category) => (
                            <Category>
                              <div>{category.name}</div>
                              <div
                                className={getRiskClassName(category.result)}
                              >
                                {getDetailDisplayName(category.result)}
                              </div>
                            </Category>
                          ))}
                      </Categories>
                    </AssessmentSummary>
                    {!assessmentResult.freeVersion &&
                      ((realm === "ir35/kingsbridge" && userToken) ||
                        realm !== "ir35/kingsbridge") && (
                        <Downloads>
                          <DownloadButton
                            noFullWidth
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_BASE_URL}/userassessments/generate-contractor-pdf/${assessmentToken}/${urlRealm}?access_token=${userToken}`
                              )
                            }
                          >
                            <StyledFontAwesomeIcon icon={faFile} />
                            Download Report
                          </DownloadButton>
                          {assessmentResult.result !== "BORDERLINE" && (
                            <DownloadButton
                              noFullWidth
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_BASE_URL}/userassessments/generate-contractor-sds/${assessmentToken}/${urlRealm}?access_token=${userToken}`
                                )
                              }
                            >
                              <StyledFontAwesomeIcon icon={faFileInvoice} />{" "}
                              Download Result
                            </DownloadButton>
                          )}
                        </Downloads>
                      )}
                  </ResultElement>
                )}
                <ProductsWrapper>
                  {((assessmentResult.freeVersion && !hasValidAccount) ||
                    manualReviewRequiresPurchase) && (
                    <>
                      {manualReviewRequiresPurchase ? (
                        <SubHeading>Pay for manual review</SubHeading>
                      ) : (
                        <SubHeading>Buy your Report</SubHeading>
                      )}
                      <Reason>
                        {realm === "ir35/crunch" && (
                          <>
                            <strong>Why invest in a detailed report?</strong>{" "}
                            Explains your outcome and the reasons why with clear
                            recommendations and your own Status Result. Please
                            check the answers you provide carefully - you cannot
                            change an answer once submitted. If you purchase an
                            IR35 report and Status Result , it will be based on
                            your submitted answers and cannot be changed.
                          </>
                        )}
                        {realm === "ir35/kingsbridge" &&
                          !manualReviewRequiresPurchase && (
                            <>
                              <strong>Why invest in a detailed report?</strong>{" "}
                              It provides you with an accurate IR35 result that
                              will outline the strengths and weaknesses of your
                              engagement and provides specific feedback on your
                              answers. Please check the answers you provide
                              before purchasing as you cannot change them once
                              the review is submitted. If you receive an
                              ‘Indeterminate’ result our in-house IR35
                              consultancy will review your answers further and
                              be in touch within 5 working days to discuss your
                              result.
                            </>
                          )}
                        {realm === "ir35/kingsbridge" &&
                          manualReviewRequiresPurchase && (
                            <>
                              <strong>Pay for a manual review</strong> As your
                              result is borderline, you can pay a fee of £
                              {(standalonePrice / 100).toFixed(2)} to have a
                              manual review carried out by one of our
                              consultants
                            </>
                          )}
                      </Reason>
                      <Products>
                        <Product mainColor={mainColor}>
                          {manualReviewRequiresPurchase ? (
                            <h3>Manual Review</h3>
                          ) : (
                            <h3>Detailed IR35 report</h3>
                          )}
                          <div className="price">
                            <div
                              className={
                                discountedPrice
                                  ? "price-component discounted"
                                  : "price-component"
                              }
                            >
                              {(standalonePrice / 100).toFixed(2)}
                            </div>
                            {discountedPrice && (
                              <div>
                                <div className="price-component">
                                  {discountedPrice.toFixed(2)}
                                </div>
                              </div>
                            )}

                            <span className="vat">+VAT</span>
                            {offerAttempted && !hasOffer && (
                              <div>
                                Sorry, the offer code you provided is no longer
                                valid
                              </div>
                            )}
                            {discountedPrice && (
                              <div>You've qualified for a discount</div>
                            )}
                            {giftAmount && (
                              <div>
                                Your £{giftAmount} e-gift has been applied, this
                                will be sent to you per the terms and conditions
                                of the promotion
                              </div>
                            )}
                          </div>
                          {realm === "ir35/crunch" && (
                            <ul>
                              <li>
                                This IR35 Status Assessment will give you a
                                detailed breakdown of your result with
                                recommendations to reduce your risk of being
                                inside IR35
                              </li>
                              <li>
                                If you're making your own status assessment this
                                will help prove you've taken reasonable care to
                                determine the IR35 status of your assignment
                              </li>
                              <li>
                                Includes indicative Status Result document for
                                your information only – not to be used as a
                                legal document
                              </li>
                            </ul>
                          )}
                          {showBuyReport && (
                            <PaymentForm>
                              {manualReviewRequiresPurchase ? (
                                <h4>Purchase Manual Review</h4>
                              ) : (
                                <h4>Purchase Report</h4>
                              )}
                              {manualReviewRequiresPurchase && (
                                <p>
                                  Your card will be charged immediately and we
                                  will email you as soon as your updated result
                                  is available
                                </p>
                              )}
                              {!manualReviewRequiresPurchase && (
                                <p>
                                  Your card will be charged immediately and your
                                  report will be available straight away.
                                </p>
                              )}
                              <div className="card-wrapper">
                                <FormField
                                  name="card"
                                  label="Card details"
                                  stripe={true}
                                />
                              </div>
                              {purchaseError && (
                                <PaymentError>
                                  We were unable to take payment, please check
                                  your card details and try again
                                </PaymentError>
                              )}
                              <BuyButton
                                onClick={submitForm}
                                loading={purchaseLoading}
                              >
                                {manualReviewRequiresPurchase
                                  ? "Buy Manual Review"
                                  : "Buy Single report"}
                              </BuyButton>
                            </PaymentForm>
                          )}
                          <ProductButton
                            onClick={() => {
                              setShowBuySubscription(false);
                              setShowBuyReport(!showBuyReport);
                            }}
                          >
                            {showBuyReport
                              ? "Cancel"
                              : manualReviewRequiresPurchase
                              ? "Buy Review"
                              : "Buy report"}
                          </ProductButton>
                        </Product>
                        {allowSubs && (
                          <Product mainColor={mainColor}>
                            <div className="banner">Best Value</div>
                            <h3>Unlimited IR35 reports</h3>
                            <div className="price">
                              <div className="price-component">99.50</div>
                              <span className="vat">+VAT</span>
                            </div>
                            <ul>
                              <li>
                                Everything in the detailed report package, with
                                unlimited IR35 reports for 12 months for
                                multiple assignments
                              </li>
                              <li>
                                Go into contract negotiations with confidence
                                about your IR35 status
                              </li>
                              <li>
                                Includes detailed reports with recommendations
                                to help you improve your IR35 status and Status
                                Result to show to your client as proof of your
                                status
                              </li>
                            </ul>
                            {showBuySubscription && (
                              <PaymentForm>
                                <h4>Buy 12 months access</h4>
                                <p>
                                  Set a password for your account and enter your
                                  card details. Your card will be charged
                                  immediately and you'll have access straight
                                  away.
                                </p>
                                <p>
                                  Your username will be your email:
                                  <strong>
                                    {" "}
                                    {assessmentResult &&
                                      assessmentResult.contractor.email}
                                  </strong>{" "}
                                </p>
                                <div className="card-wrapper">
                                  <FormField
                                    name="password"
                                    type="password"
                                    label="Choose a password"
                                    changeMonitor={(value) =>
                                      setNewPassword(value)
                                    }
                                  />
                                  <FormField
                                    name="card"
                                    label="Card details"
                                    stripe={true}
                                  />
                                </div>
                                {passwordError && (
                                  <PaymentError>
                                    Your password needs to be at least 8
                                    characters long
                                  </PaymentError>
                                )}
                                {purchaseError && (
                                  <PaymentError>
                                    {purchaseError.error &&
                                    purchaseError.error ===
                                      "VALID_SUBSCRIPTION" ? (
                                      <p>
                                        You already have a subscription. Please
                                        login to view the full result of this
                                        assessment
                                      </p>
                                    ) : (
                                      <p>
                                        We were unable to take payment, please
                                        check your card details and try again
                                      </p>
                                    )}
                                  </PaymentError>
                                )}

                                <BuyButton
                                  onClick={submitForm}
                                  loading={purchaseLoading}
                                >
                                  Buy Now
                                </BuyButton>
                              </PaymentForm>
                            )}
                            <ProductButton
                              onClick={() => {
                                setShowBuyReport(false);
                                setShowBuySubscription(!showBuySubscription);
                              }}
                            >
                              {showBuySubscription
                                ? "Cancel"
                                : "Buy 12 months access"}
                            </ProductButton>
                          </Product>
                        )}
                      </Products>
                    </>
                  )}

                  {hasValidAccount && (
                    <LoginArea>
                      <h3>Login</h3>
                      <p>
                        You already have a subscription to our IR35 assessment
                        portal, so please login below to see the full result of
                        this assessment
                      </p>
                      <ContractorLogin noRedirect />
                    </LoginArea>
                  )}
                  {realm === "ir35/crunch" &&
                    creditUsed !== "CRUNCH_CREDIT" && (
                      <CrunchUpSells
                        setSelectedProduct={setSelectedProduct}
                        freeVersion={assessmentResult.freeVersion}
                      />
                    )}
                  {realm === "ir35/crunch" &&
                    creditUsed === "CRUNCH_CREDIT" && <CrunchUpSellsCredit />}
                </ProductsWrapper>
              </>
            )}
            {realm === "ir35/crunch" && creditUsed !== "CRUNCH_CREDIT" && (
              <CrunchContact />
            )}
            {realm === "ir35/crunch" && creditUsed === "CRUNCH_CREDIT" && (
              <CrunchContactCredit />
            )}
          </AssessmentWrapper>
        </Wrapper>
      </Content>
    </div>
  );
}

export default injectStripe(ContractorPartAssessment);
