import { get, getOr } from "lodash/fp";

export const getRegistrationErrors = (state) => {
  return get("users.registrationErrors", state);
};
export const getLoading = (state) => {
  return get("users.loading", state);
};
export const getResetComplete = (state) => {
  return get("users.resetComplete", state);
};
export const getChangePasswordComplete = (state) => {
  return get("users.changePasswordComplete", state);
};
export const getChangePasswordError = (state) => {
  return get("users.changePasswordError", state);
};
export const getLoginError = (state) => {
  return get("users.loginError", state);
};
export const getUser = (state) => {
  return get("users.userData", state);
};
export const getUserToken = (state) => {
  return get("users.userData.id", state);
};
export const getSubscriptionStatus = (state) => {
  return get("users.userData.userData.company.subscription_status", state);
};
export const getSubscription = (state) => {
  return get("users.userData.userData.company", state);
};
export const getIsInTrial = (state) => {
  return getSubscriptionStatus(state) === "trialing";
};
export const getIsCanceled = (state) => {
  return getSubscriptionStatus(state) === "canceled";
};
export const isBoostrapped = (state) => {
  return get("users.bootstrapped", state);
};
export const getIsWhiteLabel = (state) => {
  const userData = get("users.userData", state);
  const branding = get("users.branding", state);

  return !!branding;
};
export const getCompanyLogo = (state) => {
  const branding = get("users.branding", state);

  return branding ? branding.logo : null;
};
export const getPoweredByLogo = (state) => {
  const branding = get("users.branding", state);

  return branding ? branding.powered_logo : null;
};
export const getChildrenShareLeads = (state) => {
  const branding = get("users.branding", state);

  return branding ? branding.children_share_leads : null;
};
export const getPaymentType = (state) => {
  return get("users.userData.userData.company.payment_type", state);
};
export const getIsChild = (state) => {
  return get("users.userData.userData.company.isChild", state);
};
export const getCompanyUsers = (state, companyId) => {
  return getOr([], `${companyId}`, getOr({}, "users.companyUsers", state));
};
export const getUserById = (state, id, companyId) => {
  const users = getCompanyUsers(state, companyId);
  return users ? users.find((user) => user.id === id) : null;
};
export const getCompanyId = (state) => {
  return get("users.userData.userData.company.id", state);
};

export const getMainColor = (state) => {
  const branding = get("users.branding", state);
  const defaultColor = "#1a6eff";

  return branding && branding.cta_bg ? branding.cta_bg : defaultColor;
};

export const getShowStandalonePreview = (state) => {
  return get("users.branding.standalone_preview_result", state);
};
export const getStandaloneSubscriptionEnabled = (state) => {
  return get("users.branding.standalone_subscription", state);
};

export const getUsingCredits = (state) => {
  return get("users.userData.userData.company.using_credits", state);
};

export const getCreditBalance = (state) => {
  return get("users.userData.userData.company.credit_balance", state);
};

export const getCreditsFewerThan6 = (state) => {
  return (
    getUsingCredits(state) &&
    getCreditBalance(state) < 6 &&
    getCreditBalance(state) > 0
  );
};

export const getOutOfCredits = (state) => {
  return getUsingCredits(state) && getCreditBalance(state) === 0;
};

export const getCreditContactDetails = (state) => {
  let branding = get("users.branding", state),
    email = get("email", branding),
    phone = get("phone_number", branding);

  return email || phone
    ? `${email ? email : ""} ${email && phone ? "or" : ""} ${
        phone ? phone : ""
      }`
    : null;
};

export const getCurrentTermsVersion = (state) => {
  return get("users.userData.userData.terms.current_version", state);
};
export const getCurrentAcceptedTermsVersion = (state) => {
  return get("users.userData.userData.version_terms_accepted", state);
};

export const getNeedsToConfirmTerms = (state) => {
  const currentTerms = getCurrentTermsVersion(state);
  const accepted = getCurrentAcceptedTermsVersion(state);

  return currentTerms && currentTerms !== accepted;
};

export const getIsStandaloneCompany = (state) => {
  return get("users.userData.userData.company.contains_standalone", state);
};
export const getStandaloneAssessmentPrice = (state) => {
  return get("users.branding.standalone_assessment_price", state);
};

export const getIsCompanyReviewer = (state, companyId) => {
  const company = get("users.userData.userData.company", state);
  return Number(company.id) === Number(companyId) && company.manual_reviewer;
};

export const getHasStandaloneRole = (state) => {
  let roles = get("users.userData.userData.roles", state);

  return roles ? roles.find((role) => role.name === "standalone") : false;
};

export const getHasContractorRole = (state) => {
  let roles = get("users.userData.userData.roles", state);

  return roles ? roles.find((role) => role.name === "contractor") : false;
};

export const getHasCampaignRole = (state) => {
  let roles = get("users.userData.userData.roles", state);

  return roles ? roles.find((role) => role.name === "campaign") : false;
};

export const getHasSuperAdminRole = (state) => {
  let roles = get("users.userData.userData.roles", state);

  return roles ? roles.find((role) => role.name === "super_admin") : false;
};

export const getHasAdminRole = (state) => {
  let roles = get("users.userData.userData.roles", state);

  return roles
    ? roles.find((role) => role.name === "admin" || role.name === "super_admin")
    : false;
};

export const getHasManualReviewerRole = (state) => {
  let roles = get("users.userData.userData.roles", state);

  return roles ? roles.find((role) => role.name === "manual_reviewer") : false;
};
export const getHasCSTRole = (state) => {
  let roles = get("users.userData.userData.roles", state);

  return roles ? roles.find((role) => role.name === "cst") : false;
};

export const getHasClientAdminRole = (state) => {
  let roles = get("users.userData.userData.roles", state);

  return roles ? roles.find((role) => role.name === "client_admin") : false;
};

export const getCanViewSettings = (state) => {
  return (
    getHasSuperAdminRole(state) ||
    getHasAdminRole(state) ||
    getHasClientAdminRole(state) ||
    getHasCSTRole(state)
  );
};

export const getUserCompany = (state) => {
  return getOr({}, "users.userData.userData.company", state);
};

export const getUserId = (state) => {
  return getOr({}, "users.userData.userData.id", state);
};

export const getCompanyType = (state) => {
  let company = getUserCompany(state);

  return company.company_type;
};

export const getHasFeature = (state, feature) => {
  return getUserCompany(state)[feature];
};

export const getRealm = (state, encode) => {
  const value = get("users.branding.realm", state);

  return encode ? encodeURIComponent(value) : value;
};

export const getNearestAgency = (state) => {
  return get("users.userData.userData.nearestAgency", state);
};

export const getLogoSpace = (state) => {
  const branding = get("users.branding", state);

  return branding ? branding.logo_space : null;
};

export const getReviewerCompany = (state) => {
  const branding = get("users.branding", state);

  return branding ? branding.reviewerCompany : null;
};

export const getUploadLoading = (state) => {
  return get("users.uploadLoading", state);
};

export const getHideValidations = (state) => {
  return get("users.userData.userData.company.hide_validations", state);
};
export const getSearchTerm = (state) => {
  return get("users.userSearch", state);
};
export const getUserType = (state) => {
  return get("users.userType", state);
};
