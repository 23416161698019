import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Header from "../components/Header";
import Assessment from "../components/Assessment";
import { H1, Content } from "../components/Elements";
import {
  submitContractorAnswers,
  loadContractorPartAssessment,
} from "../store/assessment/actions";
import { device } from "../components/Devices";
import {
  getSubmissionError,
  getShouldShareLeads,
} from "../store/assessment/selectors";
import { errorStrong, errorWeak } from "../styles/colors";

const H1custom = styled(H1)`
  margin-top: 0 !important;
`;

const Wrapper = styled.div`
  padding: 10px;

  margin: 10px;
  @media ${device.laptop} {
    width: 800px;
    padding: 40px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }

  background-color: #fff;
`;
const AssessmentWrapper = styled.div`
  margin-top: 20px;

  p {
    text-align: center;
  }
`;

const ErrorWrapper = styled.div`
  border: 1px solid ${errorStrong};
  padding: 10px;
  margin: 10px 0px;
  background-color: ${errorWeak}l;
`;

function ContractorPartAssessment({ match }) {
  const dispatch = useDispatch();
  const [complete, setComplete] = useState(false);
  const [invalid, setInvalid] = useState(false);
  const submissionError = useSelector(getSubmissionError);
  const shouldShareLeads = useSelector(getShouldShareLeads);

  const sendAssessment = async (
    assessmentId,
    answers,
    reopen,
    notes,
    additionalAnswers
  ) => {
    const response = await dispatch(
      submitContractorAnswers(
        match.params.assessmenthash,
        answers.map((answer) => ({ id: answer })),
        "CONTRACTOR",
        notes,
        additionalAnswers
      )
    );
    if (response) {
      setComplete(true);
    }
  };

  const loadAssessment = async () => {
    var result = await dispatch(
      loadContractorPartAssessment(match.params.assessmenthash)
    );

    if ((result && result.invalid) || !result) {
      setInvalid(true);
    }
  };
  return (
    <div>
      <Header />
      <Content>
        <Wrapper>
          <H1custom>IR35 Assessment</H1custom>
          {submissionError && (
            <ErrorWrapper>
              <h2>Error</h2>
              <p>
                Something went wrong, please check your internet connection and
                try again
              </p>
            </ErrorWrapper>
          )}
          {invalid && (
            <>
              <h2>Not found</h2>
              <p>This link is no longer valid</p>
            </>
          )}
          {complete && !submissionError && (
            <>
              <h2>Thank you</h2>
              <p>
                We've got your answers, and once they've been collated we'll be
                in touch.
              </p>
            </>
          )}
          {!invalid && !complete && (
            <AssessmentWrapper>
              <p>
                You must answer the following questions as truthfully and
                accurately as possible. Please consider the reality of the
                engagement when answering the questions and not just what the
                contract says.
              </p>
              <Assessment
                wrapped={true}
                onSubmit={sendAssessment}
                exclude="CLIENT"
                removeHeadings={true}
                target="contractor"
                loadAssessment={loadAssessment}
                additionalConditional={{
                  "PART_AND_PARCEL_BUSINESS-INDEMNITY": shouldShareLeads,
                }}
              />
            </AssessmentWrapper>
          )}
        </Wrapper>
      </Content>
    </div>
  );
}

export default ContractorPartAssessment;
