import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOr } from "lodash/fp";
import styled from "styled-components";
import Header from "../components/Header";
import { H1, Content } from "../components/Elements";
import { loadRAFOffer } from "../store/campaigns/actions";
import { getRAFOffer, getCampaignLoading } from "../store/campaigns/selectors";
import Button from "../components/Button";
import { device } from "../components/Devices";
import Loader from "../components/Loader";
import { CopyToClipboard } from "react-copy-to-clipboard";

const H1custom = styled(H1)`
  margin-top: 0 !important;
`;

const Wrapper = styled.div`
  padding: 10px;

  margin: 10px;
  @media ${device.laptop} {
    width: 800px;
    padding: 40px;
    margin: auto;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  background-color: #fff;

  h2 {
    text-align: center;
  }

  p {
    text-align: center;
  }
`;

const Terms = styled.div`
  color: #aaa;
  border-top: 1px solid #aaa;
  margin-top: 40px;
  padding-top: 20px;
`;

const ShareWrap = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

const Share = styled.div`
  display: inline-block;
  padding: 5px;
  border: 1px dashed #000;
  font-size: 20px;
  text-align: center;
`;

const Buttons = styled.div`
  text-align: center;
`;

function ReferAFriend({ history }) {
  const dispatch = useDispatch();
  const offer = useSelector(getRAFOffer);
  const loading = useSelector(getCampaignLoading);
  const raf = getOr({}, "raf", offer);

  useEffect(() => {
    dispatch(loadRAFOffer());
  }, []);

  return (
    <div>
      <Header showMenu />
      <Content>
        <Wrapper>
          <H1custom>Refer a Friend</H1custom>
          {loading && <Loader text="Loading, please wait" />}
          <h2>{raf.raf_headline}</h2>
          <p>{raf.raf_explainer}</p>
          <h2>Share your link</h2>
          <ShareWrap>
            <Share>{offer.url}</Share>
          </ShareWrap>
          <Buttons>
            <CopyToClipboard text={offer.url}>
              <Button noFullWidth>Copy URL</Button>
            </CopyToClipboard>
          </Buttons>
          <Terms>{raf.raf_terms}</Terms>
        </Wrapper>
      </Content>
    </div>
  );
}

export default ReferAFriend;
