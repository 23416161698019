import React, { useState, useEffect, useImperativeHandle } from "react";
import styled from "styled-components";
import { faChevronUp, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AnimateHeight from "react-animate-height";

const Wrapper = styled.div`
  position: relative;
  border: 1px solid #eee;
  padding: 10px;
  border-radius: 6px;
  margin-bottom: 10px;

  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-top: 0;
  }
`;

const Minimise = styled.div`
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const HeaderWrap = styled.div`
  cursor: pointer;
  transition: opacity 200ms ease-in;

  &:hover {
    opacity: 0.6;
  }
`;

const MaxMin = React.forwardRef((props, ref) => {
  const { children, heading, headingLevel, initialMinimised = false } = props;

  const [minimised, setMinimised] = useState(initialMinimised);
  const [height, setHeight] = useState("auto");

  useImperativeHandle(ref, () => ({
    minimise: () => {
      setMinimised(true);
    },
    maximise: () => {
      setMinimised(false);
    },
  }));

  useEffect(() => {
    if (minimised) {
      setHeight(0);
    } else {
      setHeight("auto");
    }
  }, [minimised]);

  const renderHeading = () => {
    switch (headingLevel) {
      case "h1":
        return <h1>{heading}</h1>;

      case "h2":
        return <h2>{heading}</h2>;
      case "h3":
        return <h3>{heading}</h3>;
      case "h4":
        return <h4>{heading}</h4>;
    }
  };

  return (
    <Wrapper>
      <Minimise onClick={() => setMinimised(!minimised)}>
        <FontAwesomeIcon icon={minimised ? faChevronDown : faChevronUp} />
      </Minimise>
      <HeaderWrap onClick={() => setMinimised(!minimised)}>
        {renderHeading()}
      </HeaderWrap>
      <AnimateHeight
        id="example-panel"
        duration={500}
        height={height} // see props documentation below
      >
        {children}
      </AnimateHeight>
    </Wrapper>
  );
});

export default MaxMin;
