import { fetch } from "../fetchMiddleware";
import download from "downloadjs";

export const LOAD_CAMPAIGNS_REQUEST = "LOAD_CAMPAIGNS_REQUEST";
export const LOAD_CAMPAIGNS_SUCCESS = "LOAD_CAMPAIGNS_SUCCESS";
export const LOAD_CAMPAIGNS_FAILURE = "LOAD_CAMPAIGNS_FAILURE";

export const loadCampaigns = (archived = false) => async (dispatch) => {
  dispatch({ type: LOAD_CAMPAIGNS_REQUEST });

  let where = "?filter[where][archived]=false";
  if (archived) {
    where = "";
  }

  try {
    const { data: response } = await dispatch(
      fetch(`/Campaigns${where}`, {
        method: "GET",
      })
    );
    await dispatch({
      type: LOAD_CAMPAIGNS_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    console.log("E", error);
    await dispatch({
      type: LOAD_CAMPAIGNS_FAILURE,
      payload: error && error.response && error.response.data,
    });
  }
};

export const SAVE_CAMPAIGN_REQUEST = "SAVE_CAMPAIGN_REQUEST";
export const SAVE_CAMPAIGN_SUCCESS = "SAVE_CAMPAIGN_SUCCESS";
export const SAVE_CAMPAIGN_FAILURE = "SAVE_CAMPAIGN_FAILURE";

export const saveCampaign = (id, data) => async (dispatch) => {
  dispatch({ type: SAVE_CAMPAIGN_REQUEST });

  const url = id ? `/Campaigns/${id}` : "/Campaigns";
  const method = id ? `PATCH` : "POST";

  try {
    const { data: response } = await dispatch(
      fetch(url, {
        method: method,
        data,
      })
    );
    await dispatch({
      type: SAVE_CAMPAIGN_SUCCESS,
      payload: response,
      id,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: SAVE_CAMPAIGN_FAILURE,
      payload: error && error.data && error.data.error,
    });
  }
};

export const PUBLISH_CAMPAIGN_REQUEST = "PUBLISH_CAMPAIGN_REQUEST";
export const PUBLISH_CAMPAIGN_SUCCESS = "PUBLISH_CAMPAIGN_SUCCESS";
export const PUBLISH_CAMPAIGN_FAILURE = "PUBLISH_CAMPAIGN_FAILURE";

export const publishCampaign = (id) => async (dispatch) => {
  dispatch({ type: PUBLISH_CAMPAIGN_FAILURE });

  const url = `/Campaigns/publish/${id}`;
  const method = "GET";

  try {
    const { data: response } = await dispatch(
      fetch(url, {
        method: method,
      })
    );
    await dispatch({
      type: PUBLISH_CAMPAIGN_SUCCESS,
      payload: response,
      id,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: PUBLISH_CAMPAIGN_FAILURE,
      payload: error && error.data && error.data.error,
    });
  }
};

export const UNPUBLISH_CAMPAIGN_REQUEST = "UNPUBLISH_CAMPAIGN_REQUEST";
export const UNPUBLISH_CAMPAIGN_SUCCESS = "UNPUBLISH_CAMPAIGN_SUCCESS";
export const UNPUBLISH_CAMPAIGN_FAILURE = "UNPUBLISH_CAMPAIGN_FAILURE";

export const unpublishCampaign = (id) => async (dispatch) => {
  dispatch({ type: UNPUBLISH_CAMPAIGN_REQUEST });

  const url = `/Campaigns/unpublish/${id}`;
  const method = "GET";

  try {
    const { data: response } = await dispatch(
      fetch(url, {
        method: method,
      })
    );
    await dispatch({
      type: UNPUBLISH_CAMPAIGN_SUCCESS,
      payload: response,
      id,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: UNPUBLISH_CAMPAIGN_FAILURE,
      payload: error && error.data && error.data.error,
    });
  }
};

export const UPLOAD_URLS_REQUEST = "UPLOAD_URLS_REQUEST";
export const UPLOAD_URLS_SUCCESS = "UPLOAD_URLS_SUCCESS";
export const UPLOAD_URLS_FAILURE = "UPLOAD_URLS_FAILURE";

export const uploadURLs = (id, file) => async (dispatch) => {
  dispatch({ type: UPLOAD_URLS_REQUEST });

  const url = `/Campaigns/generate-urls/${id}`;
  const method = "POST";

  try {
    const { data: response } = await dispatch(
      fetch(url, {
        method: method,
        data: file,
      })
    );
    await dispatch({
      type: UPLOAD_URLS_SUCCESS,
      payload: response,
      id,
    });
    download(response, "file.csv");
    return response;
  } catch (error) {
    await dispatch({
      type: UPLOAD_URLS_FAILURE,
      payload: error && error.data && error.data.error,
    });
  }
};

export const LOAD_CAMPAIGN_DATA_REQUEST = "LOAD_CAMPAIGN_DATA_REQUEST";
export const LOAD_CAMPAIGN_DATA_SUCCESS = "LOAD_CAMPAIGN_DATA_SUCCESS";
export const LOAD_CAMPAIGN_DATA_FAILURE = "LOAD_CAMPAIGN_DATA_FAILURE";

export const loadCampaignData = (id) => async (dispatch) => {
  dispatch({ type: LOAD_CAMPAIGN_DATA_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Campaigns/data/${id}`, {
        method: "GET",
      })
    );
    await dispatch({
      type: LOAD_CAMPAIGN_DATA_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: LOAD_CAMPAIGN_DATA_FAILURE,
      payload: error && error.response && error.response.data,
    });
  }
};

export const LOAD_RAF_REQUEST = "LOAD_RAF_REQUEST";
export const LOAD_RAF_SUCCESS = "LOAD_RAF_SUCCESS";
export const LOAD_RAF_FAILURE = "LOAD_RAF_FAILURE";

export const loadRAFOffer = () => async (dispatch) => {
  dispatch({ type: LOAD_RAF_REQUEST });

  try {
    const { data: response } = await dispatch(
      fetch(`/Campaigns/get-current-raf`, {
        method: "GET",
      })
    );
    await dispatch({
      type: LOAD_RAF_SUCCESS,
      payload: response,
    });
    return response;
  } catch (error) {
    await dispatch({
      type: LOAD_RAF_FAILURE,
      payload: error && error.response && error.response.data,
    });
  }
};
