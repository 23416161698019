import React from "react";
import styled from "styled-components";
import { Contact } from "../pages/ContractorMainAssessmentResult";

function CrunchContactCredit() {
  return (
    <Contact>
      Any questions? Call us on 033 3311 8008 or email{" "}
      <a href="mailto:support@crunch.co.uk">support@crunch.co.uk</a>.
      <p>
        We're available from 9am - 5:30pm Monday to Friday. If you've contacted
        us out of hours we'll get back to you the next working day. Find out how{" "}
        <a href="https://www.crunch.co.uk/ir35/" target="_blank">
          IR35 could affect you
        </a>{" "}
        .
      </p>
    </Contact>
  );
}

export default CrunchContactCredit;
