import styled from "styled-components";
import { device } from "./Devices";

export default styled.form`
  border-radius: 4px;
  ${(props) =>
    props.internal
      ? `background-color: #fff; margin: 0px 0px;padding: 0px 0px;`
      : `background-color: #eee;border: 1px solid #ccc;padding: 0px 0px;`}

  flex-basis: 100%;
  @media ${device.laptop} {
    flex: 1.5;
    ${(props) =>
      props.internal
        ? `background-color: #fff; margin: 0px 20px;padding: 20px 20px;`
        : `background-color: #eee;border: 1px solid #ccc;padding: 40px 20px;`}
  }

  ${(props) =>
    props.noPad ? `margin: 0px 0px!important;padding: 0px 0px!important;` : ``}

  h2 {
    font-weight: 400;
  }
`;
