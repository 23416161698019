import React from "react";
import { Redirect } from "react-router-dom";

function CampaignRedirect({ match }) {
  sessionStorage.setItem("campaign", match.params.campaign_code);
  sessionStorage.setItem("passedReference", match.params.referer);

  return (
    <Redirect
      to={`/contractor-assessment/0?campaign=${match.params.campaign_code}`}
    />
  );
}

export default CampaignRedirect;
