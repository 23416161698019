import React, { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { confirmAlert } from "react-confirm-alert";
import { getIsSelected } from "../../store/assessment/selectors";
import { addAnswer, removeAnswer } from "../../store/assessment/actions";
import { ColorContext } from "../../components/Bootstrap";

const AnswerElement = styled.div`
  ${(props) => (props.isReviewer ? "cursor: not-allowed" : "")}

  background-color: #f4f5f5;
  padding: 15px 20px 15px 60px;
  border-radius: 30px;
  line-height: 20px;
  margin-right: 20px;
  position: relative;
  &:before {
    border-radius: 50%;
    background-color: #ddd;
    content: "";
    top: 0;
    left: 3px;
    width: 45px;
    height: 45px;
    position: absolute;
    bottom: 0;
    margin: auto;
  }

  &:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    /* display: inline-block; */
    padding: 6px 3px;
    -webkit-transform: rotate(-315deg);
    -ms-transform: rotate(-315deg);
    transform: rotate(-315deg);
    left: 21px;
    height: 12px;
  }

  &.selected:before {
    ${(props) => `background-color: ${props.mainColor};`}
  }

  &.selected:after {
    border-color: #fff;
  }
`;

function Answer({ answer, isReviewer }) {
  const dispatch = useDispatch();
  const selected = useSelector((state) => getIsSelected(state, answer.id));
  const mainColor = useContext(ColorContext);
  const handleValueChange = (newValue) => {
    if (newValue) {
      dispatch(addAnswer(answer.id));
      if (answer.override) {
        const options = {
          title: "Are you sure?",
          message:
            "This answer would ensure IR35 applies - only continue if this is the case",
          buttons: [
            {
              label: "OK",
            },
          ],
        };
        confirmAlert(options);
      }
    } else {
      dispatch(removeAnswer(answer.id));
    }
  };

  return (
    <AnswerElement
      mainColor={mainColor}
      className={selected ? "selected" : ""}
      isReviewer={isReviewer}
      onClick={() => {
        if (!isReviewer) {
          handleValueChange(!selected);
        }
      }}
    >
      {answer.title}
    </AnswerElement>
  );
}

export default Answer;
