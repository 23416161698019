export const mainColor = "#1a6eff";
export const lightColor = "#ccdfff";
export const contrastColor = "#14d9d6";
export const contrastFontColor = "#0a5c5b";
export const errorStrong = "#C52C30";
export const errorWeak = "#ffccd7";
export const successWeak = "#d7ffdb";
export const successStrong = "#4E8F27";
export const undeterminedStrong = "#F4B536";
export const errorStronger = "#881E21";
export const errorScale2 = "#E17B36";
export const errorScale3 = "#F2AC37";
export const errorScale4 = "#CAAB33";
export const errorScale5 = "#909E2E";
export const warnBG = "#ece4bb";
export const warnCopy = "#665a19";
export const progressBar = "#FFBB00";
export const progressBarBase = "#F4F5F5";
export const reviewDark = "#59adfc";
export const reviewLight = "#dbeeff";
export const distributeDark = "#66b941";
export const distributeLight = "#c9e0bf";
export const appealDark = "#b941b8";
export const appealLight = "#dea9dd";
