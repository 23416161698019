import { get, getOr } from "lodash/fp";

export const getTags = (state) => {
  return getOr([], "tags.list", state);
};

export const getTagsLoading = (state) => {
  return get("tags.loading", state);
};

export const getTagsError = (state) => {
  return get("tags.error", state);
};

export const getTagById = (id, state) => {
  return getOr([], "tags.list", state).find((tag) => tag.id === id);
};

export const getTagLoading = (id, state) => {
  return getOr({}, "tags.linkingLoading", state)[id];
};

export const getTagsForEntity = (entityType, entityId, state) => {
  return get(`tags.tagsForEntities.${entityType}.${entityId}`, state);
};

export const getSearches = (state) => {
  return getOr([], `tags.searches`, state);
};

export const getSearchesLoading = (state) => {
  return get(`tags.searchesLoading`, state);
};

export const getReportingEntityLoading = (entityType, state) => {
  return get(`tags.reportsLoading.${entityType}`, state);
};

export const getReportingEntityCount = (entityType, state) => {
  return get(`tags.reportsCount.${entityType}`, state);
};

export const getReportingEntity = (entityType, state) => {
  return get(`tags.reports.${entityType}`, state);
};
