import { createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { users } from "./users/reducer";
import { contractors } from "./contractors/reducer";
import { assessment } from "./assessment/reducer";
import { clients } from "./clients/reducer";
import { review } from "./review/reducer";
import { sds } from "./sds/reducer";
import { tags } from "./tags/reducer";
import { campaigns } from "./campaigns/reducer";
import fetchMiddleware from "./fetchMiddleware";
import { logoutWithoutRequest } from "./users/actions";

const reducers = combineReducers({
  users,
  contractors,
  assessment,
  clients,
  review,
  sds,
  tags,
  campaigns,
});

const store = createStore(
  reducers,
  applyMiddleware(
    thunk,
    fetchMiddleware({
      onError: (body) => (dispatch) => {
        const is401 = body && body.error && body.error.statusCode === 401;
        if (is401) {
          dispatch(logoutWithoutRequest());
        }
      },
    })
  )
);

export default store;
